/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Determined API (Beta)
 * Determined helps deep learning teams train models more quickly, easily share GPU resources, and effectively collaborate. Determined allows deep learning engineers to focus on building and training models at scale, without needing to worry about DevOps or writing custom code for common tasks like fault tolerance or experiment tracking.  You can think of Determined as a platform that bridges the gap between tools like TensorFlow and PyTorch --- which work great for a single researcher with a single GPU --- to the challenges that arise when doing deep learning at scale, as teams, clusters, and data sets all increase in size.
 *
 * OpenAPI spec version: 0.1
 * Contact: community@determined.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "http://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * The current state of the checkpoint.   - STATE_UNSPECIFIED: The state of the checkpoint is unknown.  - STATE_ACTIVE: The checkpoint is in an active state.  - STATE_COMPLETED: The checkpoint is persisted to checkpoint storage.  - STATE_ERROR: The checkpoint errored.  - STATE_DELETED: The checkpoint has been deleted.
 * @export
 * @enum {string}
 */
export enum Determinedcheckpointv1State {
    UNSPECIFIED = <any> 'STATE_UNSPECIFIED',
    ACTIVE = <any> 'STATE_ACTIVE',
    COMPLETED = <any> 'STATE_COMPLETED',
    ERROR = <any> 'STATE_ERROR',
    DELETED = <any> 'STATE_DELETED'
}

/**
 * The current state of the container.   - STATE_UNSPECIFIED: The container state is unknown.  - STATE_ASSIGNED: The container has been assigned to an agent but has not started yet.  - STATE_PULLING: The container's base image is being pulled from the Docker registry.  - STATE_STARTING: The image has been built and the container is being started, but the service in the container is not ready yet.  - STATE_RUNNING: The service in the container is able to accept requests.  - STATE_TERMINATED: The container has completely exited or the container has been aborted prior to getting assigned.
 * @export
 * @enum {string}
 */
export enum Determinedcontainerv1State {
    UNSPECIFIED = <any> 'STATE_UNSPECIFIED',
    ASSIGNED = <any> 'STATE_ASSIGNED',
    PULLING = <any> 'STATE_PULLING',
    STARTING = <any> 'STATE_STARTING',
    RUNNING = <any> 'STATE_RUNNING',
    TERMINATED = <any> 'STATE_TERMINATED'
}

/**
 * The type of the Device.   - TYPE_UNSPECIFIED: An unspecified device type.  - TYPE_CPU: A CPU device.  - TYPE_CUDA: CUDA device.  - TYPE_ROCM: ROCM.
 * @export
 * @enum {string}
 */
export enum Determineddevicev1Type {
    UNSPECIFIED = <any> 'TYPE_UNSPECIFIED',
    CPU = <any> 'TYPE_CPU',
    CUDA = <any> 'TYPE_CUDA',
    ROCM = <any> 'TYPE_ROCM'
}

/**
 * The current state of the experiment.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
 * @export
 * @enum {string}
 */
export enum Determinedexperimentv1State {
    UNSPECIFIED = <any> 'STATE_UNSPECIFIED',
    ACTIVE = <any> 'STATE_ACTIVE',
    PAUSED = <any> 'STATE_PAUSED',
    STOPPINGCOMPLETED = <any> 'STATE_STOPPING_COMPLETED',
    STOPPINGCANCELED = <any> 'STATE_STOPPING_CANCELED',
    STOPPINGERROR = <any> 'STATE_STOPPING_ERROR',
    COMPLETED = <any> 'STATE_COMPLETED',
    CANCELED = <any> 'STATE_CANCELED',
    ERROR = <any> 'STATE_ERROR',
    DELETED = <any> 'STATE_DELETED',
    DELETING = <any> 'STATE_DELETING',
    DELETEFAILED = <any> 'STATE_DELETE_FAILED',
    STOPPINGKILLED = <any> 'STATE_STOPPING_KILLED',
    QUEUED = <any> 'STATE_QUEUED',
    PULLING = <any> 'STATE_PULLING',
    STARTING = <any> 'STATE_STARTING',
    RUNNING = <any> 'STATE_RUNNING'
}

/**
 * Job state.   - STATE_UNSPECIFIED: Unspecified state.  - STATE_QUEUED: Job is queued and waiting to be schedlued.  - STATE_SCHEDULED: Job is scheduled.  - STATE_SCHEDULED_BACKFILLED: Job is scheduled as a backfill.
 * @export
 * @enum {string}
 */
export enum Determinedjobv1State {
    UNSPECIFIED = <any> 'STATE_UNSPECIFIED',
    QUEUED = <any> 'STATE_QUEUED',
    SCHEDULED = <any> 'STATE_SCHEDULED',
    SCHEDULEDBACKFILLED = <any> 'STATE_SCHEDULED_BACKFILLED'
}

/**
 * Job type.   - TYPE_UNSPECIFIED: Unspecified state.  - TYPE_EXPERIMENT: Experiement Job.  - TYPE_NOTEBOOK: Jupyter Notebook Job.  - TYPE_TENSORBOARD: TensorBoard Job.  - TYPE_SHELL: Shell Job.  - TYPE_COMMAND: Command Job.  - TYPE_CHECKPOINT_GC: CheckpointGC Job.
 * @export
 * @enum {string}
 */
export enum Determinedjobv1Type {
    UNSPECIFIED = <any> 'TYPE_UNSPECIFIED',
    EXPERIMENT = <any> 'TYPE_EXPERIMENT',
    NOTEBOOK = <any> 'TYPE_NOTEBOOK',
    TENSORBOARD = <any> 'TYPE_TENSORBOARD',
    SHELL = <any> 'TYPE_SHELL',
    COMMAND = <any> 'TYPE_COMMAND',
    CHECKPOINTGC = <any> 'TYPE_CHECKPOINT_GC'
}

/**
 * The current state of the task.   - STATE_UNSPECIFIED: The task state is unknown.  - STATE_PULLING: The task's base image is being pulled from the Docker registry.  - STATE_STARTING: The image has been pulled and the task is being started, but the task is not ready yet.  - STATE_RUNNING: The service in the task is running.  - STATE_TERMINATED: The task has exited or has been aborted.  - STATE_TERMINATING: The task has begun to exit.  - STATE_WAITING: The task is waiting on something to complete.  - STATE_QUEUED: Additional state to cover queueing operations.
 * @export
 * @enum {string}
 */
export enum Determinedtaskv1State {
    UNSPECIFIED = <any> 'STATE_UNSPECIFIED',
    PULLING = <any> 'STATE_PULLING',
    STARTING = <any> 'STATE_STARTING',
    RUNNING = <any> 'STATE_RUNNING',
    TERMINATED = <any> 'STATE_TERMINATED',
    TERMINATING = <any> 'STATE_TERMINATING',
    WAITING = <any> 'STATE_WAITING',
    QUEUED = <any> 'STATE_QUEUED'
}

/**
 * - STATE_UNSPECIFIED: The trial is in an unspecified state.  - STATE_ACTIVE: The trial is in an active state.  - STATE_PAUSED: The trial is in a paused state  - STATE_STOPPING_CANCELED: The trial is canceled and is shutting down.  - STATE_STOPPING_KILLED: The trial is killed and is shutting down.  - STATE_STOPPING_COMPLETED: The trial is completed and is shutting down.  - STATE_STOPPING_ERROR: The trial is errored and is shutting down.  - STATE_CANCELED: The trial is canceled and is shut down.  - STATE_COMPLETED: The trial is completed and is shut down.  - STATE_ERROR: The trial is errored and is shut down.
 * @export
 * @enum {string}
 */
export enum Determinedtrialv1State {
    UNSPECIFIED = <any> 'STATE_UNSPECIFIED',
    ACTIVE = <any> 'STATE_ACTIVE',
    PAUSED = <any> 'STATE_PAUSED',
    STOPPINGCANCELED = <any> 'STATE_STOPPING_CANCELED',
    STOPPINGKILLED = <any> 'STATE_STOPPING_KILLED',
    STOPPINGCOMPLETED = <any> 'STATE_STOPPING_COMPLETED',
    STOPPINGERROR = <any> 'STATE_STOPPING_ERROR',
    CANCELED = <any> 'STATE_CANCELED',
    COMPLETED = <any> 'STATE_COMPLETED',
    ERROR = <any> 'STATE_ERROR'
}

/**
 * Series of data points for an experiment trial.
 * @export
 * @interface ExpCompareTrialsSampleResponseExpTrial
 */
export interface ExpCompareTrialsSampleResponseExpTrial {
    /**
     * The id of the trial.
     * @type {number}
     * @memberof ExpCompareTrialsSampleResponseExpTrial
     */
    trialId: number;
    /**
     * Hyperparamters values for this specific trial.
     * @type {any}
     * @memberof ExpCompareTrialsSampleResponseExpTrial
     */
    hparams: any;
    /**
     * Data related to a trial.
     * @type {Array<V1DataPoint>}
     * @memberof ExpCompareTrialsSampleResponseExpTrial
     */
    data: Array<V1DataPoint>;
    /**
     * 
     * @type {number}
     * @memberof ExpCompareTrialsSampleResponseExpTrial
     */
    experimentId: number;
}

/**
 * Hyperparameter importance as computed with respect for one specific metric.
 * @export
 * @interface GetHPImportanceResponseMetricHPImportance
 */
export interface GetHPImportanceResponseMetricHPImportance {
    /**
     * A map between hyperparameter names and their relative importance.
     * @type {{ [key: string]: number; }}
     * @memberof GetHPImportanceResponseMetricHPImportance
     */
    hpImportance?: { [key: string]: number; };
    /**
     * The approximate portion of the experiment that was complete when the data was read.
     * @type {number}
     * @memberof GetHPImportanceResponseMetricHPImportance
     */
    experimentProgress?: number;
    /**
     * A description of why computation failed. Empty unless the state is (or was) 'failed'.
     * @type {string}
     * @memberof GetHPImportanceResponseMetricHPImportance
     */
    error?: string;
    /**
     * Whether or not a request to compute results for this metric is queued.
     * @type {boolean}
     * @memberof GetHPImportanceResponseMetricHPImportance
     */
    pending?: boolean;
    /**
     * Whether or not results for this metric are currently being computed.
     * @type {boolean}
     * @memberof GetHPImportanceResponseMetricHPImportance
     */
    inProgress?: boolean;
}

/**
 * Filter workloads with training, validation, and checkpoint information.   - FILTER_OPTION_UNSPECIFIED: Any workload.  - FILTER_OPTION_CHECKPOINT: Only workloads with an associated checkpoint.  - FILTER_OPTION_VALIDATION: Only validation workloads.  - FILTER_OPTION_CHECKPOINT_OR_VALIDATION: Only validation workloads or ones with an associated checkpoint.
 * @export
 * @enum {string}
 */
export enum GetTrialWorkloadsRequestFilterOption {
    UNSPECIFIED = <any> 'FILTER_OPTION_UNSPECIFIED',
    CHECKPOINT = <any> 'FILTER_OPTION_CHECKPOINT',
    VALIDATION = <any> 'FILTER_OPTION_VALIDATION',
    CHECKPOINTORVALIDATION = <any> 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION'
}

/**
 * https://developers.google.com/protocol-buffers/docs/reference/java/com/google/protobuf/Any
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    /**
     * https://developers.google.com/protocol-buffers/docs/reference/java/com/google/protobuf/Any
     * @type {string}
     * @memberof ProtobufAny
     */
    typeUrl?: string;
    /**
     * Must be a valid serialized protocol buffer of the above specified type.
     * @type {string}
     * @memberof ProtobufAny
     */
    value?: string;
}

/**
 * `NullValue` is a singleton enumeration to represent the null value for the `Value` type union.   The JSON representation for `NullValue` is JSON `null`.   - NULL_VALUE: Null value.
 * @export
 * @enum {string}
 */
export enum ProtobufNullValue {
    NULLVALUE = <any> 'NULL_VALUE'
}

/**
 * 
 * @export
 * @interface RuntimeError
 */
export interface RuntimeError {
    /**
     * 
     * @type {string}
     * @memberof RuntimeError
     */
    error?: string;
    /**
     * 
     * @type {number}
     * @memberof RuntimeError
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof RuntimeError
     */
    message?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof RuntimeError
     */
    details?: Array<ProtobufAny>;
}

/**
 * 
 * @export
 * @interface RuntimeStreamError
 */
export interface RuntimeStreamError {
    /**
     * 
     * @type {number}
     * @memberof RuntimeStreamError
     */
    grpcCode?: number;
    /**
     * 
     * @type {number}
     * @memberof RuntimeStreamError
     */
    httpCode?: number;
    /**
     * 
     * @type {string}
     * @memberof RuntimeStreamError
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof RuntimeStreamError
     */
    httpStatus?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof RuntimeStreamError
     */
    details?: Array<ProtobufAny>;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1ExpCompareMetricNamesResponse
 */
export interface StreamResultOfV1ExpCompareMetricNamesResponse {
    /**
     * 
     * @type {V1ExpCompareMetricNamesResponse}
     * @memberof StreamResultOfV1ExpCompareMetricNamesResponse
     */
    result?: V1ExpCompareMetricNamesResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1ExpCompareMetricNamesResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1ExpCompareTrialsSampleResponse
 */
export interface StreamResultOfV1ExpCompareTrialsSampleResponse {
    /**
     * 
     * @type {V1ExpCompareTrialsSampleResponse}
     * @memberof StreamResultOfV1ExpCompareTrialsSampleResponse
     */
    result?: V1ExpCompareTrialsSampleResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1ExpCompareTrialsSampleResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1GetHPImportanceResponse
 */
export interface StreamResultOfV1GetHPImportanceResponse {
    /**
     * 
     * @type {V1GetHPImportanceResponse}
     * @memberof StreamResultOfV1GetHPImportanceResponse
     */
    result?: V1GetHPImportanceResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1GetHPImportanceResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1GetTrialProfilerAvailableSeriesResponse
 */
export interface StreamResultOfV1GetTrialProfilerAvailableSeriesResponse {
    /**
     * 
     * @type {V1GetTrialProfilerAvailableSeriesResponse}
     * @memberof StreamResultOfV1GetTrialProfilerAvailableSeriesResponse
     */
    result?: V1GetTrialProfilerAvailableSeriesResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1GetTrialProfilerAvailableSeriesResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1GetTrialProfilerMetricsResponse
 */
export interface StreamResultOfV1GetTrialProfilerMetricsResponse {
    /**
     * 
     * @type {V1GetTrialProfilerMetricsResponse}
     * @memberof StreamResultOfV1GetTrialProfilerMetricsResponse
     */
    result?: V1GetTrialProfilerMetricsResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1GetTrialProfilerMetricsResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1MasterLogsResponse
 */
export interface StreamResultOfV1MasterLogsResponse {
    /**
     * 
     * @type {V1MasterLogsResponse}
     * @memberof StreamResultOfV1MasterLogsResponse
     */
    result?: V1MasterLogsResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1MasterLogsResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1MetricBatchesResponse
 */
export interface StreamResultOfV1MetricBatchesResponse {
    /**
     * 
     * @type {V1MetricBatchesResponse}
     * @memberof StreamResultOfV1MetricBatchesResponse
     */
    result?: V1MetricBatchesResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1MetricBatchesResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1MetricNamesResponse
 */
export interface StreamResultOfV1MetricNamesResponse {
    /**
     * 
     * @type {V1MetricNamesResponse}
     * @memberof StreamResultOfV1MetricNamesResponse
     */
    result?: V1MetricNamesResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1MetricNamesResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1TaskLogsFieldsResponse
 */
export interface StreamResultOfV1TaskLogsFieldsResponse {
    /**
     * 
     * @type {V1TaskLogsFieldsResponse}
     * @memberof StreamResultOfV1TaskLogsFieldsResponse
     */
    result?: V1TaskLogsFieldsResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1TaskLogsFieldsResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1TaskLogsResponse
 */
export interface StreamResultOfV1TaskLogsResponse {
    /**
     * 
     * @type {V1TaskLogsResponse}
     * @memberof StreamResultOfV1TaskLogsResponse
     */
    result?: V1TaskLogsResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1TaskLogsResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1TrialLogsFieldsResponse
 */
export interface StreamResultOfV1TrialLogsFieldsResponse {
    /**
     * 
     * @type {V1TrialLogsFieldsResponse}
     * @memberof StreamResultOfV1TrialLogsFieldsResponse
     */
    result?: V1TrialLogsFieldsResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1TrialLogsFieldsResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1TrialLogsResponse
 */
export interface StreamResultOfV1TrialLogsResponse {
    /**
     * 
     * @type {V1TrialLogsResponse}
     * @memberof StreamResultOfV1TrialLogsResponse
     */
    result?: V1TrialLogsResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1TrialLogsResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1TrialsSampleResponse
 */
export interface StreamResultOfV1TrialsSampleResponse {
    /**
     * 
     * @type {V1TrialsSampleResponse}
     * @memberof StreamResultOfV1TrialsSampleResponse
     */
    result?: V1TrialsSampleResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1TrialsSampleResponse
     */
    error?: RuntimeStreamError;
}

/**
 * 
 * @export
 * @interface StreamResultOfV1TrialsSnapshotResponse
 */
export interface StreamResultOfV1TrialsSnapshotResponse {
    /**
     * 
     * @type {V1TrialsSnapshotResponse}
     * @memberof StreamResultOfV1TrialsSnapshotResponse
     */
    result?: V1TrialsSnapshotResponse;
    /**
     * 
     * @type {RuntimeStreamError}
     * @memberof StreamResultOfV1TrialsSnapshotResponse
     */
    error?: RuntimeStreamError;
}

/**
 * The reason for an early exit.   - EXITED_REASON_UNSPECIFIED: Zero-value (not allowed).  - EXITED_REASON_INVALID_HP: Indicates the trial exited due to an invalid hyperparameter.  - EXITED_REASON_INIT_INVALID_HP: Indicates the trial exited due to an invalid hyperparameter in the trial init.
 * @export
 * @enum {string}
 */
export enum TrialEarlyExitExitedReason {
    UNSPECIFIED = <any> 'EXITED_REASON_UNSPECIFIED',
    INVALIDHP = <any> 'EXITED_REASON_INVALID_HP',
    INITINVALIDHP = <any> 'EXITED_REASON_INIT_INVALID_HP'
}

/**
 * Specifies a ranking for trials within their experiment.
 * @export
 * @interface TrialFiltersRankWithinExp
 */
export interface TrialFiltersRankWithinExp {
    /**
     * 
     * @type {V1TrialSorter}
     * @memberof TrialFiltersRankWithinExp
     */
    sorter?: V1TrialSorter;
    /**
     * 
     * @type {number}
     * @memberof TrialFiltersRankWithinExp
     */
    rank?: number;
}

/**
 * To distinguish the 2 different categories of metrics.   - PROFILER_METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - PROFILER_METRIC_TYPE_SYSTEM: For systems metrics, like GPU utilization or memory.  - PROFILER_METRIC_TYPE_TIMING: For timing metrics, like how long a backwards pass or getting a batch from the dataloader took.  - PROFILER_METRIC_TYPE_MISC: For other miscellaneous metrics.
 * @export
 * @enum {string}
 */
export enum TrialProfilerMetricLabelsProfilerMetricType {
    UNSPECIFIED = <any> 'PROFILER_METRIC_TYPE_UNSPECIFIED',
    SYSTEM = <any> 'PROFILER_METRIC_TYPE_SYSTEM',
    TIMING = <any> 'PROFILER_METRIC_TYPE_TIMING',
    MISC = <any> 'PROFILER_METRIC_TYPE_MISC'
}

/**
 * - NAMESPACE_UNSPECIFIED: Namespace for columns in the trials table  - NAMESPACE_HPARAMS: Namespace corrspondings to trials->hparams  - NAMESPACE_TRAINING_METRICS: Namespace corrspondings to steps->avg_metrics  - NAMESPACE_VALIDATION_METRICS: Namespace corrspondings to validations->validation_metrics
 * @export
 * @enum {string}
 */
export enum TrialSorterNamespace {
    UNSPECIFIED = <any> 'NAMESPACE_UNSPECIFIED',
    HPARAMS = <any> 'NAMESPACE_HPARAMS',
    TRAININGMETRICS = <any> 'NAMESPACE_TRAINING_METRICS',
    VALIDATIONMETRICS = <any> 'NAMESPACE_VALIDATION_METRICS'
}

/**
 * Trial is a set of workloads and are exploring a determined set of hyperparameters.
 * @export
 * @interface Trialv1Trial
 */
export interface Trialv1Trial {
    /**
     * The id of the trial.
     * @type {number}
     * @memberof Trialv1Trial
     */
    id: number;
    /**
     * The id of the parent experiment.
     * @type {number}
     * @memberof Trialv1Trial
     */
    experimentId: number;
    /**
     * The time the trial was started.
     * @type {Date}
     * @memberof Trialv1Trial
     */
    startTime: Date;
    /**
     * The time the trial ended if the trial is stopped.
     * @type {Date}
     * @memberof Trialv1Trial
     */
    endTime?: Date;
    /**
     * The current state of the trial.
     * @type {Determinedexperimentv1State}
     * @memberof Trialv1Trial
     */
    state: Determinedexperimentv1State;
    /**
     * Number times the trial restarted.
     * @type {number}
     * @memberof Trialv1Trial
     */
    restarts: number;
    /**
     * Trial hyperparameters.
     * @type {any}
     * @memberof Trialv1Trial
     */
    hparams: any;
    /**
     * The current processed batches.
     * @type {number}
     * @memberof Trialv1Trial
     */
    totalBatchesProcessed: number;
    /**
     * Best validation.
     * @type {V1MetricsWorkload}
     * @memberof Trialv1Trial
     */
    bestValidation?: V1MetricsWorkload;
    /**
     * Latest validation.
     * @type {V1MetricsWorkload}
     * @memberof Trialv1Trial
     */
    latestValidation?: V1MetricsWorkload;
    /**
     * Best checkpoint.
     * @type {V1CheckpointWorkload}
     * @memberof Trialv1Trial
     */
    bestCheckpoint?: V1CheckpointWorkload;
    /**
     * Latest training step.
     * @type {V1MetricsWorkload}
     * @memberof Trialv1Trial
     */
    latestTraining?: V1MetricsWorkload;
    /**
     * The last reported state of the trial runner (harness code).
     * @type {string}
     * @memberof Trialv1Trial
     */
    runnerState?: string;
    /**
     * The wall clock time is all active time of the cluster for the trial, inclusive of everything (restarts, initiailization, etc), in seconds.
     * @type {number}
     * @memberof Trialv1Trial
     */
    wallClockTime?: number;
    /**
     * UUID of checkpoint that this trial started from.
     * @type {string}
     * @memberof Trialv1Trial
     */
    warmStartCheckpointUuid?: string;
    /**
     * Id of task associated with this trial.
     * @type {string}
     * @memberof Trialv1Trial
     */
    taskId?: string;
    /**
     * The sum of sizes of all resources in all checkpoints for the trial.
     * @type {string}
     * @memberof Trialv1Trial
     */
    totalCheckpointSize?: string;
}

/**
 * struct for the ids parameter.
 * @export
 * @interface UpdateTrialTagsRequestIds
 */
export interface UpdateTrialTagsRequestIds {
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateTrialTagsRequestIds
     */
    ids?: Array<number>;
}

/**
 * Acknowledge the receipt of some stop signal.
 * @export
 * @interface V1AckAllocationPreemptionSignalRequest
 */
export interface V1AckAllocationPreemptionSignalRequest {
    /**
     * The allocation that is acknowledging the request.
     * @type {string}
     * @memberof V1AckAllocationPreemptionSignalRequest
     */
    allocationId: string;
}

/**
 * Response to AckAllocationPreemptionSignalRequest.
 * @export
 * @interface V1AckAllocationPreemptionSignalResponse
 */
export interface V1AckAllocationPreemptionSignalResponse {
}

/**
 * Response to ActivateExperimentRequest.
 * @export
 * @interface V1ActivateExperimentResponse
 */
export interface V1ActivateExperimentResponse {
}

/**
 * Response to AddProjectNoteRequest.
 * @export
 * @interface V1AddProjectNoteResponse
 */
export interface V1AddProjectNoteResponse {
    /**
     * The complete list of notes on a project.
     * @type {Array<V1Note>}
     * @memberof V1AddProjectNoteResponse
     */
    notes: Array<V1Note>;
}

/**
 * Agent is a pool of resources where containers are run.
 * @export
 * @interface V1Agent
 */
export interface V1Agent {
    /**
     * The unique id of the agent.
     * @type {string}
     * @memberof V1Agent
     */
    id: string;
    /**
     * The time when the agent registered with the master.
     * @type {Date}
     * @memberof V1Agent
     */
    registeredTime?: Date;
    /**
     * A map of slot id to each slot of this agent.
     * @type {{ [key: string]: V1Slot; }}
     * @memberof V1Agent
     */
    slots?: { [key: string]: V1Slot; };
    /**
     * A map of container id to all containers assigned to this agent.
     * @type {{ [key: string]: V1Container; }}
     * @memberof V1Agent
     */
    containers?: { [key: string]: V1Container; };
    /**
     * An optional label applied to the agent for scheduling restrictions.
     * @type {string}
     * @memberof V1Agent
     */
    label?: string;
    /**
     * The addresses of the agent.
     * @type {Array<string>}
     * @memberof V1Agent
     */
    addresses?: Array<string>;
    /**
     * Flag notifying if containers can be scheduled on this agent.
     * @type {boolean}
     * @memberof V1Agent
     */
    enabled?: boolean;
    /**
     * Flag notifying if this agent is in the draining mode: current containers will be allowed to finish but no new ones will be scheduled.
     * @type {boolean}
     * @memberof V1Agent
     */
    draining?: boolean;
    /**
     * The Determined version that this agent was built from.
     * @type {string}
     * @memberof V1Agent
     */
    version?: string;
    /**
     * The name of the resource pools the agent is in. Only slurm can contain multiples.
     * @type {Array<string>}
     * @memberof V1Agent
     */
    resourcePools?: Array<string>;
}

/**
 * AgentUserGroup represents a username and primary group for a user on an agent host machine.
 * @export
 * @interface V1AgentUserGroup
 */
export interface V1AgentUserGroup {
    /**
     * The user id on the agent.
     * @type {number}
     * @memberof V1AgentUserGroup
     */
    agentUid?: number;
    /**
     * The group id on the agent.
     * @type {number}
     * @memberof V1AgentUserGroup
     */
    agentGid?: number;
    /**
     * User name.
     * @type {string}
     * @memberof V1AgentUserGroup
     */
    agentUser?: string;
    /**
     * Group name.
     * @type {string}
     * @memberof V1AgentUserGroup
     */
    agentGroup?: string;
}

/**
 * Aggregate statistics for a queue.
 * @export
 * @interface V1AggregateQueueStats
 */
export interface V1AggregateQueueStats {
    /**
     * The date of this entry.
     * @type {string}
     * @memberof V1AggregateQueueStats
     */
    periodStart: string;
    /**
     * The total number of seconds queued.
     * @type {number}
     * @memberof V1AggregateQueueStats
     */
    seconds: number;
}

/**
 * Allocation tracks a specific instance of a Task.
 * @export
 * @interface V1Allocation
 */
export interface V1Allocation {
    /**
     * Unique ID of task associated with the allocation.
     * @type {string}
     * @memberof V1Allocation
     */
    taskId?: string;
    /**
     * The current state of the allocation.
     * @type {Determinedtaskv1State}
     * @memberof V1Allocation
     */
    state?: Determinedtaskv1State;
    /**
     * Whether the allocation is ready to access.
     * @type {boolean}
     * @memberof V1Allocation
     */
    isReady?: boolean;
    /**
     * Start timestamp.
     * @type {string}
     * @memberof V1Allocation
     */
    startTime?: string;
    /**
     * End timestamp if completed.
     * @type {string}
     * @memberof V1Allocation
     */
    endTime?: string;
    /**
     * Unique ID of the allocation.
     * @type {string}
     * @memberof V1Allocation
     */
    allocationId?: string;
}

/**
 * Arguments to an all gather.
 * @export
 * @interface V1AllocationAllGatherRequest
 */
export interface V1AllocationAllGatherRequest {
    /**
     * The ID of the allocation.
     * @type {string}
     * @memberof V1AllocationAllGatherRequest
     */
    allocationId: string;
    /**
     * The UUID of the participant in an all gather.
     * @type {string}
     * @memberof V1AllocationAllGatherRequest
     */
    requestUuid?: string;
    /**
     * The number of process to wait for.
     * @type {number}
     * @memberof V1AllocationAllGatherRequest
     */
    numPeers?: number;
    /**
     * The data from this process.
     * @type {any}
     * @memberof V1AllocationAllGatherRequest
     */
    data: any;
}

/**
 * 
 * @export
 * @interface V1AllocationAllGatherResponse
 */
export interface V1AllocationAllGatherResponse {
    /**
     * The data for all the processes.
     * @type {Array<any>}
     * @memberof V1AllocationAllGatherResponse
     */
    data: Array<any>;
}

/**
 * 
 * @export
 * @interface V1AllocationPendingPreemptionSignalRequest
 */
export interface V1AllocationPendingPreemptionSignalRequest {
    /**
     * The id of the allocation.
     * @type {string}
     * @memberof V1AllocationPendingPreemptionSignalRequest
     */
    allocationId: string;
}

/**
 * 
 * @export
 * @interface V1AllocationPendingPreemptionSignalResponse
 */
export interface V1AllocationPendingPreemptionSignalResponse {
}

/**
 * Response to AllocationPreemptionSignalRequest.
 * @export
 * @interface V1AllocationPreemptionSignalResponse
 */
export interface V1AllocationPreemptionSignalResponse {
    /**
     * True if signaling preempt, otherwise just a synchronization marker.
     * @type {boolean}
     * @memberof V1AllocationPreemptionSignalResponse
     */
    preempt?: boolean;
}

/**
 * Mark the given task as ready.
 * @export
 * @interface V1AllocationReadyRequest
 */
export interface V1AllocationReadyRequest {
    /**
     * The id of the allocation.
     * @type {string}
     * @memberof V1AllocationReadyRequest
     */
    allocationId?: string;
}

/**
 * Response to AllocationReadyRequest.
 * @export
 * @interface V1AllocationReadyResponse
 */
export interface V1AllocationReadyResponse {
}

/**
 * 
 * @export
 * @interface V1AllocationRendezvousInfoResponse
 */
export interface V1AllocationRendezvousInfoResponse {
    /**
     * The rendezvous information.
     * @type {V1RendezvousInfo}
     * @memberof V1AllocationRendezvousInfoResponse
     */
    rendezvousInfo: V1RendezvousInfo;
}

/**
 * Mark the given task as waiting.
 * @export
 * @interface V1AllocationWaitingRequest
 */
export interface V1AllocationWaitingRequest {
    /**
     * The id of the allocation.
     * @type {string}
     * @memberof V1AllocationWaitingRequest
     */
    allocationId?: string;
}

/**
 * Response to AllocationWaitingRequest.
 * @export
 * @interface V1AllocationWaitingResponse
 */
export interface V1AllocationWaitingResponse {
}

/**
 * Response to ArchiveExperimentRequest.
 * @export
 * @interface V1ArchiveExperimentResponse
 */
export interface V1ArchiveExperimentResponse {
}

/**
 * 
 * @export
 * @interface V1ArchiveModelResponse
 */
export interface V1ArchiveModelResponse {
}

/**
 * Response to ArchiveProjectRequest.
 * @export
 * @interface V1ArchiveProjectResponse
 */
export interface V1ArchiveProjectResponse {
}

/**
 * Response to ArchiveWorkspaceRequest.
 * @export
 * @interface V1ArchiveWorkspaceResponse
 */
export interface V1ArchiveWorkspaceResponse {
}

/**
 * AssignRolesRequest is the body of the request for the call to grant a user or group a role. It requires group_id, role_id, and either scope_workspace_id or scope_project_id.
 * @export
 * @interface V1AssignRolesRequest
 */
export interface V1AssignRolesRequest {
    /**
     * the set of groups being assigned to a role.
     * @type {Array<V1GroupRoleAssignment>}
     * @memberof V1AssignRolesRequest
     */
    groupRoleAssignments?: Array<V1GroupRoleAssignment>;
    /**
     * the set of users being assigned to a role.
     * @type {Array<V1UserRoleAssignment>}
     * @memberof V1AssignRolesRequest
     */
    userRoleAssignments?: Array<V1UserRoleAssignment>;
}

/**
 * AssignRolesResponse is the body of the request for the call to grant a user or group a role.
 * @export
 * @interface V1AssignRolesResponse
 */
export interface V1AssignRolesResponse {
}

/**
 * Augmented Trial struct to service trial comparison functionality.
 * @export
 * @interface V1AugmentedTrial
 */
export interface V1AugmentedTrial {
    /**
     * The id of the trial.
     * @type {number}
     * @memberof V1AugmentedTrial
     */
    trialId: number;
    /**
     * The state of the trial.
     * @type {Determinedtrialv1State}
     * @memberof V1AugmentedTrial
     */
    state: Determinedtrialv1State;
    /**
     * 
     * @type {any}
     * @memberof V1AugmentedTrial
     */
    hparams: any;
    /**
     * The training metrics for the trial.
     * @type {any}
     * @memberof V1AugmentedTrial
     */
    trainingMetrics: any;
    /**
     * The validation metrics for the trial.
     * @type {any}
     * @memberof V1AugmentedTrial
     */
    validationMetrics: any;
    /**
     * The tags for the trial.
     * @type {any}
     * @memberof V1AugmentedTrial
     */
    tags: any;
    /**
     * The start time for the trial.
     * @type {Date}
     * @memberof V1AugmentedTrial
     */
    startTime: Date;
    /**
     * The end time for the trial.
     * @type {Date}
     * @memberof V1AugmentedTrial
     */
    endTime: Date;
    /**
     * The searcher type for the trial.
     * @type {string}
     * @memberof V1AugmentedTrial
     */
    searcherType: string;
    /**
     * The trials rank within the experiment, according to the sorter that is provided.
     * @type {number}
     * @memberof V1AugmentedTrial
     */
    rankWithinExp?: number;
    /**
     * The experiment id for the trial.
     * @type {number}
     * @memberof V1AugmentedTrial
     */
    experimentId: number;
    /**
     * The experiment name for the trial.
     * @type {string}
     * @memberof V1AugmentedTrial
     */
    experimentName: string;
    /**
     * The experiment description for the trial.
     * @type {string}
     * @memberof V1AugmentedTrial
     */
    experimentDescription: string;
    /**
     * The labels for the experiment corresponding to the trial.
     * @type {Array<string>}
     * @memberof V1AugmentedTrial
     */
    experimentLabels: Array<string>;
    /**
     * The id of the user who created the experiment corresponding to the trial.
     * @type {number}
     * @memberof V1AugmentedTrial
     */
    userId: number;
    /**
     * The project id for the trial.
     * @type {number}
     * @memberof V1AugmentedTrial
     */
    projectId: number;
    /**
     * The workspace id for the trial.
     * @type {number}
     * @memberof V1AugmentedTrial
     */
    workspaceId: number;
    /**
     * The total number of batches in the trial.
     * @type {number}
     * @memberof V1AugmentedTrial
     */
    totalBatches: number;
    /**
     * The name of the trials searcher metric.
     * @type {string}
     * @memberof V1AugmentedTrial
     */
    searcherMetric?: string;
    /**
     * The value for the trials searcher metric.
     * @type {number}
     * @memberof V1AugmentedTrial
     */
    searcherMetricValue?: number;
    /**
     * The loss for the trials searcher metric.
     * @type {number}
     * @memberof V1AugmentedTrial
     */
    searcherMetricLoss?: number;
}

/**
 * 
 * @export
 * @interface V1AwsCustomTag
 */
export interface V1AwsCustomTag {
    /**
     * 
     * @type {string}
     * @memberof V1AwsCustomTag
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof V1AwsCustomTag
     */
    value: string;
}

/**
 * Response to CancelExperimentRequest.
 * @export
 * @interface V1CancelExperimentResponse
 */
export interface V1CancelExperimentResponse {
}

/**
 * Checkpoint a collection of files saved by a task.
 * @export
 * @interface V1Checkpoint
 */
export interface V1Checkpoint {
    /**
     * ID of the task which generated this checkpoint.
     * @type {string}
     * @memberof V1Checkpoint
     */
    taskId?: string;
    /**
     * ID of the allocation which generated this checkpoint.
     * @type {string}
     * @memberof V1Checkpoint
     */
    allocationId?: string;
    /**
     * UUID of the checkpoint.
     * @type {string}
     * @memberof V1Checkpoint
     */
    uuid: string;
    /**
     * Timestamp when the checkpoint was reported.
     * @type {Date}
     * @memberof V1Checkpoint
     */
    reportTime?: Date;
    /**
     * Dictionary of file paths to file sizes in bytes of all files in the checkpoint.
     * @type {{ [key: string]: string; }}
     * @memberof V1Checkpoint
     */
    resources: { [key: string]: string; };
    /**
     * User defined metadata associated with the checkpoint.
     * @type {any}
     * @memberof V1Checkpoint
     */
    metadata: any;
    /**
     * The state of the underlying checkpoint.
     * @type {Determinedcheckpointv1State}
     * @memberof V1Checkpoint
     */
    state: Determinedcheckpointv1State;
    /**
     * Training-related data for this checkpoint.
     * @type {V1CheckpointTrainingMetadata}
     * @memberof V1Checkpoint
     */
    training: V1CheckpointTrainingMetadata;
}

/**
 * CheckpointTrainingMetadata is specifically metadata about training.
 * @export
 * @interface V1CheckpointTrainingMetadata
 */
export interface V1CheckpointTrainingMetadata {
    /**
     * The ID of the trial that created this checkpoint.
     * @type {number}
     * @memberof V1CheckpointTrainingMetadata
     */
    trialId?: number;
    /**
     * The ID of the experiment that created this checkpoint.
     * @type {number}
     * @memberof V1CheckpointTrainingMetadata
     */
    experimentId?: number;
    /**
     * The configuration of the experiment that created this checkpoint.
     * @type {any}
     * @memberof V1CheckpointTrainingMetadata
     */
    experimentConfig?: any;
    /**
     * Hyperparameter values for the trial that created this checkpoint.
     * @type {any}
     * @memberof V1CheckpointTrainingMetadata
     */
    hparams?: any;
    /**
     * Training metrics reported at the same steps_completed as the checkpoint.
     * @type {V1Metrics}
     * @memberof V1CheckpointTrainingMetadata
     */
    trainingMetrics?: V1Metrics;
    /**
     * Validation metrics reported at the same steps_completed as the checkpoint.
     * @type {V1Metrics}
     * @memberof V1CheckpointTrainingMetadata
     */
    validationMetrics?: V1Metrics;
    /**
     * Searcher metric (as specified by the expconf) at the same steps_completed of the checkpoint.
     * @type {number}
     * @memberof V1CheckpointTrainingMetadata
     */
    searcherMetric?: number;
}

/**
 * CheckpointWorkload is an artifact created by a trial during training.
 * @export
 * @interface V1CheckpointWorkload
 */
export interface V1CheckpointWorkload {
    /**
     * UUID of the checkpoint.
     * @type {string}
     * @memberof V1CheckpointWorkload
     */
    uuid?: string;
    /**
     * The time the workload finished or was stopped.
     * @type {Date}
     * @memberof V1CheckpointWorkload
     */
    endTime?: Date;
    /**
     * The state of the checkpoint.
     * @type {Determinedcheckpointv1State}
     * @memberof V1CheckpointWorkload
     */
    state: Determinedcheckpointv1State;
    /**
     * Dictionary of file paths to file sizes in bytes of all files in the checkpoint.
     * @type {{ [key: string]: string; }}
     * @memberof V1CheckpointWorkload
     */
    resources?: { [key: string]: string; };
    /**
     * Total number of batches as of this workload's completion.
     * @type {number}
     * @memberof V1CheckpointWorkload
     */
    totalBatches: number;
    /**
     * User defined metadata associated with the checkpoint.
     * @type {any}
     * @memberof V1CheckpointWorkload
     */
    metadata?: any;
}

/**
 * 
 * @export
 * @interface V1ColumnFilter
 */
export interface V1ColumnFilter {
    /**
     * The name of the field on which the filters is applied.
     * @type {string}
     * @memberof V1ColumnFilter
     */
    name?: string;
    /**
     * 
     * @type {V1DoubleFieldFilter}
     * @memberof V1ColumnFilter
     */
    filter?: V1DoubleFieldFilter;
}

/**
 * Command is a single container running the configured command.
 * @export
 * @interface V1Command
 */
export interface V1Command {
    /**
     * The id of the command.
     * @type {string}
     * @memberof V1Command
     */
    id: string;
    /**
     * The description of the command.
     * @type {string}
     * @memberof V1Command
     */
    description: string;
    /**
     * The state of the command.
     * @type {Determinedtaskv1State}
     * @memberof V1Command
     */
    state: Determinedtaskv1State;
    /**
     * The time the command was started.
     * @type {Date}
     * @memberof V1Command
     */
    startTime: Date;
    /**
     * The container running the command.
     * @type {V1Container}
     * @memberof V1Command
     */
    container?: V1Container;
    /**
     * The display name of the user that created the command.
     * @type {string}
     * @memberof V1Command
     */
    displayName?: string;
    /**
     * The id of the user that created the command.
     * @type {number}
     * @memberof V1Command
     */
    userId?: number;
    /**
     * The username of the user that created the command.
     * @type {string}
     * @memberof V1Command
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof V1Command
     */
    resourcePool: string;
    /**
     * 
     * @type {string}
     * @memberof V1Command
     */
    exitStatus?: string;
    /**
     * The associated job id.
     * @type {string}
     * @memberof V1Command
     */
    jobId: string;
}

/**
 * Container for a requested trial and its metrics.
 * @export
 * @interface V1ComparableTrial
 */
export interface V1ComparableTrial {
    /**
     * The requested trial.
     * @type {Trialv1Trial}
     * @memberof V1ComparableTrial
     */
    trial: Trialv1Trial;
    /**
     * The downsampled datapoints.
     * @type {Array<V1SummarizedMetric>}
     * @memberof V1ComparableTrial
     */
    metrics: Array<V1SummarizedMetric>;
}

/**
 * Response to CompareTrialsRequest.
 * @export
 * @interface V1CompareTrialsResponse
 */
export interface V1CompareTrialsResponse {
    /**
     * A list of objects containing trial and metrics information.
     * @type {Array<V1ComparableTrial>}
     * @memberof V1CompareTrialsResponse
     */
    trials: Array<V1ComparableTrial>;
}

/**
 * 
 * @export
 * @interface V1CompleteTrialSearcherValidationResponse
 */
export interface V1CompleteTrialSearcherValidationResponse {
}

/**
 * Used to complete a ValidateAfterOperation.
 * @export
 * @interface V1CompleteValidateAfterOperation
 */
export interface V1CompleteValidateAfterOperation {
    /**
     * The ValidateAfterOperation being completed.
     * @type {V1ValidateAfterOperation}
     * @memberof V1CompleteValidateAfterOperation
     */
    op?: V1ValidateAfterOperation;
    /**
     * The value of searcher metric associated with this completed metric. The metric provided should be the metric used to guide HP search.
     * @type {number}
     * @memberof V1CompleteValidateAfterOperation
     */
    searcherMetric?: number;
}

/**
 * 
 * @export
 * @interface V1ComputeHPImportanceResponse
 */
export interface V1ComputeHPImportanceResponse {
}

/**
 * Container is a Docker container that is either scheduled to run or is currently running on a set of slots.
 * @export
 * @interface V1Container
 */
export interface V1Container {
    /**
     * The id of the task that is currently managing this container.
     * @type {string}
     * @memberof V1Container
     */
    parent?: string;
    /**
     * The unique id of this instance of a container.
     * @type {string}
     * @memberof V1Container
     */
    id: string;
    /**
     * The current state that the container is currently in.
     * @type {Determinedcontainerv1State}
     * @memberof V1Container
     */
    state: Determinedcontainerv1State;
    /**
     * A list of devices that is being used by this container.
     * @type {Array<V1Device>}
     * @memberof V1Container
     */
    devices?: Array<V1Device>;
}

/**
 * Request to create a new experiment.
 * @export
 * @interface V1CreateExperimentRequest
 */
export interface V1CreateExperimentRequest {
    /**
     * Experiment context.
     * @type {Array<V1File>}
     * @memberof V1CreateExperimentRequest
     */
    modelDefinition?: Array<V1File>;
    /**
     * Experiment config (YAML).
     * @type {string}
     * @memberof V1CreateExperimentRequest
     */
    config?: string;
    /**
     * Only validate instead of creating the experiment. A dry run.
     * @type {boolean}
     * @memberof V1CreateExperimentRequest
     */
    validateOnly?: boolean;
    /**
     * Parent experiment id.
     * @type {number}
     * @memberof V1CreateExperimentRequest
     */
    parentId?: number;
    /**
     * Request to auto-activate the experiment.
     * @type {boolean}
     * @memberof V1CreateExperimentRequest
     */
    activate?: boolean;
    /**
     * Project id to contain the experiment.
     * @type {number}
     * @memberof V1CreateExperimentRequest
     */
    projectId?: number;
}

/**
 * Response to CreateExperimentRequest.
 * @export
 * @interface V1CreateExperimentResponse
 */
export interface V1CreateExperimentResponse {
    /**
     * The created experiment.
     * @type {V1Experiment}
     * @memberof V1CreateExperimentResponse
     */
    experiment: V1Experiment;
    /**
     * The created experiment config.
     * @type {any}
     * @memberof V1CreateExperimentResponse
     */
    config: any;
}

/**
 * CreateGroupRequest is the body of the request for the call to create a group.
 * @export
 * @interface V1CreateGroupRequest
 */
export interface V1CreateGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof V1CreateGroupRequest
     */
    name: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1CreateGroupRequest
     */
    addUsers?: Array<number>;
}

/**
 * CreateGroupResponse is the body of the response for the call to update a group and its members.
 * @export
 * @interface V1CreateGroupResponse
 */
export interface V1CreateGroupResponse {
    /**
     * Info about the group after the update succeeded.
     * @type {V1GroupDetails}
     * @memberof V1CreateGroupResponse
     */
    group: V1GroupDetails;
}

/**
 * Request body for CeateTrials request which includes TrialFilters.
 * @export
 * @interface V1CreateTrialsCollectionRequest
 */
export interface V1CreateTrialsCollectionRequest {
    /**
     * The name for the collection.
     * @type {string}
     * @memberof V1CreateTrialsCollectionRequest
     */
    name: string;
    /**
     * The project id for the collection.
     * @type {number}
     * @memberof V1CreateTrialsCollectionRequest
     */
    projectId: number;
    /**
     * The filters for the collection.
     * @type {V1TrialFilters}
     * @memberof V1CreateTrialsCollectionRequest
     */
    filters: V1TrialFilters;
    /**
     * The sorter for the collection.
     * @type {V1TrialSorter}
     * @memberof V1CreateTrialsCollectionRequest
     */
    sorter: V1TrialSorter;
}

/**
 * Response message to CreateTrialCollection.
 * @export
 * @interface V1CreateTrialsCollectionResponse
 */
export interface V1CreateTrialsCollectionResponse {
    /**
     * The collection of trials.
     * @type {V1TrialsCollection}
     * @memberof V1CreateTrialsCollectionResponse
     */
    collection?: V1TrialsCollection;
}

/**
 * Response to CurrentUserRequest.
 * @export
 * @interface V1CurrentUserResponse
 */
export interface V1CurrentUserResponse {
    /**
     * The currently logged in user.
     * @type {V1User}
     * @memberof V1CurrentUserResponse
     */
    user: V1User;
}

/**
 * One datapoint in a series of metrics from a trial.
 * @export
 * @interface V1DataPoint
 */
export interface V1DataPoint {
    /**
     * Total batches processed by the time this measurement is taken.
     * @type {number}
     * @memberof V1DataPoint
     */
    batches: number;
    /**
     * Value of the requested metric at this point in the trial.
     * @type {number}
     * @memberof V1DataPoint
     */
    value: number;
}

/**
 * 
 * @export
 * @interface V1DeleteCheckpointsRequest
 */
export interface V1DeleteCheckpointsRequest {
    /**
     * The list of checkpoint_uuids for the requested checkpoint.
     * @type {Array<string>}
     * @memberof V1DeleteCheckpointsRequest
     */
    checkpointUuids: Array<string>;
}

/**
 * 
 * @export
 * @interface V1DeleteCheckpointsResponse
 */
export interface V1DeleteCheckpointsResponse {
}

/**
 * Response to DeleteExperimentRequest.
 * @export
 * @interface V1DeleteExperimentResponse
 */
export interface V1DeleteExperimentResponse {
}

/**
 * DeleteGroupResponse is the body of the response for the call to delete a group.
 * @export
 * @interface V1DeleteGroupResponse
 */
export interface V1DeleteGroupResponse {
}

/**
 * 
 * @export
 * @interface V1DeleteModelResponse
 */
export interface V1DeleteModelResponse {
}

/**
 * 
 * @export
 * @interface V1DeleteModelVersionResponse
 */
export interface V1DeleteModelVersionResponse {
}

/**
 * Response to DeleteProjectRequest.
 * @export
 * @interface V1DeleteProjectResponse
 */
export interface V1DeleteProjectResponse {
    /**
     * Status of deletion.
     * @type {boolean}
     * @memberof V1DeleteProjectResponse
     */
    completed: boolean;
}

/**
 * Response to DeleteTemplateRequest.
 * @export
 * @interface V1DeleteTemplateResponse
 */
export interface V1DeleteTemplateResponse {
}

/**
 * Empty response for DeleteTrialsCollection.
 * @export
 * @interface V1DeleteTrialsCollectionResponse
 */
export interface V1DeleteTrialsCollectionResponse {
}

/**
 * Response to DeleteWorkspaceRequest.
 * @export
 * @interface V1DeleteWorkspaceResponse
 */
export interface V1DeleteWorkspaceResponse {
    /**
     * Status of deletion.
     * @type {boolean}
     * @memberof V1DeleteWorkspaceResponse
     */
    completed: boolean;
}

/**
 * Device represents a single computational device on an agent.
 * @export
 * @interface V1Device
 */
export interface V1Device {
    /**
     * The index of the device.
     * @type {number}
     * @memberof V1Device
     */
    id?: number;
    /**
     * The brand name of the device.
     * @type {string}
     * @memberof V1Device
     */
    brand?: string;
    /**
     * The unique UUID of the device.
     * @type {string}
     * @memberof V1Device
     */
    uuid?: string;
    /**
     * The type of the Device.
     * @type {Determineddevicev1Type}
     * @memberof V1Device
     */
    type?: Determineddevicev1Type;
}

/**
 * Disable the agent.
 * @export
 * @interface V1DisableAgentRequest
 */
export interface V1DisableAgentRequest {
    /**
     * The id of the agent.
     * @type {string}
     * @memberof V1DisableAgentRequest
     */
    agentId?: string;
    /**
     * If true, wait for running tasks to finish.
     * @type {boolean}
     * @memberof V1DisableAgentRequest
     */
    drain?: boolean;
}

/**
 * Response to DisableAgentRequest.
 * @export
 * @interface V1DisableAgentResponse
 */
export interface V1DisableAgentResponse {
    /**
     * The disabled agent.
     * @type {V1Agent}
     * @memberof V1DisableAgentResponse
     */
    agent?: V1Agent;
}

/**
 * Response to DisableSlotRequest.
 * @export
 * @interface V1DisableSlotResponse
 */
export interface V1DisableSlotResponse {
    /**
     * The disabled slot.
     * @type {V1Slot}
     * @memberof V1DisableSlotResponse
     */
    slot?: V1Slot;
}

/**
 * Double filters.
 * @export
 * @interface V1DoubleFieldFilter
 */
export interface V1DoubleFieldFilter {
    /**
     * Less than.
     * @type {number}
     * @memberof V1DoubleFieldFilter
     */
    lt?: number;
    /**
     * Less than or equal.
     * @type {number}
     * @memberof V1DoubleFieldFilter
     */
    lte?: number;
    /**
     * Greater than.
     * @type {number}
     * @memberof V1DoubleFieldFilter
     */
    gt?: number;
    /**
     * Greater than or equal.
     * @type {number}
     * @memberof V1DoubleFieldFilter
     */
    gte?: number;
}

/**
 * Response to EnableAgentRequest.
 * @export
 * @interface V1EnableAgentResponse
 */
export interface V1EnableAgentResponse {
    /**
     * The enabled agent.
     * @type {V1Agent}
     * @memberof V1EnableAgentResponse
     */
    agent?: V1Agent;
}

/**
 * Response to EnableSlotRequest.
 * @export
 * @interface V1EnableSlotResponse
 */
export interface V1EnableSlotResponse {
    /**
     * The enabled slot.
     * @type {V1Slot}
     * @memberof V1EnableSlotResponse
     */
    slot?: V1Slot;
}

/**
 * Response to MetricNamesRequest.
 * @export
 * @interface V1ExpCompareMetricNamesResponse
 */
export interface V1ExpCompareMetricNamesResponse {
    /**
     * List of training metric names.
     * @type {Array<string>}
     * @memberof V1ExpCompareMetricNamesResponse
     */
    trainingMetrics?: Array<string>;
    /**
     * List of validation metric names.
     * @type {Array<string>}
     * @memberof V1ExpCompareMetricNamesResponse
     */
    validationMetrics?: Array<string>;
}

/**
 * 
 * @export
 * @interface V1ExpCompareTrialsSampleResponse
 */
export interface V1ExpCompareTrialsSampleResponse {
    /**
     * A historical or incremental series of data points for the trials.
     * @type {Array<ExpCompareTrialsSampleResponseExpTrial>}
     * @memberof V1ExpCompareTrialsSampleResponse
     */
    trials: Array<ExpCompareTrialsSampleResponseExpTrial>;
    /**
     * IDs of trials that are newly included in the data.
     * @type {Array<number>}
     * @memberof V1ExpCompareTrialsSampleResponse
     */
    promotedTrials: Array<number>;
    /**
     * IDs of trials that are no loger included in the top N trials.
     * @type {Array<number>}
     * @memberof V1ExpCompareTrialsSampleResponse
     */
    demotedTrials: Array<number>;
}

/**
 * Experiment is a collection of one or more trials that are exploring a user-defined hyperparameter space.
 * @export
 * @interface V1Experiment
 */
export interface V1Experiment {
    /**
     * The id of the experiment.
     * @type {number}
     * @memberof V1Experiment
     */
    id: number;
    /**
     * The description of the experiment.
     * @type {string}
     * @memberof V1Experiment
     */
    description?: string;
    /**
     * Labels attached to the experiment.
     * @type {Array<string>}
     * @memberof V1Experiment
     */
    labels?: Array<string>;
    /**
     * The time the experiment was started.
     * @type {Date}
     * @memberof V1Experiment
     */
    startTime: Date;
    /**
     * The time the experiment ended if the experiment is stopped.
     * @type {Date}
     * @memberof V1Experiment
     */
    endTime?: Date;
    /**
     * The current state of the experiment.
     * @type {Determinedexperimentv1State}
     * @memberof V1Experiment
     */
    state: Determinedexperimentv1State;
    /**
     * Boolean denoting whether the experiment was archived.
     * @type {boolean}
     * @memberof V1Experiment
     */
    archived: boolean;
    /**
     * The number of trials linked to the experiment.
     * @type {number}
     * @memberof V1Experiment
     */
    numTrials: number;
    /**
     * The ids of trials linked to the experiment.
     * @type {Array<number>}
     * @memberof V1Experiment
     */
    trialIds?: Array<number>;
    /**
     * The display name of the user that created the experiment.
     * @type {string}
     * @memberof V1Experiment
     */
    displayName?: string;
    /**
     * The id of the user that created the experiment.
     * @type {number}
     * @memberof V1Experiment
     */
    userId?: number;
    /**
     * The username of the user that created the experiment.
     * @type {string}
     * @memberof V1Experiment
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof V1Experiment
     */
    resourcePool?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Experiment
     */
    searcherType: string;
    /**
     * The experiment name.
     * @type {string}
     * @memberof V1Experiment
     */
    name: string;
    /**
     * The experiment notes.
     * @type {string}
     * @memberof V1Experiment
     */
    notes?: string;
    /**
     * Associated job's id.
     * @type {string}
     * @memberof V1Experiment
     */
    jobId: string;
    /**
     * Original id of a forked or continued experiment.
     * @type {number}
     * @memberof V1Experiment
     */
    forkedFrom?: number;
    /**
     * The current progress of a running experiment.
     * @type {number}
     * @memberof V1Experiment
     */
    progress?: number;
    /**
     * The id of the project associated with this experiment.
     * @type {number}
     * @memberof V1Experiment
     */
    projectId: number;
    /**
     * The name of the project associated with this experiment.
     * @type {string}
     * @memberof V1Experiment
     */
    projectName?: string;
    /**
     * The id of the workspace associated with this experiment.
     * @type {number}
     * @memberof V1Experiment
     */
    workspaceId?: number;
    /**
     * The name of the workspace associated with this experiment.
     * @type {string}
     * @memberof V1Experiment
     */
    workspaceName?: string;
    /**
     * The archived status of the parent project (can be inherited from workspace).
     * @type {boolean}
     * @memberof V1Experiment
     */
    parentArchived?: boolean;
    /**
     * The configuration of the experiment.
     * @type {any}
     * @memberof V1Experiment
     */
    config: any;
    /**
     * The original configuration that the user submitted.
     * @type {string}
     * @memberof V1Experiment
     */
    originalConfig: string;
    /**
     * The id of the user who created the parent project.
     * @type {number}
     * @memberof V1Experiment
     */
    projectOwnerId: number;
}

/**
 * ExperimentSimulation holds the configuration and results of simulated run of a searcher.
 * @export
 * @interface V1ExperimentSimulation
 */
export interface V1ExperimentSimulation {
    /**
     * The simulated experiment config.
     * @type {any}
     * @memberof V1ExperimentSimulation
     */
    config?: any;
    /**
     * The searcher simulation seed.
     * @type {number}
     * @memberof V1ExperimentSimulation
     */
    seed?: number;
    /**
     * The list of trials in the simulation.
     * @type {Array<V1TrialSimulation>}
     * @memberof V1ExperimentSimulation
     */
    trials?: Array<V1TrialSimulation>;
}

/**
 * File is a Unix file.
 * @export
 * @interface V1File
 */
export interface V1File {
    /**
     * Path to the file.
     * @type {string}
     * @memberof V1File
     */
    path: string;
    /**
     * File type.
     * @type {number}
     * @memberof V1File
     */
    type: number;
    /**
     * base64 encoded contents of the file.
     * @type {string}
     * @memberof V1File
     */
    content: string;
    /**
     * Modified time (Unix timestamp).
     * @type {string}
     * @memberof V1File
     */
    mtime: string;
    /**
     * File mode.
     * @type {number}
     * @memberof V1File
     */
    mode: number;
    /**
     * User ID.
     * @type {number}
     * @memberof V1File
     */
    uid: number;
    /**
     * Group ID.
     * @type {number}
     * @memberof V1File
     */
    gid: number;
}

/**
 * File node is one node of file in experiment model definition file tree.
 * @export
 * @interface V1FileNode
 */
export interface V1FileNode {
    /**
     * Path of file.
     * @type {string}
     * @memberof V1FileNode
     */
    path?: string;
    /**
     * Name of file.
     * @type {string}
     * @memberof V1FileNode
     */
    name?: string;
    /**
     * Modification time of file.
     * @type {Date}
     * @memberof V1FileNode
     */
    modifiedTime?: Date;
    /**
     * Number of bytes in file content.
     * @type {number}
     * @memberof V1FileNode
     */
    contentLength?: number;
    /**
     * Is this a directory.
     * @type {boolean}
     * @memberof V1FileNode
     */
    isDir?: boolean;
    /**
     * MIME type of file.
     * @type {string}
     * @memberof V1FileNode
     */
    contentType?: string;
    /**
     * Subdirectory files.
     * @type {Array<V1FileNode>}
     * @memberof V1FileNode
     */
    files?: Array<V1FileNode>;
}

/**
 * The fitting policy of the scheduler.   - FITTING_POLICY_UNSPECIFIED: Unspecified. This value will never actually be returned by the API, it is just an artifact of using protobuf.  - FITTING_POLICY_BEST: Best fit. Tasks are preferentially “packed” together on the smallest number of agents  - FITTING_POLICY_WORST: Worst fit. Tasks are placed on under-utilized agents, spreading out the tasks.  - FITTING_POLICY_KUBERNETES: A kubernetes placeholder. In k8s, the task placement is delegated to the k8s scheduler so the fitting policy is not relevant.  - FITTING_POLICY_SLURM: A slurm placeholder. When running on slurm, task placement is delegated.  - FITTING_POLICY_PBS: A PBS placeholder. When running on PBS, task placement is delegated.
 * @export
 * @enum {string}
 */
export enum V1FittingPolicy {
    UNSPECIFIED = <any> 'FITTING_POLICY_UNSPECIFIED',
    BEST = <any> 'FITTING_POLICY_BEST',
    WORST = <any> 'FITTING_POLICY_WORST',
    KUBERNETES = <any> 'FITTING_POLICY_KUBERNETES',
    SLURM = <any> 'FITTING_POLICY_SLURM',
    PBS = <any> 'FITTING_POLICY_PBS'
}

/**
 * Response to GetActiveTasksCountRequest.
 * @export
 * @interface V1GetActiveTasksCountResponse
 */
export interface V1GetActiveTasksCountResponse {
    /**
     * The count of commands.
     * @type {number}
     * @memberof V1GetActiveTasksCountResponse
     */
    commands: number;
    /**
     * The count of notebooks.
     * @type {number}
     * @memberof V1GetActiveTasksCountResponse
     */
    notebooks: number;
    /**
     * The count of shells.
     * @type {number}
     * @memberof V1GetActiveTasksCountResponse
     */
    shells: number;
    /**
     * The count of TensorBoards.
     * @type {number}
     * @memberof V1GetActiveTasksCountResponse
     */
    tensorboards: number;
}

/**
 * Response to GetAgentRequest.
 * @export
 * @interface V1GetAgentResponse
 */
export interface V1GetAgentResponse {
    /**
     * The requested agent.
     * @type {V1Agent}
     * @memberof V1GetAgentResponse
     */
    agent?: V1Agent;
}

/**
 * Sorts agents by the given field.   - SORT_BY_UNSPECIFIED: Returns agents in an unsorted list.  - SORT_BY_ID: Returns agents sorted by id.  - SORT_BY_TIME: Returns agents sorted by time.
 * @export
 * @enum {string}
 */
export enum V1GetAgentsRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    ID = <any> 'SORT_BY_ID',
    TIME = <any> 'SORT_BY_TIME'
}

/**
 * Response to GetAgentsRequest.
 * @export
 * @interface V1GetAgentsResponse
 */
export interface V1GetAgentsResponse {
    /**
     * The list of returned agents.
     * @type {Array<V1Agent>}
     * @memberof V1GetAgentsResponse
     */
    agents?: Array<V1Agent>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetAgentsResponse
     */
    pagination?: V1Pagination;
}

/**
 * Response to GetBestSearcherValidationMetricRequest.
 * @export
 * @interface V1GetBestSearcherValidationMetricResponse
 */
export interface V1GetBestSearcherValidationMetricResponse {
    /**
     * The value of the metric.
     * @type {number}
     * @memberof V1GetBestSearcherValidationMetricResponse
     */
    metric?: number;
}

/**
 * Response to GetCheckpointRequest.
 * @export
 * @interface V1GetCheckpointResponse
 */
export interface V1GetCheckpointResponse {
    /**
     * The requested checkpoint.
     * @type {V1Checkpoint}
     * @memberof V1GetCheckpointResponse
     */
    checkpoint: V1Checkpoint;
}

/**
 * Response to GetCommandRequest.
 * @export
 * @interface V1GetCommandResponse
 */
export interface V1GetCommandResponse {
    /**
     * The requested command.
     * @type {V1Command}
     * @memberof V1GetCommandResponse
     */
    command?: V1Command;
    /**
     * The command config.
     * @type {any}
     * @memberof V1GetCommandResponse
     */
    config?: any;
}

/**
 * Sorts commands by the given field.   - SORT_BY_UNSPECIFIED: Returns commands in an unsorted list.  - SORT_BY_ID: Returns commands sorted by id.  - SORT_BY_DESCRIPTION: Returns commands sorted by description.  - SORT_BY_START_TIME: Return commands sorted by start time.
 * @export
 * @enum {string}
 */
export enum V1GetCommandsRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    ID = <any> 'SORT_BY_ID',
    DESCRIPTION = <any> 'SORT_BY_DESCRIPTION',
    STARTTIME = <any> 'SORT_BY_START_TIME'
}

/**
 * Response to GetCommandsRequest.
 * @export
 * @interface V1GetCommandsResponse
 */
export interface V1GetCommandsResponse {
    /**
     * The list of returned commands.
     * @type {Array<V1Command>}
     * @memberof V1GetCommandsResponse
     */
    commands?: Array<V1Command>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetCommandsResponse
     */
    pagination?: V1Pagination;
}

/**
 * 
 * @export
 * @interface V1GetCurrentTrialSearcherOperationResponse
 */
export interface V1GetCurrentTrialSearcherOperationResponse {
    /**
     * The current searcher operation.
     * @type {V1SearcherOperation}
     * @memberof V1GetCurrentTrialSearcherOperationResponse
     */
    op?: V1SearcherOperation;
    /**
     * The status of the searcher operation.
     * @type {boolean}
     * @memberof V1GetCurrentTrialSearcherOperationResponse
     */
    completed?: boolean;
}

/**
 * Sorts checkpoints by the given field.   - SORT_BY_UNSPECIFIED: Returns checkpoints in an unsorted list.  - SORT_BY_UUID: Returns checkpoints sorted by UUID.  - SORT_BY_TRIAL_ID: Returns checkpoints sorted by trial id.  - SORT_BY_BATCH_NUMBER: Returns checkpoints sorted by batch number.  - SORT_BY_END_TIME: Returns checkpoints sorted by end time.  - SORT_BY_STATE: Returns checkpoints sorted by state.  - SORT_BY_SEARCHER_METRIC: Returns checkpoints sorted by the experiment's `searcher.metric` configuration setting.
 * @export
 * @enum {string}
 */
export enum V1GetExperimentCheckpointsRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    UUID = <any> 'SORT_BY_UUID',
    TRIALID = <any> 'SORT_BY_TRIAL_ID',
    BATCHNUMBER = <any> 'SORT_BY_BATCH_NUMBER',
    ENDTIME = <any> 'SORT_BY_END_TIME',
    STATE = <any> 'SORT_BY_STATE',
    SEARCHERMETRIC = <any> 'SORT_BY_SEARCHER_METRIC'
}

/**
 * Response to GetExperimentCheckpointsRequest.
 * @export
 * @interface V1GetExperimentCheckpointsResponse
 */
export interface V1GetExperimentCheckpointsResponse {
    /**
     * The list of returned checkpoints.
     * @type {Array<V1Checkpoint>}
     * @memberof V1GetExperimentCheckpointsResponse
     */
    checkpoints: Array<V1Checkpoint>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetExperimentCheckpointsResponse
     */
    pagination: V1Pagination;
}

/**
 * Response to GetExperimentsLabelsRequest.
 * @export
 * @interface V1GetExperimentLabelsResponse
 */
export interface V1GetExperimentLabelsResponse {
    /**
     * The list of labels used across all experiments.
     * @type {Array<string>}
     * @memberof V1GetExperimentLabelsResponse
     */
    labels?: Array<string>;
}

/**
 * Response to GetExperimentRequest.
 * @export
 * @interface V1GetExperimentResponse
 */
export interface V1GetExperimentResponse {
    /**
     * The requested experiment.
     * @type {V1Experiment}
     * @memberof V1GetExperimentResponse
     */
    experiment: V1Experiment;
    /**
     * Associated job summary.
     * @type {V1JobSummary}
     * @memberof V1GetExperimentResponse
     */
    jobSummary?: V1JobSummary;
}

/**
 * Sorts trials by the given field.   - SORT_BY_UNSPECIFIED: Returns trials in an unsorted list.  - SORT_BY_ID: Returns trials sorted by id.  - SORT_BY_START_TIME: Return trials sorted by start time.  - SORT_BY_END_TIME: Return trials sorted by end time. Trials without end times are returned after trials that are.  - SORT_BY_STATE: Return trials sorted by state.  - SORT_BY_BEST_VALIDATION_METRIC: Return the trials sorted by the best metric so far, where the metric is specified by `searcher.metric` in the experiment configuration.  - SORT_BY_LATEST_VALIDATION_METRIC: Return the trials sorted by the latest metric so far, where the metric is specified by `searcher.metric` in the experiment configuration.  - SORT_BY_BATCHES_PROCESSED: Return the trials sorted by the number of batches completed.  - SORT_BY_DURATION: Return the trials sorted by the total duration.  - SORT_BY_RESTARTS: Return the trials sorted by the number of restarts.
 * @export
 * @enum {string}
 */
export enum V1GetExperimentTrialsRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    ID = <any> 'SORT_BY_ID',
    STARTTIME = <any> 'SORT_BY_START_TIME',
    ENDTIME = <any> 'SORT_BY_END_TIME',
    STATE = <any> 'SORT_BY_STATE',
    BESTVALIDATIONMETRIC = <any> 'SORT_BY_BEST_VALIDATION_METRIC',
    LATESTVALIDATIONMETRIC = <any> 'SORT_BY_LATEST_VALIDATION_METRIC',
    BATCHESPROCESSED = <any> 'SORT_BY_BATCHES_PROCESSED',
    DURATION = <any> 'SORT_BY_DURATION',
    RESTARTS = <any> 'SORT_BY_RESTARTS'
}

/**
 * Response to GetExperimentTrialsRequest.
 * @export
 * @interface V1GetExperimentTrialsResponse
 */
export interface V1GetExperimentTrialsResponse {
    /**
     * The list of returned trials.
     * @type {Array<Trialv1Trial>}
     * @memberof V1GetExperimentTrialsResponse
     */
    trials: Array<Trialv1Trial>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetExperimentTrialsResponse
     */
    pagination: V1Pagination;
}

/**
 * Response to GetExperimentValidationHistoryRequest.
 * @export
 * @interface V1GetExperimentValidationHistoryResponse
 */
export interface V1GetExperimentValidationHistoryResponse {
    /**
     * validation_history is a collection of zero or more validation metrics for an experiment, describing the best metrics as they were reported through the lifetime of an experiment. The historical list of best validations.
     * @type {Array<V1ValidationHistoryEntry>}
     * @memberof V1GetExperimentValidationHistoryResponse
     */
    validationHistory?: Array<V1ValidationHistoryEntry>;
}

/**
 * Sorts experiments by the given field.   - SORT_BY_UNSPECIFIED: Returns experiments in an unsorted list.  - SORT_BY_ID: Returns experiments sorted by id.  - SORT_BY_DESCRIPTION: Returns experiments sorted by description.  - SORT_BY_START_TIME: Return experiments sorted by start time.  - SORT_BY_END_TIME: Return experiments sorted by end time. Experiments without end_time are returned after the ones with end_time.  - SORT_BY_STATE: Return experiments sorted by state.  - SORT_BY_NUM_TRIALS: Return experiments sorted by number of trials.  - SORT_BY_PROGRESS: Return experiments sorted by progress.  - SORT_BY_USER: Return experiments sorted by user.  - SORT_BY_NAME: Returns experiments sorted by name.  - SORT_BY_FORKED_FROM: Returns experiments sorted by originating model.  - SORT_BY_RESOURCE_POOL: Returns experiments sorted by resource pool.  - SORT_BY_PROJECT_ID: Returns experiments sorted by project.
 * @export
 * @enum {string}
 */
export enum V1GetExperimentsRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    ID = <any> 'SORT_BY_ID',
    DESCRIPTION = <any> 'SORT_BY_DESCRIPTION',
    STARTTIME = <any> 'SORT_BY_START_TIME',
    ENDTIME = <any> 'SORT_BY_END_TIME',
    STATE = <any> 'SORT_BY_STATE',
    NUMTRIALS = <any> 'SORT_BY_NUM_TRIALS',
    PROGRESS = <any> 'SORT_BY_PROGRESS',
    USER = <any> 'SORT_BY_USER',
    NAME = <any> 'SORT_BY_NAME',
    FORKEDFROM = <any> 'SORT_BY_FORKED_FROM',
    RESOURCEPOOL = <any> 'SORT_BY_RESOURCE_POOL',
    PROJECTID = <any> 'SORT_BY_PROJECT_ID'
}

/**
 * Response to GetExperimentsRequest.
 * @export
 * @interface V1GetExperimentsResponse
 */
export interface V1GetExperimentsResponse {
    /**
     * The list of returned experiments.
     * @type {Array<V1Experiment>}
     * @memberof V1GetExperimentsResponse
     */
    experiments: Array<V1Experiment>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetExperimentsResponse
     */
    pagination: V1Pagination;
}

/**
 * GetGroupResponse is the body of the response for the call to get a group by id.
 * @export
 * @interface V1GetGroupResponse
 */
export interface V1GetGroupResponse {
    /**
     * 
     * @type {V1GroupDetails}
     * @memberof V1GetGroupResponse
     */
    group: V1GroupDetails;
}

/**
 * Response object for GetGroupsAndUsersAssignedToWorkspace.
 * @export
 * @interface V1GetGroupsAndUsersAssignedToWorkspaceResponse
 */
export interface V1GetGroupsAndUsersAssignedToWorkspaceResponse {
    /**
     * Groups with a role assigned to the given workspace scope. Contains user membership of each group.
     * @type {Array<V1GroupDetails>}
     * @memberof V1GetGroupsAndUsersAssignedToWorkspaceResponse
     */
    groups: Array<V1GroupDetails>;
    /**
     * Only contains users assigned directly to roles on the workspace scope.
     * @type {Array<V1User>}
     * @memberof V1GetGroupsAndUsersAssignedToWorkspaceResponse
     */
    usersAssignedDirectly: Array<V1User>;
    /**
     * Roles assigned to workspace with associations between groups and users_assigned_directly with roles.
     * @type {Array<V1RoleWithAssignments>}
     * @memberof V1GetGroupsAndUsersAssignedToWorkspaceResponse
     */
    assignments: Array<V1RoleWithAssignments>;
}

/**
 * GetGroupsRequest is the body of the request for the call to search for groups.
 * @export
 * @interface V1GetGroupsRequest
 */
export interface V1GetGroupsRequest {
    /**
     * The id of the user to use to find groups to which the user belongs.
     * @type {number}
     * @memberof V1GetGroupsRequest
     */
    userId?: number;
    /**
     * The group name to use when searching.
     * @type {string}
     * @memberof V1GetGroupsRequest
     */
    name?: string;
    /**
     * Skip the number of groups before returning results. Negative values denote number of groups to skip from the end before returning results.
     * @type {number}
     * @memberof V1GetGroupsRequest
     */
    offset?: number;
    /**
     * Limit the number of groups. Required and must be must be <= 500.
     * @type {number}
     * @memberof V1GetGroupsRequest
     */
    limit: number;
}

/**
 * GetGroupsResponse is the body of the response for the call to search for groups.
 * @export
 * @interface V1GetGroupsResponse
 */
export interface V1GetGroupsResponse {
    /**
     * 
     * @type {Array<V1GroupSearchResult>}
     * @memberof V1GetGroupsResponse
     */
    groups?: Array<V1GroupSearchResult>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetGroupsResponse
     */
    pagination?: V1Pagination;
}

/**
 * 
 * @export
 * @interface V1GetHPImportanceResponse
 */
export interface V1GetHPImportanceResponse {
    /**
     * A map of training metric names to their respective entries.
     * @type {{ [key: string]: GetHPImportanceResponseMetricHPImportance; }}
     * @memberof V1GetHPImportanceResponse
     */
    trainingMetrics: { [key: string]: GetHPImportanceResponseMetricHPImportance; };
    /**
     * A map of validation metric names to their respective entries.
     * @type {{ [key: string]: GetHPImportanceResponseMetricHPImportance; }}
     * @memberof V1GetHPImportanceResponse
     */
    validationMetrics: { [key: string]: GetHPImportanceResponseMetricHPImportance; };
}

/**
 * Response to GetJobQueueStatsRequest.
 * @export
 * @interface V1GetJobQueueStatsResponse
 */
export interface V1GetJobQueueStatsResponse {
    /**
     * List of queue stats per resource pool.
     * @type {Array<V1RPQueueStat>}
     * @memberof V1GetJobQueueStatsResponse
     */
    results: Array<V1RPQueueStat>;
}

/**
 * Response to GetJobsRequest.
 * @export
 * @interface V1GetJobsResponse
 */
export interface V1GetJobsResponse {
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetJobsResponse
     */
    pagination: V1Pagination;
    /**
     * List of the request jobs.
     * @type {Array<V1Job>}
     * @memberof V1GetJobsResponse
     */
    jobs: Array<V1Job>;
}

/**
 * Response to GetMasterRequest.
 * @export
 * @interface V1GetMasterConfigResponse
 */
export interface V1GetMasterConfigResponse {
    /**
     * The config file loaded by the master.
     * @type {any}
     * @memberof V1GetMasterConfigResponse
     */
    config: any;
}

/**
 * Response to GetMasterRequest.
 * @export
 * @interface V1GetMasterResponse
 */
export interface V1GetMasterResponse {
    /**
     * The current version of the master.
     * @type {string}
     * @memberof V1GetMasterResponse
     */
    version: string;
    /**
     * The current instance id of the master.
     * @type {string}
     * @memberof V1GetMasterResponse
     */
    masterId: string;
    /**
     * The global cluster id of the master.
     * @type {string}
     * @memberof V1GetMasterResponse
     */
    clusterId: string;
    /**
     * The cluster name.
     * @type {string}
     * @memberof V1GetMasterResponse
     */
    clusterName: string;
    /**
     * Telemetry status.
     * @type {boolean}
     * @memberof V1GetMasterResponse
     */
    telemetryEnabled?: boolean;
    /**
     * SSO providers.
     * @type {Array<V1SSOProvider>}
     * @memberof V1GetMasterResponse
     */
    ssoProviders?: Array<V1SSOProvider>;
    /**
     * Redirect for starting internal sessions..
     * @type {string}
     * @memberof V1GetMasterResponse
     */
    externalLoginUri?: string;
    /**
     * Redirect for ending external sessions.
     * @type {string}
     * @memberof V1GetMasterResponse
     */
    externalLogoutUri?: string;
    /**
     * Branding style to use on front-end.
     * @type {string}
     * @memberof V1GetMasterResponse
     */
    branding?: string;
    /**
     * Feature flag for RBAC and user groups.
     * @type {boolean}
     * @memberof V1GetMasterResponse
     */
    rbacEnabled?: boolean;
}

/**
 * Request to get a file of model definition.
 * @export
 * @interface V1GetModelDefFileRequest
 */
export interface V1GetModelDefFileRequest {
    /**
     * The id of the experiment.
     * @type {number}
     * @memberof V1GetModelDefFileRequest
     */
    experimentId?: number;
    /**
     * The path of file.
     * @type {string}
     * @memberof V1GetModelDefFileRequest
     */
    path?: string;
}

/**
 * Response to GetModelDefFileRequest.
 * @export
 * @interface V1GetModelDefFileResponse
 */
export interface V1GetModelDefFileResponse {
    /**
     * Content of file.
     * @type {string}
     * @memberof V1GetModelDefFileResponse
     */
    file?: string;
}

/**
 * Response to GetModelDefRequest.
 * @export
 * @interface V1GetModelDefResponse
 */
export interface V1GetModelDefResponse {
    /**
     * The base64-encoded, gzipped, tarball.
     * @type {string}
     * @memberof V1GetModelDefResponse
     */
    b64Tgz: string;
}

/**
 * Response to GetModelDefTreeRequest.
 * @export
 * @interface V1GetModelDefTreeResponse
 */
export interface V1GetModelDefTreeResponse {
    /**
     * File tree of an experiment.
     * @type {Array<V1FileNode>}
     * @memberof V1GetModelDefTreeResponse
     */
    files?: Array<V1FileNode>;
}

/**
 * Response to GetModelLabelsRequest.
 * @export
 * @interface V1GetModelLabelsResponse
 */
export interface V1GetModelLabelsResponse {
    /**
     * List of labels used across all models.
     * @type {Array<string>}
     * @memberof V1GetModelLabelsResponse
     */
    labels: Array<string>;
}

/**
 * Response to GetModelRequest.
 * @export
 * @interface V1GetModelResponse
 */
export interface V1GetModelResponse {
    /**
     * The model requested.
     * @type {V1Model}
     * @memberof V1GetModelResponse
     */
    model: V1Model;
}

/**
 * Response for GetModelVersionRequest.
 * @export
 * @interface V1GetModelVersionResponse
 */
export interface V1GetModelVersionResponse {
    /**
     * The model version requested.
     * @type {V1ModelVersion}
     * @memberof V1GetModelVersionResponse
     */
    modelVersion: V1ModelVersion;
}

/**
 * Sort models by the given field.   - SORT_BY_UNSPECIFIED: Returns model versions in an unsorted list.  - SORT_BY_VERSION: Returns model versions sorted by version number.  - SORT_BY_CREATION_TIME: Returns model versions sorted by creation_time.
 * @export
 * @enum {string}
 */
export enum V1GetModelVersionsRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    VERSION = <any> 'SORT_BY_VERSION',
    CREATIONTIME = <any> 'SORT_BY_CREATION_TIME'
}

/**
 * Response for GetModelVersionRequest.
 * @export
 * @interface V1GetModelVersionsResponse
 */
export interface V1GetModelVersionsResponse {
    /**
     * The model requested.
     * @type {V1Model}
     * @memberof V1GetModelVersionsResponse
     */
    model: V1Model;
    /**
     * The list of returned model versions.
     * @type {Array<V1ModelVersion>}
     * @memberof V1GetModelVersionsResponse
     */
    modelVersions: Array<V1ModelVersion>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetModelVersionsResponse
     */
    pagination: V1Pagination;
}

/**
 * Sort models by the given field.   - SORT_BY_UNSPECIFIED: Returns models in an unsorted list.  - SORT_BY_NAME: Returns models sorted by name.  - SORT_BY_DESCRIPTION: Returns models sorted by description.  - SORT_BY_CREATION_TIME: Returns models sorted by creation time.  - SORT_BY_LAST_UPDATED_TIME: Returns models sorted by last updated time.  - SORT_BY_NUM_VERSIONS: Returns models sorted by number of version.
 * @export
 * @enum {string}
 */
export enum V1GetModelsRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    NAME = <any> 'SORT_BY_NAME',
    DESCRIPTION = <any> 'SORT_BY_DESCRIPTION',
    CREATIONTIME = <any> 'SORT_BY_CREATION_TIME',
    LASTUPDATEDTIME = <any> 'SORT_BY_LAST_UPDATED_TIME',
    NUMVERSIONS = <any> 'SORT_BY_NUM_VERSIONS'
}

/**
 * Response to GetModelsRequest.
 * @export
 * @interface V1GetModelsResponse
 */
export interface V1GetModelsResponse {
    /**
     * The list of returned models.
     * @type {Array<V1Model>}
     * @memberof V1GetModelsResponse
     */
    models: Array<V1Model>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetModelsResponse
     */
    pagination: V1Pagination;
}

/**
 * Response to GetNotebookRequest.
 * @export
 * @interface V1GetNotebookResponse
 */
export interface V1GetNotebookResponse {
    /**
     * The requested notebook.
     * @type {V1Notebook}
     * @memberof V1GetNotebookResponse
     */
    notebook?: V1Notebook;
    /**
     * The notebook config.
     * @type {any}
     * @memberof V1GetNotebookResponse
     */
    config?: any;
}

/**
 * Sorts notebooks by the given field.   - SORT_BY_UNSPECIFIED: Returns notebooks in an unsorted list.  - SORT_BY_ID: Returns notebooks sorted by id.  - SORT_BY_DESCRIPTION: Returns notebooks sorted by description.  - SORT_BY_START_TIME: Return notebooks sorted by start time.
 * @export
 * @enum {string}
 */
export enum V1GetNotebooksRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    ID = <any> 'SORT_BY_ID',
    DESCRIPTION = <any> 'SORT_BY_DESCRIPTION',
    STARTTIME = <any> 'SORT_BY_START_TIME'
}

/**
 * Response to GetNotebooksRequest.
 * @export
 * @interface V1GetNotebooksResponse
 */
export interface V1GetNotebooksResponse {
    /**
     * The list of returned notebooks.
     * @type {Array<V1Notebook>}
     * @memberof V1GetNotebooksResponse
     */
    notebooks?: Array<V1Notebook>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetNotebooksResponse
     */
    pagination?: V1Pagination;
}

/**
 * Response to GetPermissionsSummaryRequest.
 * @export
 * @interface V1GetPermissionsSummaryResponse
 */
export interface V1GetPermissionsSummaryResponse {
    /**
     * A group of roles in cluster and other scopes.
     * @type {Array<V1Role>}
     * @memberof V1GetPermissionsSummaryResponse
     */
    roles: Array<V1Role>;
    /**
     * Lists of assignments for the cluster and other scopes.
     * @type {Array<V1RoleAssignmentSummary>}
     * @memberof V1GetPermissionsSummaryResponse
     */
    assignments: Array<V1RoleAssignmentSummary>;
}

/**
 * Response to GetProjectRequest.
 * @export
 * @interface V1GetProjectResponse
 */
export interface V1GetProjectResponse {
    /**
     * The project requested.
     * @type {V1Project}
     * @memberof V1GetProjectResponse
     */
    project: V1Project;
}

/**
 * Response to GetResourcePoolsRequest.
 * @export
 * @interface V1GetResourcePoolsResponse
 */
export interface V1GetResourcePoolsResponse {
    /**
     * The list of returned resource pools.
     * @type {Array<V1ResourcePool>}
     * @memberof V1GetResourcePoolsResponse
     */
    resourcePools?: Array<V1ResourcePool>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetResourcePoolsResponse
     */
    pagination?: V1Pagination;
}

/**
 * 
 * @export
 * @interface V1GetRolesAssignedToGroupResponse
 */
export interface V1GetRolesAssignedToGroupResponse {
    /**
     * 
     * @type {Array<V1Role>}
     * @memberof V1GetRolesAssignedToGroupResponse
     */
    roles?: Array<V1Role>;
}

/**
 * 
 * @export
 * @interface V1GetRolesAssignedToUserResponse
 */
export interface V1GetRolesAssignedToUserResponse {
    /**
     * 
     * @type {Array<V1Role>}
     * @memberof V1GetRolesAssignedToUserResponse
     */
    roles?: Array<V1Role>;
}

/**
 * 
 * @export
 * @interface V1GetRolesByIDRequest
 */
export interface V1GetRolesByIDRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof V1GetRolesByIDRequest
     */
    roleIds?: Array<number>;
}

/**
 * 
 * @export
 * @interface V1GetRolesByIDResponse
 */
export interface V1GetRolesByIDResponse {
    /**
     * 
     * @type {Array<V1RoleWithAssignments>}
     * @memberof V1GetRolesByIDResponse
     */
    roles?: Array<V1RoleWithAssignments>;
}

/**
 * Response to GetShellRequest.
 * @export
 * @interface V1GetShellResponse
 */
export interface V1GetShellResponse {
    /**
     * The requested shell.
     * @type {V1Shell}
     * @memberof V1GetShellResponse
     */
    shell?: V1Shell;
    /**
     * The shell config.
     * @type {any}
     * @memberof V1GetShellResponse
     */
    config?: any;
}

/**
 * Sorts shells by the given field.   - SORT_BY_UNSPECIFIED: Returns shells in an unsorted list.  - SORT_BY_ID: Returns shells sorted by id.  - SORT_BY_DESCRIPTION: Returns shells sorted by description.  - SORT_BY_START_TIME: Return shells sorted by start time.
 * @export
 * @enum {string}
 */
export enum V1GetShellsRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    ID = <any> 'SORT_BY_ID',
    DESCRIPTION = <any> 'SORT_BY_DESCRIPTION',
    STARTTIME = <any> 'SORT_BY_START_TIME'
}

/**
 * Response to GetShellsRequest.
 * @export
 * @interface V1GetShellsResponse
 */
export interface V1GetShellsResponse {
    /**
     * The list of returned shells.
     * @type {Array<V1Shell>}
     * @memberof V1GetShellsResponse
     */
    shells?: Array<V1Shell>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetShellsResponse
     */
    pagination?: V1Pagination;
}

/**
 * Response to GetSlotRequest.
 * @export
 * @interface V1GetSlotResponse
 */
export interface V1GetSlotResponse {
    /**
     * The requested slot.
     * @type {V1Slot}
     * @memberof V1GetSlotResponse
     */
    slot?: V1Slot;
}

/**
 * Response to GetSlotsRequest.
 * @export
 * @interface V1GetSlotsResponse
 */
export interface V1GetSlotsResponse {
    /**
     * The requested slots.
     * @type {Array<V1Slot>}
     * @memberof V1GetSlotsResponse
     */
    slots?: Array<V1Slot>;
}

/**
 * Response to GetTaskRequest.
 * @export
 * @interface V1GetTaskResponse
 */
export interface V1GetTaskResponse {
    /**
     * The requested task.
     * @type {V1Task}
     * @memberof V1GetTaskResponse
     */
    task?: V1Task;
}

/**
 * Response to GetTelemetryRequest.
 * @export
 * @interface V1GetTelemetryResponse
 */
export interface V1GetTelemetryResponse {
    /**
     * Whether telemetry is enabled or not.
     * @type {boolean}
     * @memberof V1GetTelemetryResponse
     */
    enabled: boolean;
    /**
     * The key used for analytics in the UI.
     * @type {string}
     * @memberof V1GetTelemetryResponse
     */
    segmentKey?: string;
}

/**
 * Response to GetTemplateRequest.
 * @export
 * @interface V1GetTemplateResponse
 */
export interface V1GetTemplateResponse {
    /**
     * The requested template.
     * @type {V1Template}
     * @memberof V1GetTemplateResponse
     */
    template?: V1Template;
}

/**
 * Sorts templates by the given field.   - SORT_BY_UNSPECIFIED: Returns templates in an unsorted list.  - SORT_BY_NAME: Returns templates sorted by name.
 * @export
 * @enum {string}
 */
export enum V1GetTemplatesRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    NAME = <any> 'SORT_BY_NAME'
}

/**
 * Response to GetTemplatesRequest.
 * @export
 * @interface V1GetTemplatesResponse
 */
export interface V1GetTemplatesResponse {
    /**
     * the list of requested templates.
     * @type {Array<V1Template>}
     * @memberof V1GetTemplatesResponse
     */
    templates?: Array<V1Template>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetTemplatesResponse
     */
    pagination?: V1Pagination;
}

/**
 * Response to GetTensorboardRequest.
 * @export
 * @interface V1GetTensorboardResponse
 */
export interface V1GetTensorboardResponse {
    /**
     * The requested tensorboard.
     * @type {V1Tensorboard}
     * @memberof V1GetTensorboardResponse
     */
    tensorboard?: V1Tensorboard;
    /**
     * 
     * @type {any}
     * @memberof V1GetTensorboardResponse
     */
    config?: any;
}

/**
 * Sorts tensorboards by the given field.   - SORT_BY_UNSPECIFIED: Returns tensorboards in an unsorted list.  - SORT_BY_ID: Returns tensorboards sorted by id.  - SORT_BY_DESCRIPTION: Returns tensorboards sorted by description.  - SORT_BY_START_TIME: Return tensorboards sorted by start time.
 * @export
 * @enum {string}
 */
export enum V1GetTensorboardsRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    ID = <any> 'SORT_BY_ID',
    DESCRIPTION = <any> 'SORT_BY_DESCRIPTION',
    STARTTIME = <any> 'SORT_BY_START_TIME'
}

/**
 * Response to GetTensorboardsRequest.
 * @export
 * @interface V1GetTensorboardsResponse
 */
export interface V1GetTensorboardsResponse {
    /**
     * The list of returned tensorboards.
     * @type {Array<V1Tensorboard>}
     * @memberof V1GetTensorboardsResponse
     */
    tensorboards?: Array<V1Tensorboard>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetTensorboardsResponse
     */
    pagination?: V1Pagination;
}

/**
 * Sorts checkpoints by the given field.   - SORT_BY_UNSPECIFIED: Returns checkpoints in an unsorted list.  - SORT_BY_UUID: Returns checkpoints sorted by UUID.  - SORT_BY_BATCH_NUMBER: Returns checkpoints sorted by batch number.  - SORT_BY_END_TIME: Returns checkpoints sorted by end time.  - SORT_BY_STATE: Returns checkpoints sorted by state.
 * @export
 * @enum {string}
 */
export enum V1GetTrialCheckpointsRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    UUID = <any> 'SORT_BY_UUID',
    BATCHNUMBER = <any> 'SORT_BY_BATCH_NUMBER',
    ENDTIME = <any> 'SORT_BY_END_TIME',
    STATE = <any> 'SORT_BY_STATE'
}

/**
 * Response to GetTrialCheckpointsRequest.
 * @export
 * @interface V1GetTrialCheckpointsResponse
 */
export interface V1GetTrialCheckpointsResponse {
    /**
     * The list of returned checkpoints.
     * @type {Array<V1Checkpoint>}
     * @memberof V1GetTrialCheckpointsResponse
     */
    checkpoints: Array<V1Checkpoint>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetTrialCheckpointsResponse
     */
    pagination: V1Pagination;
}

/**
 * Response to TrialProfilerAvailableSeriesRequest.
 * @export
 * @interface V1GetTrialProfilerAvailableSeriesResponse
 */
export interface V1GetTrialProfilerAvailableSeriesResponse {
    /**
     * The labels for the series.
     * @type {Array<V1TrialProfilerMetricLabels>}
     * @memberof V1GetTrialProfilerAvailableSeriesResponse
     */
    labels: Array<V1TrialProfilerMetricLabels>;
}

/**
 * 
 * @export
 * @interface V1GetTrialProfilerMetricsResponse
 */
export interface V1GetTrialProfilerMetricsResponse {
    /**
     * A batch matching the series requested.
     * @type {V1TrialProfilerMetricsBatch}
     * @memberof V1GetTrialProfilerMetricsResponse
     */
    batch: V1TrialProfilerMetricsBatch;
}

/**
 * Response to GetTrialRequest.
 * @export
 * @interface V1GetTrialResponse
 */
export interface V1GetTrialResponse {
    /**
     * The requested trial.
     * @type {Trialv1Trial}
     * @memberof V1GetTrialResponse
     */
    trial: Trialv1Trial;
}

/**
 * Response to GetTrialWorkloadsRequest.
 * @export
 * @interface V1GetTrialWorkloadsResponse
 */
export interface V1GetTrialWorkloadsResponse {
    /**
     * The list of returned workloads.
     * @type {Array<V1WorkloadContainer>}
     * @memberof V1GetTrialWorkloadsResponse
     */
    workloads: Array<V1WorkloadContainer>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetTrialWorkloadsResponse
     */
    pagination: V1Pagination;
}

/**
 * Response message for GetTrialsCollection.
 * @export
 * @interface V1GetTrialsCollectionsResponse
 */
export interface V1GetTrialsCollectionsResponse {
    /**
     * The collections belonging to that project.
     * @type {Array<V1TrialsCollection>}
     * @memberof V1GetTrialsCollectionsResponse
     */
    collections?: Array<V1TrialsCollection>;
}

/**
 * Response to GetUserRequest.
 * @export
 * @interface V1GetUserResponse
 */
export interface V1GetUserResponse {
    /**
     * The requested user.
     * @type {V1User}
     * @memberof V1GetUserResponse
     */
    user: V1User;
}

/**
 * Response to GetUserSettingRequest.
 * @export
 * @interface V1GetUserSettingResponse
 */
export interface V1GetUserSettingResponse {
    /**
     * List of user settings.
     * @type {Array<V1UserWebSetting>}
     * @memberof V1GetUserSettingResponse
     */
    settings: Array<V1UserWebSetting>;
}

/**
 * Sort users by the given field.   - SORT_BY_UNSPECIFIED: Returns users in an unsorted list.  - SORT_BY_DISPLAY_NAME: Returns users sorted by display name.  - SORT_BY_USER_NAME: Returns users sorted by user name.  - SORT_BY_ADMIN: Returns users sorted by if they are admin.  - SORT_BY_ACTIVE: Returns users sorted by if they are active.  - SORT_BY_MODIFIED_TIME: Returns users sorted by modified time.
 * @export
 * @enum {string}
 */
export enum V1GetUsersRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    DISPLAYNAME = <any> 'SORT_BY_DISPLAY_NAME',
    USERNAME = <any> 'SORT_BY_USER_NAME',
    ADMIN = <any> 'SORT_BY_ADMIN',
    ACTIVE = <any> 'SORT_BY_ACTIVE',
    MODIFIEDTIME = <any> 'SORT_BY_MODIFIED_TIME'
}

/**
 * Response to GetUsersRequest.
 * @export
 * @interface V1GetUsersResponse
 */
export interface V1GetUsersResponse {
    /**
     * The list of requested users.
     * @type {Array<V1User>}
     * @memberof V1GetUsersResponse
     */
    users?: Array<V1User>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetUsersResponse
     */
    pagination?: V1Pagination;
}

/**
 * Sort associated projects by the given field.   - SORT_BY_UNSPECIFIED: Returns projects in an unsorted list.  - SORT_BY_CREATION_TIME: Returns projects sorted by time that they were created.  - SORT_BY_LAST_EXPERIMENT_START_TIME: Returns projects sorted by most recent start of an experiment.  - SORT_BY_NAME: Returns projects sorted by name.  - SORT_BY_DESCRIPTION: Returns projects sorted by description.  - SORT_BY_ID: Returns projects sorted by ID.
 * @export
 * @enum {string}
 */
export enum V1GetWorkspaceProjectsRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    CREATIONTIME = <any> 'SORT_BY_CREATION_TIME',
    LASTEXPERIMENTSTARTTIME = <any> 'SORT_BY_LAST_EXPERIMENT_START_TIME',
    NAME = <any> 'SORT_BY_NAME',
    DESCRIPTION = <any> 'SORT_BY_DESCRIPTION',
    ID = <any> 'SORT_BY_ID'
}

/**
 * Response to GetWorkspaceProjectsRequest.
 * @export
 * @interface V1GetWorkspaceProjectsResponse
 */
export interface V1GetWorkspaceProjectsResponse {
    /**
     * The projects associated with the workspace.
     * @type {Array<V1Project>}
     * @memberof V1GetWorkspaceProjectsResponse
     */
    projects: Array<V1Project>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetWorkspaceProjectsResponse
     */
    pagination: V1Pagination;
}

/**
 * Response to GetWorkspaceRequest.
 * @export
 * @interface V1GetWorkspaceResponse
 */
export interface V1GetWorkspaceResponse {
    /**
     * The workspace requested.
     * @type {V1Workspace}
     * @memberof V1GetWorkspaceResponse
     */
    workspace: V1Workspace;
}

/**
 * Sort workspaces by the given field.   - SORT_BY_UNSPECIFIED: Returns workspaces in an unsorted list.  - SORT_BY_ID: Returns workspaces sorted by id.  - SORT_BY_NAME: Returns workspaces sorted by name.
 * @export
 * @enum {string}
 */
export enum V1GetWorkspacesRequestSortBy {
    UNSPECIFIED = <any> 'SORT_BY_UNSPECIFIED',
    ID = <any> 'SORT_BY_ID',
    NAME = <any> 'SORT_BY_NAME'
}

/**
 * Response to GetWorkspacesRequest.
 * @export
 * @interface V1GetWorkspacesResponse
 */
export interface V1GetWorkspacesResponse {
    /**
     * The list of returned workspaces.
     * @type {Array<V1Workspace>}
     * @memberof V1GetWorkspacesResponse
     */
    workspaces: Array<V1Workspace>;
    /**
     * Pagination information of the full dataset.
     * @type {V1Pagination}
     * @memberof V1GetWorkspacesResponse
     */
    pagination: V1Pagination;
}

/**
 * 
 * @export
 * @interface V1Group
 */
export interface V1Group {
    /**
     * 
     * @type {number}
     * @memberof V1Group
     */
    groupId?: number;
    /**
     * 
     * @type {string}
     * @memberof V1Group
     */
    name?: string;
}

/**
 * GroupDetails contains detailed information about a specific Group including which users belong to the group.
 * @export
 * @interface V1GroupDetails
 */
export interface V1GroupDetails {
    /**
     * 
     * @type {number}
     * @memberof V1GroupDetails
     */
    groupId?: number;
    /**
     * 
     * @type {string}
     * @memberof V1GroupDetails
     */
    name?: string;
    /**
     * 
     * @type {Array<V1User>}
     * @memberof V1GroupDetails
     */
    users?: Array<V1User>;
}

/**
 * GroupRoleAssignment contains information about the groups belonging to a role.
 * @export
 * @interface V1GroupRoleAssignment
 */
export interface V1GroupRoleAssignment {
    /**
     * 
     * @type {number}
     * @memberof V1GroupRoleAssignment
     */
    groupId: number;
    /**
     * The role and scope of the assignment.
     * @type {V1RoleAssignment}
     * @memberof V1GroupRoleAssignment
     */
    roleAssignment: V1RoleAssignment;
}

/**
 * GroupSearchResult is the representation of groups as they're returned by the search endpoint.
 * @export
 * @interface V1GroupSearchResult
 */
export interface V1GroupSearchResult {
    /**
     * 
     * @type {V1Group}
     * @memberof V1GroupSearchResult
     */
    group: V1Group;
    /**
     * 
     * @type {number}
     * @memberof V1GroupSearchResult
     */
    numMembers: number;
}

/**
 * Kill the requested notebook if idle.
 * @export
 * @interface V1IdleNotebookRequest
 */
export interface V1IdleNotebookRequest {
    /**
     * The id of the notebook.
     * @type {string}
     * @memberof V1IdleNotebookRequest
     */
    notebookId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1IdleNotebookRequest
     */
    idle?: boolean;
}

/**
 * Response to IdleNotebookRequest.
 * @export
 * @interface V1IdleNotebookResponse
 */
export interface V1IdleNotebookResponse {
}

/**
 * Int32 filters.
 * @export
 * @interface V1Int32FieldFilter
 */
export interface V1Int32FieldFilter {
    /**
     * TODO(ilia): add `exact`. Less than.
     * @type {number}
     * @memberof V1Int32FieldFilter
     */
    lt?: number;
    /**
     * Less than or equal.
     * @type {number}
     * @memberof V1Int32FieldFilter
     */
    lte?: number;
    /**
     * Greater than.
     * @type {number}
     * @memberof V1Int32FieldFilter
     */
    gt?: number;
    /**
     * Greater than or equal.
     * @type {number}
     * @memberof V1Int32FieldFilter
     */
    gte?: number;
    /**
     * In a set. `in` is a reserved word in python.
     * @type {Array<number>}
     * @memberof V1Int32FieldFilter
     */
    incl?: Array<number>;
    /**
     * Not in a set.
     * @type {Array<number>}
     * @memberof V1Int32FieldFilter
     */
    notIn?: Array<number>;
}

/**
 * Job represents a user submitted work that is not in a terminal state.
 * @export
 * @interface V1Job
 */
export interface V1Job {
    /**
     * Job summary.
     * @type {V1JobSummary}
     * @memberof V1Job
     */
    summary?: V1JobSummary;
    /**
     * Job type.
     * @type {Determinedjobv1Type}
     * @memberof V1Job
     */
    type: Determinedjobv1Type;
    /**
     * The time when the job was submitted by the user.
     * @type {Date}
     * @memberof V1Job
     */
    submissionTime: Date;
    /**
     * The username of the user who submitted the job.
     * @type {string}
     * @memberof V1Job
     */
    username: string;
    /**
     * The id of the user who submitted the job.
     * @type {number}
     * @memberof V1Job
     */
    userId?: number;
    /**
     * Associated resource pool.
     * @type {string}
     * @memberof V1Job
     */
    resourcePool: string;
    /**
     * Whether the job is preemptible.
     * @type {boolean}
     * @memberof V1Job
     */
    isPreemptible: boolean;
    /**
     * The job priority in priority scheduler.
     * @type {number}
     * @memberof V1Job
     */
    priority?: number;
    /**
     * The job weight in fairshare scheduler.
     * @type {number}
     * @memberof V1Job
     */
    weight?: number;
    /**
     * Entity ID.
     * @type {string}
     * @memberof V1Job
     */
    entityId: string;
    /**
     * Job type.
     * @type {string}
     * @memberof V1Job
     */
    jobId: string;
    /**
     * Number of requested slots.
     * @type {number}
     * @memberof V1Job
     */
    requestedSlots: number;
    /**
     * Number of allocated slots.
     * @type {number}
     * @memberof V1Job
     */
    allocatedSlots: number;
    /**
     * Job name.
     * @type {string}
     * @memberof V1Job
     */
    name: string;
    /**
     * Job's progress from 0 to 1.
     * @type {number}
     * @memberof V1Job
     */
    progress?: number;
}

/**
 * Job summary.
 * @export
 * @interface V1JobSummary
 */
export interface V1JobSummary {
    /**
     * The scheduling state of the job.
     * @type {Determinedjobv1State}
     * @memberof V1JobSummary
     */
    state: Determinedjobv1State;
    /**
     * The number of jobs ahead of this one in the queue.
     * @type {number}
     * @memberof V1JobSummary
     */
    jobsAhead: number;
}

/**
 * 
 * @export
 * @interface V1K8PriorityClass
 */
export interface V1K8PriorityClass {
    /**
     * Priority class name.
     * @type {string}
     * @memberof V1K8PriorityClass
     */
    priorityClass?: string;
    /**
     * Priority class value.
     * @type {number}
     * @memberof V1K8PriorityClass
     */
    priorityValue?: number;
}

/**
 * Response to KillCommandRequest.
 * @export
 * @interface V1KillCommandResponse
 */
export interface V1KillCommandResponse {
    /**
     * The requested command.
     * @type {V1Command}
     * @memberof V1KillCommandResponse
     */
    command?: V1Command;
}

/**
 * Response to KillExperimentRequest.
 * @export
 * @interface V1KillExperimentResponse
 */
export interface V1KillExperimentResponse {
}

/**
 * Response to KillNotebookRequest.
 * @export
 * @interface V1KillNotebookResponse
 */
export interface V1KillNotebookResponse {
    /**
     * The requested notebook.
     * @type {V1Notebook}
     * @memberof V1KillNotebookResponse
     */
    notebook?: V1Notebook;
}

/**
 * Response to KillShellRequest.
 * @export
 * @interface V1KillShellResponse
 */
export interface V1KillShellResponse {
    /**
     * The requested shell.
     * @type {V1Shell}
     * @memberof V1KillShellResponse
     */
    shell?: V1Shell;
}

/**
 * Response to KillTensorboardRequest.
 * @export
 * @interface V1KillTensorboardResponse
 */
export interface V1KillTensorboardResponse {
    /**
     * The requested tensorboard.
     * @type {V1Tensorboard}
     * @memberof V1KillTensorboardResponse
     */
    tensorboard?: V1Tensorboard;
}

/**
 * Response to KillTrialRequest.
 * @export
 * @interface V1KillTrialResponse
 */
export interface V1KillTrialResponse {
}

/**
 * Request to launch a command.
 * @export
 * @interface V1LaunchCommandRequest
 */
export interface V1LaunchCommandRequest {
    /**
     * Command config (JSON).
     * @type {any}
     * @memberof V1LaunchCommandRequest
     */
    config?: any;
    /**
     * Template name.
     * @type {string}
     * @memberof V1LaunchCommandRequest
     */
    templateName?: string;
    /**
     * The files to run with the command.
     * @type {Array<V1File>}
     * @memberof V1LaunchCommandRequest
     */
    files?: Array<V1File>;
    /**
     * Additional data.
     * @type {string}
     * @memberof V1LaunchCommandRequest
     */
    data?: string;
}

/**
 * Response to LaunchCommandRequest.
 * @export
 * @interface V1LaunchCommandResponse
 */
export interface V1LaunchCommandResponse {
    /**
     * The requested command.
     * @type {V1Command}
     * @memberof V1LaunchCommandResponse
     */
    command: V1Command;
    /**
     * 
     * @type {any}
     * @memberof V1LaunchCommandResponse
     */
    config: any;
}

/**
 * Request to launch a notebook.
 * @export
 * @interface V1LaunchNotebookRequest
 */
export interface V1LaunchNotebookRequest {
    /**
     * Notebook config (JSON).
     * @type {any}
     * @memberof V1LaunchNotebookRequest
     */
    config?: any;
    /**
     * Template name.
     * @type {string}
     * @memberof V1LaunchNotebookRequest
     */
    templateName?: string;
    /**
     * The files to run with the command.
     * @type {Array<V1File>}
     * @memberof V1LaunchNotebookRequest
     */
    files?: Array<V1File>;
    /**
     * Preview a launching request without actually creating a Notebook.
     * @type {boolean}
     * @memberof V1LaunchNotebookRequest
     */
    preview?: boolean;
}

/**
 * Response to LaunchNotebookRequest.
 * @export
 * @interface V1LaunchNotebookResponse
 */
export interface V1LaunchNotebookResponse {
    /**
     * The requested notebook.
     * @type {V1Notebook}
     * @memberof V1LaunchNotebookResponse
     */
    notebook: V1Notebook;
    /**
     * 
     * @type {any}
     * @memberof V1LaunchNotebookResponse
     */
    config: any;
}

/**
 * Request to launch a shell.
 * @export
 * @interface V1LaunchShellRequest
 */
export interface V1LaunchShellRequest {
    /**
     * Shell config (JSON).
     * @type {any}
     * @memberof V1LaunchShellRequest
     */
    config?: any;
    /**
     * Template name.
     * @type {string}
     * @memberof V1LaunchShellRequest
     */
    templateName?: string;
    /**
     * The files to run with the command.
     * @type {Array<V1File>}
     * @memberof V1LaunchShellRequest
     */
    files?: Array<V1File>;
    /**
     * Additional data.
     * @type {string}
     * @memberof V1LaunchShellRequest
     */
    data?: string;
}

/**
 * Response to LaunchShellRequest.
 * @export
 * @interface V1LaunchShellResponse
 */
export interface V1LaunchShellResponse {
    /**
     * The requested shell.
     * @type {V1Shell}
     * @memberof V1LaunchShellResponse
     */
    shell: V1Shell;
    /**
     * 
     * @type {any}
     * @memberof V1LaunchShellResponse
     */
    config: any;
}

/**
 * Request to launch a tensorboard.
 * @export
 * @interface V1LaunchTensorboardRequest
 */
export interface V1LaunchTensorboardRequest {
    /**
     * List of source experiment ids.
     * @type {Array<number>}
     * @memberof V1LaunchTensorboardRequest
     */
    experimentIds?: Array<number>;
    /**
     * List of source trial ids.
     * @type {Array<number>}
     * @memberof V1LaunchTensorboardRequest
     */
    trialIds?: Array<number>;
    /**
     * Tensorboard config (JSON).
     * @type {any}
     * @memberof V1LaunchTensorboardRequest
     */
    config?: any;
    /**
     * Tensorboard template name.
     * @type {string}
     * @memberof V1LaunchTensorboardRequest
     */
    templateName?: string;
    /**
     * The files to run with the command.
     * @type {Array<V1File>}
     * @memberof V1LaunchTensorboardRequest
     */
    files?: Array<V1File>;
}

/**
 * Response to LaunchTensorboardRequest.
 * @export
 * @interface V1LaunchTensorboardResponse
 */
export interface V1LaunchTensorboardResponse {
    /**
     * The requested tensorboard.
     * @type {V1Tensorboard}
     * @memberof V1LaunchTensorboardResponse
     */
    tensorboard: V1Tensorboard;
    /**
     * 
     * @type {any}
     * @memberof V1LaunchTensorboardResponse
     */
    config: any;
}

/**
 * ListRolesRequest is the body of the request for the call to search for a role.
 * @export
 * @interface V1ListRolesRequest
 */
export interface V1ListRolesRequest {
    /**
     * the offset for pagination.
     * @type {number}
     * @memberof V1ListRolesRequest
     */
    offset?: number;
    /**
     * the limit for pagination.
     * @type {number}
     * @memberof V1ListRolesRequest
     */
    limit: number;
}

/**
 * ListRolesResponse is the body of the response for the call to search for a role.
 * @export
 * @interface V1ListRolesResponse
 */
export interface V1ListRolesResponse {
    /**
     * a set of roles and all assignments belonging to it.
     * @type {Array<V1Role>}
     * @memberof V1ListRolesResponse
     */
    roles: Array<V1Role>;
    /**
     * pagination information.
     * @type {V1Pagination}
     * @memberof V1ListRolesResponse
     */
    pagination: V1Pagination;
}

/**
 * LogEntry is a log event.
 * @export
 * @interface V1LogEntry
 */
export interface V1LogEntry {
    /**
     * The id.
     * @type {number}
     * @memberof V1LogEntry
     */
    id: number;
    /**
     * The message.
     * @type {string}
     * @memberof V1LogEntry
     */
    message?: string;
    /**
     * The timestamp.
     * @type {Date}
     * @memberof V1LogEntry
     */
    timestamp?: Date;
    /**
     * The log level.
     * @type {V1LogLevel}
     * @memberof V1LogEntry
     */
    level?: V1LogLevel;
}

/**
 * LogLevel specifies the level for a log.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
 * @export
 * @enum {string}
 */
export enum V1LogLevel {
    UNSPECIFIED = <any> 'LOG_LEVEL_UNSPECIFIED',
    TRACE = <any> 'LOG_LEVEL_TRACE',
    DEBUG = <any> 'LOG_LEVEL_DEBUG',
    INFO = <any> 'LOG_LEVEL_INFO',
    WARNING = <any> 'LOG_LEVEL_WARNING',
    ERROR = <any> 'LOG_LEVEL_ERROR',
    CRITICAL = <any> 'LOG_LEVEL_CRITICAL'
}

/**
 * Login the user.
 * @export
 * @interface V1LoginRequest
 */
export interface V1LoginRequest {
    /**
     * The username of the user.
     * @type {string}
     * @memberof V1LoginRequest
     */
    username: string;
    /**
     * The password of the user.
     * @type {string}
     * @memberof V1LoginRequest
     */
    password: string;
    /**
     * Indicate whether the provided password is pre-salted & hashed or not.
     * @type {boolean}
     * @memberof V1LoginRequest
     */
    isHashed?: boolean;
}

/**
 * Response to LoginRequest.
 * @export
 * @interface V1LoginResponse
 */
export interface V1LoginResponse {
    /**
     * The token to be used when sending results.
     * @type {string}
     * @memberof V1LoginResponse
     */
    token: string;
    /**
     * The logged in user.
     * @type {V1User}
     * @memberof V1LoginResponse
     */
    user: V1User;
}

/**
 * Response to LogoutRequest.
 * @export
 * @interface V1LogoutResponse
 */
export interface V1LogoutResponse {
}

/**
 * Mark some reservation as a daemon.
 * @export
 * @interface V1MarkAllocationResourcesDaemonRequest
 */
export interface V1MarkAllocationResourcesDaemonRequest {
    /**
     * The id of the allocation.
     * @type {string}
     * @memberof V1MarkAllocationResourcesDaemonRequest
     */
    allocationId: string;
    /**
     * The id of the clump of resources to mark as daemon.
     * @type {string}
     * @memberof V1MarkAllocationResourcesDaemonRequest
     */
    resourcesId?: string;
}

/**
 * Response to MarkAllocationResourcesDaemonRequest.
 * @export
 * @interface V1MarkAllocationResourcesDaemonResponse
 */
export interface V1MarkAllocationResourcesDaemonResponse {
}

/**
 * Response to MasterLogsRequest.
 * @export
 * @interface V1MasterLogsResponse
 */
export interface V1MasterLogsResponse {
    /**
     * The log entry.
     * @type {V1LogEntry}
     * @memberof V1MasterLogsResponse
     */
    logEntry?: V1LogEntry;
}

/**
 * Response to MetricBatchesRequest.
 * @export
 * @interface V1MetricBatchesResponse
 */
export interface V1MetricBatchesResponse {
    /**
     * Milestones (in batches processed) at which the specified metric is recorded.
     * @type {Array<number>}
     * @memberof V1MetricBatchesResponse
     */
    batches?: Array<number>;
}

/**
 * Response to MetricNamesRequest.
 * @export
 * @interface V1MetricNamesResponse
 */
export interface V1MetricNamesResponse {
    /**
     * The name of the searcher metric.
     * @type {string}
     * @memberof V1MetricNamesResponse
     */
    searcherMetric?: string;
    /**
     * List of training metric names.
     * @type {Array<string>}
     * @memberof V1MetricNamesResponse
     */
    trainingMetrics?: Array<string>;
    /**
     * List of validation metric names.
     * @type {Array<string>}
     * @memberof V1MetricNamesResponse
     */
    validationMetrics?: Array<string>;
}

/**
 * To distinguish the 2 different categories of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
 * @export
 * @enum {string}
 */
export enum V1MetricType {
    UNSPECIFIED = <any> 'METRIC_TYPE_UNSPECIFIED',
    TRAINING = <any> 'METRIC_TYPE_TRAINING',
    VALIDATION = <any> 'METRIC_TYPE_VALIDATION'
}

/**
 * 
 * @export
 * @interface V1Metrics
 */
export interface V1Metrics {
    /**
     * 
     * @type {any}
     * @memberof V1Metrics
     */
    avgMetrics: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof V1Metrics
     */
    batchMetrics?: Array<any>;
}

/**
 * MetricsWorkload is a workload generating metrics.
 * @export
 * @interface V1MetricsWorkload
 */
export interface V1MetricsWorkload {
    /**
     * The time the workload finished or was stopped.
     * @type {Date}
     * @memberof V1MetricsWorkload
     */
    endTime?: Date;
    /**
     * The current validation state.
     * @type {Determinedexperimentv1State}
     * @memberof V1MetricsWorkload
     */
    state: Determinedexperimentv1State;
    /**
     * Metrics.
     * @type {V1Metrics}
     * @memberof V1MetricsWorkload
     */
    metrics: V1Metrics;
    /**
     * Number of inputs processed.
     * @type {number}
     * @memberof V1MetricsWorkload
     */
    numInputs: number;
    /**
     * Total number of batches as of this workload's completion.
     * @type {number}
     * @memberof V1MetricsWorkload
     */
    totalBatches: number;
}

/**
 * Model is a named collection of model versions.
 * @export
 * @interface V1Model
 */
export interface V1Model {
    /**
     * The name of the model.
     * @type {string}
     * @memberof V1Model
     */
    name: string;
    /**
     * The description of the model.
     * @type {string}
     * @memberof V1Model
     */
    description?: string;
    /**
     * The user-defined metadata of the model.
     * @type {any}
     * @memberof V1Model
     */
    metadata: any;
    /**
     * The time the model was created.
     * @type {Date}
     * @memberof V1Model
     */
    creationTime: Date;
    /**
     * The time the model was last updated.
     * @type {Date}
     * @memberof V1Model
     */
    lastUpdatedTime: Date;
    /**
     * The id of this model.
     * @type {number}
     * @memberof V1Model
     */
    id: number;
    /**
     * The number of versions associated with this model.
     * @type {number}
     * @memberof V1Model
     */
    numVersions: number;
    /**
     * Labels associated with this model.
     * @type {Array<string>}
     * @memberof V1Model
     */
    labels?: Array<string>;
    /**
     * Username of the user who created this model.
     * @type {string}
     * @memberof V1Model
     */
    username: string;
    /**
     * Id of the user who created this model.
     * @type {number}
     * @memberof V1Model
     */
    userId: number;
    /**
     * Whether this model is archived or not.
     * @type {boolean}
     * @memberof V1Model
     */
    archived: boolean;
    /**
     * Notes associated with this model.
     * @type {string}
     * @memberof V1Model
     */
    notes?: string;
}

/**
 * A version of a model containing a checkpoint. Users can label checkpoints as a version of a model and use the model name and version to locate a checkpoint.
 * @export
 * @interface V1ModelVersion
 */
export interface V1ModelVersion {
    /**
     * The model the version is related to.
     * @type {V1Model}
     * @memberof V1ModelVersion
     */
    model: V1Model;
    /**
     * The checkpoint of the model version.
     * @type {V1Checkpoint}
     * @memberof V1ModelVersion
     */
    checkpoint: V1Checkpoint;
    /**
     * The version number.
     * @type {number}
     * @memberof V1ModelVersion
     */
    version: number;
    /**
     * The time the model version was created.
     * @type {Date}
     * @memberof V1ModelVersion
     */
    creationTime: Date;
    /**
     * Unique id for each model version.
     * @type {number}
     * @memberof V1ModelVersion
     */
    id: number;
    /**
     * Name for this model version.
     * @type {string}
     * @memberof V1ModelVersion
     */
    name?: string;
    /**
     * Metadata associated with this model version.
     * @type {any}
     * @memberof V1ModelVersion
     */
    metadata?: any;
    /**
     * The time this model version was last updated.
     * @type {Date}
     * @memberof V1ModelVersion
     */
    lastUpdatedTime: Date;
    /**
     * Comment associated with this model version.
     * @type {string}
     * @memberof V1ModelVersion
     */
    comment?: string;
    /**
     * Username of the user who created this model version.
     * @type {string}
     * @memberof V1ModelVersion
     */
    username?: string;
    /**
     * Id of the user who created this model version.
     * @type {number}
     * @memberof V1ModelVersion
     */
    userId?: number;
    /**
     * Labels associated with this model version.
     * @type {Array<string>}
     * @memberof V1ModelVersion
     */
    labels?: Array<string>;
    /**
     * Notes associated with this model version.
     * @type {string}
     * @memberof V1ModelVersion
     */
    notes?: string;
}

/**
 * Request to move an experiment into a project.
 * @export
 * @interface V1MoveExperimentRequest
 */
export interface V1MoveExperimentRequest {
    /**
     * The id of the experiment being moved.
     * @type {number}
     * @memberof V1MoveExperimentRequest
     */
    experimentId: number;
    /**
     * The id of the new parent project.
     * @type {number}
     * @memberof V1MoveExperimentRequest
     */
    destinationProjectId: number;
}

/**
 * Response to MoveExperimentRequest.
 * @export
 * @interface V1MoveExperimentResponse
 */
export interface V1MoveExperimentResponse {
}

/**
 * Request to move a project into a workspace.
 * @export
 * @interface V1MoveProjectRequest
 */
export interface V1MoveProjectRequest {
    /**
     * The id of the project being moved.
     * @type {number}
     * @memberof V1MoveProjectRequest
     */
    projectId: number;
    /**
     * The id of the new parent workspace.
     * @type {number}
     * @memberof V1MoveProjectRequest
     */
    destinationWorkspaceId: number;
}

/**
 * Response to MoveProjectRequest.
 * @export
 * @interface V1MoveProjectResponse
 */
export interface V1MoveProjectResponse {
}

/**
 * Note is a user comment connected to a project.
 * @export
 * @interface V1Note
 */
export interface V1Note {
    /**
     * The name or title of the note.
     * @type {string}
     * @memberof V1Note
     */
    name: string;
    /**
     * The text contents of the note.
     * @type {string}
     * @memberof V1Note
     */
    contents: string;
}

/**
 * Notebook is a Jupyter notebook in a containerized environment.
 * @export
 * @interface V1Notebook
 */
export interface V1Notebook {
    /**
     * The id of the notebook.
     * @type {string}
     * @memberof V1Notebook
     */
    id: string;
    /**
     * The description of the notebook.
     * @type {string}
     * @memberof V1Notebook
     */
    description: string;
    /**
     * The state of the notebook.
     * @type {Determinedtaskv1State}
     * @memberof V1Notebook
     */
    state: Determinedtaskv1State;
    /**
     * The time the notebook was started.
     * @type {Date}
     * @memberof V1Notebook
     */
    startTime: Date;
    /**
     * The container running the notebook.
     * @type {V1Container}
     * @memberof V1Notebook
     */
    container?: V1Container;
    /**
     * The display name of the user that created the notebook.
     * @type {string}
     * @memberof V1Notebook
     */
    displayName?: string;
    /**
     * The id of the user that created the notebook.
     * @type {number}
     * @memberof V1Notebook
     */
    userId?: number;
    /**
     * The username of the user that created the notebook.
     * @type {string}
     * @memberof V1Notebook
     */
    username: string;
    /**
     * The service address.
     * @type {string}
     * @memberof V1Notebook
     */
    serviceAddress?: string;
    /**
     * The name of the resource pool the Notebook was created in.
     * @type {string}
     * @memberof V1Notebook
     */
    resourcePool: string;
    /**
     * The exit status.
     * @type {string}
     * @memberof V1Notebook
     */
    exitStatus?: string;
    /**
     * The associated job id.
     * @type {string}
     * @memberof V1Notebook
     */
    jobId: string;
}

/**
 * Order records in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
 * @export
 * @enum {string}
 */
export enum V1OrderBy {
    UNSPECIFIED = <any> 'ORDER_BY_UNSPECIFIED',
    ASC = <any> 'ORDER_BY_ASC',
    DESC = <any> 'ORDER_BY_DESC'
}

/**
 * Pagination provides information about the offset, limit, and total number of records returned in the results.
 * @export
 * @interface V1Pagination
 */
export interface V1Pagination {
    /**
     * The number of records skipped before returning results.
     * @type {number}
     * @memberof V1Pagination
     */
    offset?: number;
    /**
     * The amount of records limited in the results.
     * @type {number}
     * @memberof V1Pagination
     */
    limit?: number;
    /**
     * The index of the first record in the dataset.
     * @type {number}
     * @memberof V1Pagination
     */
    startIndex?: number;
    /**
     * The index+1 of the last record in the dataset.
     * @type {number}
     * @memberof V1Pagination
     */
    endIndex?: number;
    /**
     * The total number of values that match the filter.
     * @type {number}
     * @memberof V1Pagination
     */
    total?: number;
}

/**
 * PatchExperiment is a partial update to an experiment with only id required.
 * @export
 * @interface V1PatchExperiment
 */
export interface V1PatchExperiment {
    /**
     * The id of the experiment.
     * @type {number}
     * @memberof V1PatchExperiment
     */
    id: number;
    /**
     * The description of the experiment.
     * @type {string}
     * @memberof V1PatchExperiment
     */
    description?: string;
    /**
     * Labels attached to the experiment.
     * @type {Array<string>}
     * @memberof V1PatchExperiment
     */
    labels?: Array<string>;
    /**
     * The experiment name.
     * @type {string}
     * @memberof V1PatchExperiment
     */
    name?: string;
    /**
     * The experiment notes.
     * @type {string}
     * @memberof V1PatchExperiment
     */
    notes?: string;
}

/**
 * Response to PatchExperimentRequest.
 * @export
 * @interface V1PatchExperimentResponse
 */
export interface V1PatchExperimentResponse {
    /**
     * Patched experiment.
     * @type {V1Experiment}
     * @memberof V1PatchExperimentResponse
     */
    experiment?: V1Experiment;
}

/**
 * PatchModel is a partial update to a model with only name required.
 * @export
 * @interface V1PatchModel
 */
export interface V1PatchModel {
    /**
     * An updated name for the model.
     * @type {string}
     * @memberof V1PatchModel
     */
    name?: string;
    /**
     * An updated description for the model.
     * @type {string}
     * @memberof V1PatchModel
     */
    description?: string;
    /**
     * An updated metadata object for the model.
     * @type {any}
     * @memberof V1PatchModel
     */
    metadata?: any;
    /**
     * An updated label list for the model.
     * @type {Array<string>}
     * @memberof V1PatchModel
     */
    labels?: Array<string>;
    /**
     * Updated notes associated with this model.
     * @type {string}
     * @memberof V1PatchModel
     */
    notes?: string;
}

/**
 * Response to PatchModelRequest.
 * @export
 * @interface V1PatchModelResponse
 */
export interface V1PatchModelResponse {
    /**
     * The model created.
     * @type {V1Model}
     * @memberof V1PatchModelResponse
     */
    model: V1Model;
}

/**
 * 
 * @export
 * @interface V1PatchModelVersion
 */
export interface V1PatchModelVersion {
    /**
     * An updated checkpoint to associate with the model version.
     * @type {V1Checkpoint}
     * @memberof V1PatchModelVersion
     */
    checkpoint?: V1Checkpoint;
    /**
     * An updated name for the model version.
     * @type {string}
     * @memberof V1PatchModelVersion
     */
    name?: string;
    /**
     * An updated metadata object for the model version.
     * @type {any}
     * @memberof V1PatchModelVersion
     */
    metadata?: any;
    /**
     * An updated comment for the model version.
     * @type {string}
     * @memberof V1PatchModelVersion
     */
    comment?: string;
    /**
     * An updated label list for the model version.
     * @type {Array<string>}
     * @memberof V1PatchModelVersion
     */
    labels?: Array<string>;
    /**
     * Updated text notes for the model version.
     * @type {string}
     * @memberof V1PatchModelVersion
     */
    notes?: string;
}

/**
 * Response to PatchModelVersionRequest.
 * @export
 * @interface V1PatchModelVersionResponse
 */
export interface V1PatchModelVersionResponse {
    /**
     * The model version created.
     * @type {V1ModelVersion}
     * @memberof V1PatchModelVersionResponse
     */
    modelVersion: V1ModelVersion;
}

/**
 * PatchProject is a partial update to a project with all optional fields.
 * @export
 * @interface V1PatchProject
 */
export interface V1PatchProject {
    /**
     * The new name for the project.
     * @type {string}
     * @memberof V1PatchProject
     */
    name?: string;
    /**
     * The new description for the project.
     * @type {string}
     * @memberof V1PatchProject
     */
    description?: string;
}

/**
 * Response to PatchProjectRequest.
 * @export
 * @interface V1PatchProjectResponse
 */
export interface V1PatchProjectResponse {
    /**
     * The updated project.
     * @type {V1Project}
     * @memberof V1PatchProjectResponse
     */
    project: V1Project;
}

/**
 * Request message to PatchTrialsCollection.
 * @export
 * @interface V1PatchTrialsCollectionRequest
 */
export interface V1PatchTrialsCollectionRequest {
    /**
     * The ID of the collection to be patched.
     * @type {number}
     * @memberof V1PatchTrialsCollectionRequest
     */
    id: number;
    /**
     * The new name for the collection.
     * @type {string}
     * @memberof V1PatchTrialsCollectionRequest
     */
    name?: string;
    /**
     * The new filters for the collection.
     * @type {V1TrialFilters}
     * @memberof V1PatchTrialsCollectionRequest
     */
    filters?: V1TrialFilters;
    /**
     * The new sorter for the collection.
     * @type {V1TrialSorter}
     * @memberof V1PatchTrialsCollectionRequest
     */
    sorter?: V1TrialSorter;
}

/**
 * 
 * @export
 * @interface V1PatchTrialsCollectionResponse
 */
export interface V1PatchTrialsCollectionResponse {
    /**
     * The patched collection.
     * @type {V1TrialsCollection}
     * @memberof V1PatchTrialsCollectionResponse
     */
    collection?: V1TrialsCollection;
}

/**
 * Request to edit fields for a user.
 * @export
 * @interface V1PatchUser
 */
export interface V1PatchUser {
    /**
     * Bool denoting whether the account is an admin account.
     * @type {boolean}
     * @memberof V1PatchUser
     */
    admin?: boolean;
    /**
     * Bool denoting whether the account is active.
     * @type {boolean}
     * @memberof V1PatchUser
     */
    active?: boolean;
    /**
     * Name to display in the web UI.
     * @type {string}
     * @memberof V1PatchUser
     */
    displayName?: string;
    /**
     * The user and group on the agent host machine.
     * @type {V1AgentUserGroup}
     * @memberof V1PatchUser
     */
    agentUserGroup?: V1AgentUserGroup;
}

/**
 * Response to PatchUserRequest.
 * @export
 * @interface V1PatchUserResponse
 */
export interface V1PatchUserResponse {
    /**
     * The updated user.
     * @type {V1User}
     * @memberof V1PatchUserResponse
     */
    user: V1User;
}

/**
 * PatchWorkspace is a partial update to a workspace with all optional fields.
 * @export
 * @interface V1PatchWorkspace
 */
export interface V1PatchWorkspace {
    /**
     * The new name for the workspace.
     * @type {string}
     * @memberof V1PatchWorkspace
     */
    name?: string;
    /**
     * Optional agent host uid and gid override.
     * @type {V1AgentUserGroup}
     * @memberof V1PatchWorkspace
     */
    agentUserGroup?: V1AgentUserGroup;
}

/**
 * Response to PatchWorkspaceRequest.
 * @export
 * @interface V1PatchWorkspaceResponse
 */
export interface V1PatchWorkspaceResponse {
    /**
     * The updated workspace.
     * @type {V1Workspace}
     * @memberof V1PatchWorkspaceResponse
     */
    workspace: V1Workspace;
}

/**
 * Response to PauseExperimentRequest.
 * @export
 * @interface V1PauseExperimentResponse
 */
export interface V1PauseExperimentResponse {
}

/**
 * 
 * @export
 * @interface V1Permission
 */
export interface V1Permission {
    /**
     * 
     * @type {V1PermissionType}
     * @memberof V1Permission
     */
    id: V1PermissionType;
    /**
     * 
     * @type {string}
     * @memberof V1Permission
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1Permission
     */
    isGlobal?: boolean;
}

/**
 * List of permissions types. Value of the enum has 9xxxx for global only permissions. Permissions on the same object share the thousands place value like 2001 and 2002.   - PERMISSION_TYPE_UNSPECIFIED: The permission type is unknown.  - PERMISSION_TYPE_ADMINISTRATE_USER: Can create and update other users. Allows updating other users passwords making this permission give all other permissions effectively.  - PERMISSION_TYPE_CREATE_EXPERIMENT: Ability to create experiments.  - PERMISSION_TYPE_VIEW_EXPERIMENT_ARTIFACTS: Ability to view experiment's model code, checkpoints, trials.  - PERMISSION_TYPE_VIEW_EXPERIMENT_METADATA: Ability to view experiment's metadata like experiment config, progress.  - PERMISSION_TYPE_UPDATE_EXPERIMENT: Ability to update experiment and experiment's lifecycle.  - PERMISSION_TYPE_UPDATE_EXPERIMENT_METADATA: Ability to update experiment's metadata.  - PERMISSION_TYPE_DELETE_EXPERIMENT: Ability to delete experiment.  - PERMISSION_TYPE_UPDATE_GROUP: Ability to create, update, and add / remove users from groups.  - PERMISSION_TYPE_CREATE_WORKSPACE: Ability to create workspaces.  - PERMISSION_TYPE_VIEW_WORKSPACE: Ability to view workspace.  - PERMISSION_TYPE_UPDATE_WORKSPACE: Ability to update workspace.  - PERMISSION_TYPE_DELETE_WORKSPACE: Ability to delete workspace.  - PERMISSION_TYPE_SET_WORKSPACE_AGENT_USER_GROUP: Ability to set workspace agent user group config.  - PERMISSION_TYPE_CREATE_PROJECT: Ability to create projects.  - PERMISSION_TYPE_VIEW_PROJECT: Ability to view projects.  - PERMISSION_TYPE_UPDATE_PROJECT: Ability to update projects.  - PERMISSION_TYPE_DELETE_PROJECT: Ability to delete projects.  - PERMISSION_TYPE_UPDATE_ROLES: Ability to create and update role definitions.  - PERMISSION_TYPE_ASSIGN_ROLES: Ability to assign roles to groups / users. If assigned at a workspace scope, can only assign roles to that workspace scope.
 * @export
 * @enum {string}
 */
export enum V1PermissionType {
    UNSPECIFIED = <any> 'PERMISSION_TYPE_UNSPECIFIED',
    ADMINISTRATEUSER = <any> 'PERMISSION_TYPE_ADMINISTRATE_USER',
    CREATEEXPERIMENT = <any> 'PERMISSION_TYPE_CREATE_EXPERIMENT',
    VIEWEXPERIMENTARTIFACTS = <any> 'PERMISSION_TYPE_VIEW_EXPERIMENT_ARTIFACTS',
    VIEWEXPERIMENTMETADATA = <any> 'PERMISSION_TYPE_VIEW_EXPERIMENT_METADATA',
    UPDATEEXPERIMENT = <any> 'PERMISSION_TYPE_UPDATE_EXPERIMENT',
    UPDATEEXPERIMENTMETADATA = <any> 'PERMISSION_TYPE_UPDATE_EXPERIMENT_METADATA',
    DELETEEXPERIMENT = <any> 'PERMISSION_TYPE_DELETE_EXPERIMENT',
    UPDATEGROUP = <any> 'PERMISSION_TYPE_UPDATE_GROUP',
    CREATEWORKSPACE = <any> 'PERMISSION_TYPE_CREATE_WORKSPACE',
    VIEWWORKSPACE = <any> 'PERMISSION_TYPE_VIEW_WORKSPACE',
    UPDATEWORKSPACE = <any> 'PERMISSION_TYPE_UPDATE_WORKSPACE',
    DELETEWORKSPACE = <any> 'PERMISSION_TYPE_DELETE_WORKSPACE',
    SETWORKSPACEAGENTUSERGROUP = <any> 'PERMISSION_TYPE_SET_WORKSPACE_AGENT_USER_GROUP',
    CREATEPROJECT = <any> 'PERMISSION_TYPE_CREATE_PROJECT',
    VIEWPROJECT = <any> 'PERMISSION_TYPE_VIEW_PROJECT',
    UPDATEPROJECT = <any> 'PERMISSION_TYPE_UPDATE_PROJECT',
    DELETEPROJECT = <any> 'PERMISSION_TYPE_DELETE_PROJECT',
    UPDATEROLES = <any> 'PERMISSION_TYPE_UPDATE_ROLES',
    ASSIGNROLES = <any> 'PERMISSION_TYPE_ASSIGN_ROLES'
}

/**
 * Response to PinWorkspaceRequest.
 * @export
 * @interface V1PinWorkspaceResponse
 */
export interface V1PinWorkspaceResponse {
}

/**
 * Set the proxy address for some allocation.
 * @export
 * @interface V1PostAllocationProxyAddressRequest
 */
export interface V1PostAllocationProxyAddressRequest {
    /**
     * The id of the allocation.
     * @type {string}
     * @memberof V1PostAllocationProxyAddressRequest
     */
    allocationId?: string;
    /**
     * The address of the host where the service is, w.r.t. the master.
     * @type {string}
     * @memberof V1PostAllocationProxyAddressRequest
     */
    proxyAddress?: string;
}

/**
 * 
 * @export
 * @interface V1PostAllocationProxyAddressResponse
 */
export interface V1PostAllocationProxyAddressResponse {
}

/**
 * Request for updating a checkpoints metadata.
 * @export
 * @interface V1PostCheckpointMetadataRequest
 */
export interface V1PostCheckpointMetadataRequest {
    /**
     * The desired checkpoint fields and values.
     * @type {V1Checkpoint}
     * @memberof V1PostCheckpointMetadataRequest
     */
    checkpoint?: V1Checkpoint;
}

/**
 * Response to PostCheckpointRequest.
 * @export
 * @interface V1PostCheckpointMetadataResponse
 */
export interface V1PostCheckpointMetadataResponse {
    /**
     * The updated checkpoint.
     * @type {V1Checkpoint}
     * @memberof V1PostCheckpointMetadataResponse
     */
    checkpoint?: V1Checkpoint;
}

/**
 * Request for creating a model in the registry.
 * @export
 * @interface V1PostModelRequest
 */
export interface V1PostModelRequest {
    /**
     * The name of the model.
     * @type {string}
     * @memberof V1PostModelRequest
     */
    name: string;
    /**
     * The description of the model.
     * @type {string}
     * @memberof V1PostModelRequest
     */
    description?: string;
    /**
     * The user-defined metadata of the model.
     * @type {any}
     * @memberof V1PostModelRequest
     */
    metadata?: any;
    /**
     * Labels associated with this model.
     * @type {Array<string>}
     * @memberof V1PostModelRequest
     */
    labels?: Array<string>;
    /**
     * Notes associated with this model.
     * @type {string}
     * @memberof V1PostModelRequest
     */
    notes?: string;
}

/**
 * Response to PostModelRequest.
 * @export
 * @interface V1PostModelResponse
 */
export interface V1PostModelResponse {
    /**
     * The model created.
     * @type {V1Model}
     * @memberof V1PostModelResponse
     */
    model: V1Model;
}

/**
 * Request for creating a model version.
 * @export
 * @interface V1PostModelVersionRequest
 */
export interface V1PostModelVersionRequest {
    /**
     * The name of the model to add this version to.
     * @type {string}
     * @memberof V1PostModelVersionRequest
     */
    modelName: string;
    /**
     * UUID of the checkpoint.
     * @type {string}
     * @memberof V1PostModelVersionRequest
     */
    checkpointUuid: string;
    /**
     * User-friendly name for the model version.
     * @type {string}
     * @memberof V1PostModelVersionRequest
     */
    name?: string;
    /**
     * User-written comment for the model version.
     * @type {string}
     * @memberof V1PostModelVersionRequest
     */
    comment?: string;
    /**
     * The user-defined metadata of the model version.
     * @type {any}
     * @memberof V1PostModelVersionRequest
     */
    metadata?: any;
    /**
     * Labels associated with this model version.
     * @type {Array<string>}
     * @memberof V1PostModelVersionRequest
     */
    labels?: Array<string>;
    /**
     * Notes associated with this model version.
     * @type {string}
     * @memberof V1PostModelVersionRequest
     */
    notes?: string;
}

/**
 * Response for PostModelVersionRequest.
 * @export
 * @interface V1PostModelVersionResponse
 */
export interface V1PostModelVersionResponse {
    /**
     * The model version requested.
     * @type {V1ModelVersion}
     * @memberof V1PostModelVersionResponse
     */
    modelVersion: V1ModelVersion;
}

/**
 * Request for creating a project.
 * @export
 * @interface V1PostProjectRequest
 */
export interface V1PostProjectRequest {
    /**
     * The name of the project.
     * @type {string}
     * @memberof V1PostProjectRequest
     */
    name: string;
    /**
     * Description of the project.
     * @type {string}
     * @memberof V1PostProjectRequest
     */
    description?: string;
    /**
     * Id of the associated workspace.
     * @type {number}
     * @memberof V1PostProjectRequest
     */
    workspaceId: number;
}

/**
 * Response to PostProjectRequest.
 * @export
 * @interface V1PostProjectResponse
 */
export interface V1PostProjectResponse {
    /**
     * The project created.
     * @type {V1Project}
     * @memberof V1PostProjectResponse
     */
    project: V1Project;
}

/**
 * Create a batch of trial profiler metrics.
 * @export
 * @interface V1PostTrialProfilerMetricsBatchRequest
 */
export interface V1PostTrialProfilerMetricsBatchRequest {
    /**
     * The batches to create.
     * @type {Array<V1TrialProfilerMetricsBatch>}
     * @memberof V1PostTrialProfilerMetricsBatchRequest
     */
    batches?: Array<V1TrialProfilerMetricsBatch>;
}

/**
 * 
 * @export
 * @interface V1PostTrialProfilerMetricsBatchResponse
 */
export interface V1PostTrialProfilerMetricsBatchResponse {
}

/**
 * 
 * @export
 * @interface V1PostTrialRunnerMetadataResponse
 */
export interface V1PostTrialRunnerMetadataResponse {
}

/**
 * Create a new user.
 * @export
 * @interface V1PostUserRequest
 */
export interface V1PostUserRequest {
    /**
     * The user to create.
     * @type {V1User}
     * @memberof V1PostUserRequest
     */
    user?: V1User;
    /**
     * The password of the user.
     * @type {string}
     * @memberof V1PostUserRequest
     */
    password?: string;
}

/**
 * Response to PostUserRequest.
 * @export
 * @interface V1PostUserResponse
 */
export interface V1PostUserResponse {
    /**
     * The created user.
     * @type {V1User}
     * @memberof V1PostUserResponse
     */
    user?: V1User;
}

/**
 * Update user settings.
 * @export
 * @interface V1PostUserSettingRequest
 */
export interface V1PostUserSettingRequest {
    /**
     * Storage path for setting.
     * @type {string}
     * @memberof V1PostUserSettingRequest
     */
    storagePath: string;
    /**
     * Setting key value pair.
     * @type {V1UserWebSetting}
     * @memberof V1PostUserSettingRequest
     */
    setting: V1UserWebSetting;
}

/**
 * Response to PostUserSettingRequest.
 * @export
 * @interface V1PostUserSettingResponse
 */
export interface V1PostUserSettingResponse {
}

/**
 * Request for creating a workspace.
 * @export
 * @interface V1PostWorkspaceRequest
 */
export interface V1PostWorkspaceRequest {
    /**
     * The name of the workspace.
     * @type {string}
     * @memberof V1PostWorkspaceRequest
     */
    name: string;
    /**
     * Optional agent host uid and gid override.
     * @type {V1AgentUserGroup}
     * @memberof V1PostWorkspaceRequest
     */
    agentUserGroup?: V1AgentUserGroup;
}

/**
 * Response to PostWorkspaceRequest.
 * @export
 * @interface V1PostWorkspaceResponse
 */
export interface V1PostWorkspaceResponse {
    /**
     * The workspace created.
     * @type {V1Workspace}
     * @memberof V1PostWorkspaceResponse
     */
    workspace: V1Workspace;
}

/**
 * Preview hyperparameter search.
 * @export
 * @interface V1PreviewHPSearchRequest
 */
export interface V1PreviewHPSearchRequest {
    /**
     * The experiment config to simulate.
     * @type {any}
     * @memberof V1PreviewHPSearchRequest
     */
    config?: any;
    /**
     * The searcher simulation seed.
     * @type {number}
     * @memberof V1PreviewHPSearchRequest
     */
    seed?: number;
}

/**
 * Response to PreviewSearchRequest.
 * @export
 * @interface V1PreviewHPSearchResponse
 */
export interface V1PreviewHPSearchResponse {
    /**
     * The resulting simulation.
     * @type {V1ExperimentSimulation}
     * @memberof V1PreviewHPSearchResponse
     */
    simulation?: V1ExperimentSimulation;
}

/**
 * Project is a named collection of experiments.
 * @export
 * @interface V1Project
 */
export interface V1Project {
    /**
     * The unique id of the project.
     * @type {number}
     * @memberof V1Project
     */
    id: number;
    /**
     * The unique name of the project.
     * @type {string}
     * @memberof V1Project
     */
    name: string;
    /**
     * The id of the associated workspace.
     * @type {number}
     * @memberof V1Project
     */
    workspaceId: number;
    /**
     * The description of the project.
     * @type {string}
     * @memberof V1Project
     */
    description?: string;
    /**
     * Time of most recently started experiment within this project.
     * @type {Date}
     * @memberof V1Project
     */
    lastExperimentStartedAt?: Date;
    /**
     * Notes associated with this project.
     * @type {Array<V1Note>}
     * @memberof V1Project
     */
    notes: Array<V1Note>;
    /**
     * Count of experiments associated with this project.
     * @type {number}
     * @memberof V1Project
     */
    numExperiments: number;
    /**
     * Count of active experiments associated with this project.
     * @type {number}
     * @memberof V1Project
     */
    numActiveExperiments: number;
    /**
     * Whether this project is archived or not.
     * @type {boolean}
     * @memberof V1Project
     */
    archived: boolean;
    /**
     * User who created this project.
     * @type {string}
     * @memberof V1Project
     */
    username: string;
    /**
     * Whether this project is immutable (default uncategorized project).
     * @type {boolean}
     * @memberof V1Project
     */
    immutable: boolean;
    /**
     * ID of the user who created this project.
     * @type {number}
     * @memberof V1Project
     */
    userId: number;
    /**
     * The name of the associated workspace.
     * @type {string}
     * @memberof V1Project
     */
    workspaceName?: string;
    /**
     * State of project during deletion.
     * @type {V1WorkspaceState}
     * @memberof V1Project
     */
    state: V1WorkspaceState;
    /**
     * Message stored from errors on async-deleting a project.
     * @type {string}
     * @memberof V1Project
     */
    errorMessage: string;
}

/**
 * Request for setting project notes.
 * @export
 * @interface V1PutProjectNotesRequest
 */
export interface V1PutProjectNotesRequest {
    /**
     * The complete list of notes.
     * @type {Array<V1Note>}
     * @memberof V1PutProjectNotesRequest
     */
    notes: Array<V1Note>;
    /**
     * The id of the project.
     * @type {number}
     * @memberof V1PutProjectNotesRequest
     */
    projectId: number;
}

/**
 * Response to PutProjectNotesRequest.
 * @export
 * @interface V1PutProjectNotesResponse
 */
export interface V1PutProjectNotesResponse {
    /**
     * The complete list of notes on a project.
     * @type {Array<V1Note>}
     * @memberof V1PutProjectNotesResponse
     */
    notes: Array<V1Note>;
}

/**
 * Response to PutTemplateRequest.
 * @export
 * @interface V1PutTemplateResponse
 */
export interface V1PutTemplateResponse {
    /**
     * The updated or created template.
     * @type {V1Template}
     * @memberof V1PutTemplateResponse
     */
    template?: V1Template;
}

/**
 * Request to QueryTrials includes pagination parameters and TrialFilters.
 * @export
 * @interface V1QueryTrialsRequest
 */
export interface V1QueryTrialsRequest {
    /**
     * The filters to be appled to the trial.
     * @type {V1TrialFilters}
     * @memberof V1QueryTrialsRequest
     */
    filters: V1TrialFilters;
    /**
     * Sort trials by the given field.
     * @type {V1TrialSorter}
     * @memberof V1QueryTrialsRequest
     */
    sorter?: V1TrialSorter;
    /**
     * Offset for pagination.
     * @type {number}
     * @memberof V1QueryTrialsRequest
     */
    offset?: number;
    /**
     * Limit the number of trials. A value of 0 denotes no limit.
     * @type {number}
     * @memberof V1QueryTrialsRequest
     */
    limit?: number;
}

/**
 * Response for QueryTrials.
 * @export
 * @interface V1QueryTrialsResponse
 */
export interface V1QueryTrialsResponse {
    /**
     * The trials matching the query, with extra data.
     * @type {Array<V1AugmentedTrial>}
     * @memberof V1QueryTrialsResponse
     */
    trials: Array<V1AugmentedTrial>;
}

/**
 * Describes a message to control jobs in a queue.
 * @export
 * @interface V1QueueControl
 */
export interface V1QueueControl {
    /**
     * Job id.
     * @type {string}
     * @memberof V1QueueControl
     */
    jobId: string;
    /**
     * The desired job position in the queue in terms of another job.
     * @type {string}
     * @memberof V1QueueControl
     */
    aheadOf?: string;
    /**
     * The desired job position in the queue in terms of another job.
     * @type {string}
     * @memberof V1QueueControl
     */
    behindOf?: string;
    /**
     * Name of the target resource_pool to move the job to.
     * @type {string}
     * @memberof V1QueueControl
     */
    resourcePool?: string;
    /**
     * The desired job priority in priority scheduler.
     * @type {number}
     * @memberof V1QueueControl
     */
    priority?: number;
    /**
     * The desired job weight in fairshare scheduler.
     * @type {number}
     * @memberof V1QueueControl
     */
    weight?: number;
}

/**
 * Statistics for a queue.
 * @export
 * @interface V1QueueStats
 */
export interface V1QueueStats {
    /**
     * Number of queued jobs in the queue.
     * @type {number}
     * @memberof V1QueueStats
     */
    queuedCount: number;
    /**
     * Number of scheduled jobs in the queue.
     * @type {number}
     * @memberof V1QueueStats
     */
    scheduledCount: number;
}

/**
 * Job stats for a resource pool.
 * @export
 * @interface V1RPQueueStat
 */
export interface V1RPQueueStat {
    /**
     * Job queue stats.
     * @type {V1QueueStats}
     * @memberof V1RPQueueStat
     */
    stats: V1QueueStats;
    /**
     * Resource pool.
     * @type {string}
     * @memberof V1RPQueueStat
     */
    resourcePool: string;
    /**
     * Aggregate stats.
     * @type {Array<V1AggregateQueueStats>}
     * @memberof V1RPQueueStat
     */
    aggregates?: Array<V1AggregateQueueStats>;
}

/**
 * RemoveAssignmentsRequest is the body of the request for the call to remove a user or group from a role.
 * @export
 * @interface V1RemoveAssignmentsRequest
 */
export interface V1RemoveAssignmentsRequest {
    /**
     * the set of groups being removed from a role.
     * @type {Array<V1GroupRoleAssignment>}
     * @memberof V1RemoveAssignmentsRequest
     */
    groupRoleAssignments?: Array<V1GroupRoleAssignment>;
    /**
     * the set of users being removed from a role.
     * @type {Array<V1UserRoleAssignment>}
     * @memberof V1RemoveAssignmentsRequest
     */
    userRoleAssignments?: Array<V1UserRoleAssignment>;
}

/**
 * RemoveAssignmentsResponse is the body of the response for teh call to remove a user or group from a role.
 * @export
 * @interface V1RemoveAssignmentsResponse
 */
export interface V1RemoveAssignmentsResponse {
}

/**
 * The rendezvous info for the trial to rendezvous with sibling containers.
 * @export
 * @interface V1RendezvousInfo
 */
export interface V1RendezvousInfo {
    /**
     * The rendezvous addresses of the other containers.
     * @type {Array<string>}
     * @memberof V1RendezvousInfo
     */
    addresses: Array<string>;
    /**
     * The container rank.
     * @type {number}
     * @memberof V1RendezvousInfo
     */
    rank: number;
}

/**
 * Response to ReportCheckpointRequest.
 * @export
 * @interface V1ReportCheckpointResponse
 */
export interface V1ReportCheckpointResponse {
}

/**
 * 
 * @export
 * @interface V1ReportTrialProgressResponse
 */
export interface V1ReportTrialProgressResponse {
}

/**
 * 
 * @export
 * @interface V1ReportTrialSearcherEarlyExitResponse
 */
export interface V1ReportTrialSearcherEarlyExitResponse {
}

/**
 * 
 * @export
 * @interface V1ReportTrialTrainingMetricsResponse
 */
export interface V1ReportTrialTrainingMetricsResponse {
}

/**
 * 
 * @export
 * @interface V1ReportTrialValidationMetricsResponse
 */
export interface V1ReportTrialValidationMetricsResponse {
}

/**
 * Response to ResetUserSettingRequest.
 * @export
 * @interface V1ResetUserSettingResponse
 */
export interface V1ResetUserSettingResponse {
}

/**
 * One instance of slots in the cluster being allocated to a task during a period (aggregated).
 * @export
 * @interface V1ResourceAllocationAggregatedEntry
 */
export interface V1ResourceAllocationAggregatedEntry {
    /**
     * The date of this entry.
     * @type {string}
     * @memberof V1ResourceAllocationAggregatedEntry
     */
    periodStart: string;
    /**
     * The period over which aggregation occurred.
     * @type {V1ResourceAllocationAggregationPeriod}
     * @memberof V1ResourceAllocationAggregatedEntry
     */
    period: V1ResourceAllocationAggregationPeriod;
    /**
     * The total number of seconds included in this allocation entry.
     * @type {number}
     * @memberof V1ResourceAllocationAggregatedEntry
     */
    seconds: number;
    /**
     * The seconds in the cluster used by experiments belonging to each user.
     * @type {{ [key: string]: number; }}
     * @memberof V1ResourceAllocationAggregatedEntry
     */
    byUsername: { [key: string]: number; };
    /**
     * The seconds in the cluster used by experiments labeled with each label.
     * @type {{ [key: string]: number; }}
     * @memberof V1ResourceAllocationAggregatedEntry
     */
    byExperimentLabel: { [key: string]: number; };
    /**
     * The seconds in the cluster used by experiments assigned to each resource pool.
     * @type {{ [key: string]: number; }}
     * @memberof V1ResourceAllocationAggregatedEntry
     */
    byResourcePool: { [key: string]: number; };
    /**
     * The seconds in the cluster used by experiments assigned to each agent label.
     * @type {{ [key: string]: number; }}
     * @memberof V1ResourceAllocationAggregatedEntry
     */
    byAgentLabel: { [key: string]: number; };
}

/**
 * Response to ResourceAllocationAggregatedRequest.
 * @export
 * @interface V1ResourceAllocationAggregatedResponse
 */
export interface V1ResourceAllocationAggregatedResponse {
    /**
     * An entry summarizing one workload.
     * @type {Array<V1ResourceAllocationAggregatedEntry>}
     * @memberof V1ResourceAllocationAggregatedResponse
     */
    resourceEntries: Array<V1ResourceAllocationAggregatedEntry>;
}

/**
 * The period over which to perform aggregation.   - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED: Unspecified. This value will never actually be returned by the API, it is just an artifact of using protobuf.  - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY: Aggregation by day.  - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY: Aggregation by month.
 * @export
 * @enum {string}
 */
export enum V1ResourceAllocationAggregationPeriod {
    UNSPECIFIED = <any> 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED',
    DAILY = <any> 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY',
    MONTHLY = <any> 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY'
}

/**
 * One instance of slots in the cluster being allocated to a task.
 * @export
 * @interface V1ResourceAllocationRawEntry
 */
export interface V1ResourceAllocationRawEntry {
    /**
     * The kind of workload being run during this allocation (training, checkpoint, or validation).
     * @type {string}
     * @memberof V1ResourceAllocationRawEntry
     */
    kind?: string;
    /**
     * The time at which the allocation began.
     * @type {Date}
     * @memberof V1ResourceAllocationRawEntry
     */
    startTime?: Date;
    /**
     * The time at which the allocation ended.
     * @type {Date}
     * @memberof V1ResourceAllocationRawEntry
     */
    endTime?: Date;
    /**
     * The ID of the experiment the allocation is a part of.
     * @type {number}
     * @memberof V1ResourceAllocationRawEntry
     */
    experimentId?: number;
    /**
     * The username of the user who ran the experiment.
     * @type {string}
     * @memberof V1ResourceAllocationRawEntry
     */
    username?: string;
    /**
     * The username of the user who ran the experiment.
     * @type {number}
     * @memberof V1ResourceAllocationRawEntry
     */
    userId?: number;
    /**
     * The labels assigned to the experiment.
     * @type {Array<string>}
     * @memberof V1ResourceAllocationRawEntry
     */
    labels?: Array<string>;
    /**
     * The number of seconds for which the allocation was active during the requested period.
     * @type {number}
     * @memberof V1ResourceAllocationRawEntry
     */
    seconds?: number;
    /**
     * The number of slots used by the allocation.
     * @type {number}
     * @memberof V1ResourceAllocationRawEntry
     */
    slots?: number;
}

/**
 * Response to ResourceAllocationRawRequest.
 * @export
 * @interface V1ResourceAllocationRawResponse
 */
export interface V1ResourceAllocationRawResponse {
    /**
     * An entry summarizing one workload.
     * @type {Array<V1ResourceAllocationRawEntry>}
     * @memberof V1ResourceAllocationRawResponse
     */
    resourceEntries?: Array<V1ResourceAllocationRawEntry>;
}

/**
 * A Resource Pool is a pool of resources where containers are run.
 * @export
 * @interface V1ResourcePool
 */
export interface V1ResourcePool {
    /**
     * The unique name of the resource pool.
     * @type {string}
     * @memberof V1ResourcePool
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePool
     */
    description: string;
    /**
     * 
     * @type {V1ResourcePoolType}
     * @memberof V1ResourcePool
     */
    type: V1ResourcePoolType;
    /**
     * 
     * @type {number}
     * @memberof V1ResourcePool
     */
    numAgents: number;
    /**
     * 
     * @type {number}
     * @memberof V1ResourcePool
     */
    slotsAvailable: number;
    /**
     * 
     * @type {number}
     * @memberof V1ResourcePool
     */
    slotsUsed: number;
    /**
     * Slot device type: cpu, gpu, ...
     * @type {Determineddevicev1Type}
     * @memberof V1ResourcePool
     */
    slotType: Determineddevicev1Type;
    /**
     * 
     * @type {number}
     * @memberof V1ResourcePool
     */
    auxContainerCapacity: number;
    /**
     * 
     * @type {number}
     * @memberof V1ResourcePool
     */
    auxContainersRunning: number;
    /**
     * 
     * @type {boolean}
     * @memberof V1ResourcePool
     */
    defaultComputePool: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ResourcePool
     */
    defaultAuxPool: boolean;
    /**
     * Is this resource pool using preemptible/spot instances? Only meaningful in an AWS or GCP resource pool.
     * @type {boolean}
     * @memberof V1ResourcePool
     */
    preemptible: boolean;
    /**
     * When using dynamic agents, the minimum number of agents that can exist in the resource pool.
     * @type {number}
     * @memberof V1ResourcePool
     */
    minAgents: number;
    /**
     * When using dynamic agents, the maximum number of agents that can exist in the resource pool.
     * @type {number}
     * @memberof V1ResourcePool
     */
    maxAgents: number;
    /**
     * The number of slots that exists on an dynamic agent.
     * @type {number}
     * @memberof V1ResourcePool
     */
    slotsPerAgent?: number;
    /**
     * 
     * @type {number}
     * @memberof V1ResourcePool
     */
    auxContainerCapacityPerAgent: number;
    /**
     * 
     * @type {V1SchedulerType}
     * @memberof V1ResourcePool
     */
    schedulerType: V1SchedulerType;
    /**
     * The fitting policy of the scheduler.
     * @type {V1FittingPolicy}
     * @memberof V1ResourcePool
     */
    schedulerFittingPolicy: V1FittingPolicy;
    /**
     * The location of the resource pool. For AWS this returns the region and for GCP this return the zone.
     * @type {string}
     * @memberof V1ResourcePool
     */
    location: string;
    /**
     * The VM image used for the agents when using dynamic agents.
     * @type {string}
     * @memberof V1ResourcePool
     */
    imageId: string;
    /**
     * The instance type of the agents when using dynamic agents. For AWS this is the Instance Type. For GCP this is the machine type combined with the number and types of GPUs. To work with this data programattically, we recommend working with the ResourcePool.details.aws.instanceType and ResourcePool.details.gcp.machineType/gpuType/gpuNum.
     * @type {string}
     * @memberof V1ResourcePool
     */
    instanceType: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePool
     */
    masterUrl: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePool
     */
    masterCertName: string;
    /**
     * The startup script for the agent. This runs on the node the agent runs on.
     * @type {string}
     * @memberof V1ResourcePool
     */
    startupScript: string;
    /**
     * The startup script for the agent's container. This runs in the container determined-agent runs in.
     * @type {string}
     * @memberof V1ResourcePool
     */
    containerStartupScript: string;
    /**
     * The Docker network to use for the agent when using dynamic agents.
     * @type {string}
     * @memberof V1ResourcePool
     */
    agentDockerNetwork: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePool
     */
    agentDockerRuntime: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePool
     */
    agentDockerImage: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePool
     */
    agentFluentImage: string;
    /**
     * The maximum idle period of agents in seconds. The master waits for this period of time before shutting down idle agents.
     * @type {number}
     * @memberof V1ResourcePool
     */
    maxIdleAgentPeriod: number;
    /**
     * The maximum starting period of agents in seconds. The master waits for this period of time for starting agents before retrying.
     * @type {number}
     * @memberof V1ResourcePool
     */
    maxAgentStartingPeriod: number;
    /**
     * 
     * @type {V1ResourcePoolDetail}
     * @memberof V1ResourcePool
     */
    details: V1ResourcePoolDetail;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePool
     */
    accelerator?: string;
    /**
     * 
     * @type {V1QueueStats}
     * @memberof V1ResourcePool
     */
    stats?: V1QueueStats;
}

/**
 * 
 * @export
 * @interface V1ResourcePoolAwsDetail
 */
export interface V1ResourcePoolAwsDetail {
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    region: string;
    /**
     * 
     * @type {number}
     * @memberof V1ResourcePoolAwsDetail
     */
    rootVolumeSize: number;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    imageId: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    tagKey: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    tagValue: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    instanceName: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    sshKeyName: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ResourcePoolAwsDetail
     */
    publicIp: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    subnetId?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    securityGroupId: string;
    /**
     * The Amazon Resource Name (ARN) of the IAM instance profile to attach to the agent instances.
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    iamInstanceProfileArn: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    instanceType?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    logGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    logStream?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ResourcePoolAwsDetail
     */
    spotEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolAwsDetail
     */
    spotMaxPrice?: string;
    /**
     * 
     * @type {Array<V1AwsCustomTag>}
     * @memberof V1ResourcePoolAwsDetail
     */
    customTags?: Array<V1AwsCustomTag>;
}

/**
 * 
 * @export
 * @interface V1ResourcePoolDetail
 */
export interface V1ResourcePoolDetail {
    /**
     * 
     * @type {V1ResourcePoolAwsDetail}
     * @memberof V1ResourcePoolDetail
     */
    aws?: V1ResourcePoolAwsDetail;
    /**
     * 
     * @type {V1ResourcePoolGcpDetail}
     * @memberof V1ResourcePoolDetail
     */
    gcp?: V1ResourcePoolGcpDetail;
    /**
     * 
     * @type {V1ResourcePoolPrioritySchedulerDetail}
     * @memberof V1ResourcePoolDetail
     */
    priorityScheduler?: V1ResourcePoolPrioritySchedulerDetail;
}

/**
 * 
 * @export
 * @interface V1ResourcePoolGcpDetail
 */
export interface V1ResourcePoolGcpDetail {
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolGcpDetail
     */
    project: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolGcpDetail
     */
    zone: string;
    /**
     * 
     * @type {number}
     * @memberof V1ResourcePoolGcpDetail
     */
    bootDiskSize: number;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolGcpDetail
     */
    bootDiskSourceImage: string;
    /**
     * Key for labeling the Determined agent instances.
     * @type {string}
     * @memberof V1ResourcePoolGcpDetail
     */
    labelKey: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolGcpDetail
     */
    labelValue: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolGcpDetail
     */
    namePrefix: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolGcpDetail
     */
    network: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolGcpDetail
     */
    subnetwork?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ResourcePoolGcpDetail
     */
    externalIp: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ResourcePoolGcpDetail
     */
    networkTags?: Array<string>;
    /**
     * Email of the service account for the Determined agent instances.
     * @type {string}
     * @memberof V1ResourcePoolGcpDetail
     */
    serviceAccountEmail: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ResourcePoolGcpDetail
     */
    serviceAccountScopes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolGcpDetail
     */
    machineType: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourcePoolGcpDetail
     */
    gpuType: string;
    /**
     * 
     * @type {number}
     * @memberof V1ResourcePoolGcpDetail
     */
    gpuNum: number;
    /**
     * 
     * @type {boolean}
     * @memberof V1ResourcePoolGcpDetail
     */
    preemptible: boolean;
    /**
     * 
     * @type {number}
     * @memberof V1ResourcePoolGcpDetail
     */
    operationTimeoutPeriod: number;
}

/**
 * 
 * @export
 * @interface V1ResourcePoolPrioritySchedulerDetail
 */
export interface V1ResourcePoolPrioritySchedulerDetail {
    /**
     * 
     * @type {boolean}
     * @memberof V1ResourcePoolPrioritySchedulerDetail
     */
    preemption: boolean;
    /**
     * The priority that is assigned to tasks that do not explicitly specify a priority.
     * @type {number}
     * @memberof V1ResourcePoolPrioritySchedulerDetail
     */
    defaultPriority: number;
    /**
     * List of available priorities for K8 (if applicable).
     * @type {Array<V1K8PriorityClass>}
     * @memberof V1ResourcePoolPrioritySchedulerDetail
     */
    k8Priorities?: Array<V1K8PriorityClass>;
}

/**
 * The type of the ResourcePool.   - RESOURCE_POOL_TYPE_UNSPECIFIED: Unspecified. This value will never actually be returned by the API, it is just an artifact of using protobuf.  - RESOURCE_POOL_TYPE_AWS: An AWS resource pool.  - RESOURCE_POOL_TYPE_GCP: A GCP resource pool.  - RESOURCE_POOL_TYPE_STATIC: A static resource pool.  - RESOURCE_POOL_TYPE_K8S: The kubernetes resource pool.
 * @export
 * @enum {string}
 */
export enum V1ResourcePoolType {
    UNSPECIFIED = <any> 'RESOURCE_POOL_TYPE_UNSPECIFIED',
    AWS = <any> 'RESOURCE_POOL_TYPE_AWS',
    GCP = <any> 'RESOURCE_POOL_TYPE_GCP',
    STATIC = <any> 'RESOURCE_POOL_TYPE_STATIC',
    K8S = <any> 'RESOURCE_POOL_TYPE_K8S'
}

/**
 * 
 * @export
 * @interface V1Role
 */
export interface V1Role {
    /**
     * 
     * @type {number}
     * @memberof V1Role
     */
    roleId: number;
    /**
     * 
     * @type {string}
     * @memberof V1Role
     */
    name?: string;
    /**
     * 
     * @type {Array<V1Permission>}
     * @memberof V1Role
     */
    permissions?: Array<V1Permission>;
}

/**
 * RoleAssignment contains information about the scope of the role.
 * @export
 * @interface V1RoleAssignment
 */
export interface V1RoleAssignment {
    /**
     * The role of the assignment.
     * @type {V1Role}
     * @memberof V1RoleAssignment
     */
    role: V1Role;
    /**
     * The id of the workspace the role belongs to. Omit for a global scope.
     * @type {number}
     * @memberof V1RoleAssignment
     */
    scopeWorkspaceId?: number;
}

/**
 * RoleAssignmentSummary is used to describe permissions a user has.
 * @export
 * @interface V1RoleAssignmentSummary
 */
export interface V1RoleAssignmentSummary {
    /**
     * 
     * @type {number}
     * @memberof V1RoleAssignmentSummary
     */
    roleId?: number;
    /**
     * List of workspace IDs to apply the role.
     * @type {Array<number>}
     * @memberof V1RoleAssignmentSummary
     */
    scopeWorkspaceIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof V1RoleAssignmentSummary
     */
    isGlobal?: boolean;
}

/**
 * RoleWithAssignments contains a detailed description of a role and the groups and users belonging to it.
 * @export
 * @interface V1RoleWithAssignments
 */
export interface V1RoleWithAssignments {
    /**
     * The embedded Role.
     * @type {V1Role}
     * @memberof V1RoleWithAssignments
     */
    role?: V1Role;
    /**
     * The embedded GroupRoleAssignment.
     * @type {Array<V1GroupRoleAssignment>}
     * @memberof V1RoleWithAssignments
     */
    groupRoleAssignments?: Array<V1GroupRoleAssignment>;
    /**
     * The embedded UserRoleAssignment.
     * @type {Array<V1UserRoleAssignment>}
     * @memberof V1RoleWithAssignments
     */
    userRoleAssignments?: Array<V1UserRoleAssignment>;
}

/**
 * RunnableOperation represents a single runnable operation emitted by a searcher.
 * @export
 * @interface V1RunnableOperation
 */
export interface V1RunnableOperation {
    /**
     * This is the type of the operation.
     * @type {V1RunnableType}
     * @memberof V1RunnableOperation
     */
    type?: V1RunnableType;
    /**
     * 
     * @type {string}
     * @memberof V1RunnableOperation
     */
    length?: string;
}

/**
 * RunnableType defines the type of operation that should be executed by trial runners.   - RUNNABLE_TYPE_UNSPECIFIED: Denotes an unknown runnable type.  - RUNNABLE_TYPE_TRAIN: Signals to a trial runner that it should run a train.  - RUNNABLE_TYPE_VALIDATE: Signals to a trial runner it should compute validation metrics.
 * @export
 * @enum {string}
 */
export enum V1RunnableType {
    UNSPECIFIED = <any> 'RUNNABLE_TYPE_UNSPECIFIED',
    TRAIN = <any> 'RUNNABLE_TYPE_TRAIN',
    VALIDATE = <any> 'RUNNABLE_TYPE_VALIDATE'
}

/**
 * Describe one SSO provider.
 * @export
 * @interface V1SSOProvider
 */
export interface V1SSOProvider {
    /**
     * A descriptive name for this provider.
     * @type {string}
     * @memberof V1SSOProvider
     */
    name: string;
    /**
     * The URL to use for SSO with this provider.
     * @type {string}
     * @memberof V1SSOProvider
     */
    ssoUrl: string;
}

/**
 * Scale options available in metrics charts.   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
 * @export
 * @enum {string}
 */
export enum V1Scale {
    UNSPECIFIED = <any> 'SCALE_UNSPECIFIED',
    LINEAR = <any> 'SCALE_LINEAR',
    LOG = <any> 'SCALE_LOG'
}

/**
 * The type of the Scheduler.   - SCHEDULER_TYPE_UNSPECIFIED: Unspecified. This value will never actually be returned by the API, it is just an artifact of using protobuf.  - SCHEDULER_TYPE_PRIORITY: The priority scheduler.  - SCHEDULER_TYPE_FAIR_SHARE: The fair share scheduler.  - SCHEDULER_TYPE_ROUND_ROBIN: The round robin scheduler  - SCHEDULER_TYPE_KUBERNETES: The kubernetes scheduler.  - SCHEDULER_TYPE_SLURM: A slurm placeholder. When running on slurm, all scheduling behavior is delegated.  - SCHEDULER_TYPE_PBS: A PBS placeholder. When running on PBS, all scheduling behavior is delegated.
 * @export
 * @enum {string}
 */
export enum V1SchedulerType {
    UNSPECIFIED = <any> 'SCHEDULER_TYPE_UNSPECIFIED',
    PRIORITY = <any> 'SCHEDULER_TYPE_PRIORITY',
    FAIRSHARE = <any> 'SCHEDULER_TYPE_FAIR_SHARE',
    ROUNDROBIN = <any> 'SCHEDULER_TYPE_ROUND_ROBIN',
    KUBERNETES = <any> 'SCHEDULER_TYPE_KUBERNETES',
    SLURM = <any> 'SCHEDULER_TYPE_SLURM',
    PBS = <any> 'SCHEDULER_TYPE_PBS'
}

/**
 * 
 * @export
 * @interface V1SearchRolesAssignableToScopeRequest
 */
export interface V1SearchRolesAssignableToScopeRequest {
    /**
     * 
     * @type {number}
     * @memberof V1SearchRolesAssignableToScopeRequest
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof V1SearchRolesAssignableToScopeRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof V1SearchRolesAssignableToScopeRequest
     */
    workspaceId?: number;
}

/**
 * 
 * @export
 * @interface V1SearchRolesAssignableToScopeResponse
 */
export interface V1SearchRolesAssignableToScopeResponse {
    /**
     * pagination information.
     * @type {V1Pagination}
     * @memberof V1SearchRolesAssignableToScopeResponse
     */
    pagination?: V1Pagination;
    /**
     * the set of roles and all assignments belonging to it.
     * @type {Array<V1Role>}
     * @memberof V1SearchRolesAssignableToScopeResponse
     */
    roles?: Array<V1Role>;
}

/**
 * SearcherOperation is an operation issued by the searcher.
 * @export
 * @interface V1SearcherOperation
 */
export interface V1SearcherOperation {
    /**
     * ValidateAfter is issued to tell a trial to train some then validate.
     * @type {V1ValidateAfterOperation}
     * @memberof V1SearcherOperation
     */
    validateAfter?: V1ValidateAfterOperation;
}

/**
 * Set the priority of the requested command.
 * @export
 * @interface V1SetCommandPriorityRequest
 */
export interface V1SetCommandPriorityRequest {
    /**
     * The id of the command.
     * @type {string}
     * @memberof V1SetCommandPriorityRequest
     */
    commandId?: string;
    /**
     * The new priority.
     * @type {number}
     * @memberof V1SetCommandPriorityRequest
     */
    priority?: number;
}

/**
 * Response to SetCommandPriorityRequest.
 * @export
 * @interface V1SetCommandPriorityResponse
 */
export interface V1SetCommandPriorityResponse {
    /**
     * The requested command.
     * @type {V1Command}
     * @memberof V1SetCommandPriorityResponse
     */
    command?: V1Command;
}

/**
 * Set the priority of the requested notebook.
 * @export
 * @interface V1SetNotebookPriorityRequest
 */
export interface V1SetNotebookPriorityRequest {
    /**
     * The id of the notebook.
     * @type {string}
     * @memberof V1SetNotebookPriorityRequest
     */
    notebookId?: string;
    /**
     * The new priority.
     * @type {number}
     * @memberof V1SetNotebookPriorityRequest
     */
    priority?: number;
}

/**
 * Response to SetNotebookPriorityRequest.
 * @export
 * @interface V1SetNotebookPriorityResponse
 */
export interface V1SetNotebookPriorityResponse {
    /**
     * The requested notebook.
     * @type {V1Notebook}
     * @memberof V1SetNotebookPriorityResponse
     */
    notebook?: V1Notebook;
}

/**
 * Set the priority of the requested shell.
 * @export
 * @interface V1SetShellPriorityRequest
 */
export interface V1SetShellPriorityRequest {
    /**
     * The id of the shell.
     * @type {string}
     * @memberof V1SetShellPriorityRequest
     */
    shellId?: string;
    /**
     * The new priority.
     * @type {number}
     * @memberof V1SetShellPriorityRequest
     */
    priority?: number;
}

/**
 * Response to SetShellPriorityRequest.
 * @export
 * @interface V1SetShellPriorityResponse
 */
export interface V1SetShellPriorityResponse {
    /**
     * The requested shell.
     * @type {V1Shell}
     * @memberof V1SetShellPriorityResponse
     */
    shell?: V1Shell;
}

/**
 * Set the priority of the requested TensorBoard.
 * @export
 * @interface V1SetTensorboardPriorityRequest
 */
export interface V1SetTensorboardPriorityRequest {
    /**
     * The id of the TensorBoard.
     * @type {string}
     * @memberof V1SetTensorboardPriorityRequest
     */
    tensorboardId?: string;
    /**
     * The new priority.
     * @type {number}
     * @memberof V1SetTensorboardPriorityRequest
     */
    priority?: number;
}

/**
 * Response to SetTensorboardPriorityRequest.
 * @export
 * @interface V1SetTensorboardPriorityResponse
 */
export interface V1SetTensorboardPriorityResponse {
    /**
     * The requested Tensorboard.
     * @type {V1Tensorboard}
     * @memberof V1SetTensorboardPriorityResponse
     */
    tensorboard?: V1Tensorboard;
}

/**
 * Response to SetUserPasswordRequest.
 * @export
 * @interface V1SetUserPasswordResponse
 */
export interface V1SetUserPasswordResponse {
    /**
     * The updated user.
     * @type {V1User}
     * @memberof V1SetUserPasswordResponse
     */
    user?: V1User;
}

/**
 * Shell is an ssh server in a containerized environment.
 * @export
 * @interface V1Shell
 */
export interface V1Shell {
    /**
     * The id of the shell.
     * @type {string}
     * @memberof V1Shell
     */
    id: string;
    /**
     * The description of the shell.
     * @type {string}
     * @memberof V1Shell
     */
    description: string;
    /**
     * The state of the shell.
     * @type {Determinedtaskv1State}
     * @memberof V1Shell
     */
    state: Determinedtaskv1State;
    /**
     * The time the shell was started.
     * @type {Date}
     * @memberof V1Shell
     */
    startTime: Date;
    /**
     * The container running the shell.
     * @type {V1Container}
     * @memberof V1Shell
     */
    container?: V1Container;
    /**
     * The private key for this shell.
     * @type {string}
     * @memberof V1Shell
     */
    privateKey?: string;
    /**
     * The public key for this shell.
     * @type {string}
     * @memberof V1Shell
     */
    publicKey?: string;
    /**
     * The display name of the user that created the shell.
     * @type {string}
     * @memberof V1Shell
     */
    displayName?: string;
    /**
     * The id of the user that created the shell.
     * @type {number}
     * @memberof V1Shell
     */
    userId?: number;
    /**
     * The username of the user that created the shell.
     * @type {string}
     * @memberof V1Shell
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof V1Shell
     */
    resourcePool: string;
    /**
     * 
     * @type {string}
     * @memberof V1Shell
     */
    exitStatus?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof V1Shell
     */
    addresses?: Array<any>;
    /**
     * 
     * @type {any}
     * @memberof V1Shell
     */
    agentUserGroup?: any;
    /**
     * The associated job id.
     * @type {string}
     * @memberof V1Shell
     */
    jobId: string;
}

/**
 * Slot wraps a single device on the agent.
 * @export
 * @interface V1Slot
 */
export interface V1Slot {
    /**
     * The unqiue id of the slot for a given agent.
     * @type {string}
     * @memberof V1Slot
     */
    id?: string;
    /**
     * The individual resource this slot wraps.
     * @type {V1Device}
     * @memberof V1Slot
     */
    device?: V1Device;
    /**
     * Flag notifying if containers can be scheduled on this slot.
     * @type {boolean}
     * @memberof V1Slot
     */
    enabled?: boolean;
    /**
     * Container that is currently running on this agent. It is unset if there is no container currently running on this slot.
     * @type {V1Container}
     * @memberof V1Slot
     */
    container?: V1Container;
    /**
     * Flag notifying if this slot is in the draining mode: current containers will be allowed to finish but no new ones will be scheduled.
     * @type {boolean}
     * @memberof V1Slot
     */
    draining?: boolean;
}

/**
 * Response to SummarizeTrialRequest.
 * @export
 * @interface V1SummarizeTrialResponse
 */
export interface V1SummarizeTrialResponse {
    /**
     * The requested trial.
     * @type {Trialv1Trial}
     * @memberof V1SummarizeTrialResponse
     */
    trial: Trialv1Trial;
    /**
     * The downsampled datapoints.
     * @type {Array<V1SummarizedMetric>}
     * @memberof V1SummarizeTrialResponse
     */
    metrics: Array<V1SummarizedMetric>;
}

/**
 * Summarized Metric captures a metric's name and downsampled data points.
 * @export
 * @interface V1SummarizedMetric
 */
export interface V1SummarizedMetric {
    /**
     * Name of the selected metric.
     * @type {string}
     * @memberof V1SummarizedMetric
     */
    name: string;
    /**
     * A possibly down-sampled series of metric readings through the progress of the trial.
     * @type {Array<V1DataPoint>}
     * @memberof V1SummarizedMetric
     */
    data: Array<V1DataPoint>;
    /**
     * Type of metrics (training, validation, or unset).
     * @type {V1MetricType}
     * @memberof V1SummarizedMetric
     */
    type: V1MetricType;
}

/**
 * Task is the model for a task in the database.
 * @export
 * @interface V1Task
 */
export interface V1Task {
    /**
     * Unique ID of task.
     * @type {string}
     * @memberof V1Task
     */
    taskId?: string;
    /**
     * Type of Task.
     * @type {string}
     * @memberof V1Task
     */
    taskType?: string;
    /**
     * List of Allocations.
     * @type {Array<V1Allocation>}
     * @memberof V1Task
     */
    allocations?: Array<V1Allocation>;
}

/**
 * Response to TaskLogsFieldsRequest.
 * @export
 * @interface V1TaskLogsFieldsResponse
 */
export interface V1TaskLogsFieldsResponse {
    /**
     * The distint allocation IDs present in the logs.
     * @type {Array<string>}
     * @memberof V1TaskLogsFieldsResponse
     */
    allocationIds?: Array<string>;
    /**
     * The distinct agent IDs present in the logs.
     * @type {Array<string>}
     * @memberof V1TaskLogsFieldsResponse
     */
    agentIds?: Array<string>;
    /**
     * The distinct container IDs present in the logs.
     * @type {Array<string>}
     * @memberof V1TaskLogsFieldsResponse
     */
    containerIds?: Array<string>;
    /**
     * The distinct rank IDs present in the logs.
     * @type {Array<number>}
     * @memberof V1TaskLogsFieldsResponse
     */
    rankIds?: Array<number>;
    /**
     * The distinct stdtypes present in the logs.
     * @type {Array<string>}
     * @memberof V1TaskLogsFieldsResponse
     */
    stdtypes?: Array<string>;
    /**
     * The distinct sources present in the logs.
     * @type {Array<string>}
     * @memberof V1TaskLogsFieldsResponse
     */
    sources?: Array<string>;
}

/**
 * Response to TaskLogsRequest.
 * @export
 * @interface V1TaskLogsResponse
 */
export interface V1TaskLogsResponse {
    /**
     * The ID of the log.
     * @type {string}
     * @memberof V1TaskLogsResponse
     */
    id: string;
    /**
     * The timestamp of the log.
     * @type {Date}
     * @memberof V1TaskLogsResponse
     */
    timestamp: Date;
    /**
     * The log message.
     * @type {string}
     * @memberof V1TaskLogsResponse
     */
    message: string;
    /**
     * The level of the log.
     * @type {V1LogLevel}
     * @memberof V1TaskLogsResponse
     */
    level: V1LogLevel;
}

/**
 * Templates move settings that are shared by many experiments into a single YAML file.
 * @export
 * @interface V1Template
 */
export interface V1Template {
    /**
     * The name of the template.
     * @type {string}
     * @memberof V1Template
     */
    name: string;
    /**
     * The template value.
     * @type {any}
     * @memberof V1Template
     */
    config: any;
}

/**
 * Tensorboard is a tensorboard instance in a containerized environment.
 * @export
 * @interface V1Tensorboard
 */
export interface V1Tensorboard {
    /**
     * The id of the tensorboard.
     * @type {string}
     * @memberof V1Tensorboard
     */
    id: string;
    /**
     * The description of the tensorboard.
     * @type {string}
     * @memberof V1Tensorboard
     */
    description: string;
    /**
     * The state of the tensorboard.
     * @type {Determinedtaskv1State}
     * @memberof V1Tensorboard
     */
    state: Determinedtaskv1State;
    /**
     * The time the tensorboard was started.
     * @type {Date}
     * @memberof V1Tensorboard
     */
    startTime: Date;
    /**
     * The container running the tensorboard.
     * @type {V1Container}
     * @memberof V1Tensorboard
     */
    container?: V1Container;
    /**
     * The experiment ids loaded into this tensorboard instance.
     * @type {Array<number>}
     * @memberof V1Tensorboard
     */
    experimentIds?: Array<number>;
    /**
     * The trial ids loaded into this tensorboard instance.
     * @type {Array<number>}
     * @memberof V1Tensorboard
     */
    trialIds?: Array<number>;
    /**
     * The display name of the user that created the tensorboard.
     * @type {string}
     * @memberof V1Tensorboard
     */
    displayName?: string;
    /**
     * The id of the user that created the tensorboard.
     * @type {number}
     * @memberof V1Tensorboard
     */
    userId?: number;
    /**
     * The username of the user that created the tensorboard.
     * @type {string}
     * @memberof V1Tensorboard
     */
    username: string;
    /**
     * The service address.
     * @type {string}
     * @memberof V1Tensorboard
     */
    serviceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Tensorboard
     */
    resourcePool: string;
    /**
     * 
     * @type {string}
     * @memberof V1Tensorboard
     */
    exitStatus?: string;
    /**
     * The associated job id.
     * @type {string}
     * @memberof V1Tensorboard
     */
    jobId: string;
}

/**
 * Timestamp filters.
 * @export
 * @interface V1TimestampFieldFilter
 */
export interface V1TimestampFieldFilter {
    /**
     * Less than.
     * @type {Date}
     * @memberof V1TimestampFieldFilter
     */
    lt?: Date;
    /**
     * Less than or equal.
     * @type {Date}
     * @memberof V1TimestampFieldFilter
     */
    lte?: Date;
    /**
     * Greater than.
     * @type {Date}
     * @memberof V1TimestampFieldFilter
     */
    gt?: Date;
    /**
     * Greater than or equal.
     * @type {Date}
     * @memberof V1TimestampFieldFilter
     */
    gte?: Date;
}

/**
 * Signals to the experiment the trial early exited.
 * @export
 * @interface V1TrialEarlyExit
 */
export interface V1TrialEarlyExit {
    /**
     * The reason for the exit.
     * @type {TrialEarlyExitExitedReason}
     * @memberof V1TrialEarlyExit
     */
    reason: TrialEarlyExitExitedReason;
}

/**
 * Filters to be applied to trials. Can be used to query, patch, and create dynamic collections of trials.
 * @export
 * @interface V1TrialFilters
 */
export interface V1TrialFilters {
    /**
     * Filter trials by their corresponding experiment ids.
     * @type {Array<number>}
     * @memberof V1TrialFilters
     */
    experimentIds?: Array<number>;
    /**
     * Filter trials by their corresponding project ids.
     * @type {Array<number>}
     * @memberof V1TrialFilters
     */
    projectIds?: Array<number>;
    /**
     * Filter trials by their corresponding workspace ids.
     * @type {Array<number>}
     * @memberof V1TrialFilters
     */
    workspaceIds?: Array<number>;
    /**
     * Filter trials to those containing ALL validation_metrics within the indicated ranges.
     * @type {Array<V1ColumnFilter>}
     * @memberof V1TrialFilters
     */
    validationMetrics?: Array<V1ColumnFilter>;
    /**
     * Filter trials to those containing ALL training_metrics within the indicated ranges.
     * @type {Array<V1ColumnFilter>}
     * @memberof V1TrialFilters
     */
    trainingMetrics?: Array<V1ColumnFilter>;
    /**
     * Filter trials to those containing ALL hyperparameters within the indicated ranges. Nested hparams are specified with dots.
     * @type {Array<V1ColumnFilter>}
     * @memberof V1TrialFilters
     */
    hparams?: Array<V1ColumnFilter>;
    /**
     * Filter trials according to the creators user id.
     * @type {Array<number>}
     * @memberof V1TrialFilters
     */
    userIds?: Array<number>;
    /**
     * Filter trials according to the name of the searcher used.
     * @type {string}
     * @memberof V1TrialFilters
     */
    searcher?: string;
    /**
     * Filter trials to those containing ANY of the provided tags.
     * @type {Array<V1TrialTag>}
     * @memberof V1TrialFilters
     */
    tags?: Array<V1TrialTag>;
    /**
     * Filter trials according to their rank within the experiment.
     * @type {TrialFiltersRankWithinExp}
     * @memberof V1TrialFilters
     */
    rankWithinExp?: TrialFiltersRankWithinExp;
    /**
     * Filter trials to those starting within the given range.
     * @type {V1TimestampFieldFilter}
     * @memberof V1TrialFilters
     */
    startTime?: V1TimestampFieldFilter;
    /**
     * Filter trials to those ending within the given range.
     * @type {V1TimestampFieldFilter}
     * @memberof V1TrialFilters
     */
    endTime?: V1TimestampFieldFilter;
    /**
     * Filter trials to those with any of the given states.
     * @type {Array<Determinedtrialv1State>}
     * @memberof V1TrialFilters
     */
    states?: Array<Determinedtrialv1State>;
    /**
     * Filter trials to those with the given searcher metric.
     * @type {string}
     * @memberof V1TrialFilters
     */
    searcherMetric?: string;
    /**
     * 
     * @type {V1DoubleFieldFilter}
     * @memberof V1TrialFilters
     */
    searcherMetricValue?: V1DoubleFieldFilter;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1TrialFilters
     */
    trialIds?: Array<number>;
}

/**
 * Response to TrialLogFieldsRequest.
 * @export
 * @interface V1TrialLogsFieldsResponse
 */
export interface V1TrialLogsFieldsResponse {
    /**
     * The distinct agent IDs present in the logs.
     * @type {Array<string>}
     * @memberof V1TrialLogsFieldsResponse
     */
    agentIds?: Array<string>;
    /**
     * The distinct container IDs present in the logs.
     * @type {Array<string>}
     * @memberof V1TrialLogsFieldsResponse
     */
    containerIds?: Array<string>;
    /**
     * The distinct rank IDs present in the logs.
     * @type {Array<number>}
     * @memberof V1TrialLogsFieldsResponse
     */
    rankIds?: Array<number>;
    /**
     * The distinct stdtypes present in the logs.
     * @type {Array<string>}
     * @memberof V1TrialLogsFieldsResponse
     */
    stdtypes?: Array<string>;
    /**
     * The distinct sources present in the logs.
     * @type {Array<string>}
     * @memberof V1TrialLogsFieldsResponse
     */
    sources?: Array<string>;
}

/**
 * Response to TrialLogsRequest.
 * @export
 * @interface V1TrialLogsResponse
 */
export interface V1TrialLogsResponse {
    /**
     * The ID of the trial log.
     * @type {string}
     * @memberof V1TrialLogsResponse
     */
    id: string;
    /**
     * The timestamp of the log.
     * @type {Date}
     * @memberof V1TrialLogsResponse
     */
    timestamp: Date;
    /**
     * The log message.
     * @type {string}
     * @memberof V1TrialLogsResponse
     */
    message: string;
    /**
     * The level of the log.
     * @type {V1LogLevel}
     * @memberof V1TrialLogsResponse
     */
    level: V1LogLevel;
}

/**
 * Metrics from the trial some duration of training.
 * @export
 * @interface V1TrialMetrics
 */
export interface V1TrialMetrics {
    /**
     * The trial associated with these metrics.
     * @type {number}
     * @memberof V1TrialMetrics
     */
    trialId: number;
    /**
     * The trial run associated with these metrics.
     * @type {number}
     * @memberof V1TrialMetrics
     */
    trialRunId: number;
    /**
     * The number of batches trained on when these metrics were reported.
     * @type {number}
     * @memberof V1TrialMetrics
     */
    stepsCompleted: number;
    /**
     * The metrics for this bit of training, including: - avg_metrics: metrics reduced over the reporting period). - batch_metrics: (optional) per-batch metrics.
     * @type {V1Metrics}
     * @memberof V1TrialMetrics
     */
    metrics: V1Metrics;
}

/**
 * Specifies whether a tag should be added or removed.
 * @export
 * @interface V1TrialPatch
 */
export interface V1TrialPatch {
    /**
     * Specifies that a tag will be added.
     * @type {Array<V1TrialTag>}
     * @memberof V1TrialPatch
     */
    addTag?: Array<V1TrialTag>;
    /**
     * Specifies that a tag will be removed.
     * @type {Array<V1TrialTag>}
     * @memberof V1TrialPatch
     */
    removeTag?: Array<V1TrialTag>;
}

/**
 * 
 * @export
 * @interface V1TrialProfilerMetricLabels
 */
export interface V1TrialProfilerMetricLabels {
    /**
     * The ID of the trial.
     * @type {number}
     * @memberof V1TrialProfilerMetricLabels
     */
    trialId: number;
    /**
     * The name of the metric.
     * @type {string}
     * @memberof V1TrialProfilerMetricLabels
     */
    name: string;
    /**
     * The agent ID associated with the metric.
     * @type {string}
     * @memberof V1TrialProfilerMetricLabels
     */
    agentId?: string;
    /**
     * The GPU UUID associated with the metric.
     * @type {string}
     * @memberof V1TrialProfilerMetricLabels
     */
    gpuUuid?: string;
    /**
     * The type of the metric.
     * @type {TrialProfilerMetricLabelsProfilerMetricType}
     * @memberof V1TrialProfilerMetricLabels
     */
    metricType?: TrialProfilerMetricLabelsProfilerMetricType;
}

/**
 * TrialProfilerMetricsBatch is a batch of trial profiler metrics. A batch will contain metrics pertaining to a single series. The fields values, batches and timestamps will be equal length arrays with each index corresponding to a reading.
 * @export
 * @interface V1TrialProfilerMetricsBatch
 */
export interface V1TrialProfilerMetricsBatch {
    /**
     * The measurement for a reading, repeated for the batch of metrics.
     * @type {Array<number>}
     * @memberof V1TrialProfilerMetricsBatch
     */
    values: Array<number>;
    /**
     * The batch at which a reading occurred, repeated for the batch of metrics.
     * @type {Array<number>}
     * @memberof V1TrialProfilerMetricsBatch
     */
    batches: Array<number>;
    /**
     * The timestamp at which a reading occurred, repeated for the batch of metrics.
     * @type {Array<Date>}
     * @memberof V1TrialProfilerMetricsBatch
     */
    timestamps: Array<Date>;
    /**
     * The labels for this series.
     * @type {V1TrialProfilerMetricLabels}
     * @memberof V1TrialProfilerMetricsBatch
     */
    labels: V1TrialProfilerMetricLabels;
}

/**
 * The metadata pertaining to the current running task for a trial.
 * @export
 * @interface V1TrialRunnerMetadata
 */
export interface V1TrialRunnerMetadata {
    /**
     * The state of the trial runner.
     * @type {string}
     * @memberof V1TrialRunnerMetadata
     */
    state: string;
}

/**
 * TrialSimulation is a specific sequence of workloads that were run before the trial was completed.
 * @export
 * @interface V1TrialSimulation
 */
export interface V1TrialSimulation {
    /**
     * The list of operations that were run before the trial was completed.
     * @type {Array<V1RunnableOperation>}
     * @memberof V1TrialSimulation
     */
    operations?: Array<V1RunnableOperation>;
    /**
     * The number of times that this trial configuration has occurred during the simulation.
     * @type {number}
     * @memberof V1TrialSimulation
     */
    occurrences?: number;
}

/**
 * 
 * @export
 * @interface V1TrialSorter
 */
export interface V1TrialSorter {
    /**
     * The namespace for the sort field.
     * @type {TrialSorterNamespace}
     * @memberof V1TrialSorter
     */
    namespace: TrialSorterNamespace;
    /**
     * The name of the sort field.
     * @type {string}
     * @memberof V1TrialSorter
     */
    field: string;
    /**
     * The ordering for the sort.
     * @type {V1OrderBy}
     * @memberof V1TrialSorter
     */
    orderBy?: V1OrderBy;
}

/**
 * 
 * @export
 * @interface V1TrialTag
 */
export interface V1TrialTag {
    /**
     * The actual tag.
     * @type {string}
     * @memberof V1TrialTag
     */
    key: string;
}

/**
 * Struct that represents a dynamic collection of trials, along with some accompanying metadata.
 * @export
 * @interface V1TrialsCollection
 */
export interface V1TrialsCollection {
    /**
     * The id for the collection.
     * @type {number}
     * @memberof V1TrialsCollection
     */
    id: number;
    /**
     * The id of the user who created the collection.
     * @type {number}
     * @memberof V1TrialsCollection
     */
    userId: number;
    /**
     * The id of the project that the collection belongs to .
     * @type {number}
     * @memberof V1TrialsCollection
     */
    projectId: number;
    /**
     * The name of the collection.
     * @type {string}
     * @memberof V1TrialsCollection
     */
    name: string;
    /**
     * The filters that define the trials within the collection.
     * @type {V1TrialFilters}
     * @memberof V1TrialsCollection
     */
    filters: V1TrialFilters;
    /**
     * A sorter that determines the order for trials in the collection.
     * @type {V1TrialSorter}
     * @memberof V1TrialsCollection
     */
    sorter: V1TrialSorter;
}

/**
 * 
 * @export
 * @interface V1TrialsSampleResponse
 */
export interface V1TrialsSampleResponse {
    /**
     * A historical or incremental series of data points for the trials.
     * @type {Array<V1TrialsSampleResponseTrial>}
     * @memberof V1TrialsSampleResponse
     */
    trials: Array<V1TrialsSampleResponseTrial>;
    /**
     * IDs of trials that are newly included in the data.
     * @type {Array<number>}
     * @memberof V1TrialsSampleResponse
     */
    promotedTrials: Array<number>;
    /**
     * IDs of trials that are no loger included in the top N trials.
     * @type {Array<number>}
     * @memberof V1TrialsSampleResponse
     */
    demotedTrials: Array<number>;
}

/**
 * Metadata and metrics stream from a trial.
 * @export
 * @interface V1TrialsSampleResponseTrial
 */
export interface V1TrialsSampleResponseTrial {
    /**
     * The id of the trial.
     * @type {number}
     * @memberof V1TrialsSampleResponseTrial
     */
    trialId: number;
    /**
     * Hyperparamters values for this specific trial.
     * @type {any}
     * @memberof V1TrialsSampleResponseTrial
     */
    hparams: any;
    /**
     * A possibly down-sampled series of metric readings through the progress of the trial.
     * @type {Array<V1DataPoint>}
     * @memberof V1TrialsSampleResponseTrial
     */
    data: Array<V1DataPoint>;
}

/**
 * 
 * @export
 * @interface V1TrialsSnapshotResponse
 */
export interface V1TrialsSnapshotResponse {
    /**
     * A list of trials.
     * @type {Array<V1TrialsSnapshotResponseTrial>}
     * @memberof V1TrialsSnapshotResponse
     */
    trials: Array<V1TrialsSnapshotResponseTrial>;
}

/**
 * Metric value and metadata for a trial that has progress this far.
 * @export
 * @interface V1TrialsSnapshotResponseTrial
 */
export interface V1TrialsSnapshotResponseTrial {
    /**
     * The id of the trial.
     * @type {number}
     * @memberof V1TrialsSnapshotResponseTrial
     */
    trialId: number;
    /**
     * A dictionary of hyperparameter values for this trial.
     * @type {any}
     * @memberof V1TrialsSnapshotResponseTrial
     */
    hparams: any;
    /**
     * The value of the metric in this trial at this point.
     * @type {number}
     * @memberof V1TrialsSnapshotResponseTrial
     */
    metric: number;
    /**
     * The number of batches processed for this particular datapoint.
     * @type {number}
     * @memberof V1TrialsSnapshotResponseTrial
     */
    batchesProcessed: number;
}

/**
 * Response to UnarchiveExperimentRequest.
 * @export
 * @interface V1UnarchiveExperimentResponse
 */
export interface V1UnarchiveExperimentResponse {
}

/**
 * 
 * @export
 * @interface V1UnarchiveModelResponse
 */
export interface V1UnarchiveModelResponse {
}

/**
 * Response to UnarchiveProjectRequest.
 * @export
 * @interface V1UnarchiveProjectResponse
 */
export interface V1UnarchiveProjectResponse {
}

/**
 * Response to UnarchiveWorkspaceRequest.
 * @export
 * @interface V1UnarchiveWorkspaceResponse
 */
export interface V1UnarchiveWorkspaceResponse {
}

/**
 * Response to UnpinWorkspaceRequest.
 * @export
 * @interface V1UnpinWorkspaceResponse
 */
export interface V1UnpinWorkspaceResponse {
}

/**
 * UpdateGroupRequest is the body of the request for the call to update a group and its members.
 * @export
 * @interface V1UpdateGroupRequest
 */
export interface V1UpdateGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof V1UpdateGroupRequest
     */
    groupId: number;
    /**
     * 
     * @type {string}
     * @memberof V1UpdateGroupRequest
     */
    name?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1UpdateGroupRequest
     */
    addUsers?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1UpdateGroupRequest
     */
    removeUsers?: Array<number>;
}

/**
 * UpdateGroupResponse is the body of the response for the call to update a group and its members.
 * @export
 * @interface V1UpdateGroupResponse
 */
export interface V1UpdateGroupResponse {
    /**
     * Info about the group after the update succeeded.
     * @type {V1GroupDetails}
     * @memberof V1UpdateGroupResponse
     */
    group: V1GroupDetails;
}

/**
 * Request to update the job queue.
 * @export
 * @interface V1UpdateJobQueueRequest
 */
export interface V1UpdateJobQueueRequest {
    /**
     * List of job queue control requests.
     * @type {Array<V1QueueControl>}
     * @memberof V1UpdateJobQueueRequest
     */
    updates: Array<V1QueueControl>;
}

/**
 * Response to UpdateJobQueueRequest.
 * @export
 * @interface V1UpdateJobQueueResponse
 */
export interface V1UpdateJobQueueResponse {
}

/**
 * Request to patch trials. Can either specify a set of filters or set of trial ids as the patch target.
 * @export
 * @interface V1UpdateTrialTagsRequest
 */
export interface V1UpdateTrialTagsRequest {
    /**
     * patch that targets trials matching filters.
     * @type {V1TrialFilters}
     * @memberof V1UpdateTrialTagsRequest
     */
    filters?: V1TrialFilters;
    /**
     * Patch that targets a list of trials.
     * @type {UpdateTrialTagsRequestIds}
     * @memberof V1UpdateTrialTagsRequest
     */
    trial?: UpdateTrialTagsRequestIds;
    /**
     * 
     * @type {V1TrialPatch}
     * @memberof V1UpdateTrialTagsRequest
     */
    patch: V1TrialPatch;
}

/**
 * Response message to PatchTrials.
 * @export
 * @interface V1UpdateTrialTagsResponse
 */
export interface V1UpdateTrialTagsResponse {
    /**
     * The number of trials that were patched.
     * @type {number}
     * @memberof V1UpdateTrialTagsResponse
     */
    rowsAffected?: number;
}

/**
 * User is an account in the determined cluster.
 * @export
 * @interface V1User
 */
export interface V1User {
    /**
     * The user ID.
     * @type {number}
     * @memberof V1User
     */
    id?: number;
    /**
     * The user login name of the user.
     * @type {string}
     * @memberof V1User
     */
    username: string;
    /**
     * Bool denoting whether the account is an admin account.
     * @type {boolean}
     * @memberof V1User
     */
    admin: boolean;
    /**
     * Bool denoting whether the account is active.
     * @type {boolean}
     * @memberof V1User
     */
    active: boolean;
    /**
     * The user and group on the agent host machine.
     * @type {V1AgentUserGroup}
     * @memberof V1User
     */
    agentUserGroup?: V1AgentUserGroup;
    /**
     * Name to display in the web UI.
     * @type {string}
     * @memberof V1User
     */
    displayName?: string;
    /**
     * The version of the user object for caching purposes.
     * @type {Date}
     * @memberof V1User
     */
    modifiedAt?: Date;
}

/**
 * UserRoleAssignment contains information about the users belonging to a role.
 * @export
 * @interface V1UserRoleAssignment
 */
export interface V1UserRoleAssignment {
    /**
     * 
     * @type {number}
     * @memberof V1UserRoleAssignment
     */
    userId: number;
    /**
     * 
     * @type {V1RoleAssignment}
     * @memberof V1UserRoleAssignment
     */
    roleAssignment: V1RoleAssignment;
}

/**
 * UserWebSetting represents user web setting.
 * @export
 * @interface V1UserWebSetting
 */
export interface V1UserWebSetting {
    /**
     * The key of setting.
     * @type {string}
     * @memberof V1UserWebSetting
     */
    key: string;
    /**
     * The storage path of setting.
     * @type {string}
     * @memberof V1UserWebSetting
     */
    storagePath?: string;
    /**
     * The value of setting.
     * @type {string}
     * @memberof V1UserWebSetting
     */
    value?: string;
}

/**
 * ValidateAfterOperation means the trial should train and validate after training the given length.
 * @export
 * @interface V1ValidateAfterOperation
 */
export interface V1ValidateAfterOperation {
    /**
     * The length to train before reporting a validation.
     * @type {string}
     * @memberof V1ValidateAfterOperation
     */
    length?: string;
}

/**
 * ValidationHistoryEntry is a single entry for a validation history for an experiment.
 * @export
 * @interface V1ValidationHistoryEntry
 */
export interface V1ValidationHistoryEntry {
    /**
     * The id for the trial associated with this validation entry.
     * @type {number}
     * @memberof V1ValidationHistoryEntry
     */
    trialId: number;
    /**
     * The time at which the completed validation was reported.
     * @type {Date}
     * @memberof V1ValidationHistoryEntry
     */
    endTime: Date;
    /**
     * The value of the `searcher.metric`, indicated by the experiment config, for the validation.
     * @type {number}
     * @memberof V1ValidationHistoryEntry
     */
    searcherMetric: number;
}

/**
 * WorkloadContainer is a wrapper for Determined workloads to allow repeated oneof types.
 * @export
 * @interface V1WorkloadContainer
 */
export interface V1WorkloadContainer {
    /**
     * Training workload.
     * @type {V1MetricsWorkload}
     * @memberof V1WorkloadContainer
     */
    training?: V1MetricsWorkload;
    /**
     * Validation workload.
     * @type {V1MetricsWorkload}
     * @memberof V1WorkloadContainer
     */
    validation?: V1MetricsWorkload;
    /**
     * Checkpoint workload.
     * @type {V1CheckpointWorkload}
     * @memberof V1WorkloadContainer
     */
    checkpoint?: V1CheckpointWorkload;
}

/**
 * Workspace is a named collection of projects.
 * @export
 * @interface V1Workspace
 */
export interface V1Workspace {
    /**
     * The unique id of the workspace.
     * @type {number}
     * @memberof V1Workspace
     */
    id: number;
    /**
     * The unique name of the workspace.
     * @type {string}
     * @memberof V1Workspace
     */
    name: string;
    /**
     * Whether this workspace is archived or not.
     * @type {boolean}
     * @memberof V1Workspace
     */
    archived: boolean;
    /**
     * User who created this workspace.
     * @type {string}
     * @memberof V1Workspace
     */
    username: string;
    /**
     * Whether this workspace is immutable (default uncategorized workspace).
     * @type {boolean}
     * @memberof V1Workspace
     */
    immutable: boolean;
    /**
     * Number of projects associated with this workspace.
     * @type {number}
     * @memberof V1Workspace
     */
    numProjects: number;
    /**
     * Pin status of this workspace for the current user.
     * @type {boolean}
     * @memberof V1Workspace
     */
    pinned: boolean;
    /**
     * ID of the user who created this project.
     * @type {number}
     * @memberof V1Workspace
     */
    userId: number;
    /**
     * Number of experiments associated with this workspace.
     * @type {number}
     * @memberof V1Workspace
     */
    numExperiments: number;
    /**
     * State of workspace during deletion.
     * @type {V1WorkspaceState}
     * @memberof V1Workspace
     */
    state: V1WorkspaceState;
    /**
     * Message stored from errors on async-deleting a workspace.
     * @type {string}
     * @memberof V1Workspace
     */
    errorMessage: string;
    /**
     * Optional agent host uid and gid override.
     * @type {V1AgentUserGroup}
     * @memberof V1Workspace
     */
    agentUserGroup?: V1AgentUserGroup;
}

/**
 * WorkspaceState is used to track progress during a deletion.   - WORKSPACE_STATE_UNSPECIFIED: Object deletion is not in progress.  - WORKSPACE_STATE_DELETING: The object is being deleted.  - WORKSPACE_STATE_DELETE_FAILED: The object failed to delete.  - WORKSPACE_STATE_DELETED: The object finished deleting.
 * @export
 * @enum {string}
 */
export enum V1WorkspaceState {
    UNSPECIFIED = <any> 'WORKSPACE_STATE_UNSPECIFIED',
    DELETING = <any> 'WORKSPACE_STATE_DELETING',
    DELETEFAILED = <any> 'WORKSPACE_STATE_DELETE_FAILED',
    DELETED = <any> 'WORKSPACE_STATE_DELETED'
}


/**
 * AuthenticationApi - fetch parameter creator
 * @export
 */
export const AuthenticationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUser(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/auth/user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login the user.
         * @param {V1LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: V1LoginRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling login.');
            }
            const localVarPath = `/api/v1/auth/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1LoginRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/auth/logout`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUser(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1CurrentUserResponse> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).currentUser(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Login the user.
         * @param {V1LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: V1LoginRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1LoginResponse> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).login(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Logout the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1LogoutResponse> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).logout(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUser(options?: any) {
            return AuthenticationApiFp(configuration).currentUser(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Login the user.
         * @param {V1LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: V1LoginRequest, options?: any) {
            return AuthenticationApiFp(configuration).login(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Logout the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any) {
            return AuthenticationApiFp(configuration).logout(options)(fetch, basePath);
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Get the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public currentUser(options?: any) {
        return AuthenticationApiFp(this.configuration).currentUser(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Login the user.
     * @param {V1LoginRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public login(body: V1LoginRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).login(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Logout the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logout(options?: any) {
        return AuthenticationApiFp(this.configuration).logout(options)(this.fetch, this.basePath);
    }

}

/**
 * CheckpointsApi - fetch parameter creator
 * @export
 */
export const CheckpointsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Checkpoints.
         * @param {V1DeleteCheckpointsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCheckpoints(body: V1DeleteCheckpointsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteCheckpoints.');
            }
            const localVarPath = `/api/v1/checkpoints`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1DeleteCheckpointsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the requested checkpoint.
         * @param {string} checkpointUuid The uuid for the requested checkpoint.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckpoint(checkpointUuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'checkpointUuid' is not null or undefined
            if (checkpointUuid === null || checkpointUuid === undefined) {
                throw new RequiredError('checkpointUuid','Required parameter checkpointUuid was null or undefined when calling getCheckpoint.');
            }
            const localVarPath = `/api/v1/checkpoints/{checkpointUuid}`
                .replace(`{${"checkpointUuid"}}`, encodeURIComponent(String(checkpointUuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a checkpoint's contents in a tgz or zip file.
         * @param {string} checkpointUuid Checkpoint UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckpoint_1(checkpointUuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'checkpointUuid' is not null or undefined
            if (checkpointUuid === null || checkpointUuid === undefined) {
                throw new RequiredError('checkpointUuid','Required parameter checkpointUuid was null or undefined when calling getCheckpoint_1.');
            }
            const localVarPath = `/checkpoints/{checkpoint_uuid}`
                .replace(`{${"checkpoint_uuid"}}`, encodeURIComponent(String(checkpointUuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update checkpoint metadata.
         * @param {string} checkpointUuid UUID of the checkpoint.
         * @param {V1PostCheckpointMetadataRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCheckpointMetadata(checkpointUuid: string, body: V1PostCheckpointMetadataRequest, options: any = {}): FetchArgs {
            // verify required parameter 'checkpointUuid' is not null or undefined
            if (checkpointUuid === null || checkpointUuid === undefined) {
                throw new RequiredError('checkpointUuid','Required parameter checkpointUuid was null or undefined when calling postCheckpointMetadata.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postCheckpointMetadata.');
            }
            const localVarPath = `/api/v1/checkpoints/{checkpoint.uuid}/metadata`
                .replace(`{${"checkpoint.uuid"}}`, encodeURIComponent(String(checkpointUuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PostCheckpointMetadataRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckpointsApi - functional programming interface
 * @export
 */
export const CheckpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Checkpoints.
         * @param {V1DeleteCheckpointsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCheckpoints(body: V1DeleteCheckpointsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1DeleteCheckpointsResponse> {
            const localVarFetchArgs = CheckpointsApiFetchParamCreator(configuration).deleteCheckpoints(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the requested checkpoint.
         * @param {string} checkpointUuid The uuid for the requested checkpoint.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckpoint(checkpointUuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetCheckpointResponse> {
            const localVarFetchArgs = CheckpointsApiFetchParamCreator(configuration).getCheckpoint(checkpointUuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a checkpoint's contents in a tgz or zip file.
         * @param {string} checkpointUuid Checkpoint UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckpoint_1(checkpointUuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CheckpointsApiFetchParamCreator(configuration).getCheckpoint_1(checkpointUuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update checkpoint metadata.
         * @param {string} checkpointUuid UUID of the checkpoint.
         * @param {V1PostCheckpointMetadataRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCheckpointMetadata(checkpointUuid: string, body: V1PostCheckpointMetadataRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PostCheckpointMetadataResponse> {
            const localVarFetchArgs = CheckpointsApiFetchParamCreator(configuration).postCheckpointMetadata(checkpointUuid, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CheckpointsApi - factory interface
 * @export
 */
export const CheckpointsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Delete Checkpoints.
         * @param {V1DeleteCheckpointsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCheckpoints(body: V1DeleteCheckpointsRequest, options?: any) {
            return CheckpointsApiFp(configuration).deleteCheckpoints(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the requested checkpoint.
         * @param {string} checkpointUuid The uuid for the requested checkpoint.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckpoint(checkpointUuid: string, options?: any) {
            return CheckpointsApiFp(configuration).getCheckpoint(checkpointUuid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a checkpoint's contents in a tgz or zip file.
         * @param {string} checkpointUuid Checkpoint UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckpoint_1(checkpointUuid: string, options?: any) {
            return CheckpointsApiFp(configuration).getCheckpoint_1(checkpointUuid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update checkpoint metadata.
         * @param {string} checkpointUuid UUID of the checkpoint.
         * @param {V1PostCheckpointMetadataRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCheckpointMetadata(checkpointUuid: string, body: V1PostCheckpointMetadataRequest, options?: any) {
            return CheckpointsApiFp(configuration).postCheckpointMetadata(checkpointUuid, body, options)(fetch, basePath);
        },
    };
};

/**
 * CheckpointsApi - object-oriented interface
 * @export
 * @class CheckpointsApi
 * @extends {BaseAPI}
 */
export class CheckpointsApi extends BaseAPI {
    /**
     * 
     * @summary Delete Checkpoints.
     * @param {V1DeleteCheckpointsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckpointsApi
     */
    public deleteCheckpoints(body: V1DeleteCheckpointsRequest, options?: any) {
        return CheckpointsApiFp(this.configuration).deleteCheckpoints(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the requested checkpoint.
     * @param {string} checkpointUuid The uuid for the requested checkpoint.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckpointsApi
     */
    public getCheckpoint(checkpointUuid: string, options?: any) {
        return CheckpointsApiFp(this.configuration).getCheckpoint(checkpointUuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a checkpoint's contents in a tgz or zip file.
     * @param {string} checkpointUuid Checkpoint UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckpointsApi
     */
    public getCheckpoint_1(checkpointUuid: string, options?: any) {
        return CheckpointsApiFp(this.configuration).getCheckpoint_1(checkpointUuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update checkpoint metadata.
     * @param {string} checkpointUuid UUID of the checkpoint.
     * @param {V1PostCheckpointMetadataRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckpointsApi
     */
    public postCheckpointMetadata(checkpointUuid: string, body: V1PostCheckpointMetadataRequest, options?: any) {
        return CheckpointsApiFp(this.configuration).postCheckpointMetadata(checkpointUuid, body, options)(this.fetch, this.basePath);
    }

}

/**
 * ClusterApi - fetch parameter creator
 * @export
 */
export const ClusterApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Disable the agent.
         * @param {string} agentId The id of the agent.
         * @param {V1DisableAgentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableAgent(agentId: string, body: V1DisableAgentRequest, options: any = {}): FetchArgs {
            // verify required parameter 'agentId' is not null or undefined
            if (agentId === null || agentId === undefined) {
                throw new RequiredError('agentId','Required parameter agentId was null or undefined when calling disableAgent.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling disableAgent.');
            }
            const localVarPath = `/api/v1/agents/{agentId}/disable`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1DisableAgentRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable the slot.
         * @param {string} agentId The id of the agent.
         * @param {string} slotId The id of the slot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableSlot(agentId: string, slotId: string, options: any = {}): FetchArgs {
            // verify required parameter 'agentId' is not null or undefined
            if (agentId === null || agentId === undefined) {
                throw new RequiredError('agentId','Required parameter agentId was null or undefined when calling disableSlot.');
            }
            // verify required parameter 'slotId' is not null or undefined
            if (slotId === null || slotId === undefined) {
                throw new RequiredError('slotId','Required parameter slotId was null or undefined when calling disableSlot.');
            }
            const localVarPath = `/api/v1/agents/{agentId}/slots/{slotId}/disable`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable the agent.
         * @param {string} agentId The id of the agent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableAgent(agentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'agentId' is not null or undefined
            if (agentId === null || agentId === undefined) {
                throw new RequiredError('agentId','Required parameter agentId was null or undefined when calling enableAgent.');
            }
            const localVarPath = `/api/v1/agents/{agentId}/enable`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable the slot.
         * @param {string} agentId The id of the agent.
         * @param {string} slotId The id of the slot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableSlot(agentId: string, slotId: string, options: any = {}): FetchArgs {
            // verify required parameter 'agentId' is not null or undefined
            if (agentId === null || agentId === undefined) {
                throw new RequiredError('agentId','Required parameter agentId was null or undefined when calling enableSlot.');
            }
            // verify required parameter 'slotId' is not null or undefined
            if (slotId === null || slotId === undefined) {
                throw new RequiredError('slotId','Required parameter slotId was null or undefined when calling enableSlot.');
            }
            const localVarPath = `/api/v1/agents/{agentId}/slots/{slotId}/enable`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the requested agent.
         * @param {string} agentId The id of the agent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent(agentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'agentId' is not null or undefined
            if (agentId === null || agentId === undefined) {
                throw new RequiredError('agentId','Required parameter agentId was null or undefined when calling getAgent.');
            }
            const localVarPath = `/api/v1/agents/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a set of agents from the cluster.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_TIME'} [sortBy] Sort agents by the given field.   - SORT_BY_UNSPECIFIED: Returns agents in an unsorted list.  - SORT_BY_ID: Returns agents sorted by id.  - SORT_BY_TIME: Returns agents sorted by time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order agents in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of agents before returning results. Negative values denote number of agents to skip from the end before returning results.
         * @param {number} [limit] Limit the number of agents. A value of 0 denotes no limit.
         * @param {string} [label] Filter agents by their label. If no label is specified or is empty, all agents are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgents(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, label?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/agents`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an aggregated view of resource allocation during the given time period (CSV).
         * @param {string} startDate Start time to get allocations for (YYYY-MM-DD format for daily, YYYY-MM format for monthly)
         * @param {string} endDate End time to get allocations for (YYYY-MM-DD format for daily, YYYY-MM format for monthly)
         * @param {string} period Period to aggregate over (RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY or RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedResourceAllocationCsv(startDate: string, endDate: string, period: string, options: any = {}): FetchArgs {
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling getAggregatedResourceAllocationCsv.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (endDate === null || endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling getAggregatedResourceAllocationCsv.');
            }
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getAggregatedResourceAllocationCsv.');
            }
            const localVarPath = `/allocation/aggregated`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get master information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaster(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/master`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get master config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterConfig(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/master/config`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a detailed view of resource allocation during the given time period (CSV).
         * @param {string} timestampAfter Start time to get allocations for (YYYY-MM-DDTHH:MM:SSZ format)
         * @param {string} timestampBefore End time to get allocations for (YYYY-MM-DDTHH:MM:SSZ format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawResourceAllocationCsv(timestampAfter: string, timestampBefore: string, options: any = {}): FetchArgs {
            // verify required parameter 'timestampAfter' is not null or undefined
            if (timestampAfter === null || timestampAfter === undefined) {
                throw new RequiredError('timestampAfter','Required parameter timestampAfter was null or undefined when calling getRawResourceAllocationCsv.');
            }
            // verify required parameter 'timestampBefore' is not null or undefined
            if (timestampBefore === null || timestampBefore === undefined) {
                throw new RequiredError('timestampBefore','Required parameter timestampBefore was null or undefined when calling getRawResourceAllocationCsv.');
            }
            const localVarPath = `/allocation/raw`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (timestampAfter !== undefined) {
                localVarQueryParameter['timestamp_after'] = timestampAfter;
            }

            if (timestampBefore !== undefined) {
                localVarQueryParameter['timestamp_before'] = timestampBefore;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the requested slot for an agent.
         * @param {string} agentId The id of the agent.
         * @param {string} slotId The id of the slot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlot(agentId: string, slotId: string, options: any = {}): FetchArgs {
            // verify required parameter 'agentId' is not null or undefined
            if (agentId === null || agentId === undefined) {
                throw new RequiredError('agentId','Required parameter agentId was null or undefined when calling getSlot.');
            }
            // verify required parameter 'slotId' is not null or undefined
            if (slotId === null || slotId === undefined) {
                throw new RequiredError('slotId','Required parameter slotId was null or undefined when calling getSlot.');
            }
            const localVarPath = `/api/v1/agents/{agentId}/slots/{slotId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the slots for an agent.
         * @param {string} agentId The id of the agent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlots(agentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'agentId' is not null or undefined
            if (agentId === null || agentId === undefined) {
                throw new RequiredError('agentId','Required parameter agentId was null or undefined when calling getSlots.');
            }
            const localVarPath = `/api/v1/agents/{agentId}/slots`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stream master logs.
         * @param {number} [offset] Skip the number of master logs before returning results. Negative values denote number of master logs to skip from the end before returning results.
         * @param {number} [limit] Limit the number of master logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the master stops or the limit is reached.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterLogs(offset?: number, limit?: number, follow?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/master/logs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (follow !== undefined) {
                localVarQueryParameter['follow'] = follow;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an aggregated view of resource allocation during the given time period.
         * @param {string} [startDate] The first day to consider (the exact time is midnight UTC at the beginning of the day).
         * @param {string} [endDate] The last day to consider (the exact time is midnight UTC at the end of the day).
         * @param {'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY'} [period] The period over which to perform aggregation.   - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED: Unspecified. This value will never actually be returned by the API, it is just an artifact of using protobuf.  - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY: Aggregation by day.  - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY: Aggregation by month.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAllocationAggregated(startDate?: string, endDate?: string, period?: 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY', options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/resources/allocation/aggregated`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a detailed view of resource allocation during the given time period.
         * @param {Date} [timestampAfter] The start of the period to consider.
         * @param {Date} [timestampBefore] The end of the period to consider.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAllocationRaw(timestampAfter?: Date, timestampBefore?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/resources/allocation/raw`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (timestampAfter !== undefined) {
                localVarQueryParameter['timestampAfter'] = (timestampAfter as any).toISOString();
            }

            if (timestampBefore !== undefined) {
                localVarQueryParameter['timestampBefore'] = (timestampBefore as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClusterApi - functional programming interface
 * @export
 */
export const ClusterApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Disable the agent.
         * @param {string} agentId The id of the agent.
         * @param {V1DisableAgentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableAgent(agentId: string, body: V1DisableAgentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1DisableAgentResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).disableAgent(agentId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Disable the slot.
         * @param {string} agentId The id of the agent.
         * @param {string} slotId The id of the slot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableSlot(agentId: string, slotId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1DisableSlotResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).disableSlot(agentId, slotId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Enable the agent.
         * @param {string} agentId The id of the agent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableAgent(agentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1EnableAgentResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).enableAgent(agentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Enable the slot.
         * @param {string} agentId The id of the agent.
         * @param {string} slotId The id of the slot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableSlot(agentId: string, slotId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1EnableSlotResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).enableSlot(agentId, slotId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the requested agent.
         * @param {string} agentId The id of the agent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent(agentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetAgentResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).getAgent(agentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a set of agents from the cluster.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_TIME'} [sortBy] Sort agents by the given field.   - SORT_BY_UNSPECIFIED: Returns agents in an unsorted list.  - SORT_BY_ID: Returns agents sorted by id.  - SORT_BY_TIME: Returns agents sorted by time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order agents in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of agents before returning results. Negative values denote number of agents to skip from the end before returning results.
         * @param {number} [limit] Limit the number of agents. A value of 0 denotes no limit.
         * @param {string} [label] Filter agents by their label. If no label is specified or is empty, all agents are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgents(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, label?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetAgentsResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).getAgents(sortBy, orderBy, offset, limit, label, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get an aggregated view of resource allocation during the given time period (CSV).
         * @param {string} startDate Start time to get allocations for (YYYY-MM-DD format for daily, YYYY-MM format for monthly)
         * @param {string} endDate End time to get allocations for (YYYY-MM-DD format for daily, YYYY-MM format for monthly)
         * @param {string} period Period to aggregate over (RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY or RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedResourceAllocationCsv(startDate: string, endDate: string, period: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).getAggregatedResourceAllocationCsv(startDate, endDate, period, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get master information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaster(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetMasterResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).getMaster(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get master config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterConfig(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetMasterConfigResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).getMasterConfig(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a detailed view of resource allocation during the given time period (CSV).
         * @param {string} timestampAfter Start time to get allocations for (YYYY-MM-DDTHH:MM:SSZ format)
         * @param {string} timestampBefore End time to get allocations for (YYYY-MM-DDTHH:MM:SSZ format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawResourceAllocationCsv(timestampAfter: string, timestampBefore: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).getRawResourceAllocationCsv(timestampAfter, timestampBefore, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the requested slot for an agent.
         * @param {string} agentId The id of the agent.
         * @param {string} slotId The id of the slot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlot(agentId: string, slotId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetSlotResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).getSlot(agentId, slotId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all the slots for an agent.
         * @param {string} agentId The id of the agent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlots(agentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetSlotsResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).getSlots(agentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Stream master logs.
         * @param {number} [offset] Skip the number of master logs before returning results. Negative values denote number of master logs to skip from the end before returning results.
         * @param {number} [limit] Limit the number of master logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the master stops or the limit is reached.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterLogs(offset?: number, limit?: number, follow?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1MasterLogsResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).masterLogs(offset, limit, follow, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get an aggregated view of resource allocation during the given time period.
         * @param {string} [startDate] The first day to consider (the exact time is midnight UTC at the beginning of the day).
         * @param {string} [endDate] The last day to consider (the exact time is midnight UTC at the end of the day).
         * @param {'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY'} [period] The period over which to perform aggregation.   - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED: Unspecified. This value will never actually be returned by the API, it is just an artifact of using protobuf.  - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY: Aggregation by day.  - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY: Aggregation by month.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAllocationAggregated(startDate?: string, endDate?: string, period?: 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ResourceAllocationAggregatedResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).resourceAllocationAggregated(startDate, endDate, period, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a detailed view of resource allocation during the given time period.
         * @param {Date} [timestampAfter] The start of the period to consider.
         * @param {Date} [timestampBefore] The end of the period to consider.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAllocationRaw(timestampAfter?: Date, timestampBefore?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ResourceAllocationRawResponse> {
            const localVarFetchArgs = ClusterApiFetchParamCreator(configuration).resourceAllocationRaw(timestampAfter, timestampBefore, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ClusterApi - factory interface
 * @export
 */
export const ClusterApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Disable the agent.
         * @param {string} agentId The id of the agent.
         * @param {V1DisableAgentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableAgent(agentId: string, body: V1DisableAgentRequest, options?: any) {
            return ClusterApiFp(configuration).disableAgent(agentId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Disable the slot.
         * @param {string} agentId The id of the agent.
         * @param {string} slotId The id of the slot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableSlot(agentId: string, slotId: string, options?: any) {
            return ClusterApiFp(configuration).disableSlot(agentId, slotId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Enable the agent.
         * @param {string} agentId The id of the agent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableAgent(agentId: string, options?: any) {
            return ClusterApiFp(configuration).enableAgent(agentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Enable the slot.
         * @param {string} agentId The id of the agent.
         * @param {string} slotId The id of the slot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableSlot(agentId: string, slotId: string, options?: any) {
            return ClusterApiFp(configuration).enableSlot(agentId, slotId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the requested agent.
         * @param {string} agentId The id of the agent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent(agentId: string, options?: any) {
            return ClusterApiFp(configuration).getAgent(agentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a set of agents from the cluster.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_TIME'} [sortBy] Sort agents by the given field.   - SORT_BY_UNSPECIFIED: Returns agents in an unsorted list.  - SORT_BY_ID: Returns agents sorted by id.  - SORT_BY_TIME: Returns agents sorted by time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order agents in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of agents before returning results. Negative values denote number of agents to skip from the end before returning results.
         * @param {number} [limit] Limit the number of agents. A value of 0 denotes no limit.
         * @param {string} [label] Filter agents by their label. If no label is specified or is empty, all agents are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgents(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, label?: string, options?: any) {
            return ClusterApiFp(configuration).getAgents(sortBy, orderBy, offset, limit, label, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get an aggregated view of resource allocation during the given time period (CSV).
         * @param {string} startDate Start time to get allocations for (YYYY-MM-DD format for daily, YYYY-MM format for monthly)
         * @param {string} endDate End time to get allocations for (YYYY-MM-DD format for daily, YYYY-MM format for monthly)
         * @param {string} period Period to aggregate over (RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY or RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedResourceAllocationCsv(startDate: string, endDate: string, period: string, options?: any) {
            return ClusterApiFp(configuration).getAggregatedResourceAllocationCsv(startDate, endDate, period, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get master information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaster(options?: any) {
            return ClusterApiFp(configuration).getMaster(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get master config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterConfig(options?: any) {
            return ClusterApiFp(configuration).getMasterConfig(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a detailed view of resource allocation during the given time period (CSV).
         * @param {string} timestampAfter Start time to get allocations for (YYYY-MM-DDTHH:MM:SSZ format)
         * @param {string} timestampBefore End time to get allocations for (YYYY-MM-DDTHH:MM:SSZ format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawResourceAllocationCsv(timestampAfter: string, timestampBefore: string, options?: any) {
            return ClusterApiFp(configuration).getRawResourceAllocationCsv(timestampAfter, timestampBefore, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the requested slot for an agent.
         * @param {string} agentId The id of the agent.
         * @param {string} slotId The id of the slot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlot(agentId: string, slotId: string, options?: any) {
            return ClusterApiFp(configuration).getSlot(agentId, slotId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all the slots for an agent.
         * @param {string} agentId The id of the agent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlots(agentId: string, options?: any) {
            return ClusterApiFp(configuration).getSlots(agentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Stream master logs.
         * @param {number} [offset] Skip the number of master logs before returning results. Negative values denote number of master logs to skip from the end before returning results.
         * @param {number} [limit] Limit the number of master logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the master stops or the limit is reached.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterLogs(offset?: number, limit?: number, follow?: boolean, options?: any) {
            return ClusterApiFp(configuration).masterLogs(offset, limit, follow, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get an aggregated view of resource allocation during the given time period.
         * @param {string} [startDate] The first day to consider (the exact time is midnight UTC at the beginning of the day).
         * @param {string} [endDate] The last day to consider (the exact time is midnight UTC at the end of the day).
         * @param {'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY'} [period] The period over which to perform aggregation.   - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED: Unspecified. This value will never actually be returned by the API, it is just an artifact of using protobuf.  - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY: Aggregation by day.  - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY: Aggregation by month.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAllocationAggregated(startDate?: string, endDate?: string, period?: 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY', options?: any) {
            return ClusterApiFp(configuration).resourceAllocationAggregated(startDate, endDate, period, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a detailed view of resource allocation during the given time period.
         * @param {Date} [timestampAfter] The start of the period to consider.
         * @param {Date} [timestampBefore] The end of the period to consider.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAllocationRaw(timestampAfter?: Date, timestampBefore?: Date, options?: any) {
            return ClusterApiFp(configuration).resourceAllocationRaw(timestampAfter, timestampBefore, options)(fetch, basePath);
        },
    };
};

/**
 * ClusterApi - object-oriented interface
 * @export
 * @class ClusterApi
 * @extends {BaseAPI}
 */
export class ClusterApi extends BaseAPI {
    /**
     * 
     * @summary Disable the agent.
     * @param {string} agentId The id of the agent.
     * @param {V1DisableAgentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public disableAgent(agentId: string, body: V1DisableAgentRequest, options?: any) {
        return ClusterApiFp(this.configuration).disableAgent(agentId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Disable the slot.
     * @param {string} agentId The id of the agent.
     * @param {string} slotId The id of the slot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public disableSlot(agentId: string, slotId: string, options?: any) {
        return ClusterApiFp(this.configuration).disableSlot(agentId, slotId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Enable the agent.
     * @param {string} agentId The id of the agent.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public enableAgent(agentId: string, options?: any) {
        return ClusterApiFp(this.configuration).enableAgent(agentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Enable the slot.
     * @param {string} agentId The id of the agent.
     * @param {string} slotId The id of the slot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public enableSlot(agentId: string, slotId: string, options?: any) {
        return ClusterApiFp(this.configuration).enableSlot(agentId, slotId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the requested agent.
     * @param {string} agentId The id of the agent.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getAgent(agentId: string, options?: any) {
        return ClusterApiFp(this.configuration).getAgent(agentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a set of agents from the cluster.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_TIME'} [sortBy] Sort agents by the given field.   - SORT_BY_UNSPECIFIED: Returns agents in an unsorted list.  - SORT_BY_ID: Returns agents sorted by id.  - SORT_BY_TIME: Returns agents sorted by time.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order agents in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of agents before returning results. Negative values denote number of agents to skip from the end before returning results.
     * @param {number} [limit] Limit the number of agents. A value of 0 denotes no limit.
     * @param {string} [label] Filter agents by their label. If no label is specified or is empty, all agents are returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getAgents(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, label?: string, options?: any) {
        return ClusterApiFp(this.configuration).getAgents(sortBy, orderBy, offset, limit, label, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get an aggregated view of resource allocation during the given time period (CSV).
     * @param {string} startDate Start time to get allocations for (YYYY-MM-DD format for daily, YYYY-MM format for monthly)
     * @param {string} endDate End time to get allocations for (YYYY-MM-DD format for daily, YYYY-MM format for monthly)
     * @param {string} period Period to aggregate over (RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY or RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getAggregatedResourceAllocationCsv(startDate: string, endDate: string, period: string, options?: any) {
        return ClusterApiFp(this.configuration).getAggregatedResourceAllocationCsv(startDate, endDate, period, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get master information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getMaster(options?: any) {
        return ClusterApiFp(this.configuration).getMaster(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get master config.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getMasterConfig(options?: any) {
        return ClusterApiFp(this.configuration).getMasterConfig(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a detailed view of resource allocation during the given time period (CSV).
     * @param {string} timestampAfter Start time to get allocations for (YYYY-MM-DDTHH:MM:SSZ format)
     * @param {string} timestampBefore End time to get allocations for (YYYY-MM-DDTHH:MM:SSZ format)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getRawResourceAllocationCsv(timestampAfter: string, timestampBefore: string, options?: any) {
        return ClusterApiFp(this.configuration).getRawResourceAllocationCsv(timestampAfter, timestampBefore, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the requested slot for an agent.
     * @param {string} agentId The id of the agent.
     * @param {string} slotId The id of the slot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getSlot(agentId: string, slotId: string, options?: any) {
        return ClusterApiFp(this.configuration).getSlot(agentId, slotId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all the slots for an agent.
     * @param {string} agentId The id of the agent.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getSlots(agentId: string, options?: any) {
        return ClusterApiFp(this.configuration).getSlots(agentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Stream master logs.
     * @param {number} [offset] Skip the number of master logs before returning results. Negative values denote number of master logs to skip from the end before returning results.
     * @param {number} [limit] Limit the number of master logs. A value of 0 denotes no limit.
     * @param {boolean} [follow] Continue following logs until the master stops or the limit is reached.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public masterLogs(offset?: number, limit?: number, follow?: boolean, options?: any) {
        return ClusterApiFp(this.configuration).masterLogs(offset, limit, follow, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get an aggregated view of resource allocation during the given time period.
     * @param {string} [startDate] The first day to consider (the exact time is midnight UTC at the beginning of the day).
     * @param {string} [endDate] The last day to consider (the exact time is midnight UTC at the end of the day).
     * @param {'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY'} [period] The period over which to perform aggregation.   - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED: Unspecified. This value will never actually be returned by the API, it is just an artifact of using protobuf.  - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY: Aggregation by day.  - RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY: Aggregation by month.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public resourceAllocationAggregated(startDate?: string, endDate?: string, period?: 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_UNSPECIFIED' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_DAILY' | 'RESOURCE_ALLOCATION_AGGREGATION_PERIOD_MONTHLY', options?: any) {
        return ClusterApiFp(this.configuration).resourceAllocationAggregated(startDate, endDate, period, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a detailed view of resource allocation during the given time period.
     * @param {Date} [timestampAfter] The start of the period to consider.
     * @param {Date} [timestampBefore] The end of the period to consider.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public resourceAllocationRaw(timestampAfter?: Date, timestampBefore?: Date, options?: any) {
        return ClusterApiFp(this.configuration).resourceAllocationRaw(timestampAfter, timestampBefore, options)(this.fetch, this.basePath);
    }

}

/**
 * CommandsApi - fetch parameter creator
 * @export
 */
export const CommandsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the requested command.
         * @param {string} commandId The id of the command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommand(commandId: string, options: any = {}): FetchArgs {
            // verify required parameter 'commandId' is not null or undefined
            if (commandId === null || commandId === undefined) {
                throw new RequiredError('commandId','Required parameter commandId was null or undefined when calling getCommand.');
            }
            const localVarPath = `/api/v1/commands/{commandId}`
                .replace(`{${"commandId"}}`, encodeURIComponent(String(commandId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of commands.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort commands by the given field.   - SORT_BY_UNSPECIFIED: Returns commands in an unsorted list.  - SORT_BY_ID: Returns commands sorted by id.  - SORT_BY_DESCRIPTION: Returns commands sorted by description.  - SORT_BY_START_TIME: Return commands sorted by start time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order commands in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of commands before returning results. Negative values denote number of commands to skip from the end before returning results.
         * @param {number} [limit] Limit the number of commands. A value of 0 denotes no limit.
         * @param {Array<string>} [users] Limit commands to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit commands to those that are owned by users with the specified userIds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommands(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/commands`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kill the requested command.
         * @param {string} commandId The id of the command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killCommand(commandId: string, options: any = {}): FetchArgs {
            // verify required parameter 'commandId' is not null or undefined
            if (commandId === null || commandId === undefined) {
                throw new RequiredError('commandId','Required parameter commandId was null or undefined when calling killCommand.');
            }
            const localVarPath = `/api/v1/commands/{commandId}/kill`
                .replace(`{${"commandId"}}`, encodeURIComponent(String(commandId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Launch a command.
         * @param {V1LaunchCommandRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchCommand(body: V1LaunchCommandRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling launchCommand.');
            }
            const localVarPath = `/api/v1/commands`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1LaunchCommandRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the priority of the requested command.
         * @param {string} commandId The id of the command.
         * @param {V1SetCommandPriorityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCommandPriority(commandId: string, body: V1SetCommandPriorityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'commandId' is not null or undefined
            if (commandId === null || commandId === undefined) {
                throw new RequiredError('commandId','Required parameter commandId was null or undefined when calling setCommandPriority.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setCommandPriority.');
            }
            const localVarPath = `/api/v1/commands/{commandId}/set_priority`
                .replace(`{${"commandId"}}`, encodeURIComponent(String(commandId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1SetCommandPriorityRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommandsApi - functional programming interface
 * @export
 */
export const CommandsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the requested command.
         * @param {string} commandId The id of the command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommand(commandId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetCommandResponse> {
            const localVarFetchArgs = CommandsApiFetchParamCreator(configuration).getCommand(commandId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of commands.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort commands by the given field.   - SORT_BY_UNSPECIFIED: Returns commands in an unsorted list.  - SORT_BY_ID: Returns commands sorted by id.  - SORT_BY_DESCRIPTION: Returns commands sorted by description.  - SORT_BY_START_TIME: Return commands sorted by start time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order commands in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of commands before returning results. Negative values denote number of commands to skip from the end before returning results.
         * @param {number} [limit] Limit the number of commands. A value of 0 denotes no limit.
         * @param {Array<string>} [users] Limit commands to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit commands to those that are owned by users with the specified userIds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommands(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetCommandsResponse> {
            const localVarFetchArgs = CommandsApiFetchParamCreator(configuration).getCommands(sortBy, orderBy, offset, limit, users, userIds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Kill the requested command.
         * @param {string} commandId The id of the command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killCommand(commandId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1KillCommandResponse> {
            const localVarFetchArgs = CommandsApiFetchParamCreator(configuration).killCommand(commandId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Launch a command.
         * @param {V1LaunchCommandRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchCommand(body: V1LaunchCommandRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1LaunchCommandResponse> {
            const localVarFetchArgs = CommandsApiFetchParamCreator(configuration).launchCommand(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Set the priority of the requested command.
         * @param {string} commandId The id of the command.
         * @param {V1SetCommandPriorityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCommandPriority(commandId: string, body: V1SetCommandPriorityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1SetCommandPriorityResponse> {
            const localVarFetchArgs = CommandsApiFetchParamCreator(configuration).setCommandPriority(commandId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CommandsApi - factory interface
 * @export
 */
export const CommandsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get the requested command.
         * @param {string} commandId The id of the command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommand(commandId: string, options?: any) {
            return CommandsApiFp(configuration).getCommand(commandId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of commands.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort commands by the given field.   - SORT_BY_UNSPECIFIED: Returns commands in an unsorted list.  - SORT_BY_ID: Returns commands sorted by id.  - SORT_BY_DESCRIPTION: Returns commands sorted by description.  - SORT_BY_START_TIME: Return commands sorted by start time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order commands in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of commands before returning results. Negative values denote number of commands to skip from the end before returning results.
         * @param {number} [limit] Limit the number of commands. A value of 0 denotes no limit.
         * @param {Array<string>} [users] Limit commands to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit commands to those that are owned by users with the specified userIds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommands(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options?: any) {
            return CommandsApiFp(configuration).getCommands(sortBy, orderBy, offset, limit, users, userIds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Kill the requested command.
         * @param {string} commandId The id of the command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killCommand(commandId: string, options?: any) {
            return CommandsApiFp(configuration).killCommand(commandId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Launch a command.
         * @param {V1LaunchCommandRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchCommand(body: V1LaunchCommandRequest, options?: any) {
            return CommandsApiFp(configuration).launchCommand(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Set the priority of the requested command.
         * @param {string} commandId The id of the command.
         * @param {V1SetCommandPriorityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCommandPriority(commandId: string, body: V1SetCommandPriorityRequest, options?: any) {
            return CommandsApiFp(configuration).setCommandPriority(commandId, body, options)(fetch, basePath);
        },
    };
};

/**
 * CommandsApi - object-oriented interface
 * @export
 * @class CommandsApi
 * @extends {BaseAPI}
 */
export class CommandsApi extends BaseAPI {
    /**
     * 
     * @summary Get the requested command.
     * @param {string} commandId The id of the command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public getCommand(commandId: string, options?: any) {
        return CommandsApiFp(this.configuration).getCommand(commandId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of commands.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort commands by the given field.   - SORT_BY_UNSPECIFIED: Returns commands in an unsorted list.  - SORT_BY_ID: Returns commands sorted by id.  - SORT_BY_DESCRIPTION: Returns commands sorted by description.  - SORT_BY_START_TIME: Return commands sorted by start time.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order commands in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of commands before returning results. Negative values denote number of commands to skip from the end before returning results.
     * @param {number} [limit] Limit the number of commands. A value of 0 denotes no limit.
     * @param {Array<string>} [users] Limit commands to those that are owned by users with the specified usernames.
     * @param {Array<number>} [userIds] Limit commands to those that are owned by users with the specified userIds.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public getCommands(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options?: any) {
        return CommandsApiFp(this.configuration).getCommands(sortBy, orderBy, offset, limit, users, userIds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Kill the requested command.
     * @param {string} commandId The id of the command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public killCommand(commandId: string, options?: any) {
        return CommandsApiFp(this.configuration).killCommand(commandId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Launch a command.
     * @param {V1LaunchCommandRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public launchCommand(body: V1LaunchCommandRequest, options?: any) {
        return CommandsApiFp(this.configuration).launchCommand(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Set the priority of the requested command.
     * @param {string} commandId The id of the command.
     * @param {V1SetCommandPriorityRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public setCommandPriority(commandId: string, body: V1SetCommandPriorityRequest, options?: any) {
        return CommandsApiFp(this.configuration).setCommandPriority(commandId, body, options)(this.fetch, this.basePath);
    }

}

/**
 * ExperimentsApi - fetch parameter creator
 * @export
 */
export const ExperimentsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateExperiment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activateExperiment.');
            }
            const localVarPath = `/api/v1/experiments/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archive an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveExperiment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling archiveExperiment.');
            }
            const localVarPath = `/api/v1/experiments/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelExperiment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cancelExperiment.');
            }
            const localVarPath = `/api/v1/experiments/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return downsampled metrics from multiple trials to compare them side-by-side.
         * @param {Array<number>} [trialIds] The requested trial ids.
         * @param {number} [maxDatapoints] The maximum number of data points to return after downsampling.
         * @param {Array<string>} [metricNames] The names of selected metrics.
         * @param {number} [startBatches] Sample from metrics after this batch number.
         * @param {number} [endBatches] Sample from metrics before this batch number.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] Type of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG'} [scale] Scale of metric visualization (linear or log scale).   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compareTrials(trialIds?: Array<number>, maxDatapoints?: number, metricNames?: Array<string>, startBatches?: number, endBatches?: number, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', scale?: 'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG', options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/trials/compare`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (trialIds) {
                localVarQueryParameter['trialIds'] = trialIds;
            }

            if (maxDatapoints !== undefined) {
                localVarQueryParameter['maxDatapoints'] = maxDatapoints;
            }

            if (metricNames) {
                localVarQueryParameter['metricNames'] = metricNames;
            }

            if (startBatches !== undefined) {
                localVarQueryParameter['startBatches'] = startBatches;
            }

            if (endBatches !== undefined) {
                localVarQueryParameter['endBatches'] = endBatches;
            }

            if (metricType !== undefined) {
                localVarQueryParameter['metricType'] = metricType;
            }

            if (scale !== undefined) {
                localVarQueryParameter['scale'] = scale;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the requested experiment.
         * @param {number} experimentId The ID of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExperiment(experimentId: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling deleteExperiment.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the requested experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperiment(experimentId: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling getExperiment.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of checkpoints for an experiment.
         * @param {number} id The experiment id.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_TRIAL_ID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_SEARCHER_METRIC'} [sortBy] Sort checkpoints by the given field.   - SORT_BY_UNSPECIFIED: Returns checkpoints in an unsorted list.  - SORT_BY_UUID: Returns checkpoints sorted by UUID.  - SORT_BY_TRIAL_ID: Returns checkpoints sorted by trial id.  - SORT_BY_BATCH_NUMBER: Returns checkpoints sorted by batch number.  - SORT_BY_END_TIME: Returns checkpoints sorted by end time.  - SORT_BY_STATE: Returns checkpoints sorted by state.  - SORT_BY_SEARCHER_METRIC: Returns checkpoints sorted by the experiment&#39;s &#x60;searcher.metric&#x60; configuration setting.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order checkpoints in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of checkpoints before returning results. Negative values denote number of checkpoints to skip from the end before returning results.
         * @param {number} [limit] Limit the number of checkpoints. A value of 0 denotes no limit.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>} [states] Limit the checkpoints to those that match the states.   - STATE_UNSPECIFIED: The state of the checkpoint is unknown.  - STATE_ACTIVE: The checkpoint is in an active state.  - STATE_COMPLETED: The checkpoint is persisted to checkpoint storage.  - STATE_ERROR: The checkpoint errored.  - STATE_DELETED: The checkpoint has been deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentCheckpoints(id: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_TRIAL_ID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_SEARCHER_METRIC', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getExperimentCheckpoints.');
            }
            const localVarPath = `/api/v1/experiments/{id}/checkpoints`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of unique experiment labels (sorted by popularity).
         * @param {number} [projectId] Filter experiments by project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentLabels(projectId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/experiment/labels`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get individual file from modal definitions for download.
         * @param {number} experimentId Experiment ID
         * @param {string} path Path to the target file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentModelFile(experimentId: number, path: string, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling getExperimentModelFile.');
            }
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling getExperimentModelFile.');
            }
            const localVarPath = `/experiments/{experiment_id}/file/download`
                .replace(`{${"experiment_id"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of trials for an experiment.
         * @param {number} experimentId Limit trials to those that are owned by the specified experiments.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS'} [sortBy] Sort trials by the given field.   - SORT_BY_UNSPECIFIED: Returns trials in an unsorted list.  - SORT_BY_ID: Returns trials sorted by id.  - SORT_BY_START_TIME: Return trials sorted by start time.  - SORT_BY_END_TIME: Return trials sorted by end time. Trials without end times are returned after trials that are.  - SORT_BY_STATE: Return trials sorted by state.  - SORT_BY_BEST_VALIDATION_METRIC: Return the trials sorted by the best metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_LATEST_VALIDATION_METRIC: Return the trials sorted by the latest metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_BATCHES_PROCESSED: Return the trials sorted by the number of batches completed.  - SORT_BY_DURATION: Return the trials sorted by the total duration.  - SORT_BY_RESTARTS: Return the trials sorted by the number of restarts.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order trials in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of trials before returning results. Negative values denote number of trials to skip from the end before returning results.
         * @param {number} [limit] Limit the number of trials. A value of 0 denotes no limit.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>} [states] Limit trials to those that match the provided state.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentTrials(experimentId: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling getExperimentTrials.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/trials`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the validation history for an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentValidationHistory(experimentId: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling getExperimentValidationHistory.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/validation-history`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of experiments.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_NUM_TRIALS' | 'SORT_BY_PROGRESS' | 'SORT_BY_USER' | 'SORT_BY_NAME' | 'SORT_BY_FORKED_FROM' | 'SORT_BY_RESOURCE_POOL' | 'SORT_BY_PROJECT_ID'} [sortBy] Sort experiments by the given field.   - SORT_BY_UNSPECIFIED: Returns experiments in an unsorted list.  - SORT_BY_ID: Returns experiments sorted by id.  - SORT_BY_DESCRIPTION: Returns experiments sorted by description.  - SORT_BY_START_TIME: Return experiments sorted by start time.  - SORT_BY_END_TIME: Return experiments sorted by end time. Experiments without end_time are returned after the ones with end_time.  - SORT_BY_STATE: Return experiments sorted by state.  - SORT_BY_NUM_TRIALS: Return experiments sorted by number of trials.  - SORT_BY_PROGRESS: Return experiments sorted by progress.  - SORT_BY_USER: Return experiments sorted by user.  - SORT_BY_NAME: Returns experiments sorted by name.  - SORT_BY_FORKED_FROM: Returns experiments sorted by originating model.  - SORT_BY_RESOURCE_POOL: Returns experiments sorted by resource pool.  - SORT_BY_PROJECT_ID: Returns experiments sorted by project.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order experiments in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of experiments before returning results. Negative values denote number of experiments to skip from the end before returning results.
         * @param {number} [limit] Limit the number of experiments. 0 or Unspecified - returns a default of 100. -1               - returns everything. -2               - returns pagination info but no experiments.
         * @param {string} [description] Limit experiments to those that match the description.
         * @param {string} [name] Limit experiments to those that match the name.
         * @param {Array<string>} [labels] Limit experiments to those that match the provided labels.
         * @param {boolean} [archived] Limit experiments to those that are archived.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>} [states] Limit experiments to those that match the provided state.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
         * @param {Array<string>} [users] Limit experiments to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit experiments to those that are owned by users with the specified userIds.
         * @param {number} [projectId] Limit experiments to those within a specified project, or 0 for all projects.
         * @param {number} [experimentIdFilterLt] TODO(ilia): add &#x60;exact&#x60;. Less than.
         * @param {number} [experimentIdFilterLte] Less than or equal.
         * @param {number} [experimentIdFilterGt] Greater than.
         * @param {number} [experimentIdFilterGte] Greater than or equal.
         * @param {Array<number>} [experimentIdFilterIncl] In a set. &#x60;in&#x60; is a reserved word in python.
         * @param {Array<number>} [experimentIdFilterNotIn] Not in a set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperiments(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_NUM_TRIALS' | 'SORT_BY_PROGRESS' | 'SORT_BY_USER' | 'SORT_BY_NAME' | 'SORT_BY_FORKED_FROM' | 'SORT_BY_RESOURCE_POOL' | 'SORT_BY_PROJECT_ID', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, description?: string, name?: string, labels?: Array<string>, archived?: boolean, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>, users?: Array<string>, userIds?: Array<number>, projectId?: number, experimentIdFilterLt?: number, experimentIdFilterLte?: number, experimentIdFilterGt?: number, experimentIdFilterGte?: number, experimentIdFilterIncl?: Array<number>, experimentIdFilterNotIn?: Array<number>, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/experiments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (labels) {
                localVarQueryParameter['labels'] = labels;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (experimentIdFilterLt !== undefined) {
                localVarQueryParameter['experimentIdFilter.lt'] = experimentIdFilterLt;
            }

            if (experimentIdFilterLte !== undefined) {
                localVarQueryParameter['experimentIdFilter.lte'] = experimentIdFilterLte;
            }

            if (experimentIdFilterGt !== undefined) {
                localVarQueryParameter['experimentIdFilter.gt'] = experimentIdFilterGt;
            }

            if (experimentIdFilterGte !== undefined) {
                localVarQueryParameter['experimentIdFilter.gte'] = experimentIdFilterGte;
            }

            if (experimentIdFilterIncl) {
                localVarQueryParameter['experimentIdFilter.incl'] = experimentIdFilterIncl;
            }

            if (experimentIdFilterNotIn) {
                localVarQueryParameter['experimentIdFilter.notIn'] = experimentIdFilterNotIn;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the model definition of an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDef(experimentId: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling getModelDef.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/model_def`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one file content of model definition of an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {V1GetModelDefFileRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDefFile(experimentId: number, body: V1GetModelDefFileRequest, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling getModelDefFile.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getModelDefFile.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/file`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1GetModelDefFileRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the model definition file tree of an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDefTree(experimentId: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling getModelDefTree.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/file_tree`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single trial.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrial(trialId: number, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling getTrial.');
            }
            const localVarPath = `/api/v1/trials/{trialId}`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of checkpoints for a trial.
         * @param {number} id The trial id.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE'} [sortBy] Sort checkpoints by the given field.   - SORT_BY_UNSPECIFIED: Returns checkpoints in an unsorted list.  - SORT_BY_UUID: Returns checkpoints sorted by UUID.  - SORT_BY_BATCH_NUMBER: Returns checkpoints sorted by batch number.  - SORT_BY_END_TIME: Returns checkpoints sorted by end time.  - SORT_BY_STATE: Returns checkpoints sorted by state.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order checkpoints in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of checkpoints before returning results. Negative values denote number of checkpoints to skip from the end before returning results.
         * @param {number} [limit] Limit the number of checkpoints. A value of 0 denotes no limit.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>} [states] Limit the checkpoints to those that match the states.   - STATE_UNSPECIFIED: The state of the checkpoint is unknown.  - STATE_ACTIVE: The checkpoint is in an active state.  - STATE_COMPLETED: The checkpoint is persisted to checkpoint storage.  - STATE_ERROR: The checkpoint errored.  - STATE_DELETED: The checkpoint has been deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCheckpoints(id: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTrialCheckpoints.');
            }
            const localVarPath = `/api/v1/trials/{id}/checkpoints`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kill an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killExperiment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling killExperiment.');
            }
            const localVarPath = `/api/v1/experiments/{id}/kill`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kill a trial.
         * @param {number} id The trial id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killTrial(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling killTrial.');
            }
            const localVarPath = `/api/v1/trials/{id}/kill`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move an experiment into a project.
         * @param {number} experimentId The id of the experiment being moved.
         * @param {V1MoveExperimentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveExperiment(experimentId: number, body: V1MoveExperimentRequest, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling moveExperiment.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling moveExperiment.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/move`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1MoveExperimentRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch an experiment's fields.
         * @param {number} experimentId The id of the experiment.
         * @param {V1PatchExperiment} body Patched experiment attributes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchExperiment(experimentId: number, body: V1PatchExperiment, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling patchExperiment.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling patchExperiment.');
            }
            const localVarPath = `/api/v1/experiments/{experiment.id}`
                .replace(`{${"experiment.id"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PatchExperiment" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pause an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseExperiment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pauseExperiment.');
            }
            const localVarPath = `/api/v1/experiments/{id}/pause`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Preview hyperparameter search.
         * @param {V1PreviewHPSearchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewHPSearch(body: V1PreviewHPSearchRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling previewHPSearch.');
            }
            const localVarPath = `/api/v1/preview-hp-search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PreviewHPSearchRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Downsample metrics collected during a trial.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {number} [maxDatapoints] The maximum number of data points to return after downsampling.
         * @param {Array<string>} [metricNames] The names of selected metrics.
         * @param {number} [startBatches] Sample from metrics after this batch number.
         * @param {number} [endBatches] Sample from metrics before this batch number.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] Type of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG'} [scale] Scale of metric visualization (linear or log scale).   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summarizeTrial(trialId: number, maxDatapoints?: number, metricNames?: Array<string>, startBatches?: number, endBatches?: number, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', scale?: 'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG', options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling summarizeTrial.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/summarize`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (maxDatapoints !== undefined) {
                localVarQueryParameter['maxDatapoints'] = maxDatapoints;
            }

            if (metricNames) {
                localVarQueryParameter['metricNames'] = metricNames;
            }

            if (startBatches !== undefined) {
                localVarQueryParameter['startBatches'] = startBatches;
            }

            if (endBatches !== undefined) {
                localVarQueryParameter['endBatches'] = endBatches;
            }

            if (metricType !== undefined) {
                localVarQueryParameter['metricType'] = metricType;
            }

            if (scale !== undefined) {
                localVarQueryParameter['scale'] = scale;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stream trial logs.
         * @param {number} trialId The id of the trial.
         * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the trial stops.
         * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
         * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
         * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
         * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
         * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
         * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
         * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
         * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {string} [searchText] Search the logs by whether the text contains a substring.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialLogs(trialId: number, limit?: number, follow?: boolean, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling trialLogs.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/logs`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (follow !== undefined) {
                localVarQueryParameter['follow'] = follow;
            }

            if (agentIds) {
                localVarQueryParameter['agentIds'] = agentIds;
            }

            if (containerIds) {
                localVarQueryParameter['containerIds'] = containerIds;
            }

            if (rankIds) {
                localVarQueryParameter['rankIds'] = rankIds;
            }

            if (levels) {
                localVarQueryParameter['levels'] = levels;
            }

            if (stdtypes) {
                localVarQueryParameter['stdtypes'] = stdtypes;
            }

            if (sources) {
                localVarQueryParameter['sources'] = sources;
            }

            if (timestampBefore !== undefined) {
                localVarQueryParameter['timestampBefore'] = (timestampBefore as any).toISOString();
            }

            if (timestampAfter !== undefined) {
                localVarQueryParameter['timestampAfter'] = (timestampAfter as any).toISOString();
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stream trial log fields.
         * @param {number} trialId The ID of the trial.
         * @param {boolean} [follow] Continue following fields until the trial stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialLogsFields(trialId: number, follow?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling trialLogsFields.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/logs/fields`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (follow !== undefined) {
                localVarQueryParameter['follow'] = follow;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unarchive an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveExperiment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unarchiveExperiment.');
            }
            const localVarPath = `/api/v1/experiments/{id}/unarchive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExperimentsApi - functional programming interface
 * @export
 */
export const ExperimentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateExperiment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ActivateExperimentResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).activateExperiment(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Archive an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveExperiment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ArchiveExperimentResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).archiveExperiment(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Cancel an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelExperiment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1CancelExperimentResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).cancelExperiment(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return downsampled metrics from multiple trials to compare them side-by-side.
         * @param {Array<number>} [trialIds] The requested trial ids.
         * @param {number} [maxDatapoints] The maximum number of data points to return after downsampling.
         * @param {Array<string>} [metricNames] The names of selected metrics.
         * @param {number} [startBatches] Sample from metrics after this batch number.
         * @param {number} [endBatches] Sample from metrics before this batch number.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] Type of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG'} [scale] Scale of metric visualization (linear or log scale).   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compareTrials(trialIds?: Array<number>, maxDatapoints?: number, metricNames?: Array<string>, startBatches?: number, endBatches?: number, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', scale?: 'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1CompareTrialsResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).compareTrials(trialIds, maxDatapoints, metricNames, startBatches, endBatches, metricType, scale, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete the requested experiment.
         * @param {number} experimentId The ID of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExperiment(experimentId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1DeleteExperimentResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).deleteExperiment(experimentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the requested experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperiment(experimentId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetExperimentResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).getExperiment(experimentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of checkpoints for an experiment.
         * @param {number} id The experiment id.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_TRIAL_ID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_SEARCHER_METRIC'} [sortBy] Sort checkpoints by the given field.   - SORT_BY_UNSPECIFIED: Returns checkpoints in an unsorted list.  - SORT_BY_UUID: Returns checkpoints sorted by UUID.  - SORT_BY_TRIAL_ID: Returns checkpoints sorted by trial id.  - SORT_BY_BATCH_NUMBER: Returns checkpoints sorted by batch number.  - SORT_BY_END_TIME: Returns checkpoints sorted by end time.  - SORT_BY_STATE: Returns checkpoints sorted by state.  - SORT_BY_SEARCHER_METRIC: Returns checkpoints sorted by the experiment&#39;s &#x60;searcher.metric&#x60; configuration setting.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order checkpoints in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of checkpoints before returning results. Negative values denote number of checkpoints to skip from the end before returning results.
         * @param {number} [limit] Limit the number of checkpoints. A value of 0 denotes no limit.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>} [states] Limit the checkpoints to those that match the states.   - STATE_UNSPECIFIED: The state of the checkpoint is unknown.  - STATE_ACTIVE: The checkpoint is in an active state.  - STATE_COMPLETED: The checkpoint is persisted to checkpoint storage.  - STATE_ERROR: The checkpoint errored.  - STATE_DELETED: The checkpoint has been deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentCheckpoints(id: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_TRIAL_ID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_SEARCHER_METRIC', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetExperimentCheckpointsResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).getExperimentCheckpoints(id, sortBy, orderBy, offset, limit, states, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of unique experiment labels (sorted by popularity).
         * @param {number} [projectId] Filter experiments by project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentLabels(projectId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetExperimentLabelsResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).getExperimentLabels(projectId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get individual file from modal definitions for download.
         * @param {number} experimentId Experiment ID
         * @param {string} path Path to the target file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentModelFile(experimentId: number, path: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).getExperimentModelFile(experimentId, path, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the list of trials for an experiment.
         * @param {number} experimentId Limit trials to those that are owned by the specified experiments.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS'} [sortBy] Sort trials by the given field.   - SORT_BY_UNSPECIFIED: Returns trials in an unsorted list.  - SORT_BY_ID: Returns trials sorted by id.  - SORT_BY_START_TIME: Return trials sorted by start time.  - SORT_BY_END_TIME: Return trials sorted by end time. Trials without end times are returned after trials that are.  - SORT_BY_STATE: Return trials sorted by state.  - SORT_BY_BEST_VALIDATION_METRIC: Return the trials sorted by the best metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_LATEST_VALIDATION_METRIC: Return the trials sorted by the latest metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_BATCHES_PROCESSED: Return the trials sorted by the number of batches completed.  - SORT_BY_DURATION: Return the trials sorted by the total duration.  - SORT_BY_RESTARTS: Return the trials sorted by the number of restarts.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order trials in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of trials before returning results. Negative values denote number of trials to skip from the end before returning results.
         * @param {number} [limit] Limit the number of trials. A value of 0 denotes no limit.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>} [states] Limit trials to those that match the provided state.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentTrials(experimentId: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetExperimentTrialsResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).getExperimentTrials(experimentId, sortBy, orderBy, offset, limit, states, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the validation history for an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentValidationHistory(experimentId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetExperimentValidationHistoryResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).getExperimentValidationHistory(experimentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of experiments.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_NUM_TRIALS' | 'SORT_BY_PROGRESS' | 'SORT_BY_USER' | 'SORT_BY_NAME' | 'SORT_BY_FORKED_FROM' | 'SORT_BY_RESOURCE_POOL' | 'SORT_BY_PROJECT_ID'} [sortBy] Sort experiments by the given field.   - SORT_BY_UNSPECIFIED: Returns experiments in an unsorted list.  - SORT_BY_ID: Returns experiments sorted by id.  - SORT_BY_DESCRIPTION: Returns experiments sorted by description.  - SORT_BY_START_TIME: Return experiments sorted by start time.  - SORT_BY_END_TIME: Return experiments sorted by end time. Experiments without end_time are returned after the ones with end_time.  - SORT_BY_STATE: Return experiments sorted by state.  - SORT_BY_NUM_TRIALS: Return experiments sorted by number of trials.  - SORT_BY_PROGRESS: Return experiments sorted by progress.  - SORT_BY_USER: Return experiments sorted by user.  - SORT_BY_NAME: Returns experiments sorted by name.  - SORT_BY_FORKED_FROM: Returns experiments sorted by originating model.  - SORT_BY_RESOURCE_POOL: Returns experiments sorted by resource pool.  - SORT_BY_PROJECT_ID: Returns experiments sorted by project.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order experiments in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of experiments before returning results. Negative values denote number of experiments to skip from the end before returning results.
         * @param {number} [limit] Limit the number of experiments. 0 or Unspecified - returns a default of 100. -1               - returns everything. -2               - returns pagination info but no experiments.
         * @param {string} [description] Limit experiments to those that match the description.
         * @param {string} [name] Limit experiments to those that match the name.
         * @param {Array<string>} [labels] Limit experiments to those that match the provided labels.
         * @param {boolean} [archived] Limit experiments to those that are archived.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>} [states] Limit experiments to those that match the provided state.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
         * @param {Array<string>} [users] Limit experiments to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit experiments to those that are owned by users with the specified userIds.
         * @param {number} [projectId] Limit experiments to those within a specified project, or 0 for all projects.
         * @param {number} [experimentIdFilterLt] TODO(ilia): add &#x60;exact&#x60;. Less than.
         * @param {number} [experimentIdFilterLte] Less than or equal.
         * @param {number} [experimentIdFilterGt] Greater than.
         * @param {number} [experimentIdFilterGte] Greater than or equal.
         * @param {Array<number>} [experimentIdFilterIncl] In a set. &#x60;in&#x60; is a reserved word in python.
         * @param {Array<number>} [experimentIdFilterNotIn] Not in a set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperiments(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_NUM_TRIALS' | 'SORT_BY_PROGRESS' | 'SORT_BY_USER' | 'SORT_BY_NAME' | 'SORT_BY_FORKED_FROM' | 'SORT_BY_RESOURCE_POOL' | 'SORT_BY_PROJECT_ID', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, description?: string, name?: string, labels?: Array<string>, archived?: boolean, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>, users?: Array<string>, userIds?: Array<number>, projectId?: number, experimentIdFilterLt?: number, experimentIdFilterLte?: number, experimentIdFilterGt?: number, experimentIdFilterGte?: number, experimentIdFilterIncl?: Array<number>, experimentIdFilterNotIn?: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetExperimentsResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).getExperiments(sortBy, orderBy, offset, limit, description, name, labels, archived, states, users, userIds, projectId, experimentIdFilterLt, experimentIdFilterLte, experimentIdFilterGt, experimentIdFilterGte, experimentIdFilterIncl, experimentIdFilterNotIn, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the model definition of an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDef(experimentId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetModelDefResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).getModelDef(experimentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get one file content of model definition of an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {V1GetModelDefFileRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDefFile(experimentId: number, body: V1GetModelDefFileRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetModelDefFileResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).getModelDefFile(experimentId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the model definition file tree of an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDefTree(experimentId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetModelDefTreeResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).getModelDefTree(experimentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a single trial.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrial(trialId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetTrialResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).getTrial(trialId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of checkpoints for a trial.
         * @param {number} id The trial id.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE'} [sortBy] Sort checkpoints by the given field.   - SORT_BY_UNSPECIFIED: Returns checkpoints in an unsorted list.  - SORT_BY_UUID: Returns checkpoints sorted by UUID.  - SORT_BY_BATCH_NUMBER: Returns checkpoints sorted by batch number.  - SORT_BY_END_TIME: Returns checkpoints sorted by end time.  - SORT_BY_STATE: Returns checkpoints sorted by state.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order checkpoints in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of checkpoints before returning results. Negative values denote number of checkpoints to skip from the end before returning results.
         * @param {number} [limit] Limit the number of checkpoints. A value of 0 denotes no limit.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>} [states] Limit the checkpoints to those that match the states.   - STATE_UNSPECIFIED: The state of the checkpoint is unknown.  - STATE_ACTIVE: The checkpoint is in an active state.  - STATE_COMPLETED: The checkpoint is persisted to checkpoint storage.  - STATE_ERROR: The checkpoint errored.  - STATE_DELETED: The checkpoint has been deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCheckpoints(id: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetTrialCheckpointsResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).getTrialCheckpoints(id, sortBy, orderBy, offset, limit, states, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Kill an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killExperiment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1KillExperimentResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).killExperiment(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Kill a trial.
         * @param {number} id The trial id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killTrial(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1KillTrialResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).killTrial(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Move an experiment into a project.
         * @param {number} experimentId The id of the experiment being moved.
         * @param {V1MoveExperimentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveExperiment(experimentId: number, body: V1MoveExperimentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1MoveExperimentResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).moveExperiment(experimentId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Patch an experiment's fields.
         * @param {number} experimentId The id of the experiment.
         * @param {V1PatchExperiment} body Patched experiment attributes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchExperiment(experimentId: number, body: V1PatchExperiment, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PatchExperimentResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).patchExperiment(experimentId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Pause an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseExperiment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PauseExperimentResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).pauseExperiment(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Preview hyperparameter search.
         * @param {V1PreviewHPSearchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewHPSearch(body: V1PreviewHPSearchRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PreviewHPSearchResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).previewHPSearch(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Downsample metrics collected during a trial.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {number} [maxDatapoints] The maximum number of data points to return after downsampling.
         * @param {Array<string>} [metricNames] The names of selected metrics.
         * @param {number} [startBatches] Sample from metrics after this batch number.
         * @param {number} [endBatches] Sample from metrics before this batch number.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] Type of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG'} [scale] Scale of metric visualization (linear or log scale).   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summarizeTrial(trialId: number, maxDatapoints?: number, metricNames?: Array<string>, startBatches?: number, endBatches?: number, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', scale?: 'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1SummarizeTrialResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).summarizeTrial(trialId, maxDatapoints, metricNames, startBatches, endBatches, metricType, scale, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Stream trial logs.
         * @param {number} trialId The id of the trial.
         * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the trial stops.
         * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
         * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
         * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
         * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
         * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
         * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
         * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
         * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {string} [searchText] Search the logs by whether the text contains a substring.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialLogs(trialId: number, limit?: number, follow?: boolean, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1TrialLogsResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).trialLogs(trialId, limit, follow, agentIds, containerIds, rankIds, levels, stdtypes, sources, timestampBefore, timestampAfter, orderBy, searchText, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Stream trial log fields.
         * @param {number} trialId The ID of the trial.
         * @param {boolean} [follow] Continue following fields until the trial stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialLogsFields(trialId: number, follow?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1TrialLogsFieldsResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).trialLogsFields(trialId, follow, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unarchive an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveExperiment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1UnarchiveExperimentResponse> {
            const localVarFetchArgs = ExperimentsApiFetchParamCreator(configuration).unarchiveExperiment(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ExperimentsApi - factory interface
 * @export
 */
export const ExperimentsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Activate an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateExperiment(id: number, options?: any) {
            return ExperimentsApiFp(configuration).activateExperiment(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Archive an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveExperiment(id: number, options?: any) {
            return ExperimentsApiFp(configuration).archiveExperiment(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Cancel an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelExperiment(id: number, options?: any) {
            return ExperimentsApiFp(configuration).cancelExperiment(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return downsampled metrics from multiple trials to compare them side-by-side.
         * @param {Array<number>} [trialIds] The requested trial ids.
         * @param {number} [maxDatapoints] The maximum number of data points to return after downsampling.
         * @param {Array<string>} [metricNames] The names of selected metrics.
         * @param {number} [startBatches] Sample from metrics after this batch number.
         * @param {number} [endBatches] Sample from metrics before this batch number.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] Type of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG'} [scale] Scale of metric visualization (linear or log scale).   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compareTrials(trialIds?: Array<number>, maxDatapoints?: number, metricNames?: Array<string>, startBatches?: number, endBatches?: number, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', scale?: 'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG', options?: any) {
            return ExperimentsApiFp(configuration).compareTrials(trialIds, maxDatapoints, metricNames, startBatches, endBatches, metricType, scale, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete the requested experiment.
         * @param {number} experimentId The ID of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExperiment(experimentId: number, options?: any) {
            return ExperimentsApiFp(configuration).deleteExperiment(experimentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the requested experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperiment(experimentId: number, options?: any) {
            return ExperimentsApiFp(configuration).getExperiment(experimentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of checkpoints for an experiment.
         * @param {number} id The experiment id.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_TRIAL_ID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_SEARCHER_METRIC'} [sortBy] Sort checkpoints by the given field.   - SORT_BY_UNSPECIFIED: Returns checkpoints in an unsorted list.  - SORT_BY_UUID: Returns checkpoints sorted by UUID.  - SORT_BY_TRIAL_ID: Returns checkpoints sorted by trial id.  - SORT_BY_BATCH_NUMBER: Returns checkpoints sorted by batch number.  - SORT_BY_END_TIME: Returns checkpoints sorted by end time.  - SORT_BY_STATE: Returns checkpoints sorted by state.  - SORT_BY_SEARCHER_METRIC: Returns checkpoints sorted by the experiment&#39;s &#x60;searcher.metric&#x60; configuration setting.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order checkpoints in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of checkpoints before returning results. Negative values denote number of checkpoints to skip from the end before returning results.
         * @param {number} [limit] Limit the number of checkpoints. A value of 0 denotes no limit.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>} [states] Limit the checkpoints to those that match the states.   - STATE_UNSPECIFIED: The state of the checkpoint is unknown.  - STATE_ACTIVE: The checkpoint is in an active state.  - STATE_COMPLETED: The checkpoint is persisted to checkpoint storage.  - STATE_ERROR: The checkpoint errored.  - STATE_DELETED: The checkpoint has been deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentCheckpoints(id: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_TRIAL_ID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_SEARCHER_METRIC', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>, options?: any) {
            return ExperimentsApiFp(configuration).getExperimentCheckpoints(id, sortBy, orderBy, offset, limit, states, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of unique experiment labels (sorted by popularity).
         * @param {number} [projectId] Filter experiments by project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentLabels(projectId?: number, options?: any) {
            return ExperimentsApiFp(configuration).getExperimentLabels(projectId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get individual file from modal definitions for download.
         * @param {number} experimentId Experiment ID
         * @param {string} path Path to the target file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentModelFile(experimentId: number, path: string, options?: any) {
            return ExperimentsApiFp(configuration).getExperimentModelFile(experimentId, path, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the list of trials for an experiment.
         * @param {number} experimentId Limit trials to those that are owned by the specified experiments.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS'} [sortBy] Sort trials by the given field.   - SORT_BY_UNSPECIFIED: Returns trials in an unsorted list.  - SORT_BY_ID: Returns trials sorted by id.  - SORT_BY_START_TIME: Return trials sorted by start time.  - SORT_BY_END_TIME: Return trials sorted by end time. Trials without end times are returned after trials that are.  - SORT_BY_STATE: Return trials sorted by state.  - SORT_BY_BEST_VALIDATION_METRIC: Return the trials sorted by the best metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_LATEST_VALIDATION_METRIC: Return the trials sorted by the latest metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_BATCHES_PROCESSED: Return the trials sorted by the number of batches completed.  - SORT_BY_DURATION: Return the trials sorted by the total duration.  - SORT_BY_RESTARTS: Return the trials sorted by the number of restarts.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order trials in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of trials before returning results. Negative values denote number of trials to skip from the end before returning results.
         * @param {number} [limit] Limit the number of trials. A value of 0 denotes no limit.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>} [states] Limit trials to those that match the provided state.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentTrials(experimentId: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>, options?: any) {
            return ExperimentsApiFp(configuration).getExperimentTrials(experimentId, sortBy, orderBy, offset, limit, states, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the validation history for an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentValidationHistory(experimentId: number, options?: any) {
            return ExperimentsApiFp(configuration).getExperimentValidationHistory(experimentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of experiments.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_NUM_TRIALS' | 'SORT_BY_PROGRESS' | 'SORT_BY_USER' | 'SORT_BY_NAME' | 'SORT_BY_FORKED_FROM' | 'SORT_BY_RESOURCE_POOL' | 'SORT_BY_PROJECT_ID'} [sortBy] Sort experiments by the given field.   - SORT_BY_UNSPECIFIED: Returns experiments in an unsorted list.  - SORT_BY_ID: Returns experiments sorted by id.  - SORT_BY_DESCRIPTION: Returns experiments sorted by description.  - SORT_BY_START_TIME: Return experiments sorted by start time.  - SORT_BY_END_TIME: Return experiments sorted by end time. Experiments without end_time are returned after the ones with end_time.  - SORT_BY_STATE: Return experiments sorted by state.  - SORT_BY_NUM_TRIALS: Return experiments sorted by number of trials.  - SORT_BY_PROGRESS: Return experiments sorted by progress.  - SORT_BY_USER: Return experiments sorted by user.  - SORT_BY_NAME: Returns experiments sorted by name.  - SORT_BY_FORKED_FROM: Returns experiments sorted by originating model.  - SORT_BY_RESOURCE_POOL: Returns experiments sorted by resource pool.  - SORT_BY_PROJECT_ID: Returns experiments sorted by project.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order experiments in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of experiments before returning results. Negative values denote number of experiments to skip from the end before returning results.
         * @param {number} [limit] Limit the number of experiments. 0 or Unspecified - returns a default of 100. -1               - returns everything. -2               - returns pagination info but no experiments.
         * @param {string} [description] Limit experiments to those that match the description.
         * @param {string} [name] Limit experiments to those that match the name.
         * @param {Array<string>} [labels] Limit experiments to those that match the provided labels.
         * @param {boolean} [archived] Limit experiments to those that are archived.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>} [states] Limit experiments to those that match the provided state.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
         * @param {Array<string>} [users] Limit experiments to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit experiments to those that are owned by users with the specified userIds.
         * @param {number} [projectId] Limit experiments to those within a specified project, or 0 for all projects.
         * @param {number} [experimentIdFilterLt] TODO(ilia): add &#x60;exact&#x60;. Less than.
         * @param {number} [experimentIdFilterLte] Less than or equal.
         * @param {number} [experimentIdFilterGt] Greater than.
         * @param {number} [experimentIdFilterGte] Greater than or equal.
         * @param {Array<number>} [experimentIdFilterIncl] In a set. &#x60;in&#x60; is a reserved word in python.
         * @param {Array<number>} [experimentIdFilterNotIn] Not in a set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperiments(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_NUM_TRIALS' | 'SORT_BY_PROGRESS' | 'SORT_BY_USER' | 'SORT_BY_NAME' | 'SORT_BY_FORKED_FROM' | 'SORT_BY_RESOURCE_POOL' | 'SORT_BY_PROJECT_ID', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, description?: string, name?: string, labels?: Array<string>, archived?: boolean, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>, users?: Array<string>, userIds?: Array<number>, projectId?: number, experimentIdFilterLt?: number, experimentIdFilterLte?: number, experimentIdFilterGt?: number, experimentIdFilterGte?: number, experimentIdFilterIncl?: Array<number>, experimentIdFilterNotIn?: Array<number>, options?: any) {
            return ExperimentsApiFp(configuration).getExperiments(sortBy, orderBy, offset, limit, description, name, labels, archived, states, users, userIds, projectId, experimentIdFilterLt, experimentIdFilterLte, experimentIdFilterGt, experimentIdFilterGte, experimentIdFilterIncl, experimentIdFilterNotIn, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the model definition of an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDef(experimentId: number, options?: any) {
            return ExperimentsApiFp(configuration).getModelDef(experimentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get one file content of model definition of an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {V1GetModelDefFileRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDefFile(experimentId: number, body: V1GetModelDefFileRequest, options?: any) {
            return ExperimentsApiFp(configuration).getModelDefFile(experimentId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the model definition file tree of an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDefTree(experimentId: number, options?: any) {
            return ExperimentsApiFp(configuration).getModelDefTree(experimentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a single trial.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrial(trialId: number, options?: any) {
            return ExperimentsApiFp(configuration).getTrial(trialId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of checkpoints for a trial.
         * @param {number} id The trial id.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE'} [sortBy] Sort checkpoints by the given field.   - SORT_BY_UNSPECIFIED: Returns checkpoints in an unsorted list.  - SORT_BY_UUID: Returns checkpoints sorted by UUID.  - SORT_BY_BATCH_NUMBER: Returns checkpoints sorted by batch number.  - SORT_BY_END_TIME: Returns checkpoints sorted by end time.  - SORT_BY_STATE: Returns checkpoints sorted by state.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order checkpoints in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of checkpoints before returning results. Negative values denote number of checkpoints to skip from the end before returning results.
         * @param {number} [limit] Limit the number of checkpoints. A value of 0 denotes no limit.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>} [states] Limit the checkpoints to those that match the states.   - STATE_UNSPECIFIED: The state of the checkpoint is unknown.  - STATE_ACTIVE: The checkpoint is in an active state.  - STATE_COMPLETED: The checkpoint is persisted to checkpoint storage.  - STATE_ERROR: The checkpoint errored.  - STATE_DELETED: The checkpoint has been deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCheckpoints(id: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>, options?: any) {
            return ExperimentsApiFp(configuration).getTrialCheckpoints(id, sortBy, orderBy, offset, limit, states, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Kill an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killExperiment(id: number, options?: any) {
            return ExperimentsApiFp(configuration).killExperiment(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Kill a trial.
         * @param {number} id The trial id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killTrial(id: number, options?: any) {
            return ExperimentsApiFp(configuration).killTrial(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Move an experiment into a project.
         * @param {number} experimentId The id of the experiment being moved.
         * @param {V1MoveExperimentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveExperiment(experimentId: number, body: V1MoveExperimentRequest, options?: any) {
            return ExperimentsApiFp(configuration).moveExperiment(experimentId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Patch an experiment's fields.
         * @param {number} experimentId The id of the experiment.
         * @param {V1PatchExperiment} body Patched experiment attributes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchExperiment(experimentId: number, body: V1PatchExperiment, options?: any) {
            return ExperimentsApiFp(configuration).patchExperiment(experimentId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Pause an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseExperiment(id: number, options?: any) {
            return ExperimentsApiFp(configuration).pauseExperiment(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Preview hyperparameter search.
         * @param {V1PreviewHPSearchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewHPSearch(body: V1PreviewHPSearchRequest, options?: any) {
            return ExperimentsApiFp(configuration).previewHPSearch(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Downsample metrics collected during a trial.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {number} [maxDatapoints] The maximum number of data points to return after downsampling.
         * @param {Array<string>} [metricNames] The names of selected metrics.
         * @param {number} [startBatches] Sample from metrics after this batch number.
         * @param {number} [endBatches] Sample from metrics before this batch number.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] Type of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG'} [scale] Scale of metric visualization (linear or log scale).   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summarizeTrial(trialId: number, maxDatapoints?: number, metricNames?: Array<string>, startBatches?: number, endBatches?: number, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', scale?: 'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG', options?: any) {
            return ExperimentsApiFp(configuration).summarizeTrial(trialId, maxDatapoints, metricNames, startBatches, endBatches, metricType, scale, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Stream trial logs.
         * @param {number} trialId The id of the trial.
         * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the trial stops.
         * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
         * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
         * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
         * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
         * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
         * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
         * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
         * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {string} [searchText] Search the logs by whether the text contains a substring.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialLogs(trialId: number, limit?: number, follow?: boolean, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options?: any) {
            return ExperimentsApiFp(configuration).trialLogs(trialId, limit, follow, agentIds, containerIds, rankIds, levels, stdtypes, sources, timestampBefore, timestampAfter, orderBy, searchText, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Stream trial log fields.
         * @param {number} trialId The ID of the trial.
         * @param {boolean} [follow] Continue following fields until the trial stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialLogsFields(trialId: number, follow?: boolean, options?: any) {
            return ExperimentsApiFp(configuration).trialLogsFields(trialId, follow, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unarchive an experiment.
         * @param {number} id The experiment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveExperiment(id: number, options?: any) {
            return ExperimentsApiFp(configuration).unarchiveExperiment(id, options)(fetch, basePath);
        },
    };
};

/**
 * ExperimentsApi - object-oriented interface
 * @export
 * @class ExperimentsApi
 * @extends {BaseAPI}
 */
export class ExperimentsApi extends BaseAPI {
    /**
     * 
     * @summary Activate an experiment.
     * @param {number} id The experiment id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public activateExperiment(id: number, options?: any) {
        return ExperimentsApiFp(this.configuration).activateExperiment(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Archive an experiment.
     * @param {number} id The experiment id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public archiveExperiment(id: number, options?: any) {
        return ExperimentsApiFp(this.configuration).archiveExperiment(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Cancel an experiment.
     * @param {number} id The experiment id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public cancelExperiment(id: number, options?: any) {
        return ExperimentsApiFp(this.configuration).cancelExperiment(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return downsampled metrics from multiple trials to compare them side-by-side.
     * @param {Array<number>} [trialIds] The requested trial ids.
     * @param {number} [maxDatapoints] The maximum number of data points to return after downsampling.
     * @param {Array<string>} [metricNames] The names of selected metrics.
     * @param {number} [startBatches] Sample from metrics after this batch number.
     * @param {number} [endBatches] Sample from metrics before this batch number.
     * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] Type of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
     * @param {'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG'} [scale] Scale of metric visualization (linear or log scale).   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public compareTrials(trialIds?: Array<number>, maxDatapoints?: number, metricNames?: Array<string>, startBatches?: number, endBatches?: number, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', scale?: 'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG', options?: any) {
        return ExperimentsApiFp(this.configuration).compareTrials(trialIds, maxDatapoints, metricNames, startBatches, endBatches, metricType, scale, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete the requested experiment.
     * @param {number} experimentId The ID of the experiment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public deleteExperiment(experimentId: number, options?: any) {
        return ExperimentsApiFp(this.configuration).deleteExperiment(experimentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the requested experiment.
     * @param {number} experimentId The id of the experiment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public getExperiment(experimentId: number, options?: any) {
        return ExperimentsApiFp(this.configuration).getExperiment(experimentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of checkpoints for an experiment.
     * @param {number} id The experiment id.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_TRIAL_ID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_SEARCHER_METRIC'} [sortBy] Sort checkpoints by the given field.   - SORT_BY_UNSPECIFIED: Returns checkpoints in an unsorted list.  - SORT_BY_UUID: Returns checkpoints sorted by UUID.  - SORT_BY_TRIAL_ID: Returns checkpoints sorted by trial id.  - SORT_BY_BATCH_NUMBER: Returns checkpoints sorted by batch number.  - SORT_BY_END_TIME: Returns checkpoints sorted by end time.  - SORT_BY_STATE: Returns checkpoints sorted by state.  - SORT_BY_SEARCHER_METRIC: Returns checkpoints sorted by the experiment&#39;s &#x60;searcher.metric&#x60; configuration setting.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order checkpoints in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of checkpoints before returning results. Negative values denote number of checkpoints to skip from the end before returning results.
     * @param {number} [limit] Limit the number of checkpoints. A value of 0 denotes no limit.
     * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>} [states] Limit the checkpoints to those that match the states.   - STATE_UNSPECIFIED: The state of the checkpoint is unknown.  - STATE_ACTIVE: The checkpoint is in an active state.  - STATE_COMPLETED: The checkpoint is persisted to checkpoint storage.  - STATE_ERROR: The checkpoint errored.  - STATE_DELETED: The checkpoint has been deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public getExperimentCheckpoints(id: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_TRIAL_ID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_SEARCHER_METRIC', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>, options?: any) {
        return ExperimentsApiFp(this.configuration).getExperimentCheckpoints(id, sortBy, orderBy, offset, limit, states, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of unique experiment labels (sorted by popularity).
     * @param {number} [projectId] Filter experiments by project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public getExperimentLabels(projectId?: number, options?: any) {
        return ExperimentsApiFp(this.configuration).getExperimentLabels(projectId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get individual file from modal definitions for download.
     * @param {number} experimentId Experiment ID
     * @param {string} path Path to the target file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public getExperimentModelFile(experimentId: number, path: string, options?: any) {
        return ExperimentsApiFp(this.configuration).getExperimentModelFile(experimentId, path, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the list of trials for an experiment.
     * @param {number} experimentId Limit trials to those that are owned by the specified experiments.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS'} [sortBy] Sort trials by the given field.   - SORT_BY_UNSPECIFIED: Returns trials in an unsorted list.  - SORT_BY_ID: Returns trials sorted by id.  - SORT_BY_START_TIME: Return trials sorted by start time.  - SORT_BY_END_TIME: Return trials sorted by end time. Trials without end times are returned after trials that are.  - SORT_BY_STATE: Return trials sorted by state.  - SORT_BY_BEST_VALIDATION_METRIC: Return the trials sorted by the best metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_LATEST_VALIDATION_METRIC: Return the trials sorted by the latest metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_BATCHES_PROCESSED: Return the trials sorted by the number of batches completed.  - SORT_BY_DURATION: Return the trials sorted by the total duration.  - SORT_BY_RESTARTS: Return the trials sorted by the number of restarts.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order trials in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of trials before returning results. Negative values denote number of trials to skip from the end before returning results.
     * @param {number} [limit] Limit the number of trials. A value of 0 denotes no limit.
     * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>} [states] Limit trials to those that match the provided state.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public getExperimentTrials(experimentId: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>, options?: any) {
        return ExperimentsApiFp(this.configuration).getExperimentTrials(experimentId, sortBy, orderBy, offset, limit, states, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the validation history for an experiment.
     * @param {number} experimentId The id of the experiment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public getExperimentValidationHistory(experimentId: number, options?: any) {
        return ExperimentsApiFp(this.configuration).getExperimentValidationHistory(experimentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of experiments.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_NUM_TRIALS' | 'SORT_BY_PROGRESS' | 'SORT_BY_USER' | 'SORT_BY_NAME' | 'SORT_BY_FORKED_FROM' | 'SORT_BY_RESOURCE_POOL' | 'SORT_BY_PROJECT_ID'} [sortBy] Sort experiments by the given field.   - SORT_BY_UNSPECIFIED: Returns experiments in an unsorted list.  - SORT_BY_ID: Returns experiments sorted by id.  - SORT_BY_DESCRIPTION: Returns experiments sorted by description.  - SORT_BY_START_TIME: Return experiments sorted by start time.  - SORT_BY_END_TIME: Return experiments sorted by end time. Experiments without end_time are returned after the ones with end_time.  - SORT_BY_STATE: Return experiments sorted by state.  - SORT_BY_NUM_TRIALS: Return experiments sorted by number of trials.  - SORT_BY_PROGRESS: Return experiments sorted by progress.  - SORT_BY_USER: Return experiments sorted by user.  - SORT_BY_NAME: Returns experiments sorted by name.  - SORT_BY_FORKED_FROM: Returns experiments sorted by originating model.  - SORT_BY_RESOURCE_POOL: Returns experiments sorted by resource pool.  - SORT_BY_PROJECT_ID: Returns experiments sorted by project.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order experiments in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of experiments before returning results. Negative values denote number of experiments to skip from the end before returning results.
     * @param {number} [limit] Limit the number of experiments. 0 or Unspecified - returns a default of 100. -1               - returns everything. -2               - returns pagination info but no experiments.
     * @param {string} [description] Limit experiments to those that match the description.
     * @param {string} [name] Limit experiments to those that match the name.
     * @param {Array<string>} [labels] Limit experiments to those that match the provided labels.
     * @param {boolean} [archived] Limit experiments to those that are archived.
     * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>} [states] Limit experiments to those that match the provided state.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
     * @param {Array<string>} [users] Limit experiments to those that are owned by users with the specified usernames.
     * @param {Array<number>} [userIds] Limit experiments to those that are owned by users with the specified userIds.
     * @param {number} [projectId] Limit experiments to those within a specified project, or 0 for all projects.
     * @param {number} [experimentIdFilterLt] TODO(ilia): add &#x60;exact&#x60;. Less than.
     * @param {number} [experimentIdFilterLte] Less than or equal.
     * @param {number} [experimentIdFilterGt] Greater than.
     * @param {number} [experimentIdFilterGte] Greater than or equal.
     * @param {Array<number>} [experimentIdFilterIncl] In a set. &#x60;in&#x60; is a reserved word in python.
     * @param {Array<number>} [experimentIdFilterNotIn] Not in a set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public getExperiments(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_NUM_TRIALS' | 'SORT_BY_PROGRESS' | 'SORT_BY_USER' | 'SORT_BY_NAME' | 'SORT_BY_FORKED_FROM' | 'SORT_BY_RESOURCE_POOL' | 'SORT_BY_PROJECT_ID', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, description?: string, name?: string, labels?: Array<string>, archived?: boolean, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>, users?: Array<string>, userIds?: Array<number>, projectId?: number, experimentIdFilterLt?: number, experimentIdFilterLte?: number, experimentIdFilterGt?: number, experimentIdFilterGte?: number, experimentIdFilterIncl?: Array<number>, experimentIdFilterNotIn?: Array<number>, options?: any) {
        return ExperimentsApiFp(this.configuration).getExperiments(sortBy, orderBy, offset, limit, description, name, labels, archived, states, users, userIds, projectId, experimentIdFilterLt, experimentIdFilterLte, experimentIdFilterGt, experimentIdFilterGte, experimentIdFilterIncl, experimentIdFilterNotIn, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the model definition of an experiment.
     * @param {number} experimentId The id of the experiment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public getModelDef(experimentId: number, options?: any) {
        return ExperimentsApiFp(this.configuration).getModelDef(experimentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get one file content of model definition of an experiment.
     * @param {number} experimentId The id of the experiment.
     * @param {V1GetModelDefFileRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public getModelDefFile(experimentId: number, body: V1GetModelDefFileRequest, options?: any) {
        return ExperimentsApiFp(this.configuration).getModelDefFile(experimentId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the model definition file tree of an experiment.
     * @param {number} experimentId The id of the experiment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public getModelDefTree(experimentId: number, options?: any) {
        return ExperimentsApiFp(this.configuration).getModelDefTree(experimentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a single trial.
     * @param {number} trialId The requested trial&#39;s id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public getTrial(trialId: number, options?: any) {
        return ExperimentsApiFp(this.configuration).getTrial(trialId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of checkpoints for a trial.
     * @param {number} id The trial id.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE'} [sortBy] Sort checkpoints by the given field.   - SORT_BY_UNSPECIFIED: Returns checkpoints in an unsorted list.  - SORT_BY_UUID: Returns checkpoints sorted by UUID.  - SORT_BY_BATCH_NUMBER: Returns checkpoints sorted by batch number.  - SORT_BY_END_TIME: Returns checkpoints sorted by end time.  - SORT_BY_STATE: Returns checkpoints sorted by state.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order checkpoints in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of checkpoints before returning results. Negative values denote number of checkpoints to skip from the end before returning results.
     * @param {number} [limit] Limit the number of checkpoints. A value of 0 denotes no limit.
     * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>} [states] Limit the checkpoints to those that match the states.   - STATE_UNSPECIFIED: The state of the checkpoint is unknown.  - STATE_ACTIVE: The checkpoint is in an active state.  - STATE_COMPLETED: The checkpoint is persisted to checkpoint storage.  - STATE_ERROR: The checkpoint errored.  - STATE_DELETED: The checkpoint has been deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public getTrialCheckpoints(id: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_UUID' | 'SORT_BY_BATCH_NUMBER' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_ERROR' | 'STATE_DELETED'>, options?: any) {
        return ExperimentsApiFp(this.configuration).getTrialCheckpoints(id, sortBy, orderBy, offset, limit, states, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Kill an experiment.
     * @param {number} id The experiment id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public killExperiment(id: number, options?: any) {
        return ExperimentsApiFp(this.configuration).killExperiment(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Kill a trial.
     * @param {number} id The trial id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public killTrial(id: number, options?: any) {
        return ExperimentsApiFp(this.configuration).killTrial(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Move an experiment into a project.
     * @param {number} experimentId The id of the experiment being moved.
     * @param {V1MoveExperimentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public moveExperiment(experimentId: number, body: V1MoveExperimentRequest, options?: any) {
        return ExperimentsApiFp(this.configuration).moveExperiment(experimentId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Patch an experiment's fields.
     * @param {number} experimentId The id of the experiment.
     * @param {V1PatchExperiment} body Patched experiment attributes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public patchExperiment(experimentId: number, body: V1PatchExperiment, options?: any) {
        return ExperimentsApiFp(this.configuration).patchExperiment(experimentId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Pause an experiment.
     * @param {number} id The experiment id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public pauseExperiment(id: number, options?: any) {
        return ExperimentsApiFp(this.configuration).pauseExperiment(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Preview hyperparameter search.
     * @param {V1PreviewHPSearchRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public previewHPSearch(body: V1PreviewHPSearchRequest, options?: any) {
        return ExperimentsApiFp(this.configuration).previewHPSearch(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Downsample metrics collected during a trial.
     * @param {number} trialId The requested trial&#39;s id.
     * @param {number} [maxDatapoints] The maximum number of data points to return after downsampling.
     * @param {Array<string>} [metricNames] The names of selected metrics.
     * @param {number} [startBatches] Sample from metrics after this batch number.
     * @param {number} [endBatches] Sample from metrics before this batch number.
     * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] Type of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
     * @param {'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG'} [scale] Scale of metric visualization (linear or log scale).   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public summarizeTrial(trialId: number, maxDatapoints?: number, metricNames?: Array<string>, startBatches?: number, endBatches?: number, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', scale?: 'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG', options?: any) {
        return ExperimentsApiFp(this.configuration).summarizeTrial(trialId, maxDatapoints, metricNames, startBatches, endBatches, metricType, scale, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Stream trial logs.
     * @param {number} trialId The id of the trial.
     * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
     * @param {boolean} [follow] Continue following logs until the trial stops.
     * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
     * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
     * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
     * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
     * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
     * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
     * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
     * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {string} [searchText] Search the logs by whether the text contains a substring.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public trialLogs(trialId: number, limit?: number, follow?: boolean, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options?: any) {
        return ExperimentsApiFp(this.configuration).trialLogs(trialId, limit, follow, agentIds, containerIds, rankIds, levels, stdtypes, sources, timestampBefore, timestampAfter, orderBy, searchText, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Stream trial log fields.
     * @param {number} trialId The ID of the trial.
     * @param {boolean} [follow] Continue following fields until the trial stops.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public trialLogsFields(trialId: number, follow?: boolean, options?: any) {
        return ExperimentsApiFp(this.configuration).trialLogsFields(trialId, follow, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unarchive an experiment.
     * @param {number} id The experiment id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentsApi
     */
    public unarchiveExperiment(id: number, options?: any) {
        return ExperimentsApiFp(this.configuration).unarchiveExperiment(id, options)(this.fetch, this.basePath);
    }

}

/**
 * InternalApi - fetch parameter creator
 * @export
 */
export const InternalApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Acknowledge the receipt of a signal to stop the given allocation early. This is used indicate and exit 0 isn't final; specifically, it is used for HP search directed early stops and preemption signals (not necessarily just scheduler preemption).
         * @param {string} allocationId The allocation that is acknowledging the request.
         * @param {V1AckAllocationPreemptionSignalRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ackAllocationPreemptionSignal(allocationId: string, body: V1AckAllocationPreemptionSignalRequest, options: any = {}): FetchArgs {
            // verify required parameter 'allocationId' is not null or undefined
            if (allocationId === null || allocationId === undefined) {
                throw new RequiredError('allocationId','Required parameter allocationId was null or undefined when calling ackAllocationPreemptionSignal.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling ackAllocationPreemptionSignal.');
            }
            const localVarPath = `/api/v1/allocations/{allocationId}/signals/ack_preemption`
                .replace(`{${"allocationId"}}`, encodeURIComponent(String(allocationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1AckAllocationPreemptionSignalRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary AllocationAllGather performs an all gather through the master. An allocation can only perform once all gather at a time.
         * @param {string} allocationId The ID of the allocation.
         * @param {V1AllocationAllGatherRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationAllGather(allocationId: string, body: V1AllocationAllGatherRequest, options: any = {}): FetchArgs {
            // verify required parameter 'allocationId' is not null or undefined
            if (allocationId === null || allocationId === undefined) {
                throw new RequiredError('allocationId','Required parameter allocationId was null or undefined when calling allocationAllGather.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling allocationAllGather.');
            }
            const localVarPath = `/api/v1/allocations/{allocationId}/all_gather`
                .replace(`{${"allocationId"}}`, encodeURIComponent(String(allocationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1AllocationAllGatherRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report the receipt of a signal to stop the given allocation early. This is used to communicate back from a SLURM job that it has been notified of a pending preememption. Upon a call to this API the RM should then trigger a checkpoint and immediate exit.
         * @param {string} allocationId The id of the allocation.
         * @param {V1AllocationPendingPreemptionSignalRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationPendingPreemptionSignal(allocationId: string, body: V1AllocationPendingPreemptionSignalRequest, options: any = {}): FetchArgs {
            // verify required parameter 'allocationId' is not null or undefined
            if (allocationId === null || allocationId === undefined) {
                throw new RequiredError('allocationId','Required parameter allocationId was null or undefined when calling allocationPendingPreemptionSignal.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling allocationPendingPreemptionSignal.');
            }
            const localVarPath = `/api/v1/allocations/{allocationId}/signals/pending_preemption`
                .replace(`{${"allocationId"}}`, encodeURIComponent(String(allocationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1AllocationPendingPreemptionSignalRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Long poll preemption signals for the given allocation. If the allocation has been preempted when called, it will return so immediately. Otherwise, the connection will be kept open until the timeout is reached or the allocation is preempted.
         * @param {string} allocationId The id of the allocation.
         * @param {number} [timeoutSeconds] The timeout in seconds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationPreemptionSignal(allocationId: string, timeoutSeconds?: number, options: any = {}): FetchArgs {
            // verify required parameter 'allocationId' is not null or undefined
            if (allocationId === null || allocationId === undefined) {
                throw new RequiredError('allocationId','Required parameter allocationId was null or undefined when calling allocationPreemptionSignal.');
            }
            const localVarPath = `/api/v1/allocations/{allocationId}/signals/preemption`
                .replace(`{${"allocationId"}}`, encodeURIComponent(String(allocationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set allocation to ready state.
         * @param {string} allocationId The id of the allocation.
         * @param {V1AllocationReadyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationReady(allocationId: string, body: V1AllocationReadyRequest, options: any = {}): FetchArgs {
            // verify required parameter 'allocationId' is not null or undefined
            if (allocationId === null || allocationId === undefined) {
                throw new RequiredError('allocationId','Required parameter allocationId was null or undefined when calling allocationReady.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling allocationReady.');
            }
            const localVarPath = `/api/v1/allocations/{allocationId}/ready`
                .replace(`{${"allocationId"}}`, encodeURIComponent(String(allocationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1AllocationReadyRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gather an allocation's rendezvous info. Blocks until all trial containers connect to gather their rendezvous information and responds to them all at once.
         * @param {string} allocationId The id of the allocation.
         * @param {string} resourcesId The id of the clump of resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationRendezvousInfo(allocationId: string, resourcesId: string, options: any = {}): FetchArgs {
            // verify required parameter 'allocationId' is not null or undefined
            if (allocationId === null || allocationId === undefined) {
                throw new RequiredError('allocationId','Required parameter allocationId was null or undefined when calling allocationRendezvousInfo.');
            }
            // verify required parameter 'resourcesId' is not null or undefined
            if (resourcesId === null || resourcesId === undefined) {
                throw new RequiredError('resourcesId','Required parameter resourcesId was null or undefined when calling allocationRendezvousInfo.');
            }
            const localVarPath = `/api/v1/allocations/{allocationId}/resources/{resourcesId}/rendezvous`
                .replace(`{${"allocationId"}}`, encodeURIComponent(String(allocationId)))
                .replace(`{${"resourcesId"}}`, encodeURIComponent(String(resourcesId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set allocation to waiting state.
         * @param {string} allocationId The id of the allocation.
         * @param {V1AllocationWaitingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationWaiting(allocationId: string, body: V1AllocationWaitingRequest, options: any = {}): FetchArgs {
            // verify required parameter 'allocationId' is not null or undefined
            if (allocationId === null || allocationId === undefined) {
                throw new RequiredError('allocationId','Required parameter allocationId was null or undefined when calling allocationWaiting.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling allocationWaiting.');
            }
            const localVarPath = `/api/v1/allocations/{allocationId}/waiting`
                .replace(`{${"allocationId"}}`, encodeURIComponent(String(allocationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1AllocationWaitingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports to the searcher that the trial has completed the given searcher operation.
         * @param {number} trialId The id of the trial.
         * @param {V1CompleteValidateAfterOperation} body The completed operation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeTrialSearcherValidation(trialId: number, body: V1CompleteValidateAfterOperation, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling completeTrialSearcherValidation.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling completeTrialSearcherValidation.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/searcher/completed_operation`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1CompleteValidateAfterOperation" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger the computation of hyperparameter importance on-demand for a specific metric on a specific experiment. The status and results can be retrieved with GetHPImportance.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        computeHPImportance(experimentId: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling computeHPImportance.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/hyperparameter-importance`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an experiment.
         * @param {V1CreateExperimentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExperiment(body: V1CreateExperimentRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createExperiment.');
            }
            const localVarPath = `/api/v1/experiments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1CreateExperimentRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a group with optional members on creation.
         * @param {V1CreateGroupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(body: V1CreateGroupRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createGroup.');
            }
            const localVarPath = `/api/v1/groups`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1CreateGroupRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a group.
         * @param {number} groupId The id of the group that should be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(groupId: number, options: any = {}): FetchArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling deleteGroup.');
            }
            const localVarPath = `/api/v1/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the set of metric names recorded for a trial.
         * @param {Array<number>} trialId The id of the experiment.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expCompareMetricNames(trialId: Array<number>, periodSeconds?: number, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling expCompareMetricNames.');
            }
            const localVarPath = `/api/v1/trials/metrics-stream/metric-names`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (trialId) {
                localVarQueryParameter['trialId'] = trialId;
            }

            if (periodSeconds !== undefined) {
                localVarQueryParameter['periodSeconds'] = periodSeconds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a sample of the metrics over time for a sample of the trials.
         * @param {Array<number>} experimentIds The id of the experiment.
         * @param {string} metricName A metric name.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {number} [maxTrials] Maximum number of trials to fetch data for.
         * @param {number} [maxDatapoints] Maximum number of initial / historical data points.
         * @param {number} [startBatches] Beginning of window (inclusive) to fetch data for.
         * @param {number} [endBatches] Ending of window (inclusive) to fetch data for.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expCompareTrialsSample(experimentIds: Array<number>, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', maxTrials?: number, maxDatapoints?: number, startBatches?: number, endBatches?: number, periodSeconds?: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentIds' is not null or undefined
            if (experimentIds === null || experimentIds === undefined) {
                throw new RequiredError('experimentIds','Required parameter experimentIds was null or undefined when calling expCompareTrialsSample.');
            }
            // verify required parameter 'metricName' is not null or undefined
            if (metricName === null || metricName === undefined) {
                throw new RequiredError('metricName','Required parameter metricName was null or undefined when calling expCompareTrialsSample.');
            }
            // verify required parameter 'metricType' is not null or undefined
            if (metricType === null || metricType === undefined) {
                throw new RequiredError('metricType','Required parameter metricType was null or undefined when calling expCompareTrialsSample.');
            }
            const localVarPath = `/api/v1/experiments-compare`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (experimentIds) {
                localVarQueryParameter['experimentIds'] = experimentIds;
            }

            if (metricName !== undefined) {
                localVarQueryParameter['metricName'] = metricName;
            }

            if (metricType !== undefined) {
                localVarQueryParameter['metricType'] = metricType;
            }

            if (maxTrials !== undefined) {
                localVarQueryParameter['maxTrials'] = maxTrials;
            }

            if (maxDatapoints !== undefined) {
                localVarQueryParameter['maxDatapoints'] = maxDatapoints;
            }

            if (startBatches !== undefined) {
                localVarQueryParameter['startBatches'] = startBatches;
            }

            if (endBatches !== undefined) {
                localVarQueryParameter['endBatches'] = endBatches;
            }

            if (periodSeconds !== undefined) {
                localVarQueryParameter['periodSeconds'] = periodSeconds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the best searcher validation for an experiment by the given metric.
         * @param {number} experimentId The ID of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBestSearcherValidationMetric(experimentId: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling getBestSearcherValidationMetric.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/searcher/best_searcher_validation_metric`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current searcher operation.
         * @param {number} trialId The id of the trial.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTrialSearcherOperation(trialId: number, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling getCurrentTrialSearcherOperation.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/searcher/operation`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a group by id.
         * @param {number} groupId The id of the group to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(groupId: number, options: any = {}): FetchArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling getGroup.');
            }
            const localVarPath = `/api/v1/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for groups with optional filters.
         * @param {V1GetGroupsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(body: V1GetGroupsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getGroups.');
            }
            const localVarPath = `/api/v1/groups/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1GetGroupsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the latest computation of hyperparameter importance. Currently this is triggered for training loss (if emitted) and the searcher metric after 10% increments in an experiment's progress, but no more than every 10 minutes.
         * @param {number} experimentId The id of the experiment.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHPImportance(experimentId: number, periodSeconds?: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling getHPImportance.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/hyperparameter-importance`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (periodSeconds !== undefined) {
                localVarQueryParameter['periodSeconds'] = periodSeconds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get job queue stats for a resource pool.
         * @param {Array<string>} [resourcePools] Filter the results based on a set of resource pools.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobQueueStats(resourcePools?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/job-queues/stats`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (resourcePools) {
                localVarQueryParameter['resourcePools'] = resourcePools;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of jobs in queue.
         * @param {number} [offset] Pagination offset.
         * @param {number} [limit] Pagination limit.
         * @param {string} [resourcePool] The target resource-pool for agent resource manager.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order results in either ascending or descending order by the number of jobs ahead.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_QUEUED' | 'STATE_SCHEDULED' | 'STATE_SCHEDULED_BACKFILLED'>} [states] Filter to jobs with states among those given.   - STATE_UNSPECIFIED: Unspecified state.  - STATE_QUEUED: Job is queued and waiting to be schedlued.  - STATE_SCHEDULED: Job is scheduled.  - STATE_SCHEDULED_BACKFILLED: Job is scheduled as a backfill.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs(offset?: number, limit?: number, resourcePool?: string, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', states?: Array<'STATE_UNSPECIFIED' | 'STATE_QUEUED' | 'STATE_SCHEDULED' | 'STATE_SCHEDULED_BACKFILLED'>, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/job-queues`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourcePool !== undefined) {
                localVarQueryParameter['resourcePool'] = resourcePool;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all resource pools from the cluster.
         * @param {number} [offset] Skip the number of resource pools before returning results. Negative values denote number of resource pools to skip from the end before returning results.
         * @param {number} [limit] Limit the number of resource pools. A value of 0 denotes no limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourcePools(offset?: number, limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/resource-pools`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get telemetry information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelemetry(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/master/telemetry`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of workloads for a trial.
         * @param {number} trialId Limit workloads to those that are owned by the specified trial.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order workloads in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of workloads before returning results. Negative values denote number of workloads to skip from the end before returning results.
         * @param {number} [limit] Limit the number of workloads. A value of 0 denotes no limit.
         * @param {string} [sortKey] Sort workloads by batches, a training metric, or a validation metric.
         * @param {'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION'} [filter] Filter workloads with validation and/or checkpoint information.   - FILTER_OPTION_UNSPECIFIED: Any workload.  - FILTER_OPTION_CHECKPOINT: Only workloads with an associated checkpoint.  - FILTER_OPTION_VALIDATION: Only validation workloads.  - FILTER_OPTION_CHECKPOINT_OR_VALIDATION: Only validation workloads or ones with an associated checkpoint.
         * @param {boolean} [includeBatchMetrics] Include per-batch metrics.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] When sorting workloads by sort_key, specify training or validation form of a metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialWorkloads(trialId: number, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, sortKey?: string, filter?: 'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION', includeBatchMetrics?: boolean, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling getTrialWorkloads.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/workloads`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortKey !== undefined) {
                localVarQueryParameter['sortKey'] = sortKey;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (includeBatchMetrics !== undefined) {
                localVarQueryParameter['includeBatchMetrics'] = includeBatchMetrics;
            }

            if (metricType !== undefined) {
                localVarQueryParameter['metricType'] = metricType;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send notebook idle data to master
         * @param {string} notebookId The id of the notebook.
         * @param {V1IdleNotebookRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idleNotebook(notebookId: string, body: V1IdleNotebookRequest, options: any = {}): FetchArgs {
            // verify required parameter 'notebookId' is not null or undefined
            if (notebookId === null || notebookId === undefined) {
                throw new RequiredError('notebookId','Required parameter notebookId was null or undefined when calling idleNotebook.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling idleNotebook.');
            }
            const localVarPath = `/api/v1/notebooks/{notebookId}/report_idle`
                .replace(`{${"notebookId"}}`, encodeURIComponent(String(notebookId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1IdleNotebookRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark the given reservation (container, pod, etc) within an allocation as a daemon reservation. In the exit of a successful exit, Determined will wait for all resources to exit - unless they are marked as daemon resources, in which case Determined will clean them up regardless of exit status after all non-daemon resources have exited.
         * @param {string} allocationId The id of the allocation.
         * @param {string} resourcesId The id of the clump of resources to mark as daemon.
         * @param {V1MarkAllocationResourcesDaemonRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAllocationResourcesDaemon(allocationId: string, resourcesId: string, body: V1MarkAllocationResourcesDaemonRequest, options: any = {}): FetchArgs {
            // verify required parameter 'allocationId' is not null or undefined
            if (allocationId === null || allocationId === undefined) {
                throw new RequiredError('allocationId','Required parameter allocationId was null or undefined when calling markAllocationResourcesDaemon.');
            }
            // verify required parameter 'resourcesId' is not null or undefined
            if (resourcesId === null || resourcesId === undefined) {
                throw new RequiredError('resourcesId','Required parameter resourcesId was null or undefined when calling markAllocationResourcesDaemon.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling markAllocationResourcesDaemon.');
            }
            const localVarPath = `/api/v1/allocations/{allocationId}/resources/{resourcesId}/daemon`
                .replace(`{${"allocationId"}}`, encodeURIComponent(String(allocationId)))
                .replace(`{${"resourcesId"}}`, encodeURIComponent(String(resourcesId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1MarkAllocationResourcesDaemonRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the milestones (in batches processed) at which a metric is recorded by an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {string} metricName A metric name.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricBatches(experimentId: number, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', periodSeconds?: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling metricBatches.');
            }
            // verify required parameter 'metricName' is not null or undefined
            if (metricName === null || metricName === undefined) {
                throw new RequiredError('metricName','Required parameter metricName was null or undefined when calling metricBatches.');
            }
            // verify required parameter 'metricType' is not null or undefined
            if (metricType === null || metricType === undefined) {
                throw new RequiredError('metricType','Required parameter metricType was null or undefined when calling metricBatches.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/metrics-stream/batches`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (metricName !== undefined) {
                localVarQueryParameter['metricName'] = metricName;
            }

            if (metricType !== undefined) {
                localVarQueryParameter['metricType'] = metricType;
            }

            if (periodSeconds !== undefined) {
                localVarQueryParameter['periodSeconds'] = periodSeconds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the set of metric names recorded for an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricNames(experimentId: number, periodSeconds?: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling metricNames.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/metrics-stream/metric-names`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (periodSeconds !== undefined) {
                localVarQueryParameter['periodSeconds'] = periodSeconds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PostAllocationProxyAddress sets the proxy address to use when proxying to services provided by an allocation. Upon receipt, the master will also register any proxies specified by the task.
         * @param {string} allocationId The id of the allocation.
         * @param {V1PostAllocationProxyAddressRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocationProxyAddress(allocationId: string, body: V1PostAllocationProxyAddressRequest, options: any = {}): FetchArgs {
            // verify required parameter 'allocationId' is not null or undefined
            if (allocationId === null || allocationId === undefined) {
                throw new RequiredError('allocationId','Required parameter allocationId was null or undefined when calling postAllocationProxyAddress.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postAllocationProxyAddress.');
            }
            const localVarPath = `/api/v1/allocations/{allocationId}/proxy_address`
                .replace(`{${"allocationId"}}`, encodeURIComponent(String(allocationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PostAllocationProxyAddressRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Persist the given TrialProfilerMetricsBatch. The trial ID is in the labels.
         * @param {V1PostTrialProfilerMetricsBatchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTrialProfilerMetricsBatch(body: V1PostTrialProfilerMetricsBatchRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postTrialProfilerMetricsBatch.');
            }
            const localVarPath = `/api/v1/trials/profiler/metrics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PostTrialProfilerMetricsBatchRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary For bookkeeping, update trial runner metadata (currently just state).
         * @param {number} trialId The id of the trial.
         * @param {V1TrialRunnerMetadata} body The state for the trial runner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTrialRunnerMetadata(trialId: number, body: V1TrialRunnerMetadata, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling postTrialRunnerMetadata.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postTrialRunnerMetadata.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/runner/metadata`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1TrialRunnerMetadata" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Record a checkpoint.
         * @param {V1Checkpoint} body The training metrics to persist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportCheckpoint(body: V1Checkpoint, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling reportCheckpoint.');
            }
            const localVarPath = `/api/v1/checkpoints`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1Checkpoint" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary For bookkeeping, updates the progress towards to current requested searcher training length.
         * @param {number} trialId The id of the trial.
         * @param {number} body Total units completed by the trial, in terms of the unit used to configure the searcher.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTrialProgress(trialId: number, body: number, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling reportTrialProgress.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling reportTrialProgress.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/progress`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"number" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports to the searcher that the trial has completed the current requested amount of training with the given searcher validation metric.
         * @param {number} trialId The id of the trial.
         * @param {V1TrialEarlyExit} body The exit reason.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTrialSearcherEarlyExit(trialId: number, body: V1TrialEarlyExit, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling reportTrialSearcherEarlyExit.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling reportTrialSearcherEarlyExit.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/early_exit`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1TrialEarlyExit" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Record training metrics for specified training.
         * @param {number} trainingMetricsTrialId The trial associated with these metrics.
         * @param {V1TrialMetrics} body The training metrics to persist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTrialTrainingMetrics(trainingMetricsTrialId: number, body: V1TrialMetrics, options: any = {}): FetchArgs {
            // verify required parameter 'trainingMetricsTrialId' is not null or undefined
            if (trainingMetricsTrialId === null || trainingMetricsTrialId === undefined) {
                throw new RequiredError('trainingMetricsTrialId','Required parameter trainingMetricsTrialId was null or undefined when calling reportTrialTrainingMetrics.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling reportTrialTrainingMetrics.');
            }
            const localVarPath = `/api/v1/trials/{trainingMetrics.trialId}/training_metrics`
                .replace(`{${"trainingMetrics.trialId"}}`, encodeURIComponent(String(trainingMetricsTrialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1TrialMetrics" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Record validation metrics.
         * @param {number} validationMetricsTrialId The trial associated with these metrics.
         * @param {V1TrialMetrics} body The training metrics to persist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTrialValidationMetrics(validationMetricsTrialId: number, body: V1TrialMetrics, options: any = {}): FetchArgs {
            // verify required parameter 'validationMetricsTrialId' is not null or undefined
            if (validationMetricsTrialId === null || validationMetricsTrialId === undefined) {
                throw new RequiredError('validationMetricsTrialId','Required parameter validationMetricsTrialId was null or undefined when calling reportTrialValidationMetrics.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling reportTrialValidationMetrics.');
            }
            const localVarPath = `/api/v1/trials/{validationMetrics.trialId}/validation_metrics`
                .replace(`{${"validationMetrics.trialId"}}`, encodeURIComponent(String(validationMetricsTrialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1TrialMetrics" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a sample of the metrics over time for a sample of the trials.
         * @param {number} experimentId The id of the experiment.
         * @param {string} metricName A metric name.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {number} [maxTrials] Maximum number of trials to fetch data for.
         * @param {number} [maxDatapoints] Maximum number of initial / historical data points.
         * @param {number} [startBatches] Beginning of window (inclusive) to fetch data for.
         * @param {number} [endBatches] Ending of window (inclusive) to fetch data for.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialsSample(experimentId: number, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', maxTrials?: number, maxDatapoints?: number, startBatches?: number, endBatches?: number, periodSeconds?: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling trialsSample.');
            }
            // verify required parameter 'metricName' is not null or undefined
            if (metricName === null || metricName === undefined) {
                throw new RequiredError('metricName','Required parameter metricName was null or undefined when calling trialsSample.');
            }
            // verify required parameter 'metricType' is not null or undefined
            if (metricType === null || metricType === undefined) {
                throw new RequiredError('metricType','Required parameter metricType was null or undefined when calling trialsSample.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/metrics-stream/trials-sample`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (metricName !== undefined) {
                localVarQueryParameter['metricName'] = metricName;
            }

            if (metricType !== undefined) {
                localVarQueryParameter['metricType'] = metricType;
            }

            if (maxTrials !== undefined) {
                localVarQueryParameter['maxTrials'] = maxTrials;
            }

            if (maxDatapoints !== undefined) {
                localVarQueryParameter['maxDatapoints'] = maxDatapoints;
            }

            if (startBatches !== undefined) {
                localVarQueryParameter['startBatches'] = startBatches;
            }

            if (endBatches !== undefined) {
                localVarQueryParameter['endBatches'] = endBatches;
            }

            if (periodSeconds !== undefined) {
                localVarQueryParameter['periodSeconds'] = periodSeconds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a snapshot of a metric across all trials at a certain point of progress.
         * @param {number} experimentId The id of the experiment.
         * @param {string} metricName A metric name.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {number} batchesProcessed The point of progress at which to query metrics.
         * @param {number} [batchesMargin] A range either side of batches_processed to include near-misses.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialsSnapshot(experimentId: number, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', batchesProcessed: number, batchesMargin?: number, periodSeconds?: number, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling trialsSnapshot.');
            }
            // verify required parameter 'metricName' is not null or undefined
            if (metricName === null || metricName === undefined) {
                throw new RequiredError('metricName','Required parameter metricName was null or undefined when calling trialsSnapshot.');
            }
            // verify required parameter 'metricType' is not null or undefined
            if (metricType === null || metricType === undefined) {
                throw new RequiredError('metricType','Required parameter metricType was null or undefined when calling trialsSnapshot.');
            }
            // verify required parameter 'batchesProcessed' is not null or undefined
            if (batchesProcessed === null || batchesProcessed === undefined) {
                throw new RequiredError('batchesProcessed','Required parameter batchesProcessed was null or undefined when calling trialsSnapshot.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/metrics-stream/trials-snapshot`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (metricName !== undefined) {
                localVarQueryParameter['metricName'] = metricName;
            }

            if (metricType !== undefined) {
                localVarQueryParameter['metricType'] = metricType;
            }

            if (batchesProcessed !== undefined) {
                localVarQueryParameter['batchesProcessed'] = batchesProcessed;
            }

            if (batchesMargin !== undefined) {
                localVarQueryParameter['batchesMargin'] = batchesMargin;
            }

            if (periodSeconds !== undefined) {
                localVarQueryParameter['periodSeconds'] = periodSeconds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update group info.
         * @param {number} groupId The id of the group
         * @param {V1UpdateGroupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(groupId: number, body: V1UpdateGroupRequest, options: any = {}): FetchArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling updateGroup.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateGroup.');
            }
            const localVarPath = `/api/v1/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1UpdateGroupRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Control the job queues.
         * @param {V1UpdateJobQueueRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobQueue(body: V1UpdateJobQueueRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateJobQueue.');
            }
            const localVarPath = `/api/v1/job-queues`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1UpdateJobQueueRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Acknowledge the receipt of a signal to stop the given allocation early. This is used indicate and exit 0 isn't final; specifically, it is used for HP search directed early stops and preemption signals (not necessarily just scheduler preemption).
         * @param {string} allocationId The allocation that is acknowledging the request.
         * @param {V1AckAllocationPreemptionSignalRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ackAllocationPreemptionSignal(allocationId: string, body: V1AckAllocationPreemptionSignalRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1AckAllocationPreemptionSignalResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).ackAllocationPreemptionSignal(allocationId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary AllocationAllGather performs an all gather through the master. An allocation can only perform once all gather at a time.
         * @param {string} allocationId The ID of the allocation.
         * @param {V1AllocationAllGatherRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationAllGather(allocationId: string, body: V1AllocationAllGatherRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1AllocationAllGatherResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).allocationAllGather(allocationId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Report the receipt of a signal to stop the given allocation early. This is used to communicate back from a SLURM job that it has been notified of a pending preememption. Upon a call to this API the RM should then trigger a checkpoint and immediate exit.
         * @param {string} allocationId The id of the allocation.
         * @param {V1AllocationPendingPreemptionSignalRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationPendingPreemptionSignal(allocationId: string, body: V1AllocationPendingPreemptionSignalRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1AllocationPendingPreemptionSignalResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).allocationPendingPreemptionSignal(allocationId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Long poll preemption signals for the given allocation. If the allocation has been preempted when called, it will return so immediately. Otherwise, the connection will be kept open until the timeout is reached or the allocation is preempted.
         * @param {string} allocationId The id of the allocation.
         * @param {number} [timeoutSeconds] The timeout in seconds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationPreemptionSignal(allocationId: string, timeoutSeconds?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1AllocationPreemptionSignalResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).allocationPreemptionSignal(allocationId, timeoutSeconds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Set allocation to ready state.
         * @param {string} allocationId The id of the allocation.
         * @param {V1AllocationReadyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationReady(allocationId: string, body: V1AllocationReadyRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1AllocationReadyResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).allocationReady(allocationId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gather an allocation's rendezvous info. Blocks until all trial containers connect to gather their rendezvous information and responds to them all at once.
         * @param {string} allocationId The id of the allocation.
         * @param {string} resourcesId The id of the clump of resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationRendezvousInfo(allocationId: string, resourcesId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1AllocationRendezvousInfoResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).allocationRendezvousInfo(allocationId, resourcesId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Set allocation to waiting state.
         * @param {string} allocationId The id of the allocation.
         * @param {V1AllocationWaitingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationWaiting(allocationId: string, body: V1AllocationWaitingRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1AllocationWaitingResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).allocationWaiting(allocationId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Reports to the searcher that the trial has completed the given searcher operation.
         * @param {number} trialId The id of the trial.
         * @param {V1CompleteValidateAfterOperation} body The completed operation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeTrialSearcherValidation(trialId: number, body: V1CompleteValidateAfterOperation, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1CompleteTrialSearcherValidationResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).completeTrialSearcherValidation(trialId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Trigger the computation of hyperparameter importance on-demand for a specific metric on a specific experiment. The status and results can be retrieved with GetHPImportance.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        computeHPImportance(experimentId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ComputeHPImportanceResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).computeHPImportance(experimentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create an experiment.
         * @param {V1CreateExperimentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExperiment(body: V1CreateExperimentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1CreateExperimentResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).createExperiment(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create a group with optional members on creation.
         * @param {V1CreateGroupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(body: V1CreateGroupRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1CreateGroupResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).createGroup(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove a group.
         * @param {number} groupId The id of the group that should be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(groupId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1DeleteGroupResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).deleteGroup(groupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the set of metric names recorded for a trial.
         * @param {Array<number>} trialId The id of the experiment.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expCompareMetricNames(trialId: Array<number>, periodSeconds?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1ExpCompareMetricNamesResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).expCompareMetricNames(trialId, periodSeconds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a sample of the metrics over time for a sample of the trials.
         * @param {Array<number>} experimentIds The id of the experiment.
         * @param {string} metricName A metric name.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {number} [maxTrials] Maximum number of trials to fetch data for.
         * @param {number} [maxDatapoints] Maximum number of initial / historical data points.
         * @param {number} [startBatches] Beginning of window (inclusive) to fetch data for.
         * @param {number} [endBatches] Ending of window (inclusive) to fetch data for.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expCompareTrialsSample(experimentIds: Array<number>, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', maxTrials?: number, maxDatapoints?: number, startBatches?: number, endBatches?: number, periodSeconds?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1ExpCompareTrialsSampleResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).expCompareTrialsSample(experimentIds, metricName, metricType, maxTrials, maxDatapoints, startBatches, endBatches, periodSeconds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the best searcher validation for an experiment by the given metric.
         * @param {number} experimentId The ID of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBestSearcherValidationMetric(experimentId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetBestSearcherValidationMetricResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).getBestSearcherValidationMetric(experimentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the current searcher operation.
         * @param {number} trialId The id of the trial.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTrialSearcherOperation(trialId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetCurrentTrialSearcherOperationResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).getCurrentTrialSearcherOperation(trialId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a group by id.
         * @param {number} groupId The id of the group to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(groupId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetGroupResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).getGroup(groupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search for groups with optional filters.
         * @param {V1GetGroupsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(body: V1GetGroupsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetGroupsResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).getGroups(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve the latest computation of hyperparameter importance. Currently this is triggered for training loss (if emitted) and the searcher metric after 10% increments in an experiment's progress, but no more than every 10 minutes.
         * @param {number} experimentId The id of the experiment.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHPImportance(experimentId: number, periodSeconds?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1GetHPImportanceResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).getHPImportance(experimentId, periodSeconds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get job queue stats for a resource pool.
         * @param {Array<string>} [resourcePools] Filter the results based on a set of resource pools.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobQueueStats(resourcePools?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetJobQueueStatsResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).getJobQueueStats(resourcePools, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of jobs in queue.
         * @param {number} [offset] Pagination offset.
         * @param {number} [limit] Pagination limit.
         * @param {string} [resourcePool] The target resource-pool for agent resource manager.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order results in either ascending or descending order by the number of jobs ahead.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_QUEUED' | 'STATE_SCHEDULED' | 'STATE_SCHEDULED_BACKFILLED'>} [states] Filter to jobs with states among those given.   - STATE_UNSPECIFIED: Unspecified state.  - STATE_QUEUED: Job is queued and waiting to be schedlued.  - STATE_SCHEDULED: Job is scheduled.  - STATE_SCHEDULED_BACKFILLED: Job is scheduled as a backfill.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs(offset?: number, limit?: number, resourcePool?: string, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', states?: Array<'STATE_UNSPECIFIED' | 'STATE_QUEUED' | 'STATE_SCHEDULED' | 'STATE_SCHEDULED_BACKFILLED'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetJobsResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).getJobs(offset, limit, resourcePool, orderBy, states, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of all resource pools from the cluster.
         * @param {number} [offset] Skip the number of resource pools before returning results. Negative values denote number of resource pools to skip from the end before returning results.
         * @param {number} [limit] Limit the number of resource pools. A value of 0 denotes no limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourcePools(offset?: number, limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetResourcePoolsResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).getResourcePools(offset, limit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get telemetry information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelemetry(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetTelemetryResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).getTelemetry(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the list of workloads for a trial.
         * @param {number} trialId Limit workloads to those that are owned by the specified trial.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order workloads in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of workloads before returning results. Negative values denote number of workloads to skip from the end before returning results.
         * @param {number} [limit] Limit the number of workloads. A value of 0 denotes no limit.
         * @param {string} [sortKey] Sort workloads by batches, a training metric, or a validation metric.
         * @param {'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION'} [filter] Filter workloads with validation and/or checkpoint information.   - FILTER_OPTION_UNSPECIFIED: Any workload.  - FILTER_OPTION_CHECKPOINT: Only workloads with an associated checkpoint.  - FILTER_OPTION_VALIDATION: Only validation workloads.  - FILTER_OPTION_CHECKPOINT_OR_VALIDATION: Only validation workloads or ones with an associated checkpoint.
         * @param {boolean} [includeBatchMetrics] Include per-batch metrics.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] When sorting workloads by sort_key, specify training or validation form of a metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialWorkloads(trialId: number, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, sortKey?: string, filter?: 'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION', includeBatchMetrics?: boolean, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetTrialWorkloadsResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).getTrialWorkloads(trialId, orderBy, offset, limit, sortKey, filter, includeBatchMetrics, metricType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Send notebook idle data to master
         * @param {string} notebookId The id of the notebook.
         * @param {V1IdleNotebookRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idleNotebook(notebookId: string, body: V1IdleNotebookRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1IdleNotebookResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).idleNotebook(notebookId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Mark the given reservation (container, pod, etc) within an allocation as a daemon reservation. In the exit of a successful exit, Determined will wait for all resources to exit - unless they are marked as daemon resources, in which case Determined will clean them up regardless of exit status after all non-daemon resources have exited.
         * @param {string} allocationId The id of the allocation.
         * @param {string} resourcesId The id of the clump of resources to mark as daemon.
         * @param {V1MarkAllocationResourcesDaemonRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAllocationResourcesDaemon(allocationId: string, resourcesId: string, body: V1MarkAllocationResourcesDaemonRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1MarkAllocationResourcesDaemonResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).markAllocationResourcesDaemon(allocationId, resourcesId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the milestones (in batches processed) at which a metric is recorded by an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {string} metricName A metric name.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricBatches(experimentId: number, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', periodSeconds?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1MetricBatchesResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).metricBatches(experimentId, metricName, metricType, periodSeconds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the set of metric names recorded for an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricNames(experimentId: number, periodSeconds?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1MetricNamesResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).metricNames(experimentId, periodSeconds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary PostAllocationProxyAddress sets the proxy address to use when proxying to services provided by an allocation. Upon receipt, the master will also register any proxies specified by the task.
         * @param {string} allocationId The id of the allocation.
         * @param {V1PostAllocationProxyAddressRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocationProxyAddress(allocationId: string, body: V1PostAllocationProxyAddressRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PostAllocationProxyAddressResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).postAllocationProxyAddress(allocationId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Persist the given TrialProfilerMetricsBatch. The trial ID is in the labels.
         * @param {V1PostTrialProfilerMetricsBatchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTrialProfilerMetricsBatch(body: V1PostTrialProfilerMetricsBatchRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PostTrialProfilerMetricsBatchResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).postTrialProfilerMetricsBatch(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary For bookkeeping, update trial runner metadata (currently just state).
         * @param {number} trialId The id of the trial.
         * @param {V1TrialRunnerMetadata} body The state for the trial runner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTrialRunnerMetadata(trialId: number, body: V1TrialRunnerMetadata, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PostTrialRunnerMetadataResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).postTrialRunnerMetadata(trialId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Record a checkpoint.
         * @param {V1Checkpoint} body The training metrics to persist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportCheckpoint(body: V1Checkpoint, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ReportCheckpointResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).reportCheckpoint(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary For bookkeeping, updates the progress towards to current requested searcher training length.
         * @param {number} trialId The id of the trial.
         * @param {number} body Total units completed by the trial, in terms of the unit used to configure the searcher.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTrialProgress(trialId: number, body: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ReportTrialProgressResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).reportTrialProgress(trialId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Reports to the searcher that the trial has completed the current requested amount of training with the given searcher validation metric.
         * @param {number} trialId The id of the trial.
         * @param {V1TrialEarlyExit} body The exit reason.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTrialSearcherEarlyExit(trialId: number, body: V1TrialEarlyExit, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ReportTrialSearcherEarlyExitResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).reportTrialSearcherEarlyExit(trialId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Record training metrics for specified training.
         * @param {number} trainingMetricsTrialId The trial associated with these metrics.
         * @param {V1TrialMetrics} body The training metrics to persist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTrialTrainingMetrics(trainingMetricsTrialId: number, body: V1TrialMetrics, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ReportTrialTrainingMetricsResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).reportTrialTrainingMetrics(trainingMetricsTrialId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Record validation metrics.
         * @param {number} validationMetricsTrialId The trial associated with these metrics.
         * @param {V1TrialMetrics} body The training metrics to persist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTrialValidationMetrics(validationMetricsTrialId: number, body: V1TrialMetrics, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ReportTrialValidationMetricsResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).reportTrialValidationMetrics(validationMetricsTrialId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a sample of the metrics over time for a sample of the trials.
         * @param {number} experimentId The id of the experiment.
         * @param {string} metricName A metric name.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {number} [maxTrials] Maximum number of trials to fetch data for.
         * @param {number} [maxDatapoints] Maximum number of initial / historical data points.
         * @param {number} [startBatches] Beginning of window (inclusive) to fetch data for.
         * @param {number} [endBatches] Ending of window (inclusive) to fetch data for.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialsSample(experimentId: number, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', maxTrials?: number, maxDatapoints?: number, startBatches?: number, endBatches?: number, periodSeconds?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1TrialsSampleResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).trialsSample(experimentId, metricName, metricType, maxTrials, maxDatapoints, startBatches, endBatches, periodSeconds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a snapshot of a metric across all trials at a certain point of progress.
         * @param {number} experimentId The id of the experiment.
         * @param {string} metricName A metric name.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {number} batchesProcessed The point of progress at which to query metrics.
         * @param {number} [batchesMargin] A range either side of batches_processed to include near-misses.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialsSnapshot(experimentId: number, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', batchesProcessed: number, batchesMargin?: number, periodSeconds?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1TrialsSnapshotResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).trialsSnapshot(experimentId, metricName, metricType, batchesProcessed, batchesMargin, periodSeconds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update group info.
         * @param {number} groupId The id of the group
         * @param {V1UpdateGroupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(groupId: number, body: V1UpdateGroupRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1UpdateGroupResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).updateGroup(groupId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Control the job queues.
         * @param {V1UpdateJobQueueRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobQueue(body: V1UpdateJobQueueRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1UpdateJobQueueResponse> {
            const localVarFetchArgs = InternalApiFetchParamCreator(configuration).updateJobQueue(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Acknowledge the receipt of a signal to stop the given allocation early. This is used indicate and exit 0 isn't final; specifically, it is used for HP search directed early stops and preemption signals (not necessarily just scheduler preemption).
         * @param {string} allocationId The allocation that is acknowledging the request.
         * @param {V1AckAllocationPreemptionSignalRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ackAllocationPreemptionSignal(allocationId: string, body: V1AckAllocationPreemptionSignalRequest, options?: any) {
            return InternalApiFp(configuration).ackAllocationPreemptionSignal(allocationId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary AllocationAllGather performs an all gather through the master. An allocation can only perform once all gather at a time.
         * @param {string} allocationId The ID of the allocation.
         * @param {V1AllocationAllGatherRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationAllGather(allocationId: string, body: V1AllocationAllGatherRequest, options?: any) {
            return InternalApiFp(configuration).allocationAllGather(allocationId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Report the receipt of a signal to stop the given allocation early. This is used to communicate back from a SLURM job that it has been notified of a pending preememption. Upon a call to this API the RM should then trigger a checkpoint and immediate exit.
         * @param {string} allocationId The id of the allocation.
         * @param {V1AllocationPendingPreemptionSignalRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationPendingPreemptionSignal(allocationId: string, body: V1AllocationPendingPreemptionSignalRequest, options?: any) {
            return InternalApiFp(configuration).allocationPendingPreemptionSignal(allocationId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Long poll preemption signals for the given allocation. If the allocation has been preempted when called, it will return so immediately. Otherwise, the connection will be kept open until the timeout is reached or the allocation is preempted.
         * @param {string} allocationId The id of the allocation.
         * @param {number} [timeoutSeconds] The timeout in seconds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationPreemptionSignal(allocationId: string, timeoutSeconds?: number, options?: any) {
            return InternalApiFp(configuration).allocationPreemptionSignal(allocationId, timeoutSeconds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Set allocation to ready state.
         * @param {string} allocationId The id of the allocation.
         * @param {V1AllocationReadyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationReady(allocationId: string, body: V1AllocationReadyRequest, options?: any) {
            return InternalApiFp(configuration).allocationReady(allocationId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gather an allocation's rendezvous info. Blocks until all trial containers connect to gather their rendezvous information and responds to them all at once.
         * @param {string} allocationId The id of the allocation.
         * @param {string} resourcesId The id of the clump of resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationRendezvousInfo(allocationId: string, resourcesId: string, options?: any) {
            return InternalApiFp(configuration).allocationRendezvousInfo(allocationId, resourcesId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Set allocation to waiting state.
         * @param {string} allocationId The id of the allocation.
         * @param {V1AllocationWaitingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationWaiting(allocationId: string, body: V1AllocationWaitingRequest, options?: any) {
            return InternalApiFp(configuration).allocationWaiting(allocationId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Reports to the searcher that the trial has completed the given searcher operation.
         * @param {number} trialId The id of the trial.
         * @param {V1CompleteValidateAfterOperation} body The completed operation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeTrialSearcherValidation(trialId: number, body: V1CompleteValidateAfterOperation, options?: any) {
            return InternalApiFp(configuration).completeTrialSearcherValidation(trialId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Trigger the computation of hyperparameter importance on-demand for a specific metric on a specific experiment. The status and results can be retrieved with GetHPImportance.
         * @param {number} experimentId The id of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        computeHPImportance(experimentId: number, options?: any) {
            return InternalApiFp(configuration).computeHPImportance(experimentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create an experiment.
         * @param {V1CreateExperimentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExperiment(body: V1CreateExperimentRequest, options?: any) {
            return InternalApiFp(configuration).createExperiment(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create a group with optional members on creation.
         * @param {V1CreateGroupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(body: V1CreateGroupRequest, options?: any) {
            return InternalApiFp(configuration).createGroup(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove a group.
         * @param {number} groupId The id of the group that should be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(groupId: number, options?: any) {
            return InternalApiFp(configuration).deleteGroup(groupId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the set of metric names recorded for a trial.
         * @param {Array<number>} trialId The id of the experiment.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expCompareMetricNames(trialId: Array<number>, periodSeconds?: number, options?: any) {
            return InternalApiFp(configuration).expCompareMetricNames(trialId, periodSeconds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a sample of the metrics over time for a sample of the trials.
         * @param {Array<number>} experimentIds The id of the experiment.
         * @param {string} metricName A metric name.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {number} [maxTrials] Maximum number of trials to fetch data for.
         * @param {number} [maxDatapoints] Maximum number of initial / historical data points.
         * @param {number} [startBatches] Beginning of window (inclusive) to fetch data for.
         * @param {number} [endBatches] Ending of window (inclusive) to fetch data for.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expCompareTrialsSample(experimentIds: Array<number>, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', maxTrials?: number, maxDatapoints?: number, startBatches?: number, endBatches?: number, periodSeconds?: number, options?: any) {
            return InternalApiFp(configuration).expCompareTrialsSample(experimentIds, metricName, metricType, maxTrials, maxDatapoints, startBatches, endBatches, periodSeconds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the best searcher validation for an experiment by the given metric.
         * @param {number} experimentId The ID of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBestSearcherValidationMetric(experimentId: number, options?: any) {
            return InternalApiFp(configuration).getBestSearcherValidationMetric(experimentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the current searcher operation.
         * @param {number} trialId The id of the trial.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTrialSearcherOperation(trialId: number, options?: any) {
            return InternalApiFp(configuration).getCurrentTrialSearcherOperation(trialId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a group by id.
         * @param {number} groupId The id of the group to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(groupId: number, options?: any) {
            return InternalApiFp(configuration).getGroup(groupId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search for groups with optional filters.
         * @param {V1GetGroupsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(body: V1GetGroupsRequest, options?: any) {
            return InternalApiFp(configuration).getGroups(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve the latest computation of hyperparameter importance. Currently this is triggered for training loss (if emitted) and the searcher metric after 10% increments in an experiment's progress, but no more than every 10 minutes.
         * @param {number} experimentId The id of the experiment.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHPImportance(experimentId: number, periodSeconds?: number, options?: any) {
            return InternalApiFp(configuration).getHPImportance(experimentId, periodSeconds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get job queue stats for a resource pool.
         * @param {Array<string>} [resourcePools] Filter the results based on a set of resource pools.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobQueueStats(resourcePools?: Array<string>, options?: any) {
            return InternalApiFp(configuration).getJobQueueStats(resourcePools, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of jobs in queue.
         * @param {number} [offset] Pagination offset.
         * @param {number} [limit] Pagination limit.
         * @param {string} [resourcePool] The target resource-pool for agent resource manager.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order results in either ascending or descending order by the number of jobs ahead.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_QUEUED' | 'STATE_SCHEDULED' | 'STATE_SCHEDULED_BACKFILLED'>} [states] Filter to jobs with states among those given.   - STATE_UNSPECIFIED: Unspecified state.  - STATE_QUEUED: Job is queued and waiting to be schedlued.  - STATE_SCHEDULED: Job is scheduled.  - STATE_SCHEDULED_BACKFILLED: Job is scheduled as a backfill.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs(offset?: number, limit?: number, resourcePool?: string, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', states?: Array<'STATE_UNSPECIFIED' | 'STATE_QUEUED' | 'STATE_SCHEDULED' | 'STATE_SCHEDULED_BACKFILLED'>, options?: any) {
            return InternalApiFp(configuration).getJobs(offset, limit, resourcePool, orderBy, states, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of all resource pools from the cluster.
         * @param {number} [offset] Skip the number of resource pools before returning results. Negative values denote number of resource pools to skip from the end before returning results.
         * @param {number} [limit] Limit the number of resource pools. A value of 0 denotes no limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourcePools(offset?: number, limit?: number, options?: any) {
            return InternalApiFp(configuration).getResourcePools(offset, limit, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get telemetry information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelemetry(options?: any) {
            return InternalApiFp(configuration).getTelemetry(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the list of workloads for a trial.
         * @param {number} trialId Limit workloads to those that are owned by the specified trial.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order workloads in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of workloads before returning results. Negative values denote number of workloads to skip from the end before returning results.
         * @param {number} [limit] Limit the number of workloads. A value of 0 denotes no limit.
         * @param {string} [sortKey] Sort workloads by batches, a training metric, or a validation metric.
         * @param {'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION'} [filter] Filter workloads with validation and/or checkpoint information.   - FILTER_OPTION_UNSPECIFIED: Any workload.  - FILTER_OPTION_CHECKPOINT: Only workloads with an associated checkpoint.  - FILTER_OPTION_VALIDATION: Only validation workloads.  - FILTER_OPTION_CHECKPOINT_OR_VALIDATION: Only validation workloads or ones with an associated checkpoint.
         * @param {boolean} [includeBatchMetrics] Include per-batch metrics.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] When sorting workloads by sort_key, specify training or validation form of a metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialWorkloads(trialId: number, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, sortKey?: string, filter?: 'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION', includeBatchMetrics?: boolean, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', options?: any) {
            return InternalApiFp(configuration).getTrialWorkloads(trialId, orderBy, offset, limit, sortKey, filter, includeBatchMetrics, metricType, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Send notebook idle data to master
         * @param {string} notebookId The id of the notebook.
         * @param {V1IdleNotebookRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idleNotebook(notebookId: string, body: V1IdleNotebookRequest, options?: any) {
            return InternalApiFp(configuration).idleNotebook(notebookId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Mark the given reservation (container, pod, etc) within an allocation as a daemon reservation. In the exit of a successful exit, Determined will wait for all resources to exit - unless they are marked as daemon resources, in which case Determined will clean them up regardless of exit status after all non-daemon resources have exited.
         * @param {string} allocationId The id of the allocation.
         * @param {string} resourcesId The id of the clump of resources to mark as daemon.
         * @param {V1MarkAllocationResourcesDaemonRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAllocationResourcesDaemon(allocationId: string, resourcesId: string, body: V1MarkAllocationResourcesDaemonRequest, options?: any) {
            return InternalApiFp(configuration).markAllocationResourcesDaemon(allocationId, resourcesId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the milestones (in batches processed) at which a metric is recorded by an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {string} metricName A metric name.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricBatches(experimentId: number, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', periodSeconds?: number, options?: any) {
            return InternalApiFp(configuration).metricBatches(experimentId, metricName, metricType, periodSeconds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the set of metric names recorded for an experiment.
         * @param {number} experimentId The id of the experiment.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricNames(experimentId: number, periodSeconds?: number, options?: any) {
            return InternalApiFp(configuration).metricNames(experimentId, periodSeconds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary PostAllocationProxyAddress sets the proxy address to use when proxying to services provided by an allocation. Upon receipt, the master will also register any proxies specified by the task.
         * @param {string} allocationId The id of the allocation.
         * @param {V1PostAllocationProxyAddressRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocationProxyAddress(allocationId: string, body: V1PostAllocationProxyAddressRequest, options?: any) {
            return InternalApiFp(configuration).postAllocationProxyAddress(allocationId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Persist the given TrialProfilerMetricsBatch. The trial ID is in the labels.
         * @param {V1PostTrialProfilerMetricsBatchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTrialProfilerMetricsBatch(body: V1PostTrialProfilerMetricsBatchRequest, options?: any) {
            return InternalApiFp(configuration).postTrialProfilerMetricsBatch(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary For bookkeeping, update trial runner metadata (currently just state).
         * @param {number} trialId The id of the trial.
         * @param {V1TrialRunnerMetadata} body The state for the trial runner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTrialRunnerMetadata(trialId: number, body: V1TrialRunnerMetadata, options?: any) {
            return InternalApiFp(configuration).postTrialRunnerMetadata(trialId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Record a checkpoint.
         * @param {V1Checkpoint} body The training metrics to persist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportCheckpoint(body: V1Checkpoint, options?: any) {
            return InternalApiFp(configuration).reportCheckpoint(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary For bookkeeping, updates the progress towards to current requested searcher training length.
         * @param {number} trialId The id of the trial.
         * @param {number} body Total units completed by the trial, in terms of the unit used to configure the searcher.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTrialProgress(trialId: number, body: number, options?: any) {
            return InternalApiFp(configuration).reportTrialProgress(trialId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Reports to the searcher that the trial has completed the current requested amount of training with the given searcher validation metric.
         * @param {number} trialId The id of the trial.
         * @param {V1TrialEarlyExit} body The exit reason.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTrialSearcherEarlyExit(trialId: number, body: V1TrialEarlyExit, options?: any) {
            return InternalApiFp(configuration).reportTrialSearcherEarlyExit(trialId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Record training metrics for specified training.
         * @param {number} trainingMetricsTrialId The trial associated with these metrics.
         * @param {V1TrialMetrics} body The training metrics to persist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTrialTrainingMetrics(trainingMetricsTrialId: number, body: V1TrialMetrics, options?: any) {
            return InternalApiFp(configuration).reportTrialTrainingMetrics(trainingMetricsTrialId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Record validation metrics.
         * @param {number} validationMetricsTrialId The trial associated with these metrics.
         * @param {V1TrialMetrics} body The training metrics to persist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTrialValidationMetrics(validationMetricsTrialId: number, body: V1TrialMetrics, options?: any) {
            return InternalApiFp(configuration).reportTrialValidationMetrics(validationMetricsTrialId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a sample of the metrics over time for a sample of the trials.
         * @param {number} experimentId The id of the experiment.
         * @param {string} metricName A metric name.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {number} [maxTrials] Maximum number of trials to fetch data for.
         * @param {number} [maxDatapoints] Maximum number of initial / historical data points.
         * @param {number} [startBatches] Beginning of window (inclusive) to fetch data for.
         * @param {number} [endBatches] Ending of window (inclusive) to fetch data for.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialsSample(experimentId: number, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', maxTrials?: number, maxDatapoints?: number, startBatches?: number, endBatches?: number, periodSeconds?: number, options?: any) {
            return InternalApiFp(configuration).trialsSample(experimentId, metricName, metricType, maxTrials, maxDatapoints, startBatches, endBatches, periodSeconds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a snapshot of a metric across all trials at a certain point of progress.
         * @param {number} experimentId The id of the experiment.
         * @param {string} metricName A metric name.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {number} batchesProcessed The point of progress at which to query metrics.
         * @param {number} [batchesMargin] A range either side of batches_processed to include near-misses.
         * @param {number} [periodSeconds] Seconds to wait when polling for updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialsSnapshot(experimentId: number, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', batchesProcessed: number, batchesMargin?: number, periodSeconds?: number, options?: any) {
            return InternalApiFp(configuration).trialsSnapshot(experimentId, metricName, metricType, batchesProcessed, batchesMargin, periodSeconds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update group info.
         * @param {number} groupId The id of the group
         * @param {V1UpdateGroupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(groupId: number, body: V1UpdateGroupRequest, options?: any) {
            return InternalApiFp(configuration).updateGroup(groupId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Control the job queues.
         * @param {V1UpdateJobQueueRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobQueue(body: V1UpdateJobQueueRequest, options?: any) {
            return InternalApiFp(configuration).updateJobQueue(body, options)(fetch, basePath);
        },
    };
};

/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
    /**
     * 
     * @summary Acknowledge the receipt of a signal to stop the given allocation early. This is used indicate and exit 0 isn't final; specifically, it is used for HP search directed early stops and preemption signals (not necessarily just scheduler preemption).
     * @param {string} allocationId The allocation that is acknowledging the request.
     * @param {V1AckAllocationPreemptionSignalRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public ackAllocationPreemptionSignal(allocationId: string, body: V1AckAllocationPreemptionSignalRequest, options?: any) {
        return InternalApiFp(this.configuration).ackAllocationPreemptionSignal(allocationId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary AllocationAllGather performs an all gather through the master. An allocation can only perform once all gather at a time.
     * @param {string} allocationId The ID of the allocation.
     * @param {V1AllocationAllGatherRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public allocationAllGather(allocationId: string, body: V1AllocationAllGatherRequest, options?: any) {
        return InternalApiFp(this.configuration).allocationAllGather(allocationId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Report the receipt of a signal to stop the given allocation early. This is used to communicate back from a SLURM job that it has been notified of a pending preememption. Upon a call to this API the RM should then trigger a checkpoint and immediate exit.
     * @param {string} allocationId The id of the allocation.
     * @param {V1AllocationPendingPreemptionSignalRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public allocationPendingPreemptionSignal(allocationId: string, body: V1AllocationPendingPreemptionSignalRequest, options?: any) {
        return InternalApiFp(this.configuration).allocationPendingPreemptionSignal(allocationId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Long poll preemption signals for the given allocation. If the allocation has been preempted when called, it will return so immediately. Otherwise, the connection will be kept open until the timeout is reached or the allocation is preempted.
     * @param {string} allocationId The id of the allocation.
     * @param {number} [timeoutSeconds] The timeout in seconds.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public allocationPreemptionSignal(allocationId: string, timeoutSeconds?: number, options?: any) {
        return InternalApiFp(this.configuration).allocationPreemptionSignal(allocationId, timeoutSeconds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Set allocation to ready state.
     * @param {string} allocationId The id of the allocation.
     * @param {V1AllocationReadyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public allocationReady(allocationId: string, body: V1AllocationReadyRequest, options?: any) {
        return InternalApiFp(this.configuration).allocationReady(allocationId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gather an allocation's rendezvous info. Blocks until all trial containers connect to gather their rendezvous information and responds to them all at once.
     * @param {string} allocationId The id of the allocation.
     * @param {string} resourcesId The id of the clump of resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public allocationRendezvousInfo(allocationId: string, resourcesId: string, options?: any) {
        return InternalApiFp(this.configuration).allocationRendezvousInfo(allocationId, resourcesId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Set allocation to waiting state.
     * @param {string} allocationId The id of the allocation.
     * @param {V1AllocationWaitingRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public allocationWaiting(allocationId: string, body: V1AllocationWaitingRequest, options?: any) {
        return InternalApiFp(this.configuration).allocationWaiting(allocationId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Reports to the searcher that the trial has completed the given searcher operation.
     * @param {number} trialId The id of the trial.
     * @param {V1CompleteValidateAfterOperation} body The completed operation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public completeTrialSearcherValidation(trialId: number, body: V1CompleteValidateAfterOperation, options?: any) {
        return InternalApiFp(this.configuration).completeTrialSearcherValidation(trialId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Trigger the computation of hyperparameter importance on-demand for a specific metric on a specific experiment. The status and results can be retrieved with GetHPImportance.
     * @param {number} experimentId The id of the experiment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public computeHPImportance(experimentId: number, options?: any) {
        return InternalApiFp(this.configuration).computeHPImportance(experimentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create an experiment.
     * @param {V1CreateExperimentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public createExperiment(body: V1CreateExperimentRequest, options?: any) {
        return InternalApiFp(this.configuration).createExperiment(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create a group with optional members on creation.
     * @param {V1CreateGroupRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public createGroup(body: V1CreateGroupRequest, options?: any) {
        return InternalApiFp(this.configuration).createGroup(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove a group.
     * @param {number} groupId The id of the group that should be deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deleteGroup(groupId: number, options?: any) {
        return InternalApiFp(this.configuration).deleteGroup(groupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the set of metric names recorded for a trial.
     * @param {Array<number>} trialId The id of the experiment.
     * @param {number} [periodSeconds] Seconds to wait when polling for updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public expCompareMetricNames(trialId: Array<number>, periodSeconds?: number, options?: any) {
        return InternalApiFp(this.configuration).expCompareMetricNames(trialId, periodSeconds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a sample of the metrics over time for a sample of the trials.
     * @param {Array<number>} experimentIds The id of the experiment.
     * @param {string} metricName A metric name.
     * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
     * @param {number} [maxTrials] Maximum number of trials to fetch data for.
     * @param {number} [maxDatapoints] Maximum number of initial / historical data points.
     * @param {number} [startBatches] Beginning of window (inclusive) to fetch data for.
     * @param {number} [endBatches] Ending of window (inclusive) to fetch data for.
     * @param {number} [periodSeconds] Seconds to wait when polling for updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public expCompareTrialsSample(experimentIds: Array<number>, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', maxTrials?: number, maxDatapoints?: number, startBatches?: number, endBatches?: number, periodSeconds?: number, options?: any) {
        return InternalApiFp(this.configuration).expCompareTrialsSample(experimentIds, metricName, metricType, maxTrials, maxDatapoints, startBatches, endBatches, periodSeconds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the best searcher validation for an experiment by the given metric.
     * @param {number} experimentId The ID of the experiment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getBestSearcherValidationMetric(experimentId: number, options?: any) {
        return InternalApiFp(this.configuration).getBestSearcherValidationMetric(experimentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the current searcher operation.
     * @param {number} trialId The id of the trial.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getCurrentTrialSearcherOperation(trialId: number, options?: any) {
        return InternalApiFp(this.configuration).getCurrentTrialSearcherOperation(trialId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a group by id.
     * @param {number} groupId The id of the group to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getGroup(groupId: number, options?: any) {
        return InternalApiFp(this.configuration).getGroup(groupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search for groups with optional filters.
     * @param {V1GetGroupsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getGroups(body: V1GetGroupsRequest, options?: any) {
        return InternalApiFp(this.configuration).getGroups(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieve the latest computation of hyperparameter importance. Currently this is triggered for training loss (if emitted) and the searcher metric after 10% increments in an experiment's progress, but no more than every 10 minutes.
     * @param {number} experimentId The id of the experiment.
     * @param {number} [periodSeconds] Seconds to wait when polling for updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getHPImportance(experimentId: number, periodSeconds?: number, options?: any) {
        return InternalApiFp(this.configuration).getHPImportance(experimentId, periodSeconds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get job queue stats for a resource pool.
     * @param {Array<string>} [resourcePools] Filter the results based on a set of resource pools.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getJobQueueStats(resourcePools?: Array<string>, options?: any) {
        return InternalApiFp(this.configuration).getJobQueueStats(resourcePools, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of jobs in queue.
     * @param {number} [offset] Pagination offset.
     * @param {number} [limit] Pagination limit.
     * @param {string} [resourcePool] The target resource-pool for agent resource manager.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order results in either ascending or descending order by the number of jobs ahead.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {Array<'STATE_UNSPECIFIED' | 'STATE_QUEUED' | 'STATE_SCHEDULED' | 'STATE_SCHEDULED_BACKFILLED'>} [states] Filter to jobs with states among those given.   - STATE_UNSPECIFIED: Unspecified state.  - STATE_QUEUED: Job is queued and waiting to be schedlued.  - STATE_SCHEDULED: Job is scheduled.  - STATE_SCHEDULED_BACKFILLED: Job is scheduled as a backfill.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getJobs(offset?: number, limit?: number, resourcePool?: string, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', states?: Array<'STATE_UNSPECIFIED' | 'STATE_QUEUED' | 'STATE_SCHEDULED' | 'STATE_SCHEDULED_BACKFILLED'>, options?: any) {
        return InternalApiFp(this.configuration).getJobs(offset, limit, resourcePool, orderBy, states, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of all resource pools from the cluster.
     * @param {number} [offset] Skip the number of resource pools before returning results. Negative values denote number of resource pools to skip from the end before returning results.
     * @param {number} [limit] Limit the number of resource pools. A value of 0 denotes no limit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getResourcePools(offset?: number, limit?: number, options?: any) {
        return InternalApiFp(this.configuration).getResourcePools(offset, limit, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get telemetry information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getTelemetry(options?: any) {
        return InternalApiFp(this.configuration).getTelemetry(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the list of workloads for a trial.
     * @param {number} trialId Limit workloads to those that are owned by the specified trial.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order workloads in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of workloads before returning results. Negative values denote number of workloads to skip from the end before returning results.
     * @param {number} [limit] Limit the number of workloads. A value of 0 denotes no limit.
     * @param {string} [sortKey] Sort workloads by batches, a training metric, or a validation metric.
     * @param {'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION'} [filter] Filter workloads with validation and/or checkpoint information.   - FILTER_OPTION_UNSPECIFIED: Any workload.  - FILTER_OPTION_CHECKPOINT: Only workloads with an associated checkpoint.  - FILTER_OPTION_VALIDATION: Only validation workloads.  - FILTER_OPTION_CHECKPOINT_OR_VALIDATION: Only validation workloads or ones with an associated checkpoint.
     * @param {boolean} [includeBatchMetrics] Include per-batch metrics.
     * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] When sorting workloads by sort_key, specify training or validation form of a metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getTrialWorkloads(trialId: number, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, sortKey?: string, filter?: 'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION', includeBatchMetrics?: boolean, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', options?: any) {
        return InternalApiFp(this.configuration).getTrialWorkloads(trialId, orderBy, offset, limit, sortKey, filter, includeBatchMetrics, metricType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Send notebook idle data to master
     * @param {string} notebookId The id of the notebook.
     * @param {V1IdleNotebookRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public idleNotebook(notebookId: string, body: V1IdleNotebookRequest, options?: any) {
        return InternalApiFp(this.configuration).idleNotebook(notebookId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Mark the given reservation (container, pod, etc) within an allocation as a daemon reservation. In the exit of a successful exit, Determined will wait for all resources to exit - unless they are marked as daemon resources, in which case Determined will clean them up regardless of exit status after all non-daemon resources have exited.
     * @param {string} allocationId The id of the allocation.
     * @param {string} resourcesId The id of the clump of resources to mark as daemon.
     * @param {V1MarkAllocationResourcesDaemonRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public markAllocationResourcesDaemon(allocationId: string, resourcesId: string, body: V1MarkAllocationResourcesDaemonRequest, options?: any) {
        return InternalApiFp(this.configuration).markAllocationResourcesDaemon(allocationId, resourcesId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the milestones (in batches processed) at which a metric is recorded by an experiment.
     * @param {number} experimentId The id of the experiment.
     * @param {string} metricName A metric name.
     * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
     * @param {number} [periodSeconds] Seconds to wait when polling for updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public metricBatches(experimentId: number, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', periodSeconds?: number, options?: any) {
        return InternalApiFp(this.configuration).metricBatches(experimentId, metricName, metricType, periodSeconds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the set of metric names recorded for an experiment.
     * @param {number} experimentId The id of the experiment.
     * @param {number} [periodSeconds] Seconds to wait when polling for updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public metricNames(experimentId: number, periodSeconds?: number, options?: any) {
        return InternalApiFp(this.configuration).metricNames(experimentId, periodSeconds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary PostAllocationProxyAddress sets the proxy address to use when proxying to services provided by an allocation. Upon receipt, the master will also register any proxies specified by the task.
     * @param {string} allocationId The id of the allocation.
     * @param {V1PostAllocationProxyAddressRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public postAllocationProxyAddress(allocationId: string, body: V1PostAllocationProxyAddressRequest, options?: any) {
        return InternalApiFp(this.configuration).postAllocationProxyAddress(allocationId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Persist the given TrialProfilerMetricsBatch. The trial ID is in the labels.
     * @param {V1PostTrialProfilerMetricsBatchRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public postTrialProfilerMetricsBatch(body: V1PostTrialProfilerMetricsBatchRequest, options?: any) {
        return InternalApiFp(this.configuration).postTrialProfilerMetricsBatch(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary For bookkeeping, update trial runner metadata (currently just state).
     * @param {number} trialId The id of the trial.
     * @param {V1TrialRunnerMetadata} body The state for the trial runner.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public postTrialRunnerMetadata(trialId: number, body: V1TrialRunnerMetadata, options?: any) {
        return InternalApiFp(this.configuration).postTrialRunnerMetadata(trialId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Record a checkpoint.
     * @param {V1Checkpoint} body The training metrics to persist.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public reportCheckpoint(body: V1Checkpoint, options?: any) {
        return InternalApiFp(this.configuration).reportCheckpoint(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary For bookkeeping, updates the progress towards to current requested searcher training length.
     * @param {number} trialId The id of the trial.
     * @param {number} body Total units completed by the trial, in terms of the unit used to configure the searcher.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public reportTrialProgress(trialId: number, body: number, options?: any) {
        return InternalApiFp(this.configuration).reportTrialProgress(trialId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Reports to the searcher that the trial has completed the current requested amount of training with the given searcher validation metric.
     * @param {number} trialId The id of the trial.
     * @param {V1TrialEarlyExit} body The exit reason.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public reportTrialSearcherEarlyExit(trialId: number, body: V1TrialEarlyExit, options?: any) {
        return InternalApiFp(this.configuration).reportTrialSearcherEarlyExit(trialId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Record training metrics for specified training.
     * @param {number} trainingMetricsTrialId The trial associated with these metrics.
     * @param {V1TrialMetrics} body The training metrics to persist.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public reportTrialTrainingMetrics(trainingMetricsTrialId: number, body: V1TrialMetrics, options?: any) {
        return InternalApiFp(this.configuration).reportTrialTrainingMetrics(trainingMetricsTrialId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Record validation metrics.
     * @param {number} validationMetricsTrialId The trial associated with these metrics.
     * @param {V1TrialMetrics} body The training metrics to persist.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public reportTrialValidationMetrics(validationMetricsTrialId: number, body: V1TrialMetrics, options?: any) {
        return InternalApiFp(this.configuration).reportTrialValidationMetrics(validationMetricsTrialId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a sample of the metrics over time for a sample of the trials.
     * @param {number} experimentId The id of the experiment.
     * @param {string} metricName A metric name.
     * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
     * @param {number} [maxTrials] Maximum number of trials to fetch data for.
     * @param {number} [maxDatapoints] Maximum number of initial / historical data points.
     * @param {number} [startBatches] Beginning of window (inclusive) to fetch data for.
     * @param {number} [endBatches] Ending of window (inclusive) to fetch data for.
     * @param {number} [periodSeconds] Seconds to wait when polling for updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public trialsSample(experimentId: number, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', maxTrials?: number, maxDatapoints?: number, startBatches?: number, endBatches?: number, periodSeconds?: number, options?: any) {
        return InternalApiFp(this.configuration).trialsSample(experimentId, metricName, metricType, maxTrials, maxDatapoints, startBatches, endBatches, periodSeconds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a snapshot of a metric across all trials at a certain point of progress.
     * @param {number} experimentId The id of the experiment.
     * @param {string} metricName A metric name.
     * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} metricType The type of metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
     * @param {number} batchesProcessed The point of progress at which to query metrics.
     * @param {number} [batchesMargin] A range either side of batches_processed to include near-misses.
     * @param {number} [periodSeconds] Seconds to wait when polling for updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public trialsSnapshot(experimentId: number, metricName: string, metricType: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', batchesProcessed: number, batchesMargin?: number, periodSeconds?: number, options?: any) {
        return InternalApiFp(this.configuration).trialsSnapshot(experimentId, metricName, metricType, batchesProcessed, batchesMargin, periodSeconds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update group info.
     * @param {number} groupId The id of the group
     * @param {V1UpdateGroupRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public updateGroup(groupId: number, body: V1UpdateGroupRequest, options?: any) {
        return InternalApiFp(this.configuration).updateGroup(groupId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Control the job queues.
     * @param {V1UpdateJobQueueRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public updateJobQueue(body: V1UpdateJobQueueRequest, options?: any) {
        return InternalApiFp(this.configuration).updateJobQueue(body, options)(this.fetch, this.basePath);
    }

}

/**
 * JobsApi - fetch parameter creator
 * @export
 */
export const JobsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Stream task logs.
         * @param {string} taskId The id of the task.
         * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the trial stops.
         * @param {Array<string>} [allocationIds] Limit the task logs to particular allocations.
         * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
         * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
         * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
         * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
         * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
         * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
         * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
         * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {string} [searchText] Search the logs by whether the text contains a substring.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskLogs(taskId: string, limit?: number, follow?: boolean, allocationIds?: Array<string>, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling taskLogs.');
            }
            const localVarPath = `/api/v1/tasks/{taskId}/logs`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (follow !== undefined) {
                localVarQueryParameter['follow'] = follow;
            }

            if (allocationIds) {
                localVarQueryParameter['allocationIds'] = allocationIds;
            }

            if (agentIds) {
                localVarQueryParameter['agentIds'] = agentIds;
            }

            if (containerIds) {
                localVarQueryParameter['containerIds'] = containerIds;
            }

            if (rankIds) {
                localVarQueryParameter['rankIds'] = rankIds;
            }

            if (levels) {
                localVarQueryParameter['levels'] = levels;
            }

            if (stdtypes) {
                localVarQueryParameter['stdtypes'] = stdtypes;
            }

            if (sources) {
                localVarQueryParameter['sources'] = sources;
            }

            if (timestampBefore !== undefined) {
                localVarQueryParameter['timestampBefore'] = (timestampBefore as any).toISOString();
            }

            if (timestampAfter !== undefined) {
                localVarQueryParameter['timestampAfter'] = (timestampAfter as any).toISOString();
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stream task log fields.
         * @param {string} taskId The ID of the task.
         * @param {boolean} [follow] Continue following fields until the task stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskLogsFields(taskId: string, follow?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling taskLogsFields.');
            }
            const localVarPath = `/api/v1/tasks/{taskId}/logs/fields`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (follow !== undefined) {
                localVarQueryParameter['follow'] = follow;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Stream task logs.
         * @param {string} taskId The id of the task.
         * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the trial stops.
         * @param {Array<string>} [allocationIds] Limit the task logs to particular allocations.
         * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
         * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
         * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
         * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
         * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
         * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
         * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
         * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {string} [searchText] Search the logs by whether the text contains a substring.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskLogs(taskId: string, limit?: number, follow?: boolean, allocationIds?: Array<string>, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1TaskLogsResponse> {
            const localVarFetchArgs = JobsApiFetchParamCreator(configuration).taskLogs(taskId, limit, follow, allocationIds, agentIds, containerIds, rankIds, levels, stdtypes, sources, timestampBefore, timestampAfter, orderBy, searchText, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Stream task log fields.
         * @param {string} taskId The ID of the task.
         * @param {boolean} [follow] Continue following fields until the task stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskLogsFields(taskId: string, follow?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1TaskLogsFieldsResponse> {
            const localVarFetchArgs = JobsApiFetchParamCreator(configuration).taskLogsFields(taskId, follow, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Stream task logs.
         * @param {string} taskId The id of the task.
         * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the trial stops.
         * @param {Array<string>} [allocationIds] Limit the task logs to particular allocations.
         * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
         * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
         * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
         * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
         * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
         * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
         * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
         * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {string} [searchText] Search the logs by whether the text contains a substring.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskLogs(taskId: string, limit?: number, follow?: boolean, allocationIds?: Array<string>, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options?: any) {
            return JobsApiFp(configuration).taskLogs(taskId, limit, follow, allocationIds, agentIds, containerIds, rankIds, levels, stdtypes, sources, timestampBefore, timestampAfter, orderBy, searchText, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Stream task log fields.
         * @param {string} taskId The ID of the task.
         * @param {boolean} [follow] Continue following fields until the task stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskLogsFields(taskId: string, follow?: boolean, options?: any) {
            return JobsApiFp(configuration).taskLogsFields(taskId, follow, options)(fetch, basePath);
        },
    };
};

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
    /**
     * 
     * @summary Stream task logs.
     * @param {string} taskId The id of the task.
     * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
     * @param {boolean} [follow] Continue following logs until the trial stops.
     * @param {Array<string>} [allocationIds] Limit the task logs to particular allocations.
     * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
     * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
     * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
     * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
     * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
     * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
     * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
     * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {string} [searchText] Search the logs by whether the text contains a substring.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public taskLogs(taskId: string, limit?: number, follow?: boolean, allocationIds?: Array<string>, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options?: any) {
        return JobsApiFp(this.configuration).taskLogs(taskId, limit, follow, allocationIds, agentIds, containerIds, rankIds, levels, stdtypes, sources, timestampBefore, timestampAfter, orderBy, searchText, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Stream task log fields.
     * @param {string} taskId The ID of the task.
     * @param {boolean} [follow] Continue following fields until the task stops.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public taskLogsFields(taskId: string, follow?: boolean, options?: any) {
        return JobsApiFp(this.configuration).taskLogsFields(taskId, follow, options)(this.fetch, this.basePath);
    }

}

/**
 * ModelsApi - fetch parameter creator
 * @export
 */
export const ModelsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive a model
         * @param {string} modelName The name of the model to archive.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveModel(modelName: string, options: any = {}): FetchArgs {
            // verify required parameter 'modelName' is not null or undefined
            if (modelName === null || modelName === undefined) {
                throw new RequiredError('modelName','Required parameter modelName was null or undefined when calling archiveModel.');
            }
            const localVarPath = `/api/v1/models/{modelName}/archive`
                .replace(`{${"modelName"}}`, encodeURIComponent(String(modelName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a model
         * @param {string} modelName The name of the model to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModel(modelName: string, options: any = {}): FetchArgs {
            // verify required parameter 'modelName' is not null or undefined
            if (modelName === null || modelName === undefined) {
                throw new RequiredError('modelName','Required parameter modelName was null or undefined when calling deleteModel.');
            }
            const localVarPath = `/api/v1/models/{modelName}`
                .replace(`{${"modelName"}}`, encodeURIComponent(String(modelName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a model version
         * @param {string} modelName The name of the model associated with the model version.
         * @param {number} modelVersionId The id of the model version to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelVersion(modelName: string, modelVersionId: number, options: any = {}): FetchArgs {
            // verify required parameter 'modelName' is not null or undefined
            if (modelName === null || modelName === undefined) {
                throw new RequiredError('modelName','Required parameter modelName was null or undefined when calling deleteModelVersion.');
            }
            // verify required parameter 'modelVersionId' is not null or undefined
            if (modelVersionId === null || modelVersionId === undefined) {
                throw new RequiredError('modelVersionId','Required parameter modelVersionId was null or undefined when calling deleteModelVersion.');
            }
            const localVarPath = `/api/v1/models/{modelName}/versions/{modelVersionId}`
                .replace(`{${"modelName"}}`, encodeURIComponent(String(modelName)))
                .replace(`{${"modelVersionId"}}`, encodeURIComponent(String(modelVersionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the requested model.
         * @param {string} modelName The name of the model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModel(modelName: string, options: any = {}): FetchArgs {
            // verify required parameter 'modelName' is not null or undefined
            if (modelName === null || modelName === undefined) {
                throw new RequiredError('modelName','Required parameter modelName was null or undefined when calling getModel.');
            }
            const localVarPath = `/api/v1/models/{modelName}`
                .replace(`{${"modelName"}}`, encodeURIComponent(String(modelName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of unique model labels (sorted by popularity).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelLabels(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/model/labels`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the requested model version.
         * @param {string} modelName The name of the model.
         * @param {number} modelVersion The version number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelVersion(modelName: string, modelVersion: number, options: any = {}): FetchArgs {
            // verify required parameter 'modelName' is not null or undefined
            if (modelName === null || modelName === undefined) {
                throw new RequiredError('modelName','Required parameter modelName was null or undefined when calling getModelVersion.');
            }
            // verify required parameter 'modelVersion' is not null or undefined
            if (modelVersion === null || modelVersion === undefined) {
                throw new RequiredError('modelVersion','Required parameter modelVersion was null or undefined when calling getModelVersion.');
            }
            const localVarPath = `/api/v1/models/{modelName}/versions/{modelVersion}`
                .replace(`{${"modelName"}}`, encodeURIComponent(String(modelName)))
                .replace(`{${"modelVersion"}}`, encodeURIComponent(String(modelVersion)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of versions for the requested model.
         * @param {string} modelName The name of the model.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_VERSION' | 'SORT_BY_CREATION_TIME'} [sortBy] Sort the model versions by the given field.   - SORT_BY_UNSPECIFIED: Returns model versions in an unsorted list.  - SORT_BY_VERSION: Returns model versions sorted by version number.  - SORT_BY_CREATION_TIME: Returns model versions sorted by creation_time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order model versions in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of model versions before returning results. Negative values denote number of models to skip from the end before returning results.
         * @param {number} [limit] Limit the number of model versions. A value of 0 denotes no limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelVersions(modelName: string, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_VERSION' | 'SORT_BY_CREATION_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, options: any = {}): FetchArgs {
            // verify required parameter 'modelName' is not null or undefined
            if (modelName === null || modelName === undefined) {
                throw new RequiredError('modelName','Required parameter modelName was null or undefined when calling getModelVersions.');
            }
            const localVarPath = `/api/v1/models/{modelName}/versions`
                .replace(`{${"modelName"}}`, encodeURIComponent(String(modelName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of models.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_UPDATED_TIME' | 'SORT_BY_NUM_VERSIONS'} [sortBy] Sort the models by the given field.   - SORT_BY_UNSPECIFIED: Returns models in an unsorted list.  - SORT_BY_NAME: Returns models sorted by name.  - SORT_BY_DESCRIPTION: Returns models sorted by description.  - SORT_BY_CREATION_TIME: Returns models sorted by creation time.  - SORT_BY_LAST_UPDATED_TIME: Returns models sorted by last updated time.  - SORT_BY_NUM_VERSIONS: Returns models sorted by number of version.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order models in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of models before returning results. Negative values denote number of models to skip from the end before returning results.
         * @param {number} [limit] Limit the number of models. A value of 0 denotes no limit.
         * @param {string} [name] Limit the models to those matching or partially-matching the name.
         * @param {string} [description] Limit the models to those matching or partially-matching the description.
         * @param {Array<string>} [labels] Limit the models to those with the following labels.
         * @param {boolean} [archived] Limit to unarchived models only.
         * @param {Array<string>} [users] Limit the models to those made by the users with the following usernames.
         * @param {Array<number>} [userIds] Limit the models to those made by the users with the following userIds.
         * @param {number} [id] Limit the models to this model id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModels(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_UPDATED_TIME' | 'SORT_BY_NUM_VERSIONS', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, description?: string, labels?: Array<string>, archived?: boolean, users?: Array<string>, userIds?: Array<number>, id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/models`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (labels) {
                localVarQueryParameter['labels'] = labels;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch a model's fields.
         * @param {string} modelName The name of the model being updated.
         * @param {V1PatchModel} body The model desired model fields and values.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchModel(modelName: string, body: V1PatchModel, options: any = {}): FetchArgs {
            // verify required parameter 'modelName' is not null or undefined
            if (modelName === null || modelName === undefined) {
                throw new RequiredError('modelName','Required parameter modelName was null or undefined when calling patchModel.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling patchModel.');
            }
            const localVarPath = `/api/v1/models/{modelName}`
                .replace(`{${"modelName"}}`, encodeURIComponent(String(modelName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PatchModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch a model version's fields.
         * @param {string} modelName The name of the model being updated.
         * @param {number} modelVersionId The id of the model version being updated.
         * @param {V1PatchModelVersion} body The model version being updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchModelVersion(modelName: string, modelVersionId: number, body: V1PatchModelVersion, options: any = {}): FetchArgs {
            // verify required parameter 'modelName' is not null or undefined
            if (modelName === null || modelName === undefined) {
                throw new RequiredError('modelName','Required parameter modelName was null or undefined when calling patchModelVersion.');
            }
            // verify required parameter 'modelVersionId' is not null or undefined
            if (modelVersionId === null || modelVersionId === undefined) {
                throw new RequiredError('modelVersionId','Required parameter modelVersionId was null or undefined when calling patchModelVersion.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling patchModelVersion.');
            }
            const localVarPath = `/api/v1/models/{modelName}/versions/{modelVersionId}`
                .replace(`{${"modelName"}}`, encodeURIComponent(String(modelName)))
                .replace(`{${"modelVersionId"}}`, encodeURIComponent(String(modelVersionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PatchModelVersion" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a model in the registry.
         * @param {V1PostModelRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModel(body: V1PostModelRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postModel.');
            }
            const localVarPath = `/api/v1/models`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PostModelRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a model version.
         * @param {string} modelName The name of the model to add this version to.
         * @param {V1PostModelVersionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModelVersion(modelName: string, body: V1PostModelVersionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'modelName' is not null or undefined
            if (modelName === null || modelName === undefined) {
                throw new RequiredError('modelName','Required parameter modelName was null or undefined when calling postModelVersion.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postModelVersion.');
            }
            const localVarPath = `/api/v1/models/{modelName}/versions`
                .replace(`{${"modelName"}}`, encodeURIComponent(String(modelName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PostModelVersionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unarchive a model
         * @param {string} modelName The name of the model to un-archive.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveModel(modelName: string, options: any = {}): FetchArgs {
            // verify required parameter 'modelName' is not null or undefined
            if (modelName === null || modelName === undefined) {
                throw new RequiredError('modelName','Required parameter modelName was null or undefined when calling unarchiveModel.');
            }
            const localVarPath = `/api/v1/models/{modelName}/unarchive`
                .replace(`{${"modelName"}}`, encodeURIComponent(String(modelName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelsApi - functional programming interface
 * @export
 */
export const ModelsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive a model
         * @param {string} modelName The name of the model to archive.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveModel(modelName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ArchiveModelResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).archiveModel(modelName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a model
         * @param {string} modelName The name of the model to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModel(modelName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1DeleteModelResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).deleteModel(modelName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a model version
         * @param {string} modelName The name of the model associated with the model version.
         * @param {number} modelVersionId The id of the model version to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelVersion(modelName: string, modelVersionId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1DeleteModelVersionResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).deleteModelVersion(modelName, modelVersionId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the requested model.
         * @param {string} modelName The name of the model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModel(modelName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetModelResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).getModel(modelName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of unique model labels (sorted by popularity).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelLabels(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetModelLabelsResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).getModelLabels(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the requested model version.
         * @param {string} modelName The name of the model.
         * @param {number} modelVersion The version number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelVersion(modelName: string, modelVersion: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetModelVersionResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).getModelVersion(modelName, modelVersion, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of versions for the requested model.
         * @param {string} modelName The name of the model.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_VERSION' | 'SORT_BY_CREATION_TIME'} [sortBy] Sort the model versions by the given field.   - SORT_BY_UNSPECIFIED: Returns model versions in an unsorted list.  - SORT_BY_VERSION: Returns model versions sorted by version number.  - SORT_BY_CREATION_TIME: Returns model versions sorted by creation_time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order model versions in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of model versions before returning results. Negative values denote number of models to skip from the end before returning results.
         * @param {number} [limit] Limit the number of model versions. A value of 0 denotes no limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelVersions(modelName: string, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_VERSION' | 'SORT_BY_CREATION_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetModelVersionsResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).getModelVersions(modelName, sortBy, orderBy, offset, limit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of models.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_UPDATED_TIME' | 'SORT_BY_NUM_VERSIONS'} [sortBy] Sort the models by the given field.   - SORT_BY_UNSPECIFIED: Returns models in an unsorted list.  - SORT_BY_NAME: Returns models sorted by name.  - SORT_BY_DESCRIPTION: Returns models sorted by description.  - SORT_BY_CREATION_TIME: Returns models sorted by creation time.  - SORT_BY_LAST_UPDATED_TIME: Returns models sorted by last updated time.  - SORT_BY_NUM_VERSIONS: Returns models sorted by number of version.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order models in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of models before returning results. Negative values denote number of models to skip from the end before returning results.
         * @param {number} [limit] Limit the number of models. A value of 0 denotes no limit.
         * @param {string} [name] Limit the models to those matching or partially-matching the name.
         * @param {string} [description] Limit the models to those matching or partially-matching the description.
         * @param {Array<string>} [labels] Limit the models to those with the following labels.
         * @param {boolean} [archived] Limit to unarchived models only.
         * @param {Array<string>} [users] Limit the models to those made by the users with the following usernames.
         * @param {Array<number>} [userIds] Limit the models to those made by the users with the following userIds.
         * @param {number} [id] Limit the models to this model id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModels(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_UPDATED_TIME' | 'SORT_BY_NUM_VERSIONS', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, description?: string, labels?: Array<string>, archived?: boolean, users?: Array<string>, userIds?: Array<number>, id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetModelsResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).getModels(sortBy, orderBy, offset, limit, name, description, labels, archived, users, userIds, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Patch a model's fields.
         * @param {string} modelName The name of the model being updated.
         * @param {V1PatchModel} body The model desired model fields and values.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchModel(modelName: string, body: V1PatchModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PatchModelResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).patchModel(modelName, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Patch a model version's fields.
         * @param {string} modelName The name of the model being updated.
         * @param {number} modelVersionId The id of the model version being updated.
         * @param {V1PatchModelVersion} body The model version being updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchModelVersion(modelName: string, modelVersionId: number, body: V1PatchModelVersion, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PatchModelVersionResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).patchModelVersion(modelName, modelVersionId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create a model in the registry.
         * @param {V1PostModelRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModel(body: V1PostModelRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PostModelResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).postModel(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create a model version.
         * @param {string} modelName The name of the model to add this version to.
         * @param {V1PostModelVersionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModelVersion(modelName: string, body: V1PostModelVersionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PostModelVersionResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).postModelVersion(modelName, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unarchive a model
         * @param {string} modelName The name of the model to un-archive.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveModel(modelName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1UnarchiveModelResponse> {
            const localVarFetchArgs = ModelsApiFetchParamCreator(configuration).unarchiveModel(modelName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ModelsApi - factory interface
 * @export
 */
export const ModelsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Archive a model
         * @param {string} modelName The name of the model to archive.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveModel(modelName: string, options?: any) {
            return ModelsApiFp(configuration).archiveModel(modelName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a model
         * @param {string} modelName The name of the model to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModel(modelName: string, options?: any) {
            return ModelsApiFp(configuration).deleteModel(modelName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a model version
         * @param {string} modelName The name of the model associated with the model version.
         * @param {number} modelVersionId The id of the model version to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelVersion(modelName: string, modelVersionId: number, options?: any) {
            return ModelsApiFp(configuration).deleteModelVersion(modelName, modelVersionId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the requested model.
         * @param {string} modelName The name of the model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModel(modelName: string, options?: any) {
            return ModelsApiFp(configuration).getModel(modelName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of unique model labels (sorted by popularity).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelLabels(options?: any) {
            return ModelsApiFp(configuration).getModelLabels(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the requested model version.
         * @param {string} modelName The name of the model.
         * @param {number} modelVersion The version number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelVersion(modelName: string, modelVersion: number, options?: any) {
            return ModelsApiFp(configuration).getModelVersion(modelName, modelVersion, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of versions for the requested model.
         * @param {string} modelName The name of the model.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_VERSION' | 'SORT_BY_CREATION_TIME'} [sortBy] Sort the model versions by the given field.   - SORT_BY_UNSPECIFIED: Returns model versions in an unsorted list.  - SORT_BY_VERSION: Returns model versions sorted by version number.  - SORT_BY_CREATION_TIME: Returns model versions sorted by creation_time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order model versions in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of model versions before returning results. Negative values denote number of models to skip from the end before returning results.
         * @param {number} [limit] Limit the number of model versions. A value of 0 denotes no limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelVersions(modelName: string, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_VERSION' | 'SORT_BY_CREATION_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, options?: any) {
            return ModelsApiFp(configuration).getModelVersions(modelName, sortBy, orderBy, offset, limit, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of models.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_UPDATED_TIME' | 'SORT_BY_NUM_VERSIONS'} [sortBy] Sort the models by the given field.   - SORT_BY_UNSPECIFIED: Returns models in an unsorted list.  - SORT_BY_NAME: Returns models sorted by name.  - SORT_BY_DESCRIPTION: Returns models sorted by description.  - SORT_BY_CREATION_TIME: Returns models sorted by creation time.  - SORT_BY_LAST_UPDATED_TIME: Returns models sorted by last updated time.  - SORT_BY_NUM_VERSIONS: Returns models sorted by number of version.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order models in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of models before returning results. Negative values denote number of models to skip from the end before returning results.
         * @param {number} [limit] Limit the number of models. A value of 0 denotes no limit.
         * @param {string} [name] Limit the models to those matching or partially-matching the name.
         * @param {string} [description] Limit the models to those matching or partially-matching the description.
         * @param {Array<string>} [labels] Limit the models to those with the following labels.
         * @param {boolean} [archived] Limit to unarchived models only.
         * @param {Array<string>} [users] Limit the models to those made by the users with the following usernames.
         * @param {Array<number>} [userIds] Limit the models to those made by the users with the following userIds.
         * @param {number} [id] Limit the models to this model id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModels(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_UPDATED_TIME' | 'SORT_BY_NUM_VERSIONS', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, description?: string, labels?: Array<string>, archived?: boolean, users?: Array<string>, userIds?: Array<number>, id?: number, options?: any) {
            return ModelsApiFp(configuration).getModels(sortBy, orderBy, offset, limit, name, description, labels, archived, users, userIds, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Patch a model's fields.
         * @param {string} modelName The name of the model being updated.
         * @param {V1PatchModel} body The model desired model fields and values.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchModel(modelName: string, body: V1PatchModel, options?: any) {
            return ModelsApiFp(configuration).patchModel(modelName, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Patch a model version's fields.
         * @param {string} modelName The name of the model being updated.
         * @param {number} modelVersionId The id of the model version being updated.
         * @param {V1PatchModelVersion} body The model version being updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchModelVersion(modelName: string, modelVersionId: number, body: V1PatchModelVersion, options?: any) {
            return ModelsApiFp(configuration).patchModelVersion(modelName, modelVersionId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create a model in the registry.
         * @param {V1PostModelRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModel(body: V1PostModelRequest, options?: any) {
            return ModelsApiFp(configuration).postModel(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create a model version.
         * @param {string} modelName The name of the model to add this version to.
         * @param {V1PostModelVersionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModelVersion(modelName: string, body: V1PostModelVersionRequest, options?: any) {
            return ModelsApiFp(configuration).postModelVersion(modelName, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unarchive a model
         * @param {string} modelName The name of the model to un-archive.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveModel(modelName: string, options?: any) {
            return ModelsApiFp(configuration).unarchiveModel(modelName, options)(fetch, basePath);
        },
    };
};

/**
 * ModelsApi - object-oriented interface
 * @export
 * @class ModelsApi
 * @extends {BaseAPI}
 */
export class ModelsApi extends BaseAPI {
    /**
     * 
     * @summary Archive a model
     * @param {string} modelName The name of the model to archive.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public archiveModel(modelName: string, options?: any) {
        return ModelsApiFp(this.configuration).archiveModel(modelName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a model
     * @param {string} modelName The name of the model to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public deleteModel(modelName: string, options?: any) {
        return ModelsApiFp(this.configuration).deleteModel(modelName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a model version
     * @param {string} modelName The name of the model associated with the model version.
     * @param {number} modelVersionId The id of the model version to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public deleteModelVersion(modelName: string, modelVersionId: number, options?: any) {
        return ModelsApiFp(this.configuration).deleteModelVersion(modelName, modelVersionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the requested model.
     * @param {string} modelName The name of the model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public getModel(modelName: string, options?: any) {
        return ModelsApiFp(this.configuration).getModel(modelName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of unique model labels (sorted by popularity).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public getModelLabels(options?: any) {
        return ModelsApiFp(this.configuration).getModelLabels(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the requested model version.
     * @param {string} modelName The name of the model.
     * @param {number} modelVersion The version number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public getModelVersion(modelName: string, modelVersion: number, options?: any) {
        return ModelsApiFp(this.configuration).getModelVersion(modelName, modelVersion, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of versions for the requested model.
     * @param {string} modelName The name of the model.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_VERSION' | 'SORT_BY_CREATION_TIME'} [sortBy] Sort the model versions by the given field.   - SORT_BY_UNSPECIFIED: Returns model versions in an unsorted list.  - SORT_BY_VERSION: Returns model versions sorted by version number.  - SORT_BY_CREATION_TIME: Returns model versions sorted by creation_time.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order model versions in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of model versions before returning results. Negative values denote number of models to skip from the end before returning results.
     * @param {number} [limit] Limit the number of model versions. A value of 0 denotes no limit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public getModelVersions(modelName: string, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_VERSION' | 'SORT_BY_CREATION_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, options?: any) {
        return ModelsApiFp(this.configuration).getModelVersions(modelName, sortBy, orderBy, offset, limit, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of models.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_UPDATED_TIME' | 'SORT_BY_NUM_VERSIONS'} [sortBy] Sort the models by the given field.   - SORT_BY_UNSPECIFIED: Returns models in an unsorted list.  - SORT_BY_NAME: Returns models sorted by name.  - SORT_BY_DESCRIPTION: Returns models sorted by description.  - SORT_BY_CREATION_TIME: Returns models sorted by creation time.  - SORT_BY_LAST_UPDATED_TIME: Returns models sorted by last updated time.  - SORT_BY_NUM_VERSIONS: Returns models sorted by number of version.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order models in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of models before returning results. Negative values denote number of models to skip from the end before returning results.
     * @param {number} [limit] Limit the number of models. A value of 0 denotes no limit.
     * @param {string} [name] Limit the models to those matching or partially-matching the name.
     * @param {string} [description] Limit the models to those matching or partially-matching the description.
     * @param {Array<string>} [labels] Limit the models to those with the following labels.
     * @param {boolean} [archived] Limit to unarchived models only.
     * @param {Array<string>} [users] Limit the models to those made by the users with the following usernames.
     * @param {Array<number>} [userIds] Limit the models to those made by the users with the following userIds.
     * @param {number} [id] Limit the models to this model id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public getModels(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_UPDATED_TIME' | 'SORT_BY_NUM_VERSIONS', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, description?: string, labels?: Array<string>, archived?: boolean, users?: Array<string>, userIds?: Array<number>, id?: number, options?: any) {
        return ModelsApiFp(this.configuration).getModels(sortBy, orderBy, offset, limit, name, description, labels, archived, users, userIds, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Patch a model's fields.
     * @param {string} modelName The name of the model being updated.
     * @param {V1PatchModel} body The model desired model fields and values.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public patchModel(modelName: string, body: V1PatchModel, options?: any) {
        return ModelsApiFp(this.configuration).patchModel(modelName, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Patch a model version's fields.
     * @param {string} modelName The name of the model being updated.
     * @param {number} modelVersionId The id of the model version being updated.
     * @param {V1PatchModelVersion} body The model version being updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public patchModelVersion(modelName: string, modelVersionId: number, body: V1PatchModelVersion, options?: any) {
        return ModelsApiFp(this.configuration).patchModelVersion(modelName, modelVersionId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create a model in the registry.
     * @param {V1PostModelRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public postModel(body: V1PostModelRequest, options?: any) {
        return ModelsApiFp(this.configuration).postModel(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create a model version.
     * @param {string} modelName The name of the model to add this version to.
     * @param {V1PostModelVersionRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public postModelVersion(modelName: string, body: V1PostModelVersionRequest, options?: any) {
        return ModelsApiFp(this.configuration).postModelVersion(modelName, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unarchive a model
     * @param {string} modelName The name of the model to un-archive.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public unarchiveModel(modelName: string, options?: any) {
        return ModelsApiFp(this.configuration).unarchiveModel(modelName, options)(this.fetch, this.basePath);
    }

}

/**
 * NotebooksApi - fetch parameter creator
 * @export
 */
export const NotebooksApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the requested notebook.
         * @param {string} notebookId The id of the notebook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotebook(notebookId: string, options: any = {}): FetchArgs {
            // verify required parameter 'notebookId' is not null or undefined
            if (notebookId === null || notebookId === undefined) {
                throw new RequiredError('notebookId','Required parameter notebookId was null or undefined when calling getNotebook.');
            }
            const localVarPath = `/api/v1/notebooks/{notebookId}`
                .replace(`{${"notebookId"}}`, encodeURIComponent(String(notebookId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of notebooks.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort notebooks by the given field.   - SORT_BY_UNSPECIFIED: Returns notebooks in an unsorted list.  - SORT_BY_ID: Returns notebooks sorted by id.  - SORT_BY_DESCRIPTION: Returns notebooks sorted by description.  - SORT_BY_START_TIME: Return notebooks sorted by start time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order notebooks in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of notebooks before returning results. Negative values denote number of notebooks to skip from the end before returning results.
         * @param {number} [limit] Limit the number of notebooks. A value of 0 denotes no limit.
         * @param {Array<string>} [users] Limit notebooks to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit notebooks to those that are owned by users with the specified userIds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotebooks(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/notebooks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kill the requested notebook.
         * @param {string} notebookId The id of the notebook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killNotebook(notebookId: string, options: any = {}): FetchArgs {
            // verify required parameter 'notebookId' is not null or undefined
            if (notebookId === null || notebookId === undefined) {
                throw new RequiredError('notebookId','Required parameter notebookId was null or undefined when calling killNotebook.');
            }
            const localVarPath = `/api/v1/notebooks/{notebookId}/kill`
                .replace(`{${"notebookId"}}`, encodeURIComponent(String(notebookId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Launch a notebook.
         * @param {V1LaunchNotebookRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchNotebook(body: V1LaunchNotebookRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling launchNotebook.');
            }
            const localVarPath = `/api/v1/notebooks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1LaunchNotebookRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the priority of the requested notebook.
         * @param {string} notebookId The id of the notebook.
         * @param {V1SetNotebookPriorityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNotebookPriority(notebookId: string, body: V1SetNotebookPriorityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'notebookId' is not null or undefined
            if (notebookId === null || notebookId === undefined) {
                throw new RequiredError('notebookId','Required parameter notebookId was null or undefined when calling setNotebookPriority.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setNotebookPriority.');
            }
            const localVarPath = `/api/v1/notebooks/{notebookId}/set_priority`
                .replace(`{${"notebookId"}}`, encodeURIComponent(String(notebookId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1SetNotebookPriorityRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotebooksApi - functional programming interface
 * @export
 */
export const NotebooksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the requested notebook.
         * @param {string} notebookId The id of the notebook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotebook(notebookId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetNotebookResponse> {
            const localVarFetchArgs = NotebooksApiFetchParamCreator(configuration).getNotebook(notebookId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of notebooks.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort notebooks by the given field.   - SORT_BY_UNSPECIFIED: Returns notebooks in an unsorted list.  - SORT_BY_ID: Returns notebooks sorted by id.  - SORT_BY_DESCRIPTION: Returns notebooks sorted by description.  - SORT_BY_START_TIME: Return notebooks sorted by start time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order notebooks in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of notebooks before returning results. Negative values denote number of notebooks to skip from the end before returning results.
         * @param {number} [limit] Limit the number of notebooks. A value of 0 denotes no limit.
         * @param {Array<string>} [users] Limit notebooks to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit notebooks to those that are owned by users with the specified userIds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotebooks(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetNotebooksResponse> {
            const localVarFetchArgs = NotebooksApiFetchParamCreator(configuration).getNotebooks(sortBy, orderBy, offset, limit, users, userIds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Kill the requested notebook.
         * @param {string} notebookId The id of the notebook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killNotebook(notebookId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1KillNotebookResponse> {
            const localVarFetchArgs = NotebooksApiFetchParamCreator(configuration).killNotebook(notebookId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Launch a notebook.
         * @param {V1LaunchNotebookRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchNotebook(body: V1LaunchNotebookRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1LaunchNotebookResponse> {
            const localVarFetchArgs = NotebooksApiFetchParamCreator(configuration).launchNotebook(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Set the priority of the requested notebook.
         * @param {string} notebookId The id of the notebook.
         * @param {V1SetNotebookPriorityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNotebookPriority(notebookId: string, body: V1SetNotebookPriorityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1SetNotebookPriorityResponse> {
            const localVarFetchArgs = NotebooksApiFetchParamCreator(configuration).setNotebookPriority(notebookId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NotebooksApi - factory interface
 * @export
 */
export const NotebooksApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get the requested notebook.
         * @param {string} notebookId The id of the notebook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotebook(notebookId: string, options?: any) {
            return NotebooksApiFp(configuration).getNotebook(notebookId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of notebooks.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort notebooks by the given field.   - SORT_BY_UNSPECIFIED: Returns notebooks in an unsorted list.  - SORT_BY_ID: Returns notebooks sorted by id.  - SORT_BY_DESCRIPTION: Returns notebooks sorted by description.  - SORT_BY_START_TIME: Return notebooks sorted by start time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order notebooks in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of notebooks before returning results. Negative values denote number of notebooks to skip from the end before returning results.
         * @param {number} [limit] Limit the number of notebooks. A value of 0 denotes no limit.
         * @param {Array<string>} [users] Limit notebooks to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit notebooks to those that are owned by users with the specified userIds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotebooks(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options?: any) {
            return NotebooksApiFp(configuration).getNotebooks(sortBy, orderBy, offset, limit, users, userIds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Kill the requested notebook.
         * @param {string} notebookId The id of the notebook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killNotebook(notebookId: string, options?: any) {
            return NotebooksApiFp(configuration).killNotebook(notebookId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Launch a notebook.
         * @param {V1LaunchNotebookRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchNotebook(body: V1LaunchNotebookRequest, options?: any) {
            return NotebooksApiFp(configuration).launchNotebook(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Set the priority of the requested notebook.
         * @param {string} notebookId The id of the notebook.
         * @param {V1SetNotebookPriorityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNotebookPriority(notebookId: string, body: V1SetNotebookPriorityRequest, options?: any) {
            return NotebooksApiFp(configuration).setNotebookPriority(notebookId, body, options)(fetch, basePath);
        },
    };
};

/**
 * NotebooksApi - object-oriented interface
 * @export
 * @class NotebooksApi
 * @extends {BaseAPI}
 */
export class NotebooksApi extends BaseAPI {
    /**
     * 
     * @summary Get the requested notebook.
     * @param {string} notebookId The id of the notebook.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotebooksApi
     */
    public getNotebook(notebookId: string, options?: any) {
        return NotebooksApiFp(this.configuration).getNotebook(notebookId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of notebooks.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort notebooks by the given field.   - SORT_BY_UNSPECIFIED: Returns notebooks in an unsorted list.  - SORT_BY_ID: Returns notebooks sorted by id.  - SORT_BY_DESCRIPTION: Returns notebooks sorted by description.  - SORT_BY_START_TIME: Return notebooks sorted by start time.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order notebooks in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of notebooks before returning results. Negative values denote number of notebooks to skip from the end before returning results.
     * @param {number} [limit] Limit the number of notebooks. A value of 0 denotes no limit.
     * @param {Array<string>} [users] Limit notebooks to those that are owned by users with the specified usernames.
     * @param {Array<number>} [userIds] Limit notebooks to those that are owned by users with the specified userIds.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotebooksApi
     */
    public getNotebooks(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options?: any) {
        return NotebooksApiFp(this.configuration).getNotebooks(sortBy, orderBy, offset, limit, users, userIds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Kill the requested notebook.
     * @param {string} notebookId The id of the notebook.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotebooksApi
     */
    public killNotebook(notebookId: string, options?: any) {
        return NotebooksApiFp(this.configuration).killNotebook(notebookId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Launch a notebook.
     * @param {V1LaunchNotebookRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotebooksApi
     */
    public launchNotebook(body: V1LaunchNotebookRequest, options?: any) {
        return NotebooksApiFp(this.configuration).launchNotebook(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Set the priority of the requested notebook.
     * @param {string} notebookId The id of the notebook.
     * @param {V1SetNotebookPriorityRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotebooksApi
     */
    public setNotebookPriority(notebookId: string, body: V1SetNotebookPriorityRequest, options?: any) {
        return NotebooksApiFp(this.configuration).setNotebookPriority(notebookId, body, options)(this.fetch, this.basePath);
    }

}

/**
 * ProfilerApi - fetch parameter creator
 * @export
 */
export const ProfilerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Stream the available series in a trial's profiler metrics.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {boolean} [follow] Continue streaming labels until the trial stops. Defaults to False.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialProfilerAvailableSeries(trialId: number, follow?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling getTrialProfilerAvailableSeries.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/profiler/available_series`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (follow !== undefined) {
                localVarQueryParameter['follow'] = follow;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stream trial profiler metrics.
         * @param {number} labelsTrialId The ID of the trial.
         * @param {string} [labelsName] The name of the metric.
         * @param {string} [labelsAgentId] The agent ID associated with the metric.
         * @param {string} [labelsGpuUuid] The GPU UUID associated with the metric.
         * @param {'PROFILER_METRIC_TYPE_UNSPECIFIED' | 'PROFILER_METRIC_TYPE_SYSTEM' | 'PROFILER_METRIC_TYPE_TIMING' | 'PROFILER_METRIC_TYPE_MISC'} [labelsMetricType] The type of the metric.   - PROFILER_METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - PROFILER_METRIC_TYPE_SYSTEM: For systems metrics, like GPU utilization or memory.  - PROFILER_METRIC_TYPE_TIMING: For timing metrics, like how long a backwards pass or getting a batch from the dataloader took.  - PROFILER_METRIC_TYPE_MISC: For other miscellaneous metrics.
         * @param {boolean} [follow] Continue streaming metrics until the trial stops. Defaults to False.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialProfilerMetrics(labelsTrialId: number, labelsName?: string, labelsAgentId?: string, labelsGpuUuid?: string, labelsMetricType?: 'PROFILER_METRIC_TYPE_UNSPECIFIED' | 'PROFILER_METRIC_TYPE_SYSTEM' | 'PROFILER_METRIC_TYPE_TIMING' | 'PROFILER_METRIC_TYPE_MISC', follow?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'labelsTrialId' is not null or undefined
            if (labelsTrialId === null || labelsTrialId === undefined) {
                throw new RequiredError('labelsTrialId','Required parameter labelsTrialId was null or undefined when calling getTrialProfilerMetrics.');
            }
            const localVarPath = `/api/v1/trials/{labels.trialId}/profiler/metrics`
                .replace(`{${"labels.trialId"}}`, encodeURIComponent(String(labelsTrialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (labelsName !== undefined) {
                localVarQueryParameter['labels.name'] = labelsName;
            }

            if (labelsAgentId !== undefined) {
                localVarQueryParameter['labels.agentId'] = labelsAgentId;
            }

            if (labelsGpuUuid !== undefined) {
                localVarQueryParameter['labels.gpuUuid'] = labelsGpuUuid;
            }

            if (labelsMetricType !== undefined) {
                localVarQueryParameter['labels.metricType'] = labelsMetricType;
            }

            if (follow !== undefined) {
                localVarQueryParameter['follow'] = follow;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfilerApi - functional programming interface
 * @export
 */
export const ProfilerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Stream the available series in a trial's profiler metrics.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {boolean} [follow] Continue streaming labels until the trial stops. Defaults to False.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialProfilerAvailableSeries(trialId: number, follow?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1GetTrialProfilerAvailableSeriesResponse> {
            const localVarFetchArgs = ProfilerApiFetchParamCreator(configuration).getTrialProfilerAvailableSeries(trialId, follow, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Stream trial profiler metrics.
         * @param {number} labelsTrialId The ID of the trial.
         * @param {string} [labelsName] The name of the metric.
         * @param {string} [labelsAgentId] The agent ID associated with the metric.
         * @param {string} [labelsGpuUuid] The GPU UUID associated with the metric.
         * @param {'PROFILER_METRIC_TYPE_UNSPECIFIED' | 'PROFILER_METRIC_TYPE_SYSTEM' | 'PROFILER_METRIC_TYPE_TIMING' | 'PROFILER_METRIC_TYPE_MISC'} [labelsMetricType] The type of the metric.   - PROFILER_METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - PROFILER_METRIC_TYPE_SYSTEM: For systems metrics, like GPU utilization or memory.  - PROFILER_METRIC_TYPE_TIMING: For timing metrics, like how long a backwards pass or getting a batch from the dataloader took.  - PROFILER_METRIC_TYPE_MISC: For other miscellaneous metrics.
         * @param {boolean} [follow] Continue streaming metrics until the trial stops. Defaults to False.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialProfilerMetrics(labelsTrialId: number, labelsName?: string, labelsAgentId?: string, labelsGpuUuid?: string, labelsMetricType?: 'PROFILER_METRIC_TYPE_UNSPECIFIED' | 'PROFILER_METRIC_TYPE_SYSTEM' | 'PROFILER_METRIC_TYPE_TIMING' | 'PROFILER_METRIC_TYPE_MISC', follow?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1GetTrialProfilerMetricsResponse> {
            const localVarFetchArgs = ProfilerApiFetchParamCreator(configuration).getTrialProfilerMetrics(labelsTrialId, labelsName, labelsAgentId, labelsGpuUuid, labelsMetricType, follow, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProfilerApi - factory interface
 * @export
 */
export const ProfilerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Stream the available series in a trial's profiler metrics.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {boolean} [follow] Continue streaming labels until the trial stops. Defaults to False.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialProfilerAvailableSeries(trialId: number, follow?: boolean, options?: any) {
            return ProfilerApiFp(configuration).getTrialProfilerAvailableSeries(trialId, follow, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Stream trial profiler metrics.
         * @param {number} labelsTrialId The ID of the trial.
         * @param {string} [labelsName] The name of the metric.
         * @param {string} [labelsAgentId] The agent ID associated with the metric.
         * @param {string} [labelsGpuUuid] The GPU UUID associated with the metric.
         * @param {'PROFILER_METRIC_TYPE_UNSPECIFIED' | 'PROFILER_METRIC_TYPE_SYSTEM' | 'PROFILER_METRIC_TYPE_TIMING' | 'PROFILER_METRIC_TYPE_MISC'} [labelsMetricType] The type of the metric.   - PROFILER_METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - PROFILER_METRIC_TYPE_SYSTEM: For systems metrics, like GPU utilization or memory.  - PROFILER_METRIC_TYPE_TIMING: For timing metrics, like how long a backwards pass or getting a batch from the dataloader took.  - PROFILER_METRIC_TYPE_MISC: For other miscellaneous metrics.
         * @param {boolean} [follow] Continue streaming metrics until the trial stops. Defaults to False.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialProfilerMetrics(labelsTrialId: number, labelsName?: string, labelsAgentId?: string, labelsGpuUuid?: string, labelsMetricType?: 'PROFILER_METRIC_TYPE_UNSPECIFIED' | 'PROFILER_METRIC_TYPE_SYSTEM' | 'PROFILER_METRIC_TYPE_TIMING' | 'PROFILER_METRIC_TYPE_MISC', follow?: boolean, options?: any) {
            return ProfilerApiFp(configuration).getTrialProfilerMetrics(labelsTrialId, labelsName, labelsAgentId, labelsGpuUuid, labelsMetricType, follow, options)(fetch, basePath);
        },
    };
};

/**
 * ProfilerApi - object-oriented interface
 * @export
 * @class ProfilerApi
 * @extends {BaseAPI}
 */
export class ProfilerApi extends BaseAPI {
    /**
     * 
     * @summary Stream the available series in a trial's profiler metrics.
     * @param {number} trialId The requested trial&#39;s id.
     * @param {boolean} [follow] Continue streaming labels until the trial stops. Defaults to False.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilerApi
     */
    public getTrialProfilerAvailableSeries(trialId: number, follow?: boolean, options?: any) {
        return ProfilerApiFp(this.configuration).getTrialProfilerAvailableSeries(trialId, follow, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Stream trial profiler metrics.
     * @param {number} labelsTrialId The ID of the trial.
     * @param {string} [labelsName] The name of the metric.
     * @param {string} [labelsAgentId] The agent ID associated with the metric.
     * @param {string} [labelsGpuUuid] The GPU UUID associated with the metric.
     * @param {'PROFILER_METRIC_TYPE_UNSPECIFIED' | 'PROFILER_METRIC_TYPE_SYSTEM' | 'PROFILER_METRIC_TYPE_TIMING' | 'PROFILER_METRIC_TYPE_MISC'} [labelsMetricType] The type of the metric.   - PROFILER_METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - PROFILER_METRIC_TYPE_SYSTEM: For systems metrics, like GPU utilization or memory.  - PROFILER_METRIC_TYPE_TIMING: For timing metrics, like how long a backwards pass or getting a batch from the dataloader took.  - PROFILER_METRIC_TYPE_MISC: For other miscellaneous metrics.
     * @param {boolean} [follow] Continue streaming metrics until the trial stops. Defaults to False.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilerApi
     */
    public getTrialProfilerMetrics(labelsTrialId: number, labelsName?: string, labelsAgentId?: string, labelsGpuUuid?: string, labelsMetricType?: 'PROFILER_METRIC_TYPE_UNSPECIFIED' | 'PROFILER_METRIC_TYPE_SYSTEM' | 'PROFILER_METRIC_TYPE_TIMING' | 'PROFILER_METRIC_TYPE_MISC', follow?: boolean, options?: any) {
        return ProfilerApiFp(this.configuration).getTrialProfilerMetrics(labelsTrialId, labelsName, labelsAgentId, labelsGpuUuid, labelsMetricType, follow, options)(this.fetch, this.basePath);
    }

}

/**
 * ProjectsApi - fetch parameter creator
 * @export
 */
export const ProjectsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a note to a project.
         * @param {number} projectId The id of the project.
         * @param {V1Note} body The note to add.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectNote(projectId: number, body: V1Note, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling addProjectNote.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addProjectNote.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/notes`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1Note" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archive a project.
         * @param {number} id The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveProject(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling archiveProject.');
            }
            const localVarPath = `/api/v1/projects/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a project.
         * @param {number} id The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProject.');
            }
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the requested project.
         * @param {number} id The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProject.');
            }
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move a project into a workspace.
         * @param {number} projectId The id of the project being moved.
         * @param {V1MoveProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveProject(projectId: number, body: V1MoveProjectRequest, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling moveProject.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling moveProject.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/move`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1MoveProjectRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a project.
         * @param {number} id The id of the project.
         * @param {V1PatchProject} body The desired project fields and values to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProject(id: number, body: V1PatchProject, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchProject.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling patchProject.');
            }
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PatchProject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a project.
         * @param {number} workspaceId Id of the associated workspace.
         * @param {V1PostProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProject(workspaceId: number, body: V1PostProjectRequest, options: any = {}): FetchArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling postProject.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postProject.');
            }
            const localVarPath = `/api/v1/workspaces/{workspaceId}/projects`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PostProjectRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set project notes.
         * @param {number} projectId The id of the project.
         * @param {V1PutProjectNotesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectNotes(projectId: number, body: V1PutProjectNotesRequest, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling putProjectNotes.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putProjectNotes.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/notes`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PutProjectNotesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unarchive a project.
         * @param {number} id The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveProject(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unarchiveProject.');
            }
            const localVarPath = `/api/v1/projects/{id}/unarchive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a note to a project.
         * @param {number} projectId The id of the project.
         * @param {V1Note} body The note to add.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectNote(projectId: number, body: V1Note, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1AddProjectNoteResponse> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).addProjectNote(projectId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Archive a project.
         * @param {number} id The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveProject(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ArchiveProjectResponse> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).archiveProject(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a project.
         * @param {number} id The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1DeleteProjectResponse> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).deleteProject(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the requested project.
         * @param {number} id The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetProjectResponse> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).getProject(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Move a project into a workspace.
         * @param {number} projectId The id of the project being moved.
         * @param {V1MoveProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveProject(projectId: number, body: V1MoveProjectRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1MoveProjectResponse> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).moveProject(projectId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update a project.
         * @param {number} id The id of the project.
         * @param {V1PatchProject} body The desired project fields and values to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProject(id: number, body: V1PatchProject, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PatchProjectResponse> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).patchProject(id, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create a project.
         * @param {number} workspaceId Id of the associated workspace.
         * @param {V1PostProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProject(workspaceId: number, body: V1PostProjectRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PostProjectResponse> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).postProject(workspaceId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Set project notes.
         * @param {number} projectId The id of the project.
         * @param {V1PutProjectNotesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectNotes(projectId: number, body: V1PutProjectNotesRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PutProjectNotesResponse> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).putProjectNotes(projectId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unarchive a project.
         * @param {number} id The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveProject(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1UnarchiveProjectResponse> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).unarchiveProject(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add a note to a project.
         * @param {number} projectId The id of the project.
         * @param {V1Note} body The note to add.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectNote(projectId: number, body: V1Note, options?: any) {
            return ProjectsApiFp(configuration).addProjectNote(projectId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Archive a project.
         * @param {number} id The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveProject(id: number, options?: any) {
            return ProjectsApiFp(configuration).archiveProject(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a project.
         * @param {number} id The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(id: number, options?: any) {
            return ProjectsApiFp(configuration).deleteProject(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the requested project.
         * @param {number} id The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(id: number, options?: any) {
            return ProjectsApiFp(configuration).getProject(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Move a project into a workspace.
         * @param {number} projectId The id of the project being moved.
         * @param {V1MoveProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveProject(projectId: number, body: V1MoveProjectRequest, options?: any) {
            return ProjectsApiFp(configuration).moveProject(projectId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update a project.
         * @param {number} id The id of the project.
         * @param {V1PatchProject} body The desired project fields and values to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProject(id: number, body: V1PatchProject, options?: any) {
            return ProjectsApiFp(configuration).patchProject(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create a project.
         * @param {number} workspaceId Id of the associated workspace.
         * @param {V1PostProjectRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProject(workspaceId: number, body: V1PostProjectRequest, options?: any) {
            return ProjectsApiFp(configuration).postProject(workspaceId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Set project notes.
         * @param {number} projectId The id of the project.
         * @param {V1PutProjectNotesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectNotes(projectId: number, body: V1PutProjectNotesRequest, options?: any) {
            return ProjectsApiFp(configuration).putProjectNotes(projectId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unarchive a project.
         * @param {number} id The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveProject(id: number, options?: any) {
            return ProjectsApiFp(configuration).unarchiveProject(id, options)(fetch, basePath);
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Add a note to a project.
     * @param {number} projectId The id of the project.
     * @param {V1Note} body The note to add.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public addProjectNote(projectId: number, body: V1Note, options?: any) {
        return ProjectsApiFp(this.configuration).addProjectNote(projectId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Archive a project.
     * @param {number} id The id of the project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public archiveProject(id: number, options?: any) {
        return ProjectsApiFp(this.configuration).archiveProject(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a project.
     * @param {number} id The id of the project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProject(id: number, options?: any) {
        return ProjectsApiFp(this.configuration).deleteProject(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the requested project.
     * @param {number} id The id of the project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProject(id: number, options?: any) {
        return ProjectsApiFp(this.configuration).getProject(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Move a project into a workspace.
     * @param {number} projectId The id of the project being moved.
     * @param {V1MoveProjectRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public moveProject(projectId: number, body: V1MoveProjectRequest, options?: any) {
        return ProjectsApiFp(this.configuration).moveProject(projectId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update a project.
     * @param {number} id The id of the project.
     * @param {V1PatchProject} body The desired project fields and values to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public patchProject(id: number, body: V1PatchProject, options?: any) {
        return ProjectsApiFp(this.configuration).patchProject(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create a project.
     * @param {number} workspaceId Id of the associated workspace.
     * @param {V1PostProjectRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public postProject(workspaceId: number, body: V1PostProjectRequest, options?: any) {
        return ProjectsApiFp(this.configuration).postProject(workspaceId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Set project notes.
     * @param {number} projectId The id of the project.
     * @param {V1PutProjectNotesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public putProjectNotes(projectId: number, body: V1PutProjectNotesRequest, options?: any) {
        return ProjectsApiFp(this.configuration).putProjectNotes(projectId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unarchive a project.
     * @param {number} id The id of the project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public unarchiveProject(id: number, options?: any) {
        return ProjectsApiFp(this.configuration).unarchiveProject(id, options)(this.fetch, this.basePath);
    }

}

/**
 * RBACApi - fetch parameter creator
 * @export
 */
export const RBACApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary AssignRoles adds a set of role assignments to the system.
         * @param {V1AssignRolesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRoles(body: V1AssignRolesRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling assignRoles.');
            }
            const localVarPath = `/api/v1/roles/add-assignments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1AssignRolesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get groups and users assigned to a given workspace with what roles are assigned.
         * @param {number} workspaceId ID of workspace getting groups and users.
         * @param {string} [name] Name of groups and users to search by. Name filters by group name for groups. Name filters by display name then username if display name is null for users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupsAndUsersAssignedToWorkspace(workspaceId: number, name?: string, options: any = {}): FetchArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling getGroupsAndUsersAssignedToWorkspace.');
            }
            const localVarPath = `/api/v1/roles/workspace/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all permissions for the logged in user in all scopes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsSummary(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/permissions/summary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the roles which are assigned to a group.
         * @param {number} groupId The id of the group to search for role assignments for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesAssignedToGroup(groupId: number, options: any = {}): FetchArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling getRolesAssignedToGroup.');
            }
            const localVarPath = `/api/v1/roles/search/by-group/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the roles which are assigned to a user.
         * @param {number} userId The id of the user to search for role assignments for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesAssignedToUser(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getRolesAssignedToUser.');
            }
            const localVarPath = `/api/v1/roles/search/by-user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a set of roles with the corresponding IDs.
         * @param {V1GetRolesByIDRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesByID(body: V1GetRolesByIDRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getRolesByID.');
            }
            const localVarPath = `/api/v1/roles/search/by-ids`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1GetRolesByIDRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListRoles returns roles and groups/users granted that role.
         * @param {V1ListRolesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(body: V1ListRolesRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listRoles.');
            }
            const localVarPath = `/api/v1/roles/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1ListRolesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary RemoveAssignments removes a set of role assignments from the system.
         * @param {V1RemoveAssignmentsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAssignments(body: V1RemoveAssignmentsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling removeAssignments.');
            }
            const localVarPath = `/api/v1/roles/remove-assignments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1RemoveAssignmentsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for roles assignable to a given scope.
         * @param {V1SearchRolesAssignableToScopeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRolesAssignableToScope(body: V1SearchRolesAssignableToScopeRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling searchRolesAssignableToScope.');
            }
            const localVarPath = `/api/v1/roles/search/by-assignability`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1SearchRolesAssignableToScopeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RBACApi - functional programming interface
 * @export
 */
export const RBACApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary AssignRoles adds a set of role assignments to the system.
         * @param {V1AssignRolesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRoles(body: V1AssignRolesRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1AssignRolesResponse> {
            const localVarFetchArgs = RBACApiFetchParamCreator(configuration).assignRoles(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get groups and users assigned to a given workspace with what roles are assigned.
         * @param {number} workspaceId ID of workspace getting groups and users.
         * @param {string} [name] Name of groups and users to search by. Name filters by group name for groups. Name filters by display name then username if display name is null for users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupsAndUsersAssignedToWorkspace(workspaceId: number, name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetGroupsAndUsersAssignedToWorkspaceResponse> {
            const localVarFetchArgs = RBACApiFetchParamCreator(configuration).getGroupsAndUsersAssignedToWorkspace(workspaceId, name, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary List all permissions for the logged in user in all scopes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsSummary(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetPermissionsSummaryResponse> {
            const localVarFetchArgs = RBACApiFetchParamCreator(configuration).getPermissionsSummary(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the roles which are assigned to a group.
         * @param {number} groupId The id of the group to search for role assignments for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesAssignedToGroup(groupId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetRolesAssignedToGroupResponse> {
            const localVarFetchArgs = RBACApiFetchParamCreator(configuration).getRolesAssignedToGroup(groupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the roles which are assigned to a user.
         * @param {number} userId The id of the user to search for role assignments for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesAssignedToUser(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetRolesAssignedToUserResponse> {
            const localVarFetchArgs = RBACApiFetchParamCreator(configuration).getRolesAssignedToUser(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a set of roles with the corresponding IDs.
         * @param {V1GetRolesByIDRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesByID(body: V1GetRolesByIDRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetRolesByIDResponse> {
            const localVarFetchArgs = RBACApiFetchParamCreator(configuration).getRolesByID(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary ListRoles returns roles and groups/users granted that role.
         * @param {V1ListRolesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(body: V1ListRolesRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ListRolesResponse> {
            const localVarFetchArgs = RBACApiFetchParamCreator(configuration).listRoles(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary RemoveAssignments removes a set of role assignments from the system.
         * @param {V1RemoveAssignmentsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAssignments(body: V1RemoveAssignmentsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1RemoveAssignmentsResponse> {
            const localVarFetchArgs = RBACApiFetchParamCreator(configuration).removeAssignments(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search for roles assignable to a given scope.
         * @param {V1SearchRolesAssignableToScopeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRolesAssignableToScope(body: V1SearchRolesAssignableToScopeRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1SearchRolesAssignableToScopeResponse> {
            const localVarFetchArgs = RBACApiFetchParamCreator(configuration).searchRolesAssignableToScope(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RBACApi - factory interface
 * @export
 */
export const RBACApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary AssignRoles adds a set of role assignments to the system.
         * @param {V1AssignRolesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRoles(body: V1AssignRolesRequest, options?: any) {
            return RBACApiFp(configuration).assignRoles(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get groups and users assigned to a given workspace with what roles are assigned.
         * @param {number} workspaceId ID of workspace getting groups and users.
         * @param {string} [name] Name of groups and users to search by. Name filters by group name for groups. Name filters by display name then username if display name is null for users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupsAndUsersAssignedToWorkspace(workspaceId: number, name?: string, options?: any) {
            return RBACApiFp(configuration).getGroupsAndUsersAssignedToWorkspace(workspaceId, name, options)(fetch, basePath);
        },
        /**
         * 
         * @summary List all permissions for the logged in user in all scopes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsSummary(options?: any) {
            return RBACApiFp(configuration).getPermissionsSummary(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the roles which are assigned to a group.
         * @param {number} groupId The id of the group to search for role assignments for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesAssignedToGroup(groupId: number, options?: any) {
            return RBACApiFp(configuration).getRolesAssignedToGroup(groupId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the roles which are assigned to a user.
         * @param {number} userId The id of the user to search for role assignments for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesAssignedToUser(userId: number, options?: any) {
            return RBACApiFp(configuration).getRolesAssignedToUser(userId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a set of roles with the corresponding IDs.
         * @param {V1GetRolesByIDRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesByID(body: V1GetRolesByIDRequest, options?: any) {
            return RBACApiFp(configuration).getRolesByID(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary ListRoles returns roles and groups/users granted that role.
         * @param {V1ListRolesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(body: V1ListRolesRequest, options?: any) {
            return RBACApiFp(configuration).listRoles(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary RemoveAssignments removes a set of role assignments from the system.
         * @param {V1RemoveAssignmentsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAssignments(body: V1RemoveAssignmentsRequest, options?: any) {
            return RBACApiFp(configuration).removeAssignments(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search for roles assignable to a given scope.
         * @param {V1SearchRolesAssignableToScopeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRolesAssignableToScope(body: V1SearchRolesAssignableToScopeRequest, options?: any) {
            return RBACApiFp(configuration).searchRolesAssignableToScope(body, options)(fetch, basePath);
        },
    };
};

/**
 * RBACApi - object-oriented interface
 * @export
 * @class RBACApi
 * @extends {BaseAPI}
 */
export class RBACApi extends BaseAPI {
    /**
     * 
     * @summary AssignRoles adds a set of role assignments to the system.
     * @param {V1AssignRolesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RBACApi
     */
    public assignRoles(body: V1AssignRolesRequest, options?: any) {
        return RBACApiFp(this.configuration).assignRoles(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get groups and users assigned to a given workspace with what roles are assigned.
     * @param {number} workspaceId ID of workspace getting groups and users.
     * @param {string} [name] Name of groups and users to search by. Name filters by group name for groups. Name filters by display name then username if display name is null for users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RBACApi
     */
    public getGroupsAndUsersAssignedToWorkspace(workspaceId: number, name?: string, options?: any) {
        return RBACApiFp(this.configuration).getGroupsAndUsersAssignedToWorkspace(workspaceId, name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary List all permissions for the logged in user in all scopes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RBACApi
     */
    public getPermissionsSummary(options?: any) {
        return RBACApiFp(this.configuration).getPermissionsSummary(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the roles which are assigned to a group.
     * @param {number} groupId The id of the group to search for role assignments for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RBACApi
     */
    public getRolesAssignedToGroup(groupId: number, options?: any) {
        return RBACApiFp(this.configuration).getRolesAssignedToGroup(groupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the roles which are assigned to a user.
     * @param {number} userId The id of the user to search for role assignments for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RBACApi
     */
    public getRolesAssignedToUser(userId: number, options?: any) {
        return RBACApiFp(this.configuration).getRolesAssignedToUser(userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a set of roles with the corresponding IDs.
     * @param {V1GetRolesByIDRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RBACApi
     */
    public getRolesByID(body: V1GetRolesByIDRequest, options?: any) {
        return RBACApiFp(this.configuration).getRolesByID(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary ListRoles returns roles and groups/users granted that role.
     * @param {V1ListRolesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RBACApi
     */
    public listRoles(body: V1ListRolesRequest, options?: any) {
        return RBACApiFp(this.configuration).listRoles(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary RemoveAssignments removes a set of role assignments from the system.
     * @param {V1RemoveAssignmentsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RBACApi
     */
    public removeAssignments(body: V1RemoveAssignmentsRequest, options?: any) {
        return RBACApiFp(this.configuration).removeAssignments(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search for roles assignable to a given scope.
     * @param {V1SearchRolesAssignableToScopeRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RBACApi
     */
    public searchRolesAssignableToScope(body: V1SearchRolesAssignableToScopeRequest, options?: any) {
        return RBACApiFp(this.configuration).searchRolesAssignableToScope(body, options)(this.fetch, this.basePath);
    }

}

/**
 * ShellsApi - fetch parameter creator
 * @export
 */
export const ShellsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the requested shell.
         * @param {string} shellId The id of the shell.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShell(shellId: string, options: any = {}): FetchArgs {
            // verify required parameter 'shellId' is not null or undefined
            if (shellId === null || shellId === undefined) {
                throw new RequiredError('shellId','Required parameter shellId was null or undefined when calling getShell.');
            }
            const localVarPath = `/api/v1/shells/{shellId}`
                .replace(`{${"shellId"}}`, encodeURIComponent(String(shellId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of shells.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort shells by the given field.   - SORT_BY_UNSPECIFIED: Returns shells in an unsorted list.  - SORT_BY_ID: Returns shells sorted by id.  - SORT_BY_DESCRIPTION: Returns shells sorted by description.  - SORT_BY_START_TIME: Return shells sorted by start time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order shells in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of shells before returning results. Negative values denote number of shells to skip from the end before returning results.
         * @param {number} [limit] Limit the number of shells. A value of 0 denotes no limit.
         * @param {Array<string>} [users] Limit shells to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit shells to those that are owned by users with the specified userIds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShells(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/shells`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kill the requested shell.
         * @param {string} shellId The id of the shell.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killShell(shellId: string, options: any = {}): FetchArgs {
            // verify required parameter 'shellId' is not null or undefined
            if (shellId === null || shellId === undefined) {
                throw new RequiredError('shellId','Required parameter shellId was null or undefined when calling killShell.');
            }
            const localVarPath = `/api/v1/shells/{shellId}/kill`
                .replace(`{${"shellId"}}`, encodeURIComponent(String(shellId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Launch a shell.
         * @param {V1LaunchShellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchShell(body: V1LaunchShellRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling launchShell.');
            }
            const localVarPath = `/api/v1/shells`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1LaunchShellRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the priority of the requested shell.
         * @param {string} shellId The id of the shell.
         * @param {V1SetShellPriorityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setShellPriority(shellId: string, body: V1SetShellPriorityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'shellId' is not null or undefined
            if (shellId === null || shellId === undefined) {
                throw new RequiredError('shellId','Required parameter shellId was null or undefined when calling setShellPriority.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setShellPriority.');
            }
            const localVarPath = `/api/v1/shells/{shellId}/set_priority`
                .replace(`{${"shellId"}}`, encodeURIComponent(String(shellId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1SetShellPriorityRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShellsApi - functional programming interface
 * @export
 */
export const ShellsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the requested shell.
         * @param {string} shellId The id of the shell.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShell(shellId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetShellResponse> {
            const localVarFetchArgs = ShellsApiFetchParamCreator(configuration).getShell(shellId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of shells.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort shells by the given field.   - SORT_BY_UNSPECIFIED: Returns shells in an unsorted list.  - SORT_BY_ID: Returns shells sorted by id.  - SORT_BY_DESCRIPTION: Returns shells sorted by description.  - SORT_BY_START_TIME: Return shells sorted by start time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order shells in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of shells before returning results. Negative values denote number of shells to skip from the end before returning results.
         * @param {number} [limit] Limit the number of shells. A value of 0 denotes no limit.
         * @param {Array<string>} [users] Limit shells to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit shells to those that are owned by users with the specified userIds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShells(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetShellsResponse> {
            const localVarFetchArgs = ShellsApiFetchParamCreator(configuration).getShells(sortBy, orderBy, offset, limit, users, userIds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Kill the requested shell.
         * @param {string} shellId The id of the shell.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killShell(shellId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1KillShellResponse> {
            const localVarFetchArgs = ShellsApiFetchParamCreator(configuration).killShell(shellId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Launch a shell.
         * @param {V1LaunchShellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchShell(body: V1LaunchShellRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1LaunchShellResponse> {
            const localVarFetchArgs = ShellsApiFetchParamCreator(configuration).launchShell(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Set the priority of the requested shell.
         * @param {string} shellId The id of the shell.
         * @param {V1SetShellPriorityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setShellPriority(shellId: string, body: V1SetShellPriorityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1SetShellPriorityResponse> {
            const localVarFetchArgs = ShellsApiFetchParamCreator(configuration).setShellPriority(shellId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ShellsApi - factory interface
 * @export
 */
export const ShellsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get the requested shell.
         * @param {string} shellId The id of the shell.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShell(shellId: string, options?: any) {
            return ShellsApiFp(configuration).getShell(shellId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of shells.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort shells by the given field.   - SORT_BY_UNSPECIFIED: Returns shells in an unsorted list.  - SORT_BY_ID: Returns shells sorted by id.  - SORT_BY_DESCRIPTION: Returns shells sorted by description.  - SORT_BY_START_TIME: Return shells sorted by start time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order shells in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of shells before returning results. Negative values denote number of shells to skip from the end before returning results.
         * @param {number} [limit] Limit the number of shells. A value of 0 denotes no limit.
         * @param {Array<string>} [users] Limit shells to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit shells to those that are owned by users with the specified userIds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShells(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options?: any) {
            return ShellsApiFp(configuration).getShells(sortBy, orderBy, offset, limit, users, userIds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Kill the requested shell.
         * @param {string} shellId The id of the shell.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killShell(shellId: string, options?: any) {
            return ShellsApiFp(configuration).killShell(shellId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Launch a shell.
         * @param {V1LaunchShellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchShell(body: V1LaunchShellRequest, options?: any) {
            return ShellsApiFp(configuration).launchShell(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Set the priority of the requested shell.
         * @param {string} shellId The id of the shell.
         * @param {V1SetShellPriorityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setShellPriority(shellId: string, body: V1SetShellPriorityRequest, options?: any) {
            return ShellsApiFp(configuration).setShellPriority(shellId, body, options)(fetch, basePath);
        },
    };
};

/**
 * ShellsApi - object-oriented interface
 * @export
 * @class ShellsApi
 * @extends {BaseAPI}
 */
export class ShellsApi extends BaseAPI {
    /**
     * 
     * @summary Get the requested shell.
     * @param {string} shellId The id of the shell.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShellsApi
     */
    public getShell(shellId: string, options?: any) {
        return ShellsApiFp(this.configuration).getShell(shellId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of shells.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort shells by the given field.   - SORT_BY_UNSPECIFIED: Returns shells in an unsorted list.  - SORT_BY_ID: Returns shells sorted by id.  - SORT_BY_DESCRIPTION: Returns shells sorted by description.  - SORT_BY_START_TIME: Return shells sorted by start time.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order shells in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of shells before returning results. Negative values denote number of shells to skip from the end before returning results.
     * @param {number} [limit] Limit the number of shells. A value of 0 denotes no limit.
     * @param {Array<string>} [users] Limit shells to those that are owned by users with the specified usernames.
     * @param {Array<number>} [userIds] Limit shells to those that are owned by users with the specified userIds.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShellsApi
     */
    public getShells(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options?: any) {
        return ShellsApiFp(this.configuration).getShells(sortBy, orderBy, offset, limit, users, userIds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Kill the requested shell.
     * @param {string} shellId The id of the shell.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShellsApi
     */
    public killShell(shellId: string, options?: any) {
        return ShellsApiFp(this.configuration).killShell(shellId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Launch a shell.
     * @param {V1LaunchShellRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShellsApi
     */
    public launchShell(body: V1LaunchShellRequest, options?: any) {
        return ShellsApiFp(this.configuration).launchShell(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Set the priority of the requested shell.
     * @param {string} shellId The id of the shell.
     * @param {V1SetShellPriorityRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShellsApi
     */
    public setShellPriority(shellId: string, body: V1SetShellPriorityRequest, options?: any) {
        return ShellsApiFp(this.configuration).setShellPriority(shellId, body, options)(this.fetch, this.basePath);
    }

}

/**
 * TasksApi - fetch parameter creator
 * @export
 */
export const TasksApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a count of active tasks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveTasksCount(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/tasks/count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check the status of a requested task.
         * @param {string} taskId The requested task id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask(taskId: string, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling getTask.');
            }
            const localVarPath = `/api/v1/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stream task logs.
         * @param {string} taskId The id of the task.
         * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the trial stops.
         * @param {Array<string>} [allocationIds] Limit the task logs to particular allocations.
         * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
         * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
         * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
         * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
         * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
         * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
         * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
         * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {string} [searchText] Search the logs by whether the text contains a substring.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskLogs(taskId: string, limit?: number, follow?: boolean, allocationIds?: Array<string>, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling taskLogs.');
            }
            const localVarPath = `/api/v1/tasks/{taskId}/logs`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (follow !== undefined) {
                localVarQueryParameter['follow'] = follow;
            }

            if (allocationIds) {
                localVarQueryParameter['allocationIds'] = allocationIds;
            }

            if (agentIds) {
                localVarQueryParameter['agentIds'] = agentIds;
            }

            if (containerIds) {
                localVarQueryParameter['containerIds'] = containerIds;
            }

            if (rankIds) {
                localVarQueryParameter['rankIds'] = rankIds;
            }

            if (levels) {
                localVarQueryParameter['levels'] = levels;
            }

            if (stdtypes) {
                localVarQueryParameter['stdtypes'] = stdtypes;
            }

            if (sources) {
                localVarQueryParameter['sources'] = sources;
            }

            if (timestampBefore !== undefined) {
                localVarQueryParameter['timestampBefore'] = (timestampBefore as any).toISOString();
            }

            if (timestampAfter !== undefined) {
                localVarQueryParameter['timestampAfter'] = (timestampAfter as any).toISOString();
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stream task log fields.
         * @param {string} taskId The ID of the task.
         * @param {boolean} [follow] Continue following fields until the task stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskLogsFields(taskId: string, follow?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling taskLogsFields.');
            }
            const localVarPath = `/api/v1/tasks/{taskId}/logs/fields`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (follow !== undefined) {
                localVarQueryParameter['follow'] = follow;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a count of active tasks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveTasksCount(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetActiveTasksCountResponse> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).getActiveTasksCount(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Check the status of a requested task.
         * @param {string} taskId The requested task id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask(taskId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetTaskResponse> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).getTask(taskId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Stream task logs.
         * @param {string} taskId The id of the task.
         * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the trial stops.
         * @param {Array<string>} [allocationIds] Limit the task logs to particular allocations.
         * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
         * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
         * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
         * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
         * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
         * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
         * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
         * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {string} [searchText] Search the logs by whether the text contains a substring.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskLogs(taskId: string, limit?: number, follow?: boolean, allocationIds?: Array<string>, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1TaskLogsResponse> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).taskLogs(taskId, limit, follow, allocationIds, agentIds, containerIds, rankIds, levels, stdtypes, sources, timestampBefore, timestampAfter, orderBy, searchText, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Stream task log fields.
         * @param {string} taskId The ID of the task.
         * @param {boolean} [follow] Continue following fields until the task stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskLogsFields(taskId: string, follow?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1TaskLogsFieldsResponse> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).taskLogsFields(taskId, follow, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get a count of active tasks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveTasksCount(options?: any) {
            return TasksApiFp(configuration).getActiveTasksCount(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Check the status of a requested task.
         * @param {string} taskId The requested task id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask(taskId: string, options?: any) {
            return TasksApiFp(configuration).getTask(taskId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Stream task logs.
         * @param {string} taskId The id of the task.
         * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the trial stops.
         * @param {Array<string>} [allocationIds] Limit the task logs to particular allocations.
         * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
         * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
         * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
         * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
         * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
         * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
         * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
         * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {string} [searchText] Search the logs by whether the text contains a substring.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskLogs(taskId: string, limit?: number, follow?: boolean, allocationIds?: Array<string>, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options?: any) {
            return TasksApiFp(configuration).taskLogs(taskId, limit, follow, allocationIds, agentIds, containerIds, rankIds, levels, stdtypes, sources, timestampBefore, timestampAfter, orderBy, searchText, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Stream task log fields.
         * @param {string} taskId The ID of the task.
         * @param {boolean} [follow] Continue following fields until the task stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskLogsFields(taskId: string, follow?: boolean, options?: any) {
            return TasksApiFp(configuration).taskLogsFields(taskId, follow, options)(fetch, basePath);
        },
    };
};

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * 
     * @summary Get a count of active tasks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getActiveTasksCount(options?: any) {
        return TasksApiFp(this.configuration).getActiveTasksCount(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Check the status of a requested task.
     * @param {string} taskId The requested task id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getTask(taskId: string, options?: any) {
        return TasksApiFp(this.configuration).getTask(taskId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Stream task logs.
     * @param {string} taskId The id of the task.
     * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
     * @param {boolean} [follow] Continue following logs until the trial stops.
     * @param {Array<string>} [allocationIds] Limit the task logs to particular allocations.
     * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
     * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
     * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
     * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
     * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
     * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
     * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
     * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {string} [searchText] Search the logs by whether the text contains a substring.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public taskLogs(taskId: string, limit?: number, follow?: boolean, allocationIds?: Array<string>, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options?: any) {
        return TasksApiFp(this.configuration).taskLogs(taskId, limit, follow, allocationIds, agentIds, containerIds, rankIds, levels, stdtypes, sources, timestampBefore, timestampAfter, orderBy, searchText, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Stream task log fields.
     * @param {string} taskId The ID of the task.
     * @param {boolean} [follow] Continue following fields until the task stops.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public taskLogsFields(taskId: string, follow?: boolean, options?: any) {
        return TasksApiFp(this.configuration).taskLogsFields(taskId, follow, options)(this.fetch, this.basePath);
    }

}

/**
 * TemplatesApi - fetch parameter creator
 * @export
 */
export const TemplatesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a template.
         * @param {string} templateName The id of the template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(templateName: string, options: any = {}): FetchArgs {
            // verify required parameter 'templateName' is not null or undefined
            if (templateName === null || templateName === undefined) {
                throw new RequiredError('templateName','Required parameter templateName was null or undefined when calling deleteTemplate.');
            }
            const localVarPath = `/api/v1/templates/{templateName}`
                .replace(`{${"templateName"}}`, encodeURIComponent(String(templateName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the requested template.
         * @param {string} templateName The id of the template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(templateName: string, options: any = {}): FetchArgs {
            // verify required parameter 'templateName' is not null or undefined
            if (templateName === null || templateName === undefined) {
                throw new RequiredError('templateName','Required parameter templateName was null or undefined when calling getTemplate.');
            }
            const localVarPath = `/api/v1/templates/{templateName}`
                .replace(`{${"templateName"}}`, encodeURIComponent(String(templateName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of templates.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME'} [sortBy] Sort templates by the given field.   - SORT_BY_UNSPECIFIED: Returns templates in an unsorted list.  - SORT_BY_NAME: Returns templates sorted by name.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order templates in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of templates before returning results. Negative values denote number of templates to skip from the end before returning results.
         * @param {number} [limit] Limit the number of templates. A value of 0 denotes no limit.
         * @param {string} [name] Limit templates to those that match the name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/templates`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update or create (upsert) the requested template.
         * @param {string} templateName The name of the template.
         * @param {V1Template} body The template to put.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTemplate(templateName: string, body: V1Template, options: any = {}): FetchArgs {
            // verify required parameter 'templateName' is not null or undefined
            if (templateName === null || templateName === undefined) {
                throw new RequiredError('templateName','Required parameter templateName was null or undefined when calling putTemplate.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putTemplate.');
            }
            const localVarPath = `/api/v1/templates/{template.name}`
                .replace(`{${"template.name"}}`, encodeURIComponent(String(templateName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1Template" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplatesApi - functional programming interface
 * @export
 */
export const TemplatesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a template.
         * @param {string} templateName The id of the template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(templateName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1DeleteTemplateResponse> {
            const localVarFetchArgs = TemplatesApiFetchParamCreator(configuration).deleteTemplate(templateName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the requested template.
         * @param {string} templateName The id of the template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(templateName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetTemplateResponse> {
            const localVarFetchArgs = TemplatesApiFetchParamCreator(configuration).getTemplate(templateName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of templates.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME'} [sortBy] Sort templates by the given field.   - SORT_BY_UNSPECIFIED: Returns templates in an unsorted list.  - SORT_BY_NAME: Returns templates sorted by name.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order templates in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of templates before returning results. Negative values denote number of templates to skip from the end before returning results.
         * @param {number} [limit] Limit the number of templates. A value of 0 denotes no limit.
         * @param {string} [name] Limit templates to those that match the name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetTemplatesResponse> {
            const localVarFetchArgs = TemplatesApiFetchParamCreator(configuration).getTemplates(sortBy, orderBy, offset, limit, name, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update or create (upsert) the requested template.
         * @param {string} templateName The name of the template.
         * @param {V1Template} body The template to put.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTemplate(templateName: string, body: V1Template, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PutTemplateResponse> {
            const localVarFetchArgs = TemplatesApiFetchParamCreator(configuration).putTemplate(templateName, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TemplatesApi - factory interface
 * @export
 */
export const TemplatesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Delete a template.
         * @param {string} templateName The id of the template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(templateName: string, options?: any) {
            return TemplatesApiFp(configuration).deleteTemplate(templateName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the requested template.
         * @param {string} templateName The id of the template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(templateName: string, options?: any) {
            return TemplatesApiFp(configuration).getTemplate(templateName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of templates.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME'} [sortBy] Sort templates by the given field.   - SORT_BY_UNSPECIFIED: Returns templates in an unsorted list.  - SORT_BY_NAME: Returns templates sorted by name.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order templates in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of templates before returning results. Negative values denote number of templates to skip from the end before returning results.
         * @param {number} [limit] Limit the number of templates. A value of 0 denotes no limit.
         * @param {string} [name] Limit templates to those that match the name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, options?: any) {
            return TemplatesApiFp(configuration).getTemplates(sortBy, orderBy, offset, limit, name, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update or create (upsert) the requested template.
         * @param {string} templateName The name of the template.
         * @param {V1Template} body The template to put.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTemplate(templateName: string, body: V1Template, options?: any) {
            return TemplatesApiFp(configuration).putTemplate(templateName, body, options)(fetch, basePath);
        },
    };
};

/**
 * TemplatesApi - object-oriented interface
 * @export
 * @class TemplatesApi
 * @extends {BaseAPI}
 */
export class TemplatesApi extends BaseAPI {
    /**
     * 
     * @summary Delete a template.
     * @param {string} templateName The id of the template.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public deleteTemplate(templateName: string, options?: any) {
        return TemplatesApiFp(this.configuration).deleteTemplate(templateName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the requested template.
     * @param {string} templateName The id of the template.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public getTemplate(templateName: string, options?: any) {
        return TemplatesApiFp(this.configuration).getTemplate(templateName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of templates.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME'} [sortBy] Sort templates by the given field.   - SORT_BY_UNSPECIFIED: Returns templates in an unsorted list.  - SORT_BY_NAME: Returns templates sorted by name.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order templates in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of templates before returning results. Negative values denote number of templates to skip from the end before returning results.
     * @param {number} [limit] Limit the number of templates. A value of 0 denotes no limit.
     * @param {string} [name] Limit templates to those that match the name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public getTemplates(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_NAME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, options?: any) {
        return TemplatesApiFp(this.configuration).getTemplates(sortBy, orderBy, offset, limit, name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update or create (upsert) the requested template.
     * @param {string} templateName The name of the template.
     * @param {V1Template} body The template to put.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public putTemplate(templateName: string, body: V1Template, options?: any) {
        return TemplatesApiFp(this.configuration).putTemplate(templateName, body, options)(this.fetch, this.basePath);
    }

}

/**
 * TensorboardsApi - fetch parameter creator
 * @export
 */
export const TensorboardsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the requested tensorboard.
         * @param {string} tensorboardId The id of the tensorboard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTensorboard(tensorboardId: string, options: any = {}): FetchArgs {
            // verify required parameter 'tensorboardId' is not null or undefined
            if (tensorboardId === null || tensorboardId === undefined) {
                throw new RequiredError('tensorboardId','Required parameter tensorboardId was null or undefined when calling getTensorboard.');
            }
            const localVarPath = `/api/v1/tensorboards/{tensorboardId}`
                .replace(`{${"tensorboardId"}}`, encodeURIComponent(String(tensorboardId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of tensorboards.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort tensorboards by the given field.   - SORT_BY_UNSPECIFIED: Returns tensorboards in an unsorted list.  - SORT_BY_ID: Returns tensorboards sorted by id.  - SORT_BY_DESCRIPTION: Returns tensorboards sorted by description.  - SORT_BY_START_TIME: Return tensorboards sorted by start time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order tensorboards in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of tensorboards before returning results. Negative values denote number of tensorboards to skip from the end before returning results.
         * @param {number} [limit] Limit the number of tensorboards. A value of 0 denotes no limit.
         * @param {Array<string>} [users] Limit tensorboards to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit tensorboards to those that are owned by users with the specified userIds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTensorboards(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/tensorboards`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kill the requested tensorboard.
         * @param {string} tensorboardId The id of the tensorboard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killTensorboard(tensorboardId: string, options: any = {}): FetchArgs {
            // verify required parameter 'tensorboardId' is not null or undefined
            if (tensorboardId === null || tensorboardId === undefined) {
                throw new RequiredError('tensorboardId','Required parameter tensorboardId was null or undefined when calling killTensorboard.');
            }
            const localVarPath = `/api/v1/tensorboards/{tensorboardId}/kill`
                .replace(`{${"tensorboardId"}}`, encodeURIComponent(String(tensorboardId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Launch a tensorboard.
         * @param {V1LaunchTensorboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchTensorboard(body: V1LaunchTensorboardRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling launchTensorboard.');
            }
            const localVarPath = `/api/v1/tensorboards`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1LaunchTensorboardRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the priority of the requested TensorBoard.
         * @param {string} tensorboardId The id of the TensorBoard.
         * @param {V1SetTensorboardPriorityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTensorboardPriority(tensorboardId: string, body: V1SetTensorboardPriorityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'tensorboardId' is not null or undefined
            if (tensorboardId === null || tensorboardId === undefined) {
                throw new RequiredError('tensorboardId','Required parameter tensorboardId was null or undefined when calling setTensorboardPriority.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setTensorboardPriority.');
            }
            const localVarPath = `/api/v1/tensorboards/{tensorboardId}/set_priority`
                .replace(`{${"tensorboardId"}}`, encodeURIComponent(String(tensorboardId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1SetTensorboardPriorityRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TensorboardsApi - functional programming interface
 * @export
 */
export const TensorboardsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the requested tensorboard.
         * @param {string} tensorboardId The id of the tensorboard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTensorboard(tensorboardId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetTensorboardResponse> {
            const localVarFetchArgs = TensorboardsApiFetchParamCreator(configuration).getTensorboard(tensorboardId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of tensorboards.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort tensorboards by the given field.   - SORT_BY_UNSPECIFIED: Returns tensorboards in an unsorted list.  - SORT_BY_ID: Returns tensorboards sorted by id.  - SORT_BY_DESCRIPTION: Returns tensorboards sorted by description.  - SORT_BY_START_TIME: Return tensorboards sorted by start time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order tensorboards in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of tensorboards before returning results. Negative values denote number of tensorboards to skip from the end before returning results.
         * @param {number} [limit] Limit the number of tensorboards. A value of 0 denotes no limit.
         * @param {Array<string>} [users] Limit tensorboards to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit tensorboards to those that are owned by users with the specified userIds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTensorboards(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetTensorboardsResponse> {
            const localVarFetchArgs = TensorboardsApiFetchParamCreator(configuration).getTensorboards(sortBy, orderBy, offset, limit, users, userIds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Kill the requested tensorboard.
         * @param {string} tensorboardId The id of the tensorboard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killTensorboard(tensorboardId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1KillTensorboardResponse> {
            const localVarFetchArgs = TensorboardsApiFetchParamCreator(configuration).killTensorboard(tensorboardId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Launch a tensorboard.
         * @param {V1LaunchTensorboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchTensorboard(body: V1LaunchTensorboardRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1LaunchTensorboardResponse> {
            const localVarFetchArgs = TensorboardsApiFetchParamCreator(configuration).launchTensorboard(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Set the priority of the requested TensorBoard.
         * @param {string} tensorboardId The id of the TensorBoard.
         * @param {V1SetTensorboardPriorityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTensorboardPriority(tensorboardId: string, body: V1SetTensorboardPriorityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1SetTensorboardPriorityResponse> {
            const localVarFetchArgs = TensorboardsApiFetchParamCreator(configuration).setTensorboardPriority(tensorboardId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TensorboardsApi - factory interface
 * @export
 */
export const TensorboardsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get the requested tensorboard.
         * @param {string} tensorboardId The id of the tensorboard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTensorboard(tensorboardId: string, options?: any) {
            return TensorboardsApiFp(configuration).getTensorboard(tensorboardId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of tensorboards.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort tensorboards by the given field.   - SORT_BY_UNSPECIFIED: Returns tensorboards in an unsorted list.  - SORT_BY_ID: Returns tensorboards sorted by id.  - SORT_BY_DESCRIPTION: Returns tensorboards sorted by description.  - SORT_BY_START_TIME: Return tensorboards sorted by start time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order tensorboards in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of tensorboards before returning results. Negative values denote number of tensorboards to skip from the end before returning results.
         * @param {number} [limit] Limit the number of tensorboards. A value of 0 denotes no limit.
         * @param {Array<string>} [users] Limit tensorboards to those that are owned by users with the specified usernames.
         * @param {Array<number>} [userIds] Limit tensorboards to those that are owned by users with the specified userIds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTensorboards(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options?: any) {
            return TensorboardsApiFp(configuration).getTensorboards(sortBy, orderBy, offset, limit, users, userIds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Kill the requested tensorboard.
         * @param {string} tensorboardId The id of the tensorboard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killTensorboard(tensorboardId: string, options?: any) {
            return TensorboardsApiFp(configuration).killTensorboard(tensorboardId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Launch a tensorboard.
         * @param {V1LaunchTensorboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchTensorboard(body: V1LaunchTensorboardRequest, options?: any) {
            return TensorboardsApiFp(configuration).launchTensorboard(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Set the priority of the requested TensorBoard.
         * @param {string} tensorboardId The id of the TensorBoard.
         * @param {V1SetTensorboardPriorityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTensorboardPriority(tensorboardId: string, body: V1SetTensorboardPriorityRequest, options?: any) {
            return TensorboardsApiFp(configuration).setTensorboardPriority(tensorboardId, body, options)(fetch, basePath);
        },
    };
};

/**
 * TensorboardsApi - object-oriented interface
 * @export
 * @class TensorboardsApi
 * @extends {BaseAPI}
 */
export class TensorboardsApi extends BaseAPI {
    /**
     * 
     * @summary Get the requested tensorboard.
     * @param {string} tensorboardId The id of the tensorboard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TensorboardsApi
     */
    public getTensorboard(tensorboardId: string, options?: any) {
        return TensorboardsApiFp(this.configuration).getTensorboard(tensorboardId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of tensorboards.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME'} [sortBy] Sort tensorboards by the given field.   - SORT_BY_UNSPECIFIED: Returns tensorboards in an unsorted list.  - SORT_BY_ID: Returns tensorboards sorted by id.  - SORT_BY_DESCRIPTION: Returns tensorboards sorted by description.  - SORT_BY_START_TIME: Return tensorboards sorted by start time.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order tensorboards in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of tensorboards before returning results. Negative values denote number of tensorboards to skip from the end before returning results.
     * @param {number} [limit] Limit the number of tensorboards. A value of 0 denotes no limit.
     * @param {Array<string>} [users] Limit tensorboards to those that are owned by users with the specified usernames.
     * @param {Array<number>} [userIds] Limit tensorboards to those that are owned by users with the specified userIds.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TensorboardsApi
     */
    public getTensorboards(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_START_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, users?: Array<string>, userIds?: Array<number>, options?: any) {
        return TensorboardsApiFp(this.configuration).getTensorboards(sortBy, orderBy, offset, limit, users, userIds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Kill the requested tensorboard.
     * @param {string} tensorboardId The id of the tensorboard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TensorboardsApi
     */
    public killTensorboard(tensorboardId: string, options?: any) {
        return TensorboardsApiFp(this.configuration).killTensorboard(tensorboardId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Launch a tensorboard.
     * @param {V1LaunchTensorboardRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TensorboardsApi
     */
    public launchTensorboard(body: V1LaunchTensorboardRequest, options?: any) {
        return TensorboardsApiFp(this.configuration).launchTensorboard(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Set the priority of the requested TensorBoard.
     * @param {string} tensorboardId The id of the TensorBoard.
     * @param {V1SetTensorboardPriorityRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TensorboardsApi
     */
    public setTensorboardPriority(tensorboardId: string, body: V1SetTensorboardPriorityRequest, options?: any) {
        return TensorboardsApiFp(this.configuration).setTensorboardPriority(tensorboardId, body, options)(this.fetch, this.basePath);
    }

}

/**
 * TrialComparisonApi - fetch parameter creator
 * @export
 */
export const TrialComparisonApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Trial Collection for a set of TrialFilters.
         * @param {V1CreateTrialsCollectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrialsCollection(body: V1CreateTrialsCollectionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTrialsCollection.');
            }
            const localVarPath = `/api/v1/trial-comparison/collections`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1CreateTrialsCollectionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a TrialCollection.
         * @param {number} [id] The id for the TrialsCollection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrialsCollection(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/trial-comparison/collections`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return all collectiions for a specific project.
         * @param {number} [projectId] The id of the project for which to retrieve all collections.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialsCollections(projectId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/trial-comparison/collections`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify collection attributes: name, filters, etc.
         * @param {V1PatchTrialsCollectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTrialsCollection(body: V1PatchTrialsCollectionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling patchTrialsCollection.');
            }
            const localVarPath = `/api/v1/trial-comparison/collections`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PatchTrialsCollectionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return trials matching a set of TrialFilters.
         * @param {V1QueryTrialsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryTrials(body: V1QueryTrialsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling queryTrials.');
            }
            const localVarPath = `/api/v1/trial-comparison/query`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1QueryTrialsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update tags for trials by IDs or filters.
         * @param {V1UpdateTrialTagsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrialTags(body: V1UpdateTrialTagsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateTrialTags.');
            }
            const localVarPath = `/api/v1/trial-comparison/update-trial-tags`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1UpdateTrialTagsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrialComparisonApi - functional programming interface
 * @export
 */
export const TrialComparisonApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Trial Collection for a set of TrialFilters.
         * @param {V1CreateTrialsCollectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrialsCollection(body: V1CreateTrialsCollectionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1CreateTrialsCollectionResponse> {
            const localVarFetchArgs = TrialComparisonApiFetchParamCreator(configuration).createTrialsCollection(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Removes a TrialCollection.
         * @param {number} [id] The id for the TrialsCollection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrialsCollection(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1DeleteTrialsCollectionResponse> {
            const localVarFetchArgs = TrialComparisonApiFetchParamCreator(configuration).deleteTrialsCollection(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return all collectiions for a specific project.
         * @param {number} [projectId] The id of the project for which to retrieve all collections.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialsCollections(projectId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetTrialsCollectionsResponse> {
            const localVarFetchArgs = TrialComparisonApiFetchParamCreator(configuration).getTrialsCollections(projectId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Modify collection attributes: name, filters, etc.
         * @param {V1PatchTrialsCollectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTrialsCollection(body: V1PatchTrialsCollectionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PatchTrialsCollectionResponse> {
            const localVarFetchArgs = TrialComparisonApiFetchParamCreator(configuration).patchTrialsCollection(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return trials matching a set of TrialFilters.
         * @param {V1QueryTrialsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryTrials(body: V1QueryTrialsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1QueryTrialsResponse> {
            const localVarFetchArgs = TrialComparisonApiFetchParamCreator(configuration).queryTrials(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update tags for trials by IDs or filters.
         * @param {V1UpdateTrialTagsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrialTags(body: V1UpdateTrialTagsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1UpdateTrialTagsResponse> {
            const localVarFetchArgs = TrialComparisonApiFetchParamCreator(configuration).updateTrialTags(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TrialComparisonApi - factory interface
 * @export
 */
export const TrialComparisonApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create a Trial Collection for a set of TrialFilters.
         * @param {V1CreateTrialsCollectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrialsCollection(body: V1CreateTrialsCollectionRequest, options?: any) {
            return TrialComparisonApiFp(configuration).createTrialsCollection(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Removes a TrialCollection.
         * @param {number} [id] The id for the TrialsCollection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrialsCollection(id?: number, options?: any) {
            return TrialComparisonApiFp(configuration).deleteTrialsCollection(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return all collectiions for a specific project.
         * @param {number} [projectId] The id of the project for which to retrieve all collections.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialsCollections(projectId?: number, options?: any) {
            return TrialComparisonApiFp(configuration).getTrialsCollections(projectId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Modify collection attributes: name, filters, etc.
         * @param {V1PatchTrialsCollectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTrialsCollection(body: V1PatchTrialsCollectionRequest, options?: any) {
            return TrialComparisonApiFp(configuration).patchTrialsCollection(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return trials matching a set of TrialFilters.
         * @param {V1QueryTrialsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryTrials(body: V1QueryTrialsRequest, options?: any) {
            return TrialComparisonApiFp(configuration).queryTrials(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update tags for trials by IDs or filters.
         * @param {V1UpdateTrialTagsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrialTags(body: V1UpdateTrialTagsRequest, options?: any) {
            return TrialComparisonApiFp(configuration).updateTrialTags(body, options)(fetch, basePath);
        },
    };
};

/**
 * TrialComparisonApi - object-oriented interface
 * @export
 * @class TrialComparisonApi
 * @extends {BaseAPI}
 */
export class TrialComparisonApi extends BaseAPI {
    /**
     * 
     * @summary Create a Trial Collection for a set of TrialFilters.
     * @param {V1CreateTrialsCollectionRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialComparisonApi
     */
    public createTrialsCollection(body: V1CreateTrialsCollectionRequest, options?: any) {
        return TrialComparisonApiFp(this.configuration).createTrialsCollection(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Removes a TrialCollection.
     * @param {number} [id] The id for the TrialsCollection.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialComparisonApi
     */
    public deleteTrialsCollection(id?: number, options?: any) {
        return TrialComparisonApiFp(this.configuration).deleteTrialsCollection(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return all collectiions for a specific project.
     * @param {number} [projectId] The id of the project for which to retrieve all collections.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialComparisonApi
     */
    public getTrialsCollections(projectId?: number, options?: any) {
        return TrialComparisonApiFp(this.configuration).getTrialsCollections(projectId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Modify collection attributes: name, filters, etc.
     * @param {V1PatchTrialsCollectionRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialComparisonApi
     */
    public patchTrialsCollection(body: V1PatchTrialsCollectionRequest, options?: any) {
        return TrialComparisonApiFp(this.configuration).patchTrialsCollection(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return trials matching a set of TrialFilters.
     * @param {V1QueryTrialsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialComparisonApi
     */
    public queryTrials(body: V1QueryTrialsRequest, options?: any) {
        return TrialComparisonApiFp(this.configuration).queryTrials(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update tags for trials by IDs or filters.
     * @param {V1UpdateTrialTagsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialComparisonApi
     */
    public updateTrialTags(body: V1UpdateTrialTagsRequest, options?: any) {
        return TrialComparisonApiFp(this.configuration).updateTrialTags(body, options)(this.fetch, this.basePath);
    }

}

/**
 * TrialsApi - fetch parameter creator
 * @export
 */
export const TrialsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the list of trials for an experiment.
         * @param {number} experimentId Limit trials to those that are owned by the specified experiments.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS'} [sortBy] Sort trials by the given field.   - SORT_BY_UNSPECIFIED: Returns trials in an unsorted list.  - SORT_BY_ID: Returns trials sorted by id.  - SORT_BY_START_TIME: Return trials sorted by start time.  - SORT_BY_END_TIME: Return trials sorted by end time. Trials without end times are returned after trials that are.  - SORT_BY_STATE: Return trials sorted by state.  - SORT_BY_BEST_VALIDATION_METRIC: Return the trials sorted by the best metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_LATEST_VALIDATION_METRIC: Return the trials sorted by the latest metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_BATCHES_PROCESSED: Return the trials sorted by the number of batches completed.  - SORT_BY_DURATION: Return the trials sorted by the total duration.  - SORT_BY_RESTARTS: Return the trials sorted by the number of restarts.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order trials in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of trials before returning results. Negative values denote number of trials to skip from the end before returning results.
         * @param {number} [limit] Limit the number of trials. A value of 0 denotes no limit.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>} [states] Limit trials to those that match the provided state.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentTrials(experimentId: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>, options: any = {}): FetchArgs {
            // verify required parameter 'experimentId' is not null or undefined
            if (experimentId === null || experimentId === undefined) {
                throw new RequiredError('experimentId','Required parameter experimentId was null or undefined when calling getExperimentTrials.');
            }
            const localVarPath = `/api/v1/experiments/{experimentId}/trials`
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single trial.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrial(trialId: number, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling getTrial.');
            }
            const localVarPath = `/api/v1/trials/{trialId}`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of workloads for a trial.
         * @param {number} trialId Limit workloads to those that are owned by the specified trial.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order workloads in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of workloads before returning results. Negative values denote number of workloads to skip from the end before returning results.
         * @param {number} [limit] Limit the number of workloads. A value of 0 denotes no limit.
         * @param {string} [sortKey] Sort workloads by batches, a training metric, or a validation metric.
         * @param {'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION'} [filter] Filter workloads with validation and/or checkpoint information.   - FILTER_OPTION_UNSPECIFIED: Any workload.  - FILTER_OPTION_CHECKPOINT: Only workloads with an associated checkpoint.  - FILTER_OPTION_VALIDATION: Only validation workloads.  - FILTER_OPTION_CHECKPOINT_OR_VALIDATION: Only validation workloads or ones with an associated checkpoint.
         * @param {boolean} [includeBatchMetrics] Include per-batch metrics.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] When sorting workloads by sort_key, specify training or validation form of a metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialWorkloads(trialId: number, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, sortKey?: string, filter?: 'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION', includeBatchMetrics?: boolean, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling getTrialWorkloads.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/workloads`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortKey !== undefined) {
                localVarQueryParameter['sortKey'] = sortKey;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (includeBatchMetrics !== undefined) {
                localVarQueryParameter['includeBatchMetrics'] = includeBatchMetrics;
            }

            if (metricType !== undefined) {
                localVarQueryParameter['metricType'] = metricType;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kill a trial.
         * @param {number} id The trial id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killTrial(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling killTrial.');
            }
            const localVarPath = `/api/v1/trials/{id}/kill`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Downsample metrics collected during a trial.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {number} [maxDatapoints] The maximum number of data points to return after downsampling.
         * @param {Array<string>} [metricNames] The names of selected metrics.
         * @param {number} [startBatches] Sample from metrics after this batch number.
         * @param {number} [endBatches] Sample from metrics before this batch number.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] Type of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG'} [scale] Scale of metric visualization (linear or log scale).   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summarizeTrial(trialId: number, maxDatapoints?: number, metricNames?: Array<string>, startBatches?: number, endBatches?: number, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', scale?: 'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG', options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling summarizeTrial.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/summarize`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (maxDatapoints !== undefined) {
                localVarQueryParameter['maxDatapoints'] = maxDatapoints;
            }

            if (metricNames) {
                localVarQueryParameter['metricNames'] = metricNames;
            }

            if (startBatches !== undefined) {
                localVarQueryParameter['startBatches'] = startBatches;
            }

            if (endBatches !== undefined) {
                localVarQueryParameter['endBatches'] = endBatches;
            }

            if (metricType !== undefined) {
                localVarQueryParameter['metricType'] = metricType;
            }

            if (scale !== undefined) {
                localVarQueryParameter['scale'] = scale;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stream trial logs.
         * @param {number} trialId The id of the trial.
         * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the trial stops.
         * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
         * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
         * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
         * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
         * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
         * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
         * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
         * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {string} [searchText] Search the logs by whether the text contains a substring.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialLogs(trialId: number, limit?: number, follow?: boolean, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling trialLogs.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/logs`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (follow !== undefined) {
                localVarQueryParameter['follow'] = follow;
            }

            if (agentIds) {
                localVarQueryParameter['agentIds'] = agentIds;
            }

            if (containerIds) {
                localVarQueryParameter['containerIds'] = containerIds;
            }

            if (rankIds) {
                localVarQueryParameter['rankIds'] = rankIds;
            }

            if (levels) {
                localVarQueryParameter['levels'] = levels;
            }

            if (stdtypes) {
                localVarQueryParameter['stdtypes'] = stdtypes;
            }

            if (sources) {
                localVarQueryParameter['sources'] = sources;
            }

            if (timestampBefore !== undefined) {
                localVarQueryParameter['timestampBefore'] = (timestampBefore as any).toISOString();
            }

            if (timestampAfter !== undefined) {
                localVarQueryParameter['timestampAfter'] = (timestampAfter as any).toISOString();
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stream trial log fields.
         * @param {number} trialId The ID of the trial.
         * @param {boolean} [follow] Continue following fields until the trial stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialLogsFields(trialId: number, follow?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'trialId' is not null or undefined
            if (trialId === null || trialId === undefined) {
                throw new RequiredError('trialId','Required parameter trialId was null or undefined when calling trialLogsFields.');
            }
            const localVarPath = `/api/v1/trials/{trialId}/logs/fields`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (follow !== undefined) {
                localVarQueryParameter['follow'] = follow;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrialsApi - functional programming interface
 * @export
 */
export const TrialsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the list of trials for an experiment.
         * @param {number} experimentId Limit trials to those that are owned by the specified experiments.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS'} [sortBy] Sort trials by the given field.   - SORT_BY_UNSPECIFIED: Returns trials in an unsorted list.  - SORT_BY_ID: Returns trials sorted by id.  - SORT_BY_START_TIME: Return trials sorted by start time.  - SORT_BY_END_TIME: Return trials sorted by end time. Trials without end times are returned after trials that are.  - SORT_BY_STATE: Return trials sorted by state.  - SORT_BY_BEST_VALIDATION_METRIC: Return the trials sorted by the best metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_LATEST_VALIDATION_METRIC: Return the trials sorted by the latest metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_BATCHES_PROCESSED: Return the trials sorted by the number of batches completed.  - SORT_BY_DURATION: Return the trials sorted by the total duration.  - SORT_BY_RESTARTS: Return the trials sorted by the number of restarts.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order trials in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of trials before returning results. Negative values denote number of trials to skip from the end before returning results.
         * @param {number} [limit] Limit the number of trials. A value of 0 denotes no limit.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>} [states] Limit trials to those that match the provided state.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentTrials(experimentId: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetExperimentTrialsResponse> {
            const localVarFetchArgs = TrialsApiFetchParamCreator(configuration).getExperimentTrials(experimentId, sortBy, orderBy, offset, limit, states, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a single trial.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrial(trialId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetTrialResponse> {
            const localVarFetchArgs = TrialsApiFetchParamCreator(configuration).getTrial(trialId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the list of workloads for a trial.
         * @param {number} trialId Limit workloads to those that are owned by the specified trial.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order workloads in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of workloads before returning results. Negative values denote number of workloads to skip from the end before returning results.
         * @param {number} [limit] Limit the number of workloads. A value of 0 denotes no limit.
         * @param {string} [sortKey] Sort workloads by batches, a training metric, or a validation metric.
         * @param {'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION'} [filter] Filter workloads with validation and/or checkpoint information.   - FILTER_OPTION_UNSPECIFIED: Any workload.  - FILTER_OPTION_CHECKPOINT: Only workloads with an associated checkpoint.  - FILTER_OPTION_VALIDATION: Only validation workloads.  - FILTER_OPTION_CHECKPOINT_OR_VALIDATION: Only validation workloads or ones with an associated checkpoint.
         * @param {boolean} [includeBatchMetrics] Include per-batch metrics.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] When sorting workloads by sort_key, specify training or validation form of a metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialWorkloads(trialId: number, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, sortKey?: string, filter?: 'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION', includeBatchMetrics?: boolean, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetTrialWorkloadsResponse> {
            const localVarFetchArgs = TrialsApiFetchParamCreator(configuration).getTrialWorkloads(trialId, orderBy, offset, limit, sortKey, filter, includeBatchMetrics, metricType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Kill a trial.
         * @param {number} id The trial id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killTrial(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1KillTrialResponse> {
            const localVarFetchArgs = TrialsApiFetchParamCreator(configuration).killTrial(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Downsample metrics collected during a trial.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {number} [maxDatapoints] The maximum number of data points to return after downsampling.
         * @param {Array<string>} [metricNames] The names of selected metrics.
         * @param {number} [startBatches] Sample from metrics after this batch number.
         * @param {number} [endBatches] Sample from metrics before this batch number.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] Type of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG'} [scale] Scale of metric visualization (linear or log scale).   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summarizeTrial(trialId: number, maxDatapoints?: number, metricNames?: Array<string>, startBatches?: number, endBatches?: number, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', scale?: 'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1SummarizeTrialResponse> {
            const localVarFetchArgs = TrialsApiFetchParamCreator(configuration).summarizeTrial(trialId, maxDatapoints, metricNames, startBatches, endBatches, metricType, scale, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Stream trial logs.
         * @param {number} trialId The id of the trial.
         * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the trial stops.
         * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
         * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
         * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
         * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
         * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
         * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
         * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
         * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {string} [searchText] Search the logs by whether the text contains a substring.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialLogs(trialId: number, limit?: number, follow?: boolean, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1TrialLogsResponse> {
            const localVarFetchArgs = TrialsApiFetchParamCreator(configuration).trialLogs(trialId, limit, follow, agentIds, containerIds, rankIds, levels, stdtypes, sources, timestampBefore, timestampAfter, orderBy, searchText, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Stream trial log fields.
         * @param {number} trialId The ID of the trial.
         * @param {boolean} [follow] Continue following fields until the trial stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialLogsFields(trialId: number, follow?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StreamResultOfV1TrialLogsFieldsResponse> {
            const localVarFetchArgs = TrialsApiFetchParamCreator(configuration).trialLogsFields(trialId, follow, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TrialsApi - factory interface
 * @export
 */
export const TrialsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get the list of trials for an experiment.
         * @param {number} experimentId Limit trials to those that are owned by the specified experiments.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS'} [sortBy] Sort trials by the given field.   - SORT_BY_UNSPECIFIED: Returns trials in an unsorted list.  - SORT_BY_ID: Returns trials sorted by id.  - SORT_BY_START_TIME: Return trials sorted by start time.  - SORT_BY_END_TIME: Return trials sorted by end time. Trials without end times are returned after trials that are.  - SORT_BY_STATE: Return trials sorted by state.  - SORT_BY_BEST_VALIDATION_METRIC: Return the trials sorted by the best metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_LATEST_VALIDATION_METRIC: Return the trials sorted by the latest metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_BATCHES_PROCESSED: Return the trials sorted by the number of batches completed.  - SORT_BY_DURATION: Return the trials sorted by the total duration.  - SORT_BY_RESTARTS: Return the trials sorted by the number of restarts.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order trials in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of trials before returning results. Negative values denote number of trials to skip from the end before returning results.
         * @param {number} [limit] Limit the number of trials. A value of 0 denotes no limit.
         * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>} [states] Limit trials to those that match the provided state.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentTrials(experimentId: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>, options?: any) {
            return TrialsApiFp(configuration).getExperimentTrials(experimentId, sortBy, orderBy, offset, limit, states, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a single trial.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrial(trialId: number, options?: any) {
            return TrialsApiFp(configuration).getTrial(trialId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the list of workloads for a trial.
         * @param {number} trialId Limit workloads to those that are owned by the specified trial.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order workloads in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of workloads before returning results. Negative values denote number of workloads to skip from the end before returning results.
         * @param {number} [limit] Limit the number of workloads. A value of 0 denotes no limit.
         * @param {string} [sortKey] Sort workloads by batches, a training metric, or a validation metric.
         * @param {'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION'} [filter] Filter workloads with validation and/or checkpoint information.   - FILTER_OPTION_UNSPECIFIED: Any workload.  - FILTER_OPTION_CHECKPOINT: Only workloads with an associated checkpoint.  - FILTER_OPTION_VALIDATION: Only validation workloads.  - FILTER_OPTION_CHECKPOINT_OR_VALIDATION: Only validation workloads or ones with an associated checkpoint.
         * @param {boolean} [includeBatchMetrics] Include per-batch metrics.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] When sorting workloads by sort_key, specify training or validation form of a metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialWorkloads(trialId: number, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, sortKey?: string, filter?: 'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION', includeBatchMetrics?: boolean, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', options?: any) {
            return TrialsApiFp(configuration).getTrialWorkloads(trialId, orderBy, offset, limit, sortKey, filter, includeBatchMetrics, metricType, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Kill a trial.
         * @param {number} id The trial id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killTrial(id: number, options?: any) {
            return TrialsApiFp(configuration).killTrial(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Downsample metrics collected during a trial.
         * @param {number} trialId The requested trial&#39;s id.
         * @param {number} [maxDatapoints] The maximum number of data points to return after downsampling.
         * @param {Array<string>} [metricNames] The names of selected metrics.
         * @param {number} [startBatches] Sample from metrics after this batch number.
         * @param {number} [endBatches] Sample from metrics before this batch number.
         * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] Type of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
         * @param {'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG'} [scale] Scale of metric visualization (linear or log scale).   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summarizeTrial(trialId: number, maxDatapoints?: number, metricNames?: Array<string>, startBatches?: number, endBatches?: number, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', scale?: 'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG', options?: any) {
            return TrialsApiFp(configuration).summarizeTrial(trialId, maxDatapoints, metricNames, startBatches, endBatches, metricType, scale, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Stream trial logs.
         * @param {number} trialId The id of the trial.
         * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
         * @param {boolean} [follow] Continue following logs until the trial stops.
         * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
         * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
         * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
         * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
         * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
         * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
         * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
         * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {string} [searchText] Search the logs by whether the text contains a substring.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialLogs(trialId: number, limit?: number, follow?: boolean, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options?: any) {
            return TrialsApiFp(configuration).trialLogs(trialId, limit, follow, agentIds, containerIds, rankIds, levels, stdtypes, sources, timestampBefore, timestampAfter, orderBy, searchText, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Stream trial log fields.
         * @param {number} trialId The ID of the trial.
         * @param {boolean} [follow] Continue following fields until the trial stops.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trialLogsFields(trialId: number, follow?: boolean, options?: any) {
            return TrialsApiFp(configuration).trialLogsFields(trialId, follow, options)(fetch, basePath);
        },
    };
};

/**
 * TrialsApi - object-oriented interface
 * @export
 * @class TrialsApi
 * @extends {BaseAPI}
 */
export class TrialsApi extends BaseAPI {
    /**
     * 
     * @summary Get the list of trials for an experiment.
     * @param {number} experimentId Limit trials to those that are owned by the specified experiments.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS'} [sortBy] Sort trials by the given field.   - SORT_BY_UNSPECIFIED: Returns trials in an unsorted list.  - SORT_BY_ID: Returns trials sorted by id.  - SORT_BY_START_TIME: Return trials sorted by start time.  - SORT_BY_END_TIME: Return trials sorted by end time. Trials without end times are returned after trials that are.  - SORT_BY_STATE: Return trials sorted by state.  - SORT_BY_BEST_VALIDATION_METRIC: Return the trials sorted by the best metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_LATEST_VALIDATION_METRIC: Return the trials sorted by the latest metric so far, where the metric is specified by &#x60;searcher.metric&#x60; in the experiment configuration.  - SORT_BY_BATCHES_PROCESSED: Return the trials sorted by the number of batches completed.  - SORT_BY_DURATION: Return the trials sorted by the total duration.  - SORT_BY_RESTARTS: Return the trials sorted by the number of restarts.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order trials in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of trials before returning results. Negative values denote number of trials to skip from the end before returning results.
     * @param {number} [limit] Limit the number of trials. A value of 0 denotes no limit.
     * @param {Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>} [states] Limit trials to those that match the provided state.   - STATE_UNSPECIFIED: The state of the experiment is unknown.  - STATE_ACTIVE: The experiment is in an active state.  - STATE_PAUSED: The experiment is in a paused state  - STATE_STOPPING_COMPLETED: The experiment is completed and is shutting down.  - STATE_STOPPING_CANCELED: The experiment is canceled and is shutting down.  - STATE_STOPPING_ERROR: The experiment is errored and is shutting down.  - STATE_COMPLETED: The experiment is completed and is shut down.  - STATE_CANCELED: The experiment is canceled and is shut down.  - STATE_ERROR: The experiment is errored and is shut down.  - STATE_DELETED: The experiment has been deleted.  - STATE_DELETING: The experiment is deleting.  - STATE_DELETE_FAILED: The experiment failed to delete.  - STATE_STOPPING_KILLED: The experiment is killed and is shutting down.  - STATE_QUEUED: The experiment is queued (waiting to be run, or job state is still queued). Queued is a substate of the Active state.  - STATE_PULLING: The experiment is pulling the image. Pulling is a substate of the Active state.  - STATE_STARTING: The experiment is preparing the environment after finishing pulling the image. Starting is a substate of the Active state.  - STATE_RUNNING: The experiment has an allocation actively running. Running is a substate of the Active state.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialsApi
     */
    public getExperimentTrials(experimentId: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_START_TIME' | 'SORT_BY_END_TIME' | 'SORT_BY_STATE' | 'SORT_BY_BEST_VALIDATION_METRIC' | 'SORT_BY_LATEST_VALIDATION_METRIC' | 'SORT_BY_BATCHES_PROCESSED' | 'SORT_BY_DURATION' | 'SORT_BY_RESTARTS', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, states?: Array<'STATE_UNSPECIFIED' | 'STATE_ACTIVE' | 'STATE_PAUSED' | 'STATE_STOPPING_COMPLETED' | 'STATE_STOPPING_CANCELED' | 'STATE_STOPPING_ERROR' | 'STATE_COMPLETED' | 'STATE_CANCELED' | 'STATE_ERROR' | 'STATE_DELETED' | 'STATE_DELETING' | 'STATE_DELETE_FAILED' | 'STATE_STOPPING_KILLED' | 'STATE_QUEUED' | 'STATE_PULLING' | 'STATE_STARTING' | 'STATE_RUNNING'>, options?: any) {
        return TrialsApiFp(this.configuration).getExperimentTrials(experimentId, sortBy, orderBy, offset, limit, states, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a single trial.
     * @param {number} trialId The requested trial&#39;s id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialsApi
     */
    public getTrial(trialId: number, options?: any) {
        return TrialsApiFp(this.configuration).getTrial(trialId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the list of workloads for a trial.
     * @param {number} trialId Limit workloads to those that are owned by the specified trial.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order workloads in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of workloads before returning results. Negative values denote number of workloads to skip from the end before returning results.
     * @param {number} [limit] Limit the number of workloads. A value of 0 denotes no limit.
     * @param {string} [sortKey] Sort workloads by batches, a training metric, or a validation metric.
     * @param {'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION'} [filter] Filter workloads with validation and/or checkpoint information.   - FILTER_OPTION_UNSPECIFIED: Any workload.  - FILTER_OPTION_CHECKPOINT: Only workloads with an associated checkpoint.  - FILTER_OPTION_VALIDATION: Only validation workloads.  - FILTER_OPTION_CHECKPOINT_OR_VALIDATION: Only validation workloads or ones with an associated checkpoint.
     * @param {boolean} [includeBatchMetrics] Include per-batch metrics.
     * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] When sorting workloads by sort_key, specify training or validation form of a metric.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialsApi
     */
    public getTrialWorkloads(trialId: number, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, sortKey?: string, filter?: 'FILTER_OPTION_UNSPECIFIED' | 'FILTER_OPTION_CHECKPOINT' | 'FILTER_OPTION_VALIDATION' | 'FILTER_OPTION_CHECKPOINT_OR_VALIDATION', includeBatchMetrics?: boolean, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', options?: any) {
        return TrialsApiFp(this.configuration).getTrialWorkloads(trialId, orderBy, offset, limit, sortKey, filter, includeBatchMetrics, metricType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Kill a trial.
     * @param {number} id The trial id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialsApi
     */
    public killTrial(id: number, options?: any) {
        return TrialsApiFp(this.configuration).killTrial(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Downsample metrics collected during a trial.
     * @param {number} trialId The requested trial&#39;s id.
     * @param {number} [maxDatapoints] The maximum number of data points to return after downsampling.
     * @param {Array<string>} [metricNames] The names of selected metrics.
     * @param {number} [startBatches] Sample from metrics after this batch number.
     * @param {number} [endBatches] Sample from metrics before this batch number.
     * @param {'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION'} [metricType] Type of metrics.   - METRIC_TYPE_UNSPECIFIED: Zero-value (not allowed).  - METRIC_TYPE_TRAINING: For metrics emitted during training.  - METRIC_TYPE_VALIDATION: For metrics emitted during validation.
     * @param {'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG'} [scale] Scale of metric visualization (linear or log scale).   - SCALE_UNSPECIFIED: Unknown scale.  - SCALE_LINEAR: Downsample points with closeness plotted on a linear y-axis.  - SCALE_LOG: Downsample points with closeness plotted on a logarithmic y-axis.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialsApi
     */
    public summarizeTrial(trialId: number, maxDatapoints?: number, metricNames?: Array<string>, startBatches?: number, endBatches?: number, metricType?: 'METRIC_TYPE_UNSPECIFIED' | 'METRIC_TYPE_TRAINING' | 'METRIC_TYPE_VALIDATION', scale?: 'SCALE_UNSPECIFIED' | 'SCALE_LINEAR' | 'SCALE_LOG', options?: any) {
        return TrialsApiFp(this.configuration).summarizeTrial(trialId, maxDatapoints, metricNames, startBatches, endBatches, metricType, scale, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Stream trial logs.
     * @param {number} trialId The id of the trial.
     * @param {number} [limit] Limit the number of trial logs. A value of 0 denotes no limit.
     * @param {boolean} [follow] Continue following logs until the trial stops.
     * @param {Array<string>} [agentIds] Limit the trial logs to a subset of agents.
     * @param {Array<string>} [containerIds] Limit the trial logs to a subset of containers.
     * @param {Array<number>} [rankIds] Limit the trial logs to a subset of ranks.
     * @param {Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>} [levels] Limit the trial logs to a subset of agents.   - LOG_LEVEL_UNSPECIFIED: Unspecified log level.  - LOG_LEVEL_TRACE: A log level of TRACE.  - LOG_LEVEL_DEBUG: A log level of DEBUG.  - LOG_LEVEL_INFO: A log level of INFO.  - LOG_LEVEL_WARNING: A log level of WARNING.  - LOG_LEVEL_ERROR: A log level of ERROR.  - LOG_LEVEL_CRITICAL: A log level of CRITICAL.
     * @param {Array<string>} [stdtypes] Limit the trial logs to a subset of output streams.
     * @param {Array<string>} [sources] Limit the trial logs to a subset of sources.
     * @param {Date} [timestampBefore] Limit the trial logs to ones with a timestamp before a given time.
     * @param {Date} [timestampAfter] Limit the trial logs to ones with a timestamp after a given time.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order logs in either ascending or descending order by timestamp.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {string} [searchText] Search the logs by whether the text contains a substring.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialsApi
     */
    public trialLogs(trialId: number, limit?: number, follow?: boolean, agentIds?: Array<string>, containerIds?: Array<string>, rankIds?: Array<number>, levels?: Array<'LOG_LEVEL_UNSPECIFIED' | 'LOG_LEVEL_TRACE' | 'LOG_LEVEL_DEBUG' | 'LOG_LEVEL_INFO' | 'LOG_LEVEL_WARNING' | 'LOG_LEVEL_ERROR' | 'LOG_LEVEL_CRITICAL'>, stdtypes?: Array<string>, sources?: Array<string>, timestampBefore?: Date, timestampAfter?: Date, orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', searchText?: string, options?: any) {
        return TrialsApiFp(this.configuration).trialLogs(trialId, limit, follow, agentIds, containerIds, rankIds, levels, stdtypes, sources, timestampBefore, timestampAfter, orderBy, searchText, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Stream trial log fields.
     * @param {number} trialId The ID of the trial.
     * @param {boolean} [follow] Continue following fields until the trial stops.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialsApi
     */
    public trialLogsFields(trialId: number, follow?: boolean, options?: any) {
        return TrialsApiFp(this.configuration).trialLogsFields(trialId, follow, options)(this.fetch, this.basePath);
    }

}

/**
 * UsersApi - fetch parameter creator
 * @export
 */
export const UsersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the requested user.
         * @param {number} userId The id of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUser.');
            }
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user's settings for website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSetting(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/users/setting`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of users.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_DISPLAY_NAME' | 'SORT_BY_USER_NAME' | 'SORT_BY_ADMIN' | 'SORT_BY_ACTIVE' | 'SORT_BY_MODIFIED_TIME'} [sortBy] Sort users by the given field.   - SORT_BY_UNSPECIFIED: Returns users in an unsorted list.  - SORT_BY_DISPLAY_NAME: Returns users sorted by display name.  - SORT_BY_USER_NAME: Returns users sorted by user name.  - SORT_BY_ADMIN: Returns users sorted by if they are admin.  - SORT_BY_ACTIVE: Returns users sorted by if they are active.  - SORT_BY_MODIFIED_TIME: Returns users sorted by modified time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order users in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of projects before returning results. Negative values denote number of projects to skip from the end before returning results.
         * @param {number} [limit] Limit the number of projects. A value of 0 denotes no limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_DISPLAY_NAME' | 'SORT_BY_USER_NAME' | 'SORT_BY_ADMIN' | 'SORT_BY_ACTIVE' | 'SORT_BY_MODIFIED_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch a user's fields.
         * @param {number} userId The id of the user.
         * @param {V1PatchUser} body The updated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser(userId: number, body: V1PatchUser, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling patchUser.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling patchUser.');
            }
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PatchUser" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new user.
         * @param {V1PostUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser(body: V1PostUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postUser.');
            }
            const localVarPath = `/api/v1/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PostUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch a user's settings for website
         * @param {V1PostUserSettingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSetting(body: V1PostUserSettingRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postUserSetting.');
            }
            const localVarPath = `/api/v1/users/setting`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PostUserSettingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset a user's settings for website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserSetting(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/users/setting/reset`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the requested user's password.
         * @param {number} userId The id of the user.
         * @param {string} body The password of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPassword(userId: number, body: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling setUserPassword.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setUserPassword.');
            }
            const localVarPath = `/api/v1/users/{userId}/password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the requested user.
         * @param {number} userId The id of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetUserResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getUser(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a user's settings for website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSetting(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetUserSettingResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getUserSetting(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of users.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_DISPLAY_NAME' | 'SORT_BY_USER_NAME' | 'SORT_BY_ADMIN' | 'SORT_BY_ACTIVE' | 'SORT_BY_MODIFIED_TIME'} [sortBy] Sort users by the given field.   - SORT_BY_UNSPECIFIED: Returns users in an unsorted list.  - SORT_BY_DISPLAY_NAME: Returns users sorted by display name.  - SORT_BY_USER_NAME: Returns users sorted by user name.  - SORT_BY_ADMIN: Returns users sorted by if they are admin.  - SORT_BY_ACTIVE: Returns users sorted by if they are active.  - SORT_BY_MODIFIED_TIME: Returns users sorted by modified time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order users in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of projects before returning results. Negative values denote number of projects to skip from the end before returning results.
         * @param {number} [limit] Limit the number of projects. A value of 0 denotes no limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_DISPLAY_NAME' | 'SORT_BY_USER_NAME' | 'SORT_BY_ADMIN' | 'SORT_BY_ACTIVE' | 'SORT_BY_MODIFIED_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetUsersResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getUsers(sortBy, orderBy, offset, limit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Patch a user's fields.
         * @param {number} userId The id of the user.
         * @param {V1PatchUser} body The updated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser(userId: number, body: V1PatchUser, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PatchUserResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).patchUser(userId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create a new user.
         * @param {V1PostUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser(body: V1PostUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PostUserResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).postUser(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Patch a user's settings for website
         * @param {V1PostUserSettingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSetting(body: V1PostUserSettingRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PostUserSettingResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).postUserSetting(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Reset a user's settings for website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserSetting(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ResetUserSettingResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).resetUserSetting(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Set the requested user's password.
         * @param {number} userId The id of the user.
         * @param {string} body The password of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPassword(userId: number, body: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1SetUserPasswordResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).setUserPassword(userId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get the requested user.
         * @param {number} userId The id of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: number, options?: any) {
            return UsersApiFp(configuration).getUser(userId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a user's settings for website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSetting(options?: any) {
            return UsersApiFp(configuration).getUserSetting(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of users.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_DISPLAY_NAME' | 'SORT_BY_USER_NAME' | 'SORT_BY_ADMIN' | 'SORT_BY_ACTIVE' | 'SORT_BY_MODIFIED_TIME'} [sortBy] Sort users by the given field.   - SORT_BY_UNSPECIFIED: Returns users in an unsorted list.  - SORT_BY_DISPLAY_NAME: Returns users sorted by display name.  - SORT_BY_USER_NAME: Returns users sorted by user name.  - SORT_BY_ADMIN: Returns users sorted by if they are admin.  - SORT_BY_ACTIVE: Returns users sorted by if they are active.  - SORT_BY_MODIFIED_TIME: Returns users sorted by modified time.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order users in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of projects before returning results. Negative values denote number of projects to skip from the end before returning results.
         * @param {number} [limit] Limit the number of projects. A value of 0 denotes no limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_DISPLAY_NAME' | 'SORT_BY_USER_NAME' | 'SORT_BY_ADMIN' | 'SORT_BY_ACTIVE' | 'SORT_BY_MODIFIED_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, options?: any) {
            return UsersApiFp(configuration).getUsers(sortBy, orderBy, offset, limit, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Patch a user's fields.
         * @param {number} userId The id of the user.
         * @param {V1PatchUser} body The updated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser(userId: number, body: V1PatchUser, options?: any) {
            return UsersApiFp(configuration).patchUser(userId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create a new user.
         * @param {V1PostUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser(body: V1PostUserRequest, options?: any) {
            return UsersApiFp(configuration).postUser(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Patch a user's settings for website
         * @param {V1PostUserSettingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSetting(body: V1PostUserSettingRequest, options?: any) {
            return UsersApiFp(configuration).postUserSetting(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Reset a user's settings for website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserSetting(options?: any) {
            return UsersApiFp(configuration).resetUserSetting(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Set the requested user's password.
         * @param {number} userId The id of the user.
         * @param {string} body The password of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPassword(userId: number, body: string, options?: any) {
            return UsersApiFp(configuration).setUserPassword(userId, body, options)(fetch, basePath);
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Get the requested user.
     * @param {number} userId The id of the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(userId: number, options?: any) {
        return UsersApiFp(this.configuration).getUser(userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a user's settings for website
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserSetting(options?: any) {
        return UsersApiFp(this.configuration).getUserSetting(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of users.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_DISPLAY_NAME' | 'SORT_BY_USER_NAME' | 'SORT_BY_ADMIN' | 'SORT_BY_ACTIVE' | 'SORT_BY_MODIFIED_TIME'} [sortBy] Sort users by the given field.   - SORT_BY_UNSPECIFIED: Returns users in an unsorted list.  - SORT_BY_DISPLAY_NAME: Returns users sorted by display name.  - SORT_BY_USER_NAME: Returns users sorted by user name.  - SORT_BY_ADMIN: Returns users sorted by if they are admin.  - SORT_BY_ACTIVE: Returns users sorted by if they are active.  - SORT_BY_MODIFIED_TIME: Returns users sorted by modified time.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order users in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of projects before returning results. Negative values denote number of projects to skip from the end before returning results.
     * @param {number} [limit] Limit the number of projects. A value of 0 denotes no limit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_DISPLAY_NAME' | 'SORT_BY_USER_NAME' | 'SORT_BY_ADMIN' | 'SORT_BY_ACTIVE' | 'SORT_BY_MODIFIED_TIME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, options?: any) {
        return UsersApiFp(this.configuration).getUsers(sortBy, orderBy, offset, limit, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Patch a user's fields.
     * @param {number} userId The id of the user.
     * @param {V1PatchUser} body The updated user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchUser(userId: number, body: V1PatchUser, options?: any) {
        return UsersApiFp(this.configuration).patchUser(userId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create a new user.
     * @param {V1PostUserRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postUser(body: V1PostUserRequest, options?: any) {
        return UsersApiFp(this.configuration).postUser(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Patch a user's settings for website
     * @param {V1PostUserSettingRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postUserSetting(body: V1PostUserSettingRequest, options?: any) {
        return UsersApiFp(this.configuration).postUserSetting(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Reset a user's settings for website
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetUserSetting(options?: any) {
        return UsersApiFp(this.configuration).resetUserSetting(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Set the requested user's password.
     * @param {number} userId The id of the user.
     * @param {string} body The password of the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setUserPassword(userId: number, body: string, options?: any) {
        return UsersApiFp(this.configuration).setUserPassword(userId, body, options)(this.fetch, this.basePath);
    }

}

/**
 * WorkspacesApi - fetch parameter creator
 * @export
 */
export const WorkspacesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveWorkspace(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling archiveWorkspace.');
            }
            const localVarPath = `/api/v1/workspaces/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspace(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteWorkspace.');
            }
            const localVarPath = `/api/v1/workspaces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the requested workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspace(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getWorkspace.');
            }
            const localVarPath = `/api/v1/workspaces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get projects associated with a workspace.
         * @param {number} id The id of the workspace. When id is set to 0, return all projects across the all workspaces.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_EXPERIMENT_START_TIME' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_ID'} [sortBy] Sort the projects by the given field.   - SORT_BY_UNSPECIFIED: Returns projects in an unsorted list.  - SORT_BY_CREATION_TIME: Returns projects sorted by time that they were created.  - SORT_BY_LAST_EXPERIMENT_START_TIME: Returns projects sorted by most recent start of an experiment.  - SORT_BY_NAME: Returns projects sorted by name.  - SORT_BY_DESCRIPTION: Returns projects sorted by description.  - SORT_BY_ID: Returns projects sorted by ID.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order projects in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of projects before returning results. Negative values denote number of projects to skip from the end before returning results.
         * @param {number} [limit] Limit the number of projects. A value of 0 denotes no limit.
         * @param {string} [name] Limit the projects to those matching the name.
         * @param {boolean} [archived] Limit the projects to those with an archived status.
         * @param {Array<string>} [users] Limit the projects to those from particular users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceProjects(id: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_EXPERIMENT_START_TIME' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_ID', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, archived?: boolean, users?: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getWorkspaceProjects.');
            }
            const localVarPath = `/api/v1/workspaces/{id}/projects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of workspaces.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_NAME'} [sortBy] Sort the workspaces by the given field.   - SORT_BY_UNSPECIFIED: Returns workspaces in an unsorted list.  - SORT_BY_ID: Returns workspaces sorted by id.  - SORT_BY_NAME: Returns workspaces sorted by name.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order workspaces in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of workspaces before returning results. Negative values denote number of workspaces to skip from the end before returning results.
         * @param {number} [limit] Limit the number of workspaces. A value of 0 denotes no limit.
         * @param {string} [name] Limit the workspaces to those matching the name.
         * @param {boolean} [archived] Limit the workspaces to those with an archived status.
         * @param {Array<string>} [users] Limit the workspaces to those from particular users.
         * @param {boolean} [pinned] Limit the workspaces to those with pinned status by the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaces(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_NAME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, archived?: boolean, users?: Array<string>, pinned?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/workspaces`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (pinned !== undefined) {
                localVarQueryParameter['pinned'] = pinned;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a workspace.
         * @param {number} id The id of the workspace.
         * @param {V1PatchWorkspace} body The desired workspace fields and values to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorkspace(id: number, body: V1PatchWorkspace, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchWorkspace.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling patchWorkspace.');
            }
            const localVarPath = `/api/v1/workspaces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PatchWorkspace" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pin a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinWorkspace(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pinWorkspace.');
            }
            const localVarPath = `/api/v1/workspaces/{id}/pin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a workspace.
         * @param {V1PostWorkspaceRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspace(body: V1PostWorkspaceRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postWorkspace.');
            }
            const localVarPath = `/api/v1/workspaces`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1PostWorkspaceRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unarchive a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveWorkspace(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unarchiveWorkspace.');
            }
            const localVarPath = `/api/v1/workspaces/{id}/unarchive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unpin a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpinWorkspace(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unpinWorkspace.');
            }
            const localVarPath = `/api/v1/workspaces/{id}/unpin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspacesApi - functional programming interface
 * @export
 */
export const WorkspacesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveWorkspace(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1ArchiveWorkspaceResponse> {
            const localVarFetchArgs = WorkspacesApiFetchParamCreator(configuration).archiveWorkspace(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspace(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1DeleteWorkspaceResponse> {
            const localVarFetchArgs = WorkspacesApiFetchParamCreator(configuration).deleteWorkspace(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the requested workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspace(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetWorkspaceResponse> {
            const localVarFetchArgs = WorkspacesApiFetchParamCreator(configuration).getWorkspace(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get projects associated with a workspace.
         * @param {number} id The id of the workspace. When id is set to 0, return all projects across the all workspaces.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_EXPERIMENT_START_TIME' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_ID'} [sortBy] Sort the projects by the given field.   - SORT_BY_UNSPECIFIED: Returns projects in an unsorted list.  - SORT_BY_CREATION_TIME: Returns projects sorted by time that they were created.  - SORT_BY_LAST_EXPERIMENT_START_TIME: Returns projects sorted by most recent start of an experiment.  - SORT_BY_NAME: Returns projects sorted by name.  - SORT_BY_DESCRIPTION: Returns projects sorted by description.  - SORT_BY_ID: Returns projects sorted by ID.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order projects in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of projects before returning results. Negative values denote number of projects to skip from the end before returning results.
         * @param {number} [limit] Limit the number of projects. A value of 0 denotes no limit.
         * @param {string} [name] Limit the projects to those matching the name.
         * @param {boolean} [archived] Limit the projects to those with an archived status.
         * @param {Array<string>} [users] Limit the projects to those from particular users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceProjects(id: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_EXPERIMENT_START_TIME' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_ID', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, archived?: boolean, users?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetWorkspaceProjectsResponse> {
            const localVarFetchArgs = WorkspacesApiFetchParamCreator(configuration).getWorkspaceProjects(id, sortBy, orderBy, offset, limit, name, archived, users, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a list of workspaces.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_NAME'} [sortBy] Sort the workspaces by the given field.   - SORT_BY_UNSPECIFIED: Returns workspaces in an unsorted list.  - SORT_BY_ID: Returns workspaces sorted by id.  - SORT_BY_NAME: Returns workspaces sorted by name.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order workspaces in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of workspaces before returning results. Negative values denote number of workspaces to skip from the end before returning results.
         * @param {number} [limit] Limit the number of workspaces. A value of 0 denotes no limit.
         * @param {string} [name] Limit the workspaces to those matching the name.
         * @param {boolean} [archived] Limit the workspaces to those with an archived status.
         * @param {Array<string>} [users] Limit the workspaces to those from particular users.
         * @param {boolean} [pinned] Limit the workspaces to those with pinned status by the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaces(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_NAME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, archived?: boolean, users?: Array<string>, pinned?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1GetWorkspacesResponse> {
            const localVarFetchArgs = WorkspacesApiFetchParamCreator(configuration).getWorkspaces(sortBy, orderBy, offset, limit, name, archived, users, pinned, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update a workspace.
         * @param {number} id The id of the workspace.
         * @param {V1PatchWorkspace} body The desired workspace fields and values to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorkspace(id: number, body: V1PatchWorkspace, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PatchWorkspaceResponse> {
            const localVarFetchArgs = WorkspacesApiFetchParamCreator(configuration).patchWorkspace(id, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Pin a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinWorkspace(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PinWorkspaceResponse> {
            const localVarFetchArgs = WorkspacesApiFetchParamCreator(configuration).pinWorkspace(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create a workspace.
         * @param {V1PostWorkspaceRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspace(body: V1PostWorkspaceRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1PostWorkspaceResponse> {
            const localVarFetchArgs = WorkspacesApiFetchParamCreator(configuration).postWorkspace(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unarchive a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveWorkspace(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1UnarchiveWorkspaceResponse> {
            const localVarFetchArgs = WorkspacesApiFetchParamCreator(configuration).unarchiveWorkspace(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unpin a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpinWorkspace(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<V1UnpinWorkspaceResponse> {
            const localVarFetchArgs = WorkspacesApiFetchParamCreator(configuration).unpinWorkspace(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * WorkspacesApi - factory interface
 * @export
 */
export const WorkspacesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Archive a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveWorkspace(id: number, options?: any) {
            return WorkspacesApiFp(configuration).archiveWorkspace(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspace(id: number, options?: any) {
            return WorkspacesApiFp(configuration).deleteWorkspace(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the requested workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspace(id: number, options?: any) {
            return WorkspacesApiFp(configuration).getWorkspace(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get projects associated with a workspace.
         * @param {number} id The id of the workspace. When id is set to 0, return all projects across the all workspaces.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_EXPERIMENT_START_TIME' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_ID'} [sortBy] Sort the projects by the given field.   - SORT_BY_UNSPECIFIED: Returns projects in an unsorted list.  - SORT_BY_CREATION_TIME: Returns projects sorted by time that they were created.  - SORT_BY_LAST_EXPERIMENT_START_TIME: Returns projects sorted by most recent start of an experiment.  - SORT_BY_NAME: Returns projects sorted by name.  - SORT_BY_DESCRIPTION: Returns projects sorted by description.  - SORT_BY_ID: Returns projects sorted by ID.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order projects in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of projects before returning results. Negative values denote number of projects to skip from the end before returning results.
         * @param {number} [limit] Limit the number of projects. A value of 0 denotes no limit.
         * @param {string} [name] Limit the projects to those matching the name.
         * @param {boolean} [archived] Limit the projects to those with an archived status.
         * @param {Array<string>} [users] Limit the projects to those from particular users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceProjects(id: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_EXPERIMENT_START_TIME' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_ID', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, archived?: boolean, users?: Array<string>, options?: any) {
            return WorkspacesApiFp(configuration).getWorkspaceProjects(id, sortBy, orderBy, offset, limit, name, archived, users, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a list of workspaces.
         * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_NAME'} [sortBy] Sort the workspaces by the given field.   - SORT_BY_UNSPECIFIED: Returns workspaces in an unsorted list.  - SORT_BY_ID: Returns workspaces sorted by id.  - SORT_BY_NAME: Returns workspaces sorted by name.
         * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order workspaces in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
         * @param {number} [offset] Skip the number of workspaces before returning results. Negative values denote number of workspaces to skip from the end before returning results.
         * @param {number} [limit] Limit the number of workspaces. A value of 0 denotes no limit.
         * @param {string} [name] Limit the workspaces to those matching the name.
         * @param {boolean} [archived] Limit the workspaces to those with an archived status.
         * @param {Array<string>} [users] Limit the workspaces to those from particular users.
         * @param {boolean} [pinned] Limit the workspaces to those with pinned status by the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaces(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_NAME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, archived?: boolean, users?: Array<string>, pinned?: boolean, options?: any) {
            return WorkspacesApiFp(configuration).getWorkspaces(sortBy, orderBy, offset, limit, name, archived, users, pinned, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update a workspace.
         * @param {number} id The id of the workspace.
         * @param {V1PatchWorkspace} body The desired workspace fields and values to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorkspace(id: number, body: V1PatchWorkspace, options?: any) {
            return WorkspacesApiFp(configuration).patchWorkspace(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Pin a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinWorkspace(id: number, options?: any) {
            return WorkspacesApiFp(configuration).pinWorkspace(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create a workspace.
         * @param {V1PostWorkspaceRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspace(body: V1PostWorkspaceRequest, options?: any) {
            return WorkspacesApiFp(configuration).postWorkspace(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unarchive a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveWorkspace(id: number, options?: any) {
            return WorkspacesApiFp(configuration).unarchiveWorkspace(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unpin a workspace.
         * @param {number} id The id of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpinWorkspace(id: number, options?: any) {
            return WorkspacesApiFp(configuration).unpinWorkspace(id, options)(fetch, basePath);
        },
    };
};

/**
 * WorkspacesApi - object-oriented interface
 * @export
 * @class WorkspacesApi
 * @extends {BaseAPI}
 */
export class WorkspacesApi extends BaseAPI {
    /**
     * 
     * @summary Archive a workspace.
     * @param {number} id The id of the workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public archiveWorkspace(id: number, options?: any) {
        return WorkspacesApiFp(this.configuration).archiveWorkspace(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a workspace.
     * @param {number} id The id of the workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public deleteWorkspace(id: number, options?: any) {
        return WorkspacesApiFp(this.configuration).deleteWorkspace(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the requested workspace.
     * @param {number} id The id of the workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspace(id: number, options?: any) {
        return WorkspacesApiFp(this.configuration).getWorkspace(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get projects associated with a workspace.
     * @param {number} id The id of the workspace. When id is set to 0, return all projects across the all workspaces.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_EXPERIMENT_START_TIME' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_ID'} [sortBy] Sort the projects by the given field.   - SORT_BY_UNSPECIFIED: Returns projects in an unsorted list.  - SORT_BY_CREATION_TIME: Returns projects sorted by time that they were created.  - SORT_BY_LAST_EXPERIMENT_START_TIME: Returns projects sorted by most recent start of an experiment.  - SORT_BY_NAME: Returns projects sorted by name.  - SORT_BY_DESCRIPTION: Returns projects sorted by description.  - SORT_BY_ID: Returns projects sorted by ID.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order projects in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of projects before returning results. Negative values denote number of projects to skip from the end before returning results.
     * @param {number} [limit] Limit the number of projects. A value of 0 denotes no limit.
     * @param {string} [name] Limit the projects to those matching the name.
     * @param {boolean} [archived] Limit the projects to those with an archived status.
     * @param {Array<string>} [users] Limit the projects to those from particular users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspaceProjects(id: number, sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_CREATION_TIME' | 'SORT_BY_LAST_EXPERIMENT_START_TIME' | 'SORT_BY_NAME' | 'SORT_BY_DESCRIPTION' | 'SORT_BY_ID', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, archived?: boolean, users?: Array<string>, options?: any) {
        return WorkspacesApiFp(this.configuration).getWorkspaceProjects(id, sortBy, orderBy, offset, limit, name, archived, users, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a list of workspaces.
     * @param {'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_NAME'} [sortBy] Sort the workspaces by the given field.   - SORT_BY_UNSPECIFIED: Returns workspaces in an unsorted list.  - SORT_BY_ID: Returns workspaces sorted by id.  - SORT_BY_NAME: Returns workspaces sorted by name.
     * @param {'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC'} [orderBy] Order workspaces in either ascending or descending order.   - ORDER_BY_UNSPECIFIED: Returns records in no specific order.  - ORDER_BY_ASC: Returns records in ascending order.  - ORDER_BY_DESC: Returns records in descending order.
     * @param {number} [offset] Skip the number of workspaces before returning results. Negative values denote number of workspaces to skip from the end before returning results.
     * @param {number} [limit] Limit the number of workspaces. A value of 0 denotes no limit.
     * @param {string} [name] Limit the workspaces to those matching the name.
     * @param {boolean} [archived] Limit the workspaces to those with an archived status.
     * @param {Array<string>} [users] Limit the workspaces to those from particular users.
     * @param {boolean} [pinned] Limit the workspaces to those with pinned status by the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspaces(sortBy?: 'SORT_BY_UNSPECIFIED' | 'SORT_BY_ID' | 'SORT_BY_NAME', orderBy?: 'ORDER_BY_UNSPECIFIED' | 'ORDER_BY_ASC' | 'ORDER_BY_DESC', offset?: number, limit?: number, name?: string, archived?: boolean, users?: Array<string>, pinned?: boolean, options?: any) {
        return WorkspacesApiFp(this.configuration).getWorkspaces(sortBy, orderBy, offset, limit, name, archived, users, pinned, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update a workspace.
     * @param {number} id The id of the workspace.
     * @param {V1PatchWorkspace} body The desired workspace fields and values to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public patchWorkspace(id: number, body: V1PatchWorkspace, options?: any) {
        return WorkspacesApiFp(this.configuration).patchWorkspace(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Pin a workspace.
     * @param {number} id The id of the workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public pinWorkspace(id: number, options?: any) {
        return WorkspacesApiFp(this.configuration).pinWorkspace(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create a workspace.
     * @param {V1PostWorkspaceRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public postWorkspace(body: V1PostWorkspaceRequest, options?: any) {
        return WorkspacesApiFp(this.configuration).postWorkspace(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unarchive a workspace.
     * @param {number} id The id of the workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public unarchiveWorkspace(id: number, options?: any) {
        return WorkspacesApiFp(this.configuration).unarchiveWorkspace(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unpin a workspace.
     * @param {number} id The id of the workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public unpinWorkspace(id: number, options?: any) {
        return WorkspacesApiFp(this.configuration).unpinWorkspace(id, options)(this.fetch, this.basePath);
    }

}

